import React, { useState } from 'react';
import { notification, Select, Form, Input, Card, Flex, Grid } from 'antd';
import { optionsRoles } from 'constants/constants';
import { withTranslation } from 'react-i18next';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import { editUser, editUserPassword } from 'repository/users/UserRepository';

const Option = Select.Option;

const { useBreakpoint } = Grid;

const UserEdit = ({ ...props }) => {
  const [loading, setLoading] = useState(false);

  const { name, surname, email, agentCode, role, username } = props.location.state.user;

  const screens = useBreakpoint();

  const onFinishEditUser = (values) => {
    setLoading(true);
    editUser(props.currentCenter.name, username, values)
      .then(() => {
        setLoading(false);
        notification['success']({
          message: 'ZinkinData',
          description: props.t('users.edit.success'),
        });

        props.history.push('/users');
      })
      .catch(() => {
        setLoading(false);
        notification['error']({
          message: 'ZinkinData',
          description: props.t('users.edit.error'),
        });
      });
  };

  const changePassword = (values) => {
    setLoading(true);
    editUserPassword(props.currentCenter.name, username, values.password)
      .then(() => {
        setLoading(false);
        notification['success']({
          message: 'ZinkinData',
          description: props.t('users.edit.password.success'),
        });

        props.history.push('/users');
      })
      .catch((error) => {
        setLoading(false);

        notification['error']({
          message: 'ZinkinData',
          description: props.t('users.edit.password.error'),
        });
      });
  };

  return (
    <Flex
      style={{ width: '100%' }}
      gap={'middle'}
      vertical={!screens.lg}
    >
      <Card
        style={{ width: screens.lg ? '65%' : '100%' }}
        title={props.t('users.edit.title')}
      >
        <Form
          layout="horizontal"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          onFinish={onFinishEditUser}
        >
          <Form.Item
            label={props.t('users.create.form.name')}
            name="name"
            initialValue={name}
            rules={[
              { required: true, message: props.t('users.create.handle.name.required') },
              { min: 3, message: props.t('users.create.handle.name.min') },
            ]}
          >
            <Input
              disabled={loading}
              placeholder={props.t('users.create.form.name-placeholder')}
              size="large"
            />
          </Form.Item>

          <Form.Item
            label={props.t('users.create.form.surnames')}
            name="surname"
            initialValue={surname}
            rules={[{ min: 3, message: props.t('users.create.handle.surnames.min') }]}
          >
            <Input
              disabled={loading}
              placeholder={props.t('users.create.form.surnames-placeholder')}
              size="large"
            />
          </Form.Item>

          <Form.Item
            label={props.t('users.create.form.email')}
            name="email"
            initialValue={email}
            rules={[
              { required: true, message: props.t('users.create.handle.email.required') },
              {
                type: 'email',
                message: props.t('users.create.handle.email.error'),
              },
            ]}
          >
            <Input
              disabled={loading}
              placeholder={props.t('users.create.form.email-placeholder')}
              size="large"
            />
          </Form.Item>

          <Form.Item
            label={props.t('users.create.form.agent-code')}
            name="agentCode"
            initialValue={agentCode}
            rules={[
              { required: true, message: props.t('users.create.handle.agent-code.required') },
              { min: 5, message: props.t('users.create.handle.agent-code.equals') },
              { max: 5, message: props.t('users.create.handle.agent-code.equals') },
            ]}
          >
            <Input
              disabled={loading}
              placeholder={props.t('users.create.form.agent-code-placeholder')}
              size="large"
            />
          </Form.Item>

          <Form.Item
            label={props.t('users.create.form.role')}
            initialValue={role}
            name="role"
            rules={[{ required: true, message: props.t('users.create.handle.role.required') }]}
          >
            <Select
              size="large"
              disabled={loading}
              placeholder={props.t('users.create.form.role-placeholder')}
            >
              {optionsRoles.map((roles, key) => {
                return (
                  <Option
                    key={key}
                    value={roles.value}
                  >
                    {roles.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <ButtonPrimary
            shape="round"
            size="large"
            htmlType="submit"
            loading={loading}
            style={{ float: 'right' }}
          >
            {props.t('profile.password.update')}
          </ButtonPrimary>
        </Form>
      </Card>
      <Card
        style={{ width: screens.lg ? '35%' : '100%' }}
        title={props.t('users.edit.set-password.title')}
      >
        <Form
          layout="vertical"
          onFinish={changePassword}
        >
          <Form.Item
            name="password"
            label={props.t('profile.password.new')}
            hasFeedback
            rules={[
              {
                required: true,
                message: props.t('profile.password.password-required'),
              },
              () => ({
                validator(_, value) {
                  if (value.length < 6) {
                    return Promise.reject(new Error(props.t('users.edit.handle.password-pattern')));
                  }

                  const patron = new RegExp(
                    '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&\\-_=+#~.,])[A-Za-z\\d@$!%*?&\\-_=+#~.,]+$'
                  );
                  if (!patron.test(value)) {
                    return Promise.reject(new Error(props.t('users.edit.handle.password-pattern')));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirm"
            label={props.t('profile.password.confirm')}
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: props.t('profile.password.password-required'),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(props.t('users.create.handle.password.confirm-password')));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <ButtonPrimary
            color="black"
            shape="round"
            size="large"
            htmlType="submit"
            style={{ width: '100%' }}
          >
            {props.t('profile.password.update')}
          </ButtonPrimary>
        </Form>
      </Card>
    </Flex>
  );
};
export default withTranslation('common')(UserEdit);

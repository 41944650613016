import { API_BASE_URL, getFetchOptions } from 'constants/constants.js';

export async function getWhiteListEntries(zoneId, validity, licensePlate, status, username, temporality, centerName) {
  let pathZoneId = '';
  let path_validity = '';
  let path_license_plate = '';
  let path_status = '';
  let path_username = '';
  let path_temporality = '';

  if (validity !== null && validity !== undefined && validity !== '') {
    path_validity = '&validity=' + validity;
  }
  if (zoneId !== null && zoneId !== undefined && zoneId !== '') {
    pathZoneId = '&zone-id=' + zoneId;
  }
  if (licensePlate !== null && licensePlate !== undefined && licensePlate !== '') {
    path_license_plate = '&license-plate=' + licensePlate;
  }
  if (status !== null && status !== undefined && status !== '') {
    path_status = '&status=' + status;
  }
  if (username !== null && username !== undefined && username !== '') {
    path_username = '&username=' + username;
  }
  if (temporality !== null && temporality !== undefined && temporality !== '') {
    path_temporality = '&temporality=' + temporality;
  }

  const url = `${API_BASE_URL}/${centerName}/whitelist-entries?${pathZoneId}${path_validity}${path_license_plate}${path_status}${path_username}${path_temporality}`;
  const headers = getFetchOptions('GET');
  const response = await fetch(url, headers);

  if (response.status !== 200) {
    throw new Error('Error intern de servidor');
  }

  const data = await response.json();

  return data;
}

import { API_BASE_URL, ACCESS_TOKEN, getFetchOptions, getFetchOptionsNoAuth } from 'constants/constants.js';

export async function getCurrentUser(centerName) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject('No access token set.');
  }

  const url = `${API_BASE_URL}/${centerName}/users/me`;
  const options = getFetchOptions('GET');

  const response = await fetch(url, options);
  if (response.status !== 200) {
    throw new Error('Error intern de servidor');
  }
  return await response.json();
}

export async function resetPassword(email) {
  const url = `${API_BASE_URL}/users/reset-password?email=${email}`;
  const headers = getFetchOptionsNoAuth('POST');

  const response = await fetch(url, headers);
  if (response.status !== 200) throw new Error('Error intern de servidor');
}

export async function getUserListByCenter(centerName) {
  const url = `${API_BASE_URL}/admin/${centerName}/users`;
  const headers = getFetchOptions('GET');

  const response = await fetch(url, headers);
  if (response.status !== 200) throw new Error('Error intern de servidor');
  return await response.json();
}

export async function createUser(centerName, json) {
  const url = `${API_BASE_URL}/admin/${centerName}/users`;
  const headers = getFetchOptions('POST');

  const response = await fetch(url, Object.assign({}, headers, { body: JSON.stringify(json) }));
  if (response.status !== 201) throw new Error('Error intern de servidor');
}

export async function editPassword(centerName, json) {
  const url = `${API_BASE_URL}/${centerName}/users/password`;
  const headers = getFetchOptions('PUT');

  const response = await fetch(url, Object.assign({}, headers, { body: JSON.stringify(json) }));
  switch (response.status) {
    case 404:
      throw new Error('El usuario no existe');
    case 400:
      throw new Error('La contraseña no puede ser la misma que la caducada');
    default:
      return;
  }
}

export async function editLanguage(centerName, language) {
  const url = `${API_BASE_URL}/${centerName}/users/language?language=${language}`;
  const headers = getFetchOptions('PUT');

  const response = await fetch(url, headers);
  if (response.status !== 200) throw new Error('Error intern de servidor');
}

export async function editUser(centerName, username, json) {
  const url = `${API_BASE_URL}/admin/${centerName}/users/${username}`;
  const headers = getFetchOptions('PUT');

  const response = await fetch(url, Object.assign({}, headers, { body: JSON.stringify(json) }));
  if (response.status !== 200) throw new Error('Error intern de servidor');
}

export async function editUserPassword(centerName, username, password) {
  const url = `${API_BASE_URL}/admin/${centerName}/users/${username}/password`;
  const headers = getFetchOptions('PUT');

  const response = await fetch(url, Object.assign({}, headers, { body: JSON.stringify({ password: password }) }));
  if (response.status !== 200) throw new Error('Error intern de servidor');
}

export async function deleteUser(centerName, username) {
  const url = `${API_BASE_URL}/admin/${centerName}/users/${username}`;
  const headers = getFetchOptions('DELETE');

  const response = await fetch(url, headers);
  if (response.status !== 204) throw new Error('Error intern de servidor');
}

export async function changeUserEnable(userId, center, value) {
  const url = `${API_BASE_URL}/admin/${center}/users/${userId}/enable?value=${value}`;
  const headers = getFetchOptions('PUT');

  const response = await fetch(url, headers);
  if (response.status !== 200) throw new Error('Error intern de servidor');
}

export async function changeUserMfa(centerName, value) {
  const url = `${API_BASE_URL}/${centerName}/users/mfa?value=${value}`;
  const headers = getFetchOptions('PUT');

  const response = await fetch(url, headers);
  if (response.status !== 200) throw new Error('Error intern de servidor');
}

export async function resetUserCredentials(centerName, userId) {
  const url = `${API_BASE_URL}/admin/${centerName}/users/${userId}/reset`;
  const headers = getFetchOptions('PUT');

  const response = await fetch(url, headers);
  if (response.status !== 200) throw new Error('Error intern de servidor');
}

export async function verifyEmail(userId, code) {
  const url = `${API_BASE_URL}/users/${userId}/verify?code=${code}`;
  const headers = getFetchOptionsNoAuth('POST');

  const response = await fetch(url, headers);
  if (response.status !== 200) throw new Error('Error intern de servidor');
}

import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { MdCamera, MdCarCrash, MdTraffic, MdPhoneIphone, MdDoNotDisturbOn } from 'react-icons/md';
import { LuFileVideo2 } from 'react-icons/lu';
import { FaTruck } from 'react-icons/fa';
import { Flex, Card } from 'antd';

const Home = ({ ...props }) => {
  const homeMenuSections = [
    {
      icon: MdCamera,
      name: 'sidebar.readings',
      category: 'readings',
      path: '/readings',
    },
    {
      icon: MdCarCrash,
      name: 'sidebar.zar-violations',
      category: 'zar-violations',
      path: '/zar-violations',
    },
    {
      icon: MdTraffic,
      name: 'sidebar.red-light-violation',
      category: 'red-light-violation',
      path: '/red-light-violation',
    },
    {
      icon: MdPhoneIphone,
      name: 'sidebar.mobile-infractions',
      category: 'mobile-infractions',
      path: '/mobile-infractions',
    },
    {
      icon: MdDoNotDisturbOn,
      name: 'sidebar.opposite-direction-violation',
      category: 'opposite-direction-violation',
      path: '/opposite-direction-violation',
    },
    {
      icon: FaTruck,
      name: 'sidebar.galibo-control',
      category: 'galibo-control',
      path: '/gauge-control-violation',
    },
    {
      icon: LuFileVideo2,
      name: 'sidebar.bwc-reports',
      category: 'bwc-reports',
      path: '/bwc-reports',
    },
  ];

  const isHiddenForUser = (category) => {
    return (
      props.currentUser.role === 'ROLE_USER' &&
      (category === 'audit-logs' || category === 'zones' || category === 'cameras' || category === 'users')
    );
  };

  const isHiddenForReporter = (category) => {
    return props.currentUser.role === 'ROLE_REPORTER' && category !== 'reports';
  };

  const isModuleIncludedInCenter = (sectionName) => {
    const { infractionTypes } = props.currentCenter;

    return (
      (sectionName === 'sidebar.readings' && !infractionTypes.includes('ALPR')) ||
      (sectionName === 'sidebar.zar-violations' && !infractionTypes.includes('ZAR')) ||
      (sectionName === 'sidebar.bwc-reports' && !infractionTypes.includes('BWC_REPORTS')) ||
      (sectionName === 'sidebar.red-light-violation' && !infractionTypes.includes('RED_LIGHT')) ||
      (sectionName === 'sidebar.mobile-infractions' && !infractionTypes.includes('MOBILE_INFRACTION')) ||
      (sectionName === 'sidebar.opposite-direction-violation' && !infractionTypes.includes('OPPOSITE_DIRECTION')) ||
      (sectionName === 'sidebar.galibo-control' && !infractionTypes.includes('GAUGE_CONTROL'))
    );
  };

  const renderDashboard = () => {
    return homeMenuSections.map((section, index) => {
      if (
        isHiddenForUser(section.category) ||
        isHiddenForReporter(section.category) ||
        isModuleIncludedInCenter(section.name)
      ) {
        return null;
      }

      return (
        <Link
          to={section.path}
          key={index}
        >
          <Card style={{ width: 350 }}>
            <Flex
              align="center"
              justify="center"
              vertical
              gap={'middle'}
            >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div
                  style={{
                    borderRadius: '100%',
                    backgroundColor: '#353535',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 70,
                    height: 70,
                    fontSize: 35,
                    padding: 15,
                  }}
                >
                  <section.icon style={{ color: 'white' }}></section.icon>
                </div>
              </div>
              <span style={{ fontSize: 20 }}>{props.t(section.name)}</span>
            </Flex>
          </Card>
        </Link>
      );
    });
  };

  return (
    <Flex
      gap={'middle'}
      wrap
      justify="center"
    >
      {renderDashboard()}
    </Flex>
  );
};

export default withTranslation('common')(Home);

import React, { useState } from 'react';
import { Space, Table, notification, Dropdown, Flex } from 'antd';
import { PROFILE } from 'constants/constants';
import { MdFolderZip, MdUndo } from 'react-icons/md';
import { TiExport } from 'react-icons/ti';
import { DownOutlined } from '@ant-design/icons';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import { putOppositeDirectionViolationStatus } from 'repository/oppositeDirectionViolation/OppositeDirectionViolationRepository';
import { generateWTPZipFile } from 'repository/violations/ViolationsRepository';
import moment from 'moment';

export const OppositeDirectionViolationsApproved = ({
  data,
  t,
  currentCenter,
  initialColumns,
  fetchViolations,
  setLoading,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);

  const columns = [
    {
      title: t('opposite-direction-violation.tabs.approved.actions'),
      dataIndex: 'acciones',
      width: '200px',
      align: 'center',
      render: (text, record) => {
        return (
          <Flex
            justify="center"
            align="center"
            vertical
          >
            <ButtonPrimary
              color="black"
              size="large"
              shape="round"
              style={{
                margin: '5px 0px 5px 0px',
                width: '100%',
              }}
              onClick={() => exportViolations([record.id])}
            >
              {t('opposite-direction-violation.tabs.approved.export')}
              <TiExport
                style={{
                  verticalAlign: 'middle',
                  fontSize: '20',
                  marginLeft: '5px',
                }}
              />
            </ButtonPrimary>
            <ButtonPrimary
              color="grey"
              size="large"
              shape="round"
              style={{
                margin: '5px 0px 5px 0px',
                width: '100%',
              }}
              onClick={() => {
                setLoading(true);
                putOppositeDirectionViolationStatus([record.id], currentCenter.name, 'PENDING').then(() => {
                  fetchViolations('APPROVED');
                  notification['success']({
                    message: 'ZinkinData',
                    description: t('zar-violations.rollback-violation.success'),
                  });
                  setLoading(false);
                });
              }}
            >
              {t('opposite-direction-violation.tabs.approved.rollback')}
              <MdUndo
                style={{
                  verticalAlign: 'middle',
                  fontSize: '20',
                  marginLeft: '5px',
                }}
              />
            </ButtonPrimary>
          </Flex>
        );
      },
    },
  ];

  function exportViolations(ids) {
    setLoading(true);
    var json = {
      violations: ids,
      filename: `${currentCenter.name}_zar_${moment().valueOf()}`,
      type: 'OPPOSITE_DIRECTION',
    };
    generateWTPZipFile(currentCenter.name, json)
      .then(() => {
        const tempLink = document.createElement('a');
        if (PROFILE === 'development') {
          tempLink.href = `https://dev-lprconnect.s3.eu-west-3.amazonaws.com/wtp/${currentCenter.name}/${json.filename}.zip`;
        } else {
          tempLink.href = `https://lprconnect.s3.eu-west-3.amazonaws.com/wtp/${currentCenter.name}/${json.filename}.zip`;
        }
        tempLink.download = json.filename;
        document.body.appendChild(tempLink);
        tempLink.click();
        fetchViolations('APPROVED');
        notification['success']({
          message: 'ZinkinData',
          description: t('opposite-direction-violation.export.success'),
        });
        setLoading(true);
      })
      .catch(() => {
        notification['error']({
          message: 'ZinkinData',
          description: t('opposite-direction-violation.export.error'),
        });
      });
  }

  const approveButtons = (
    <Space
      direction="horizontal"
      style={{ float: 'right', marginBottom: '15px' }}
    >
      <ButtonPrimary
        color="black"
        shape="round"
        disabled={selectedRows.length === 0}
        onClick={() => {
          exportViolations(selectedRows.map((item) => item.id));
        }}
      >
        {t('zar-violations.menu.approved.export')}
        <MdFolderZip
          style={{
            verticalAlign: 'sub',
            fontSize: '20',
            marginLeft: '5px',
          }}
        />
      </ButtonPrimary>

      <Dropdown
        menu={{
          items: [
            {
              label: (
                <ButtonPrimary
                  color="black"
                  shape="round"
                  onClick={() => {
                    exportViolations(data.map((item) => item.id));
                  }}
                >
                  {t('zar-violations.menu.approved.export-all')}
                </ButtonPrimary>
              ),
              key: '1',
            },
          ],
        }}
      >
        <ButtonPrimary
          color="white"
          fontColor="black"
          shape="round"
        >
          <Space>
            {t('zar-violations.menu.approved.more-options')}
            <DownOutlined />
          </Space>
        </ButtonPrimary>
      </Dropdown>
    </Space>
  );

  const rowSelection = {
    type: 'checkbox',
    selectedRowKeys: selectedRows.map((item) => item.id),
    onChange: (_, selectedRows) => {
      setSelectedRows(selectedRows);
    },
  };

  return (
    <>
      {approveButtons}
      <Table
        bordered
        dataSource={data}
        columns={initialColumns.concat(columns)}
        pagination={{ pageSize: 10 }}
        rowSelection={rowSelection}
      />
    </>
  );
};

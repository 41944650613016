import { API_BASE_URL, getFetchOptions } from 'constants/constants.js';

export async function createCameraHoliday(centerName, json) {
  const url = `${API_BASE_URL}/admin/${centerName}/camera-holidays`;
  const options = getFetchOptions('POST');

  const response = await fetch(url, { ...options, body: JSON.stringify(json) });

  if (response.status !== 201) {
    throw new Error('Error intern de servidor');
  }
}

export async function getCameraHolidays(centerName, id) {
  const url = `${API_BASE_URL}/admin/${centerName}/camera-holidays?camera-id=${id}`;
  const headers = getFetchOptions('GET');

  const response = await fetch(url, headers);

  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    throw new Error('Error intern de servidor');
  }
}

export async function deleteCameraHoliday(centerName, cameraId) {
  const url = `${API_BASE_URL}/admin/${centerName}/camera-holidays/${cameraId}`;
  const headers = getFetchOptions('DELETE');

  const response = await fetch(url, headers);
  if (!response.ok) {
    throw new Error('Error intern de servidor');
  }
}

import { API_BASE_URL, getFetchOptions } from 'constants/constants.js';

export async function getExportDoneById(centerName, id) {
  const url = `${API_BASE_URL}/${centerName}/export-done/${id}`;
  const headers = getFetchOptions('GET');

  const response = await fetch(url, headers);
  if (response.status !== 200) throw new Error('Error intern de servidor');
  return await response.json();
}

export async function getAllExportDoneByCenter(centerName, type) {
  const url = `${API_BASE_URL}/${centerName}/export-done?type=${type}`;
  const headers = getFetchOptions('GET');

  const response = await fetch(url, headers);
  if (response.status !== 200) throw new Error('Error intern de servidor');
  return await response.json();
}

import React, { useState } from 'react';
import { Flex, Table, notification } from 'antd';
import { MdClose, MdUndo } from 'react-icons/md';
import moment from 'moment';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import { pendingGaugeControlViolation } from 'repository/gaugeControlViolations/GaugeControlViolarionRepository';
import { getTraductionByName } from 'constants/denied_reasons';
import { HiMiniSignal } from 'react-icons/hi2';
import { TbCalendar } from 'react-icons/tb';

export const GaugeControlViolationsDenied = ({
  data,
  t,
  currentCenter,
  fetchViolations,
  setLoading,
  initialColumns,
}) => {
  const [pageSize, setPageSize] = useState(10);

  const columns = [
    {
      title: t('gauge-control-violation.tabs.denied.table.gauge-date'),
      dataIndex: 'eventRead',
      key: 'eventRead',
      align: 'center',
      render: (text, record) => {
        return (
          <Flex
            align="center"
            gap={'samll'}
            justify="center"
          >
            <HiMiniSignal
              style={{
                fontSize: '20',
              }}
            />
            {moment(record.readDate).format('DD-MM-YYYY HH:mm:ss')}
          </Flex>
        );
      },
    },
    {
      title: t('gauge-control-violation.tabs.denied.table.read-date'),
      dataIndex: 'readDate',
      key: 'readDate',
      align: 'center',
      render: (text, record) => {
        return (
          <Flex
            align="center"
            gap={'samll'}
            justify="center"
          >
            <TbCalendar
              style={{
                fontSize: '20',
              }}
            />
            {moment(record.readDate).format('DD-MM-YYYY HH:mm:ss')}
          </Flex>
        );
      },
    },
    {
      title: t('gauge-control-violation.tabs.denied.table.deny-date'),
      dataIndex: 'processedDate',
      key: 'processedDate',
      align: 'center',
      render: (text, record) => {
        return (
          <Flex
            align="center"
            gap={'samll'}
            justify="center"
          >
            <MdClose
              style={{
                fontSize: '20',
              }}
            />
            {moment(record.processedDate).format('DD-MM-YYYY HH:mm:ss')}
          </Flex>
        );
      },
    },
    {
      title: t('zar-violations.tabs.denied.deniedReason'),
      dataIndex: 'deniedReason',
      align: 'center',
      render: (text, record) => {
        return t(getTraductionByName(record.deniedReason));
      },
    },
    {
      title: t('zar-violations.tabs.approved.actions'),
      dataIndex: 'acciones',
      align: 'center',
      render: (_, record) => {
        return (
          <Flex
            align="center"
            vertical
            justify="center"
          >
            <ButtonPrimary
              color="grey"
              shape="round"
              size="large"
              style={{
                margin: '5px 0px 5px 0px',
                width: '100%',
              }}
              onClick={() => {
                setLoading(true);
                pendingGaugeControlViolation(currentCenter.name, record.id)
                  .then(() => {
                    fetchViolations('DENIED');
                    notification['success']({
                      message: 'ZinkinData',
                      description: t('zar-violations.pending.success'),
                    });
                    setLoading(false);
                  })
                  .catch(() => {
                    this.setState({ loading: false });
                    notification['error']({
                      message: 'ZinkinData',
                      description: t('zar-violations.pending.error'),
                    });
                  });
              }}
            >
              {t('zar-violations.tabs.denied.rollback')}
              <MdUndo
                style={{
                  verticalAlign: 'sub',
                  fontSize: '20',
                  marginLeft: '5px',
                }}
              />
            </ButtonPrimary>
          </Flex>
        );
      },
    },
  ];

  return (
    <Table
      bordered
      dataSource={data}
      columns={initialColumns.concat(columns)}
      onChange={(e) => setPageSize(e.pageSize)}
      pagination={{ pageSize: pageSize, showSizeChanger: true }}
    />
  );
};

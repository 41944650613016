import React, { useState, useEffect, useRef } from 'react';
import {
  ConfigProvider,
  DatePicker,
  Input,
  Spin,
  Tabs,
  Modal,
  Space,
  notification,
  Tooltip,
  Row,
  Col,
  Table,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { MdRestartAlt, MdSearch, MdZoomIn } from 'react-icons/md';
import dayjs from 'dayjs';
import LicensePlateRender from 'components/LicensePlate/LicensePlateRender';
import { getAllExportDoneByCenter } from 'repository/exportDone/ExportDoneRepository';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import colors from 'components/Buttons/colors';
import ImageVisor from 'components/ImageVisor';
import AccessDenied from 'views/AccessDenied/AccessDenied';
import {
  editGaugeControlViolationLicensePlate,
  getAllGaugeControlViolationByCenterNameAndStatus,
} from 'repository/gaugeControlViolations/GaugeControlViolarionRepository';
import GaugeControlViolationsPending from './components/GaugeControlViolationsPending';
import { GaugeControlViolationsApprove } from './components/GaugeControlViolationsApprove';
import { GaugeControlViolationsDenied } from './components/GaugeControlViolationsDenied';
import { GaugeControlViolationsExported } from './components/GaugeControlViolationsExported';
import { GaugeControlViolationsExportedFiles } from './components/GaugeControlViolationsExportedFiles';
import { IoIosVideocam } from 'react-icons/io';

const GaugeControlViolations = ({ t, currentUser, currentCenter }) => {
  const [violations, setViolations] = useState([]);
  const [exportFiles, setExportFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dateForm, setDateForm] = useState('');
  const [activeTab, setActiveTab] = useState('PENDING');
  const [licensePlateEdit, setLicensePlateEdit] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [countData, setCountData] = useState(0);
  const zoomImageRef = useRef(null);
  const columns = [
    Table.SELECTION_COLUMN,
    Table.EXPAND_COLUMN,
    {
      title: t('gauge-control-violation.table.context-image'),
      dataIndex: 'contextImage',
      width: '200px',
      key: 'contextImage',
      align: 'center',
      render: (text, record) => {
        return (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setLicensePlateEdit(record);
              setModalVisible(true);
            }}
          >
            <ImageVisor
              centername={currentCenter.name}
              image={record.contextImage}
              t={t}
              key={record.id}
            />
          </div>
        );
      },
    },
    {
      title: t('gauge-control-violation.table.license-plate-image'),
      dataIndex: 'licensePlateImage',
      width: '200px',
      key: 'licensePlateImage',
      align: 'center',
      render: (text, record) => {
        return (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setLicensePlateEdit(record);
              setModalVisible(true);
            }}
          >
            <ImageVisor
              centername={currentCenter.name}
              image={record.licensePlateImage}
              isPlate
              t={t}
              key={record.id}
            />
          </div>
        );
      },
    },
    {
      title: t('gauge-control-violation.table.license-plate'),
      dataIndex: 'licensePlate',
      key: 'licensePlate',
      align: 'center',
      render: (text, record) => {
        return <LicensePlateRender value={record.licensePlate} />;
      },
    },
    {
      title: t('gauge-control-violation.table.camera'),
      dataIndex: 'camera',
      key: 'camera',
      align: 'center',
      render: (text, record) => {
        return (
          <>
            <IoIosVideocam
              style={{
                verticalAlign: 'sub',
                fontSize: '20',
                marginRight: '5px',
              }}
            />
            {record.camera}
          </>
        );
      },
    },
  ];

  useEffect(fetchViolations, []);

  function fetchViolations(status = 'PENDING') {
    if (status !== 'EXPORTED_FILES') {
      getAllGaugeControlViolationByCenterNameAndStatus(currentCenter.name, status, dateForm)
        .then((response) => {
          setViolations(
            response.map((violation, key) => ({
              key: violation.id,
              ...violation,
            }))
          );
          setExportFiles([]);
          setCountData(response.length);
          setLoading(false);
        })
        .catch((error) => {
          notification['error']({
            message: 'ZinkinData',
            description: t('zar-violations.get-all-error'),
          });
        });
    } else {
      getAllExportDoneByCenter(currentCenter.name, 'GAUGE_CONTROL')
        .then((response) => {
          setExportFiles(
            response.map((exportedFile) => ({
              key: exportedFile.id,
              ...exportedFile,
            }))
          );
          setViolations([]);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  function editLicensePlateRead() {
    setLoading(true);

    editGaugeControlViolationLicensePlate(currentCenter.name, licensePlateEdit.id, licensePlateEdit.licensePlate)
      .then(() => {
        setLicensePlateEdit({});
        fetchViolations(activeTab);
        setLoading(false);
        notification['success']({
          message: 'ZinkinData',
          description: t('zar-violations.edit-plate.success'),
        });
      })
      .catch((error) => {
        notification['error']({
          message: 'ZinkinData',
          description: t('zar-violations.edit-plate.error'),
        });
      });
  }

  const tabs = [
    {
      key: 'PENDING',
      label:
        t('zar-violations.tabs.pending.title') +
        (activeTab === 'PENDING' ? (countData === 100 ? ` (+99)` : ` (${countData})`) : ''),
      children: (
        <GaugeControlViolationsPending
          data={violations}
          t={t}
          currentCenter={currentCenter}
          fetchViolations={fetchViolations}
          setLoading={setLoading}
          setModalVisible={setModalVisible}
          setLicensePlateEdit={setLicensePlateEdit}
        />
      ),
    },
    {
      key: 'APPROVED',
      label:
        t('zar-violations.tabs.approved.title') +
        (activeTab === 'APPROVED' ? (countData === 100 ? ` (+99)` : ` (${countData})`) : ''),
      children: (
        <GaugeControlViolationsApprove
          data={violations.flatMap((violation) => {
            const eventRead = violation.readDate;
            return violation.violations
              .filter((elemento) => elemento.status === 'APPROVED')
              .map((elemento) => ({ key: elemento.id, eventRead: eventRead, ...elemento }));
          })}
          t={t}
          currentCenter={currentCenter}
          fetchViolations={fetchViolations}
          setLoading={setLoading}
          initialColumns={columns}
        />
      ),
    },
    {
      key: 'DENIED',
      label:
        t('zar-violations.tabs.denied.title') +
        (activeTab === 'DENIED' ? (countData === 100 ? ` (+99)` : ` (${countData})`) : ''),
      children: (
        <GaugeControlViolationsDenied
          t={t}
          currentCenter={currentCenter}
          setLoading={setLoading}
          fetchViolations={fetchViolations}
          initialColumns={columns}
          data={violations.flatMap((violation) => {
            const eventRead = violation.readDate;
            return violation.violations
              .filter((elemento) => elemento.status === 'DENIED')
              .map((elemento) => ({ key: elemento.id, eventRead: eventRead, ...elemento }));
          })}
        />
      ),
    },
    {
      key: 'EXPORTED',
      label:
        t('zar-violations.tabs.exported.title') +
        (activeTab === 'EXPORTED' ? (countData === 100 ? ` (+99)` : ` (${countData})`) : ''),
      children: (
        <GaugeControlViolationsExported
          t={t}
          initialColumns={columns}
          data={violations.flatMap((violation) => {
            const eventRead = violation.readDate;
            return violation.violations
              .filter((elemento) => elemento.status === 'EXPORTED')
              .map((elemento) => ({ key: elemento.id, eventRead: eventRead, ...elemento }));
          })}
          currentCenter={currentCenter}
        />
      ),
    },
    {
      key: 'EXPORTED_FILES',
      label: t('zar-violations.tabs.done.title'),
      children: (
        <GaugeControlViolationsExportedFiles
          t={t}
          data={exportFiles}
          currentCenter={currentCenter}
        />
      ),
    },
  ];

  if (!currentUser.isAdmin() && !currentUser.isBasicUser()) {
    return <AccessDenied t={t} />;
  }

  return (
    <>
      <Spin
        spinning={loading}
        indicator={
          <LoadingOutlined
            style={{ fontSize: 24 }}
            spin
          />
        }
        style={{ fontSize: 20 }}
      >
        <Row
          gutter={16}
          style={{ marginBottom: 20 }}
        >
          <Col span={4}>
            <ConfigProvider locale={currentUser.getLocale()}>
              <DatePicker
                style={{ width: '100%' }}
                size="large"
                onChange={(event) => {
                  setDateForm(moment(event.$d).format('YYYY-MM-DD'));
                }}
                format={'DD/MM/YYYY'}
                value={dateForm !== '' ? dayjs(dateForm) : null}
              ></DatePicker>
            </ConfigProvider>
          </Col>
          <Col span={4}>
            <ButtonPrimary
              color="blue"
              shape="round"
              with="link"
              size="large"
              style={{ width: '100%' }}
              onClick={() => fetchViolations(activeTab)}
            >
              {t('readings.search-button')}
              <MdSearch
                style={{
                  verticalAlign: 'sub',
                  fontSize: '20',
                  marginLeft: '5px',
                }}
              />
            </ButtonPrimary>
          </Col>
          <Col span={4}>
            <ButtonPrimary
              color="black"
              shape="round"
              size="large"
              style={{ width: '100%' }}
              onClick={async () => {
                setLoading(true);
                setDateForm('');
                if (activeTab !== 'EXPORTED_FILES') {
                  getAllGaugeControlViolationByCenterNameAndStatus(currentCenter.name, activeTab, undefined)
                    .then((response) => {
                      setViolations(
                        response.map((violation, key) => ({
                          key: violation.id,
                          ...violation,
                        }))
                      );
                      setExportFiles([]);
                      setCountData(response.length);
                      setLoading(false);
                    })
                    .catch((error) => {
                      notification['error']({
                        message: 'ZinkinData',
                        description: t('zar-violations.get-all-error'),
                      });
                    });
                } else {
                  getAllExportDoneByCenter(currentCenter.name, 'GAUGE_CONTROL')
                    .then((response) => {
                      setExportFiles(
                        response.map((exportedFile) => ({
                          key: exportedFile.id,
                          ...exportedFile,
                        }))
                      );
                      setViolations([]);
                      setLoading(false);
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
              }}
            >
              {t('readings.reset-button')}
              <MdRestartAlt
                style={{
                  verticalAlign: 'sub',
                  fontSize: '20',
                  marginLeft: '5px',
                }}
              />
            </ButtonPrimary>
          </Col>
        </Row>

        <Tabs
          defaultActiveKey={activeTab}
          items={tabs}
          type="card"
          onChange={(key) => {
            setLoading(true);
            if (key !== activeTab) {
              setActiveTab(key);
              fetchViolations(key);
            }
          }}
        />
      </Spin>

      <Modal
        title={t('zar-violations.modal-edit.title')}
        open={modalVisible}
        onOk={() => {
          zoomImageRef.current.resetTransform();
          setModalVisible(false);
          editLicensePlateRead();
        }}
        onCancel={() => {
          zoomImageRef.current.resetTransform();
          setModalVisible(false);
          setLicensePlateEdit({});
        }}
        okText={t('zar-violations.modal-edit.confirm')}
        cancelText={t('zar-violations.modal-edit.cancel')}
        okButtonProps={{ style: { backgroundColor: colors.blue.main } }}
      >
        <Space
          direction="vertical"
          align="center"
          style={{ width: '100%' }}
          wrap
        >
          <TransformWrapper ref={zoomImageRef}>
            <TransformComponent>
              <div>
                <ImageVisor
                  centername={currentCenter.name}
                  t={t}
                  image={licensePlateEdit.contextImage}
                  isModal
                  key={licensePlateEdit.id}
                />
                <div
                  style={{
                    position: 'absolute',
                    top: '8px',
                    left: '8px',
                  }}
                >
                  <Tooltip
                    placement="bottom"
                    title={t('zar-violations.modal-edit.make-zoom')}
                    color="#2db7f5"
                  >
                    <MdZoomIn style={{ fontSize: '30px', color: 'white' }} />
                  </Tooltip>
                </div>
              </div>
            </TransformComponent>
          </TransformWrapper>

          <ImageVisor
            centername={currentCenter.name}
            t={t}
            image={licensePlateEdit.licensePlateImage}
            isModal
            isPlate
            key={licensePlateEdit.id}
          />
          <Input
            size="large"
            style={{ width: '100%' }}
            value={licensePlateEdit.licensePlate}
            onChange={(event) => {
              setLicensePlateEdit({ ...licensePlateEdit, licensePlate: event.target.value });
            }}
          />
        </Space>
      </Modal>
    </>
  );
};

export default withTranslation('common')(GaugeControlViolations);

import { API_BASE_URL, getFetchOptions } from 'constants/constants.js';

export async function createZone(centerName, name, description) {
  const url = `${API_BASE_URL}/admin/${centerName}/zones?name=${name}&description=${description}`;
  const headers = getFetchOptions('POST');

  const response = await fetch(url, headers);
  if (response.status !== 201) throw new Error('Error intern de servidor');
}

export async function editZone(centerName, zoneId, name, description) {
  const url = `${API_BASE_URL}/admin/${centerName}/zones/${zoneId}?name=${name}&description=${description}`;
  const headers = getFetchOptions('PUT');

  const response = await fetch(url, headers);
  if (response.status !== 200) throw new Error('Error intern de servidor');
}

export async function getZonesFromCenter(centerName) {
  const url = `${API_BASE_URL}/${centerName}/zones`;
  const headers = getFetchOptions('GET');

  const response = await fetch(url, headers);
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    throw new Error('Error intern de servidor');
  }
}

export async function getZarZonesFromCenter(centerName) {
  const url = `${API_BASE_URL}/${centerName}/zones/zar`;
  const headers = getFetchOptions('GET');

  const response = await fetch(url, headers);
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    throw new Error('Error intern de servidor');
  }
}

export async function deleteZone(centerName, zoneId) {
  const url = `${API_BASE_URL}/admin/${centerName}/zones/${zoneId}`;
  const headers = getFetchOptions('DELETE');

  try {
    const response = await fetch(url, headers);
    if (response.status === 404) {
      const text = await response.text();
      if (text === 'Zone not found') throw new Error('zones.list.delete.error.zone-not-found');
      if (text === 'User not found') throw new Error('zones.list.delete.error.user-not-found');
    }
  } catch (error) {
    throw new Error(error.message);
  }
}

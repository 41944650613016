import React, { useEffect, useState } from 'react';
import { Col, Flex, Form, Input, Row, Select, Space } from 'antd';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import { MdRestartAlt, MdSearch } from 'react-icons/md';
import { v4 } from 'uuid';
import { getEntitiesFromCenter } from 'repository/specialLicensePlate/SpecialLicensePlateRepository';
import { withTranslation } from 'react-i18next';

const SpecialLicensePlatesFilters = ({ setData, getSpecialLicensePlates, setLoading, ...props }) => {
  const [form] = Form.useForm();
  const [centerEntities, setCenterEntities] = useState([]);

  const getZones = () => {
    getEntitiesFromCenter(props.currentCenter.name).then((response) => {
      setCenterEntities(response);
    });
  };

  useEffect(getZones, []);
  const getFields = () => {
    return (
      <>
        <Col
          xl={8}
          lg={12}
          md={12}
          key={v4()}
        >
          <Form.Item
            name={`centerEntity`}
            label={props.t('whitelist.filters.center-entity')}
          >
            <Select
              size="large"
              placeholder={props.t('whitelist.filters.center-entity-placeholder')}
              allowClear
            >
              {centerEntities.map((entity) => {
                return <Select.Option key={entity.name}>{entity.name}</Select.Option>;
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col
          xl={8}
          md={12}
          lg={12}
          key={v4()}
        >
          <Form.Item
            name={`plate`}
            label={props.t('whitelist.filters.license-plate')}
          >
            <Input
              size="large"
              placeholder={props.t('whitelist.filters.license-plate-placeholder')}
              allowClear
            />
          </Form.Item>
        </Col>
      </>
    );
  };
  const onFinish = (values) => {
    setLoading(true);
    getSpecialLicensePlates(values.centerEntity, values.plate);
  };

  return (
    <Form
      form={form}
      name="advanced_search"
      onFinish={onFinish}
      style={{ paddingBottom: 10 }}
    >
      <Row gutter={24}>{getFields()}</Row>
      <div
        style={{
          textAlign: 'right',
        }}
      >
        <Space size="middle">
          <ButtonPrimary
            type="primary"
            htmlType="submit"
            size="large"
            shape="round"
          >
            <Flex
              justify="center"
              align="center"
              gap={'small'}
            >
              {props.t('whitelist.filters.button')}
              <MdSearch style={{ fontSize: '20' }} />
            </Flex>
          </ButtonPrimary>
          <ButtonPrimary
            color="black"
            size="large"
            shape="round"
            onClick={() => {
              form.resetFields();
              setLoading(true);
              getSpecialLicensePlates();
            }}
          >
            <Flex
              justify="center"
              align="center"
              gap={'small'}
            >
              {props.t('whitelist.filters.reset-button')}
              <MdRestartAlt
                style={{
                  fontSize: '20',
                }}
              />
            </Flex>
          </ButtonPrimary>
        </Space>
      </div>
    </Form>
  );
};

export default withTranslation('common')(SpecialLicensePlatesFilters);

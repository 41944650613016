import React, { useState } from 'react';
import { Table } from 'antd';
import { getTraductionByName } from 'constants/denied_reasons';

export const RedLightViolationsDenied = ({ data, t, initialColumns }) => {
  const [pageSize, setPageSize] = useState(10);
  const columns = [
    {
      title: t('red-light-violation.table.denied-reason'),
      dataIndex: 'deniedReason',
      key: 'deniedReason',
      align: 'center',
      render: (text, record) => {
        return t(getTraductionByName(record.deniedReason));
      },
    },
  ];

  return (
    <>
      <Table
        bordered
        dataSource={data}
        columns={initialColumns.concat(columns)}
        onChange={(e) => setPageSize(e.pageSize)}
        pagination={{ pageSize: pageSize, showSizeChanger: true }}
      />
    </>
  );
};

import { API_BASE_URL, getFetchOptions } from "constants/constants.js";

export async function getAllBlacklistReads(centerName, acknowledge) {
  const url = `${API_BASE_URL}/${centerName}/blacklist-read?acknowledge=${acknowledge}`;
  const headers = getFetchOptions("GET");

  const response = await fetch(url, headers);
  if (response.status !== 200) throw new Error("Error intern de servidor");
  return await response.json();
}

export async function deleteBlacklistRead(centerName, id) {
  const url = `${API_BASE_URL}/${centerName}/blacklist-read?id=${id}`;
  const headers = getFetchOptions("DELETE");

  const response = await fetch(url, headers);
  if (response.status !== 200) throw new Error("Error intern de servidor");
}

export async function acknowledgeBlacklistRead(centerName, id) {
  const url = `${API_BASE_URL}/${centerName}/blacklist-read?id=${id}`;
  const headers = getFetchOptions("PUT");

  const response = await fetch(url, headers);
  if (response.status !== 200) throw new Error("Error intern de servidor");
}

import React from 'react';

import 'leaflet/dist/leaflet.css';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import { TileLayer, Marker, Tooltip, MapContainer } from 'react-leaflet';
import { Icon as MarkerIcon } from 'leaflet';
import { Flex } from 'antd';
import { IoIosVideocam } from 'react-icons/io';
import colors from 'components/Buttons/colors';
import { MdLocationOn } from 'react-icons/md';
import { v4 } from 'uuid';

export const CameraMap = ({ center, cameras }) => {
  return (
    <MapContainer
      center={[center.latitude !== null ? center.latitude : 0, center.longitude !== null ? center.longitude : 0]}
      zoom={15}
      style={{
        height: '500px',
        width: '100%',
      }}
    >
      <TileLayer url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png" />
      {cameras.map((cam) => {
        return (
          <Marker
            key={v4()}
            position={[cam.latitude, cam.longitude]}
            icon={new MarkerIcon({ iconUrl: markerIconPng })}
          >
            <Tooltip sticky>
              <Flex
                justify="center"
                vertical
              >
                <Flex
                  align="center"
                  justify="center"
                  style={{ fontSize: '20px' }}
                  gap={'small'}
                >
                  <IoIosVideocam />
                  {cam.name}
                </Flex>
                <Flex
                  align="center"
                  justify="center"
                  style={{ fontSize: '18px', color: colors.grey.main }}
                  gap={'small'}
                >
                  <MdLocationOn />
                  {cam.zone}
                </Flex>
              </Flex>
            </Tooltip>
          </Marker>
        );
      })}
    </MapContainer>
  );
};

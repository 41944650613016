import { API_BASE_URL, getFetchOptions } from 'constants/constants.js';

export async function postCamera(centerName, cameraJson) {
  const url = `${API_BASE_URL}/admin/${centerName}/cameras`;
  const headers = getFetchOptions('POST');

  const response = await fetch(url, Object.assign({}, headers, { body: JSON.stringify(cameraJson) }));
  if (response.status !== 201) throw new Error('Error intern de servidor');
}

export async function getAllCamaras(centerName) {
  const url = `${API_BASE_URL}/${centerName}/cameras`;
  const headers = getFetchOptions('GET');

  const response = await fetch(url, headers);
  if (response.status !== 200) throw new Error('Error intern de servidor');
  return await response.json();
}

export async function deleteCamara(centerName, cameraId) {
  const url = `${API_BASE_URL}/admin/${centerName}/cameras/${cameraId}`;
  const headers = getFetchOptions('DELETE');

  const response = await fetch(url, headers);
  if (response.status !== 200) throw new Error('Error intern de servidor');
}

export async function editCamera(centerName, cameraId, cameraString) {
  const url = `${API_BASE_URL}/admin/${centerName}/cameras/${cameraId}`;
  const headers = getFetchOptions('PUT');

  const response = await fetch(url, Object.assign({}, headers, { body: JSON.stringify(cameraString) }));
  if (response.status !== 200) throw new Error('Error intern de servidor');
}

import React, { useState } from 'react';
import Icon from '@ant-design/icons';

import { Spin, notification, Input, Space, Typography, Flex, Form } from 'antd';
import { withTranslation } from 'react-i18next';
import { MdSend } from 'react-icons/md';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import AccessDenied from 'views/AccessDenied/AccessDenied';
import { sendSuggeriment } from 'repository/suggestions/SuggestionRepository';
import { BiMailSend } from 'react-icons/bi';

const Suggeriments = ({ ...props }) => {
  const [loading, setLoading] = useState(false);

  if (props.currentUser.role !== 'ROLE_ADMIN' && props.currentUser.role !== 'ROLE_USER') {
    return <AccessDenied t={props.t} />;
  }

  const onFinish = (values) => {
    setLoading(true);
    sendSuggeriment(props.currentCenter.name, values)
      .then(() => {
        notification.success({
          message: 'ZinkinData',
          description: props.t('technical-assistance.suggestions.sended-succesfully-notification'),
        });

        props.history.push('/home');
        setLoading(false);
      })
      .catch((error) => {
        notification.error({
          message: 'ZinkinData',
          description: error.message,
        });
        setLoading(false);
      });
  };

  return (
    <Spin
      spinning={loading}
      indicator={
        <Icon
          type="loading"
          style={{ fontSize: 24 }}
          spin
        />
      }
    >
      <Space
        direction="vertical"
        size={'middle'}
        style={{ width: '100%' }}
      >
        <Typography.Title level={3}>
          <Flex
            align="center"
            gap={'small'}
          >
            <BiMailSend />
            {props.t('technical-assistance.suggestions.title')}
          </Flex>
        </Typography.Title>

        <Form
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            label={props.t('technical-assistance.suggestions.subject')}
            name="subject"
            rules={[{ required: true, message: props.t('technical-assistance.suggestions.empty-fields-notification') }]}
          >
            <Input
              size="large"
              placeholder={props.t('technical-assistance.suggestions.subject-placeholder')}
              disabled={loading}
            />
          </Form.Item>

          <Form.Item
            label={props.t('technical-assistance.suggestions.message')}
            name="message"
            rules={[{ required: true, message: props.t('technical-assistance.suggestions.empty-fields-notification') }]}
          >
            <Input.TextArea
              size="large"
              rows={10}
              placeholder={props.t('technical-assistance.suggestions.message')}
              disabled={loading}
            />
          </Form.Item>
          <ButtonPrimary
            color="blue"
            shape="round"
            size="large"
            htmlType="submit"
            loading={loading}
          >
            <Flex
              align="center"
              gap={'small'}
            >
              {props.t('technical-assistance.suggestions.send')}
              <MdSend
                style={{
                  fontSize: '20',
                }}
              />
            </Flex>
          </ButtonPrimary>
        </Form>
      </Space>
    </Spin>
  );
};

export default withTranslation('common')(Suggeriments);

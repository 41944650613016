import React, { useEffect, useState } from 'react';
import './sidebar.css';
import lprconnectLogo from 'assets/img/ZinkinData_100_25.png';
import { getRoutesByModulesAndUserRole } from 'routes/dashboardRoutes.jsx';
import { withTranslation } from 'react-i18next';
import { ConfigProvider, Flex, Grid, Layout, Menu, Space, Tooltip } from 'antd';
import { MdKeyboardArrowRight } from 'react-icons/md';
import colors from 'components/Buttons/colors';
import CustomAvatar from 'components/CustomAvatar';
import { userRoles } from 'constants/constants';
import Badge from 'components/Badge/Badge';
import { getPendingViolationsCount } from 'repository/violations/ViolationsRepository';

const { Sider } = Layout;
const { useBreakpoint } = Grid;

const CustomSidebar = ({ collapse, setCollapse, pendingZarViolationsCount, ...props }) => {
  const [routesItems, setRouteItems] = useState([]);
  const screens = useBreakpoint();

  const initMenuItems = () => {
    const routes = getRoutesByModulesAndUserRole(props.currentCenter.infractionTypes, props.currentUser.role);
    getPendingViolationsCount(props.currentCenter.name).then((response) => {
      const newRouteItems = routes.map((route) => {
        if (route.hasOwnProperty('collapse')) {
          return {
            label: route.hasOwnProperty('pendingCountKey') ? (
              <Flex
                align="center"
                gap={'small'}
              >
                {props.t(route.name).toUpperCase()}
                <Badge
                  count={response[route.pendingCountKey]}
                  backgroundColor="#ff003d"
                />
              </Flex>
            ) : (
              <>{props.t(route.name).toUpperCase()}</>
            ),
            key: route.path,
            icon: <route.icon style={{ fontSize: 22 }} />,
            children: route.views.map((view) => ({
              key: view.path,
              label: props.t(view.name).toUpperCase(),
              icon: <view.icon style={{ fontSize: 22 }} />,
            })),
          };
        }

        return {
          label: route.hasOwnProperty('pendingCountKey') ? (
            <Flex
              align="center"
              gap={'small'}
            >
              {props.t(route.name).toUpperCase()}
              <Badge
                count={response[route.pendingCountKey]}
                backgroundColor="#ff003d"
              />
            </Flex>
          ) : (
            <>{props.t(route.name).toUpperCase()}</>
          ),
          key: route.path,
          icon: <route.icon style={{ fontSize: 22 }} />,
        };
      });

      setRouteItems(newRouteItems);
    });
  };

  useEffect(initMenuItems, []);

  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      trigger={null}
      collapsed={collapse}
      onCollapse={(value) => setCollapse(value)}
      width={'274px'}
      style={{
        height: '100vh',
        overflow: 'auto',
        position: 'fixed',
        zIndex: 10000,
        backgroundColor: '#222',
      }}
    >
      <Flex
        style={{ width: '266px' }}
        justify="center"
      >
        <img
          src={lprconnectLogo}
          style={{
            width: '60%',
            margin: '15px 0px',
            filter: 'drop-shadow(5px 5px 3px #151515)',
          }}
          alt="lprconnect-logo"
        />
      </Flex>

      <Flex
        justify="center"
        align="center"
        vertical
        gap={5}
        className="editProfile"
        style={{ width: '260px' }}
        onClick={() => {
          props.history.push({
            pathname: '/profile',
          });
          if (!screens.lg) {
            setCollapse(true);
          }
          initMenuItems();
        }}
      >
        {<CustomAvatar src={props.currentCenter.logo} />}
        <Tooltip
          title={props.t('sidebar.see-profile')}
          placement="right"
          color={colors.blue.main}
          overlayStyle={{ zIndex: 11000 }}
        >
          <Space
            align="center"
            className="editProfile"
            style={{ color: 'white', fontSize: '20px' }}
          >
            <span>{props.currentUser.name}</span>
            {props.currentUser.surname != null && (
              <span style={{ fontWeight: 'bold' }}>{props.currentUser.surname.split(' ')[0]}</span>
            )}
            <MdKeyboardArrowRight style={{ fontSize: '20px', verticalAlign: 'middle' }} />
          </Space>
        </Tooltip>
        <span style={{ color: '#777', fontSize: 15 }}>{userRoles[props.currentUser.role]}</span>
      </Flex>
      <ConfigProvider
        theme={{
          components: {
            Menu: {
              darkItemBg: '#222',
              darkSubMenuItemBg: '#111',
              darkItemSelectedBg: '#0064ad',
              itemSelectedColor: '#0064ad',
            },
          },
        }}
      >
        <Menu
          theme="dark"
          mode="inline"
          items={routesItems}
          selectedKeys={[props.location.pathname]}
          style={{ fontSize: '13px', fontFamily: 'Open Sans', fontWeight: 'bold', marginTop: '15px' }}
          onClick={({ key }) => {
            props.history.push(key);
            if (!screens.lg) {
              setCollapse(true);
            }
            initMenuItems();
          }}
        />
      </ConfigProvider>
    </Sider>
  );
};

export default withTranslation('common')(CustomSidebar);

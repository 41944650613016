import React, { useState } from 'react';
import { Form, Input, notification } from 'antd';
import { withTranslation } from 'react-i18next';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import { verifyMfa } from 'repository/auth/AuthRepository';
import { ACCESS_TOKEN } from 'constants/constants';
import { LuCalendarCheck2 } from 'react-icons/lu';
import moment from 'moment';

const VerifyMfaForm = (props) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const onFinish = (values) => {
    setButtonLoading(true);
    verifyMfa(props.userId, values.code)
      .then(() => {
        localStorage.setItem(ACCESS_TOKEN, props.token);
        localStorage.setItem('center', props.center);
        props.onLogin();
        if (props.lastAccessAt !== null) {
          notification['info']({
            message: props.t('login.last-access'),
            description: moment(props.lastAccessAt).format('DD-MM-YYYY HH:mm:ss'),
            icon: <LuCalendarCheck2 style={{ color: '#0085cb' }} />,
            duration: 5,
          });
        }
      })
      .catch((status) => {
        if (status.message === '401') {
          notification['error']({
            message: 'ZinkinData',
            description: props.t('mfa.bad-mfa'),
            duration: 3,
          });
        } else {
          notification['error']({
            message: 'ZinkinData',
            description: 'Server internal error',
            duration: 3,
          });
        }
        setButtonLoading(false);
      });
  };
  return (
    <Form
      layout="vertical"
      size="large"
      onFinish={onFinish}
    >
      <Form.Item name="code">
        <Input placeholder={props.t('mfa.placeholder')} />
      </Form.Item>

      <ButtonPrimary
        color="blue"
        htmlType="submit"
        size="large"
        shape="round"
        style={{ width: '100%' }}
        loading={buttonLoading}
      >
        {props.t('mfa.button')}
      </ButtonPrimary>
    </Form>
  );
};

export default withTranslation('common')(VerifyMfaForm);

import { API_BASE_URL, getFetchOptions } from "constants/constants.js";

export async function getTotalGaugeControlReadings(currentCenter, startDate, endDate) {
  const url =
    API_BASE_URL +
    `/${currentCenter}/reports/gauge-control/readings?start-date=${startDate}&end-date=${endDate}`;
  const headers = getFetchOptions("GET");

  try {
    const response = await fetch(url, headers);
    return await response.json();
  } catch (error) {
    return console.log(error);
  }
}

export async function getTotalGaugeControlViolations(
  currentCenter,
  startDate,
  endDate,
  status
) {
  const url =
    API_BASE_URL +
    `/${currentCenter}/reports/gauge-control/infractions?start-date=${startDate}&end-date=${endDate}&status=${status}`;
  const headers = getFetchOptions("GET");

  try {
    const response = await fetch(url, headers);
    return await response.json();
  } catch (error) {
    return console.log(error);
  }
}

export async function getTotalGaugeControlAffluence(currentCenter, startDate, endDate) {
  const url =
    API_BASE_URL +
    `/${currentCenter}/reports/gauge-control/zones-affluence?start-date=${startDate}&end-date=${endDate}`;
  const headers = getFetchOptions("GET");

  try {
    const response = await fetch(url, headers);
    return await response.json();
  } catch (error) {
    return console.log(error);
  }
}

export async function getGaugeControlViolationPrice(centerName) {
  const url = `${API_BASE_URL}/${centerName}/reports/gauge-control/price`;
  const headers = getFetchOptions("GET")

  try {
    const response = await fetch(url, headers);
    const price = await response.json();
    return price / 100;
  } catch (error) {
    return console.log(error);
  }
}

export async function getGaugeControlViolationDeniedReasons(centerName, startDate, endDate) {
  const url =
    API_BASE_URL +
    `/${centerName}/reports/gauge-control/denied-reasons?start-date=${startDate}&end-date=${endDate}`;
  const headers = getFetchOptions("GET")

  try {
    const response = await fetch(url, headers);
    return await response.json();
  } catch (error) {
    return console.log(error);
  }
}


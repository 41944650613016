import React from 'react'
import Workbook from "react-excel-workbook";

import { useTranslation } from "react-i18next";
import { FaRegFileExcel } from 'react-icons/fa';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';

function CreateZarViolationsExcel({ data }) {
  const {t} = useTranslation('common');
  const { readings, whitelistReadings, violations, exportedViolations, totalIncome, estimatedIncome, affluence } = data;

  function getData() {
    let list = [
      {
        name: t('reports.excel.total-readings') + ':',
        value: String(readings) + " vehicles"
      },
      {
        name: t('reports.excel.total-whitelist-readings') + ':',
        value: String(whitelistReadings) + " vehicles en llista blanca"
      },
      {
        name: t('reports.excel.total-violations') + ':',
        value: String(violations) + " propostes de sanció"
      },
      {
        name: t('reports.excel.total-exported-violations') + ':',
        value: String(exportedViolations) + " infraccions exportades"
      },
      {
        name: t('reports.excel.total-income') + ':',
        value: String(totalIncome) + " €"
      },
      {
        name: t('reports.excel.estimated-income') + ':',
        value: String(estimatedIncome) + " €"
      },
      {
        name: '',
        value: ''
      },
      {
        name: "Percentatge de vehicles en llista blanca:",
        value: String(Number((whitelistReadings/readings)*100).toFixed(2)) + " %"
      },
      {
        name: "Percentatge d'infraccions:",
        value: String(Number((violations/readings)*100).toFixed(2)) + " %"
      },
      {
        name: '',
        value: ''
      },
    ]



    Object.entries(affluence).forEach(entry => {
      list.push(
        {
          name: t('reports.excel.zones-affluence') + ' ' + entry[0] + ':',
          value: String(entry[1].readings) + " vehicles"
        }
      )
    })

    return list;
  }

  return (
    <Workbook
      filename="report.xlsx"
      element={
        <ButtonPrimary color="green" size="middle" style={{width:"100%"}}>
          <FaRegFileExcel
            style={{
              verticalAlign: "sub",
              fontSize: "20",
              marginRight: "5px",
            }}
          />
          Excel
        </ButtonPrimary>
      }
    >
      <Workbook.Sheet data={getData()} name="Sheet A">
        <Workbook.Column label="" value="name" />
        <Workbook.Column label="" value="value" />
      </Workbook.Sheet>
    </Workbook>
  )
}

export default CreateZarViolationsExcel;
import React, { useState } from 'react';
import { Space, Table, Modal, notification, Radio, Collapse, Pagination, Empty, Flex } from 'antd';
import { MdLocationOn, MdOutlineDangerous } from 'react-icons/md';
import { FaCheck } from 'react-icons/fa';
import TextArea from 'antd/es/input/TextArea';
import moment from 'moment';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import { posibleDeniedReasons } from 'constants/denied_reasons';
import { withTranslation } from 'react-i18next';
import {
  approveGaugeControlViolation,
  denyGaugeControlViolation,
} from 'repository/gaugeControlViolations/GaugeControlViolarionRepository';
import { IoIosVideocam } from 'react-icons/io';
import LicensePlateRender from 'components/LicensePlate/LicensePlateRender';
import ImageVisor from 'components/ImageVisor';
import { HiMiniSignal } from 'react-icons/hi2';
import { TbCalendar } from 'react-icons/tb';

const GaugeControlViolationsPending = ({
  data,
  t,
  currentCenter,
  setLoading,
  setLicensePlateEdit,
  setModalVisible,
  fetchViolations,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [deniedModalOpen, setDeniedModalOpen] = useState(false);
  const [deniedReason, setDeniedReason] = useState('');
  const [anotherDeniedReason, setAnotherDeniedReason] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [violationsToDeny, setViolationsToDeny] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);

  const columns = [
    {
      dataIndex: 'contextImageName',
      key: 'contextImageName',
      width: '200px',
      align: 'center',
      render: (text, record) => {
        return (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setLicensePlateEdit(record);
              setModalVisible(true);
            }}
          >
            <ImageVisor
              centername={currentCenter.name}
              image={record.contextImage}
              t={t}
              key={record.id}
            />
          </div>
        );
      },
    },
    {
      dataIndex: 'licensePlateImageName',
      key: 'licensePlateImageName',
      width: '200px',
      align: 'center',
      render: (text, record) => {
        return (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setLicensePlateEdit(record);
              setModalVisible(true);
            }}
          >
            <ImageVisor
              centername={currentCenter.name}
              image={record.licensePlateImage}
              t={t}
              isPlate
              key={record.id}
            />
          </div>
        );
      },
    },
    {
      dataIndex: 'licensePlate',
      key: 'licensePlate',
      align: 'center',
      render: (text, record) => {
        return <LicensePlateRender value={record.licensePlate} />;
      },
    },
    {
      dataIndex: 'readDate',
      key: 'readDate',
      align: 'center',
      render: (text, record) => {
        return (
          <>
            <TbCalendar
              style={{
                verticalAlign: 'sub',
                fontSize: '22',
                marginRight: '5px',
              }}
            />
            {moment(record.readDate).format('DD-MM-YYYY HH:mm:ss')}
          </>
        );
      },
    },
    {
      dataIndex: 'zone',
      key: 'zone',
      align: 'zone',
      render: (text, _) => {
        return (
          <>
            <MdLocationOn
              style={{
                verticalAlign: 'sub',
                fontSize: '22',
                marginRight: '5px',
              }}
            />
            {text}
          </>
        );
      },
    },
    {
      dataIndex: 'camera',
      key: 'camera',
      align: 'center',
      render: (text, _) => {
        return (
          <>
            <IoIosVideocam
              style={{
                verticalAlign: 'sub',
                fontSize: '22',
                marginRight: '5px',
              }}
            />
            {text}
          </>
        );
      },
    },
  ];

  const deniedModalContent = (
    <>
      <Radio.Group
        onChange={(e) => {
          setDeniedReason(e.target.value);
          setAnotherDeniedReason('');
        }}
        style={{ marginTop: 10 }}
        size={'large'}
        value={deniedReason}
      >
        <Space direction="vertical">
          {posibleDeniedReasons.map((reason) => {
            return (
              <Radio
                value={reason.name}
                style={{ fontSize: 15, fontWeight: 'normal' }}
                key={reason.name}
              >
                {t(reason.t)}
              </Radio>
            );
          })}
        </Space>
      </Radio.Group>
      {deniedReason === 'ANOTHER' ? (
        <TextArea
          type="text"
          rows={4}
          value={anotherDeniedReason}
          onChange={(event) => setAnotherDeniedReason(event.target.value)}
          style={{ marginTop: 5, resize: 'none' }}
        />
      ) : null}
    </>
  );

  const renderCollapseItems = () => {
    const startIndex = (currentPage - 1) * 5;
    const endIndex = startIndex + 5;
    const paginatedData = data.slice(startIndex, endIndex);

    return paginatedData.map((item, index) => (
      <Collapse
        defaultActiveKey={[index]}
        key={index}
        items={[
          {
            key: index,
            label: (
              <Flex align="center">
                <HiMiniSignal
                  style={{
                    marginRight: '5px',
                    fontSize: '20px',
                    verticalAlign: 'sub',
                  }}
                />
                {t('gauge-control-violation.tabs.pending.collapse-header')}{' '}
                {moment(item.readDate).format('DD-MM-YYYY HH:mm:ss')}
              </Flex>
            ),
            children: (
              <>
                <Table
                  rowSelection={{
                    type: 'radio',
                    onChange: (selectedRowKeys) => {
                      setSelectedRows([...selectedRows, { index: item.id, key: selectedRowKeys }]);
                    },
                  }}
                  dataSource={item.violations}
                  columns={columns}
                  showHeader={false}
                  pagination={{
                    position: ['none', 'none'],
                  }}
                  rowKey={'id'}
                />
                <Space>
                  <ButtonPrimary
                    color="blue"
                    shape="round"
                    style={{
                      marginTop: '15px',
                    }}
                    disabled={!selectedRows.some((objeto) => objeto['index'] === item.id)}
                    onClick={() => {
                      setLoading(true);
                      approveGaugeControlViolation(
                        currentCenter.name,
                        selectedRows.find((objeto) => objeto['index'] === item.id)['key']
                      )
                        .then(() => {
                          notification['success']({
                            message: 'ZinkinData',
                            description: t('gauge-control-violation.tabs.pending.success-approve'),
                          });
                          setSelectedRows([]);
                          fetchViolations('PENDING');
                          setLoading(false);
                        })
                        .catch(() => {
                          notification['error']({
                            message: 'ZinkinData',
                            description: t('gauge-control-violation.tabs.pending.error-approve'),
                          });
                        });
                    }}
                  >
                    {t('gauge-control-violation.tabs.pending.approve')}
                    <FaCheck
                      style={{
                        verticalAlign: 'sub',
                        fontSize: '20',
                        marginLeft: '5px',
                      }}
                    />
                  </ButtonPrimary>
                  <ButtonPrimary
                    color="black"
                    shape="round"
                    style={{
                      marginTop: '15px',
                    }}
                    onClick={() => {
                      setDeniedModalOpen(true);
                      setViolationsToDeny(item.violations.map((violation) => violation.id));
                    }}
                  >
                    {t('gauge-control-violation.tabs.pending.deny')}
                    <MdOutlineDangerous
                      style={{
                        verticalAlign: 'sub',
                        fontSize: '20',
                        marginLeft: '5px',
                      }}
                    />
                  </ButtonPrimary>
                </Space>
              </>
            ),
          },
        ]}
      />
    ));
  };

  return (
    <Space
      direction="vertical"
      size={'large'}
      style={{ width: '100%' }}
    >
      {data.length === 0 ? (
        <Empty description={t('gauge-control-violation.tabs.pending.empty')} />
      ) : (
        renderCollapseItems()
      )}
      <Pagination
        defaultCurrent={currentPage}
        total={data.length}
        pageSize={5}
        style={{ float: 'right' }}
        onChange={(e) => {
          setCurrentPage(e);
        }}
      />
      <Modal
        open={deniedModalOpen}
        okButtonProps={{ className: 'button-primary-props-blue', shape: 'round', loading: modalLoading }}
        cancelButtonProps={{ className: 'button-primary-props-white', shape: 'round' }}
        onOk={() => {
          setModalLoading(true);
          setLoading(true);
          denyGaugeControlViolation(currentCenter.name, violationsToDeny[0], deniedReason, anotherDeniedReason)
            .then(() => {
              fetchViolations('PENDING');
              notification['success']({
                message: 'ZinkinData',
                description: t('zar-violations.denegate.success'),
              });
              setLoading(false);
              setModalLoading(false);
              setDeniedReason('');
              setAnotherDeniedReason('');
              setDeniedModalOpen(false);
            })
            .catch((error) => {
              setLoading(false);
              setModalLoading(false);
              setDeniedReason('');
              setAnotherDeniedReason('');
              notification['error']({
                message: 'ZinkinData',
                description: t('zar-violations.denegate.error'),
              });
              setDeniedModalOpen(false);
            });
        }}
        onCancel={() => {
          setDeniedReason('');
          setAnotherDeniedReason('');
          setDeniedModalOpen(false);
        }}
        title={t('zar-violations.modal-denied.title')}
        width={'40%'}
      >
        {deniedModalContent}
      </Modal>
    </Space>
  );
};

export default withTranslation('common')(GaugeControlViolationsPending);

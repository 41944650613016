import { Card, Space, Spin, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { MdErrorOutline, MdOutlineVerifiedUser } from 'react-icons/md';
import { verifyEmail } from 'repository/users/UserRepository';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';

const { Title, Paragraph } = Typography;

const VerifiedEmail = (props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const verify = () => {
    verifyEmail(props.match.params.userId, props.match.params.code)
      .then(() => {
        setError(false);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  };

  useEffect(verify, []);

  return (
    <Space
      align="center"
      direction="vertical"
      style={{ width: '100%', marginTop: '10%', textAlign: 'center' }}
    >
      <Card style={{ width: '500px' }}>
        {loading ? (
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 24,
                }}
                spin
              />
            }
            tip="Verificando correo electronico"
          />
        ) : error ? (
          <>
            <MdErrorOutline style={{ color: 'red', fontSize: 120 }} />
            <Title level={3}>¡Error al verificar el correo!</Title>
            <Paragraph style={{ fontSize: 18 }}>
              Vuelve a intentarlo más tarde o en su defecto contacta con tu centro
            </Paragraph>
          </>
        ) : (
          <>
            <MdOutlineVerifiedUser style={{ color: 'green', fontSize: 120 }} />
            <Title level={3}>¡Tu correo electrónico ha sido verificado exitosamente!</Title>
            <Paragraph style={{ fontSize: 18 }}>
              Ya tienes acceso a la plataforma, recibiras un correo electronico con las credenciales temporales.
            </Paragraph>
            <ButtonPrimary
              color="blue"
              size="large"
              onClick={() => props.toLogin()}
            >
              Login
            </ButtonPrimary>
          </>
        )}
      </Card>
    </Space>
  );
};

export default VerifiedEmail;

import React from 'react';
import { Flex, Select } from 'antd';
import axis from 'assets/img/axis.webp';
import vivotek from 'assets/img/vivotek.png';
import genetec from 'assets/img/genetec.png';
import hanwha from 'assets/img/hanwha.webp';
import panasonic from 'assets/img/panasonic.png';
import mobotix from 'assets/img/mobotix.webp';
import presentco from 'assets/img/presentco.jpg';
import milesight from 'assets/img/milesight.webp';
import dahua from 'assets/img/dahua.png';
import ipro from 'assets/img/ipro.webp';
import acti from 'assets/img/acti.png';
import bosch from 'assets/img/bosch.webp';
import generic from 'assets/img/generic.png';

export const camera_brands = [
  {
    name: 'GENERIC',
    logo: generic,
    label: 'Generic',
  },
  {
    name: 'GENETEC',
    logo: genetec,
    label: 'Genetec',
  },
  {
    name: 'AXIS',
    logo: axis,
    label: 'Axis Communication',
  },
  {
    name: 'HANWHA',
    logo: hanwha,
    label: 'Hanwha Vision',
  },
  {
    name: 'VIVOTEK',
    logo: vivotek,
    label: 'Vivotek',
  },
  {
    name: 'PRESENTCO',
    logo: presentco,
    label: 'Presentco',
  },
  {
    name: 'MILESIGHT',
    logo: milesight,
    label: 'Milesight',
  },
  {
    name: 'MOBOTIX',
    logo: mobotix,
    label: 'Mobotix',
  },
  {
    name: 'DAHUA',
    logo: dahua,
    label: 'Dahua',
  },
  {
    name: 'IPRO',
    logo: ipro,
    label: 'i-PRO',
  },
  {
    name: 'PANASONIC',
    logo: panasonic,
    label: 'Panasonic',
  },
  {
    name: 'ACTI',
    logo: acti,
    label: 'ACTi',
  },
  {
    name: 'BOSCH',
    logo: bosch,
    label: 'Bosch',
  },
];

export const getBrandsOptions = () => {
  return camera_brands.map((brand) => {
    return (
      <Select.Option key={brand.name}>
        {brand.logo !== '' ? (
          <img
            alt=""
            style={{ width: '30px', marginRight: '7px' }}
            src={brand.logo}
          />
        ) : (
          ''
        )}
        {brand.label}
      </Select.Option>
    );
  });
};

export const renderBrandWithLogo = (name) => {
  const brand = camera_brands.find((brand) => brand.name === name);
  return (
    <Flex
      align="center"
      justify="center"
      gap={'small'}
    >
      <img
        src={brand.logo}
        alt=""
        style={{ width: '30px' }}
      />
      {brand.label}
    </Flex>
  );
};

import React, { useState } from 'react';
import { Table, Flex, notification } from 'antd';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import { PROFILE } from 'constants/constants';
import { LuDownload, LuFileEdit } from 'react-icons/lu';
import { withTranslation } from 'react-i18next';
import { downloadBodyCamReport } from 'repository/bwcReports/BwcReportsRepository';

const BwcReportsIncomplete = ({ data, t, currentCenter, currentUser, history, initialColumns, setLoading }) => {
  const [pageSize, setPageSize] = useState(10);

  const triggerDownload = (url, filename) => {
    const tempLink = document.createElement('a');
    tempLink.href = url;
    tempLink.target = '_blank';
    tempLink.download = filename;
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
  };

  const handleDownloadClick = async (recordId, centerName) => {
    setLoading(true);
    try {
      const response = await downloadBodyCamReport(recordId, centerName);
      const filename = response.fileName;

      const DEV_URL = 'https://dev-lprconnect.s3.eu-west-3.amazonaws.com/bwc-reports/';
      const PROD_URL = 'https://lprconnect.s3.eu-west-3.amazonaws.com/bwc-reports/';
      const baseUrl = PROFILE === 'development' ? DEV_URL : PROD_URL;
      const downloadUrl = `${baseUrl}${centerName}/temp/${filename}`;
      triggerDownload(downloadUrl, filename);
    } catch (error) {
      notification.error({
        message: 'ZinkinData',
        description: t('bwc-reports.incomplete-reports.error-downloading-report'),
      });
    }
    setLoading(false);
  };

  const columns = [
    {
      title: t('bwc-reports.incomplete-reports.actions'),
      dataIndex: 'acciones',
      width: '200px',
      align: 'center',
      render: (_, record) => {
        const editButton = (
          <ButtonPrimary
            color="black"
            shape="round"
            size="large"
            style={{
              margin: '5px 0px 5px 0px',
              width: '100%',
            }}
            onClick={() => {
              history.push({
                pathname: '/edit-bwc-report',
                state: { report: record, username: currentUser.name, centerName: currentCenter.name },
              });
            }}
          >
            <LuFileEdit
              style={{
                verticalAlign: 'sub',
                fontSize: '20',
                marginRight: '5px',
              }}
            />
            {t('bwc-reports.incomplete-reports.edit-report')}
          </ButtonPrimary>
        );
        const downloadButton = (
          <ButtonPrimary
            color="blue"
            shape="round"
            size="large"
            style={{
              margin: '5px 0px 5px 0px',
              width: '100%',
            }}
            onClick={() => handleDownloadClick(record.id, currentCenter.name)}
          >
            <LuDownload
              style={{
                verticalAlign: 'sub',
                fontSize: '20',
                marginRight: '5px',
              }}
            />
            {t('bwc-reports.incomplete-reports.download-report')}
          </ButtonPrimary>
        );

        const canEdit = record.username === currentUser.username;

        return (
          <Flex
            align="center"
            vertical
          >
            {canEdit && editButton}
            {downloadButton}
          </Flex>
        );
      },
    },
  ];

  return (
    <>
      <Table
        dataSource={data}
        columns={initialColumns.concat(columns)}
        onChange={(e) => setPageSize(e.pageSize)}
        pagination={{ pageSize: pageSize, showSizeChanger: true }}
      />
    </>
  );
};

export default withTranslation('common')(BwcReportsIncomplete);

import { API_BASE_URL, getFetchOptions } from 'constants/constants.js';

export async function getBlacklistReport(centerName) {
  const url = `${API_BASE_URL}/${centerName}/blacklist-report`;
  const headers = getFetchOptions('GET');

  const response = await fetch(url, headers);
  if (response.status !== 200) throw new Error('Error intern de servidor');
  return await response.json();
}

export async function updateBlacklistReport(centerName, requestBody) {
  const url = `${API_BASE_URL}/admin/${centerName}/blacklist-report`;
  const headers = getFetchOptions('PUT');

  const response = await fetch(url, Object.assign({}, headers, { body: JSON.stringify(requestBody) }));
  if (response.status !== 200) throw new Error('Error intern de servidor');
}

export async function updateBlacklistReportEmail(centerName, status) {
  const url = `${API_BASE_URL}/admin/${centerName}/blacklist-report/email?status=${status}`;
  const headers = getFetchOptions('PUT');

  const response = await fetch(url, headers);
  if (response.status !== 200) throw new Error('Error intern de servidor');
}

export async function updateBlacklistReportTelegram(centerName, status) {
  const url = `${API_BASE_URL}/admin/${centerName}/blacklist-report/telegram?status=${status}`;
  const headers = getFetchOptions('PUT');

  const response = await fetch(url, headers);
  if (response.status !== 200) throw new Error('Error intern de servidor');
}

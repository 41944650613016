import { API_BASE_URL, getFetchOptions } from 'constants/constants.js';

export async function getViolationPrice(centerName) {
  const url = API_BASE_URL + '/' + centerName + '/reports/violations/price';
  const headers = getFetchOptions('GET');

  try {
    const response = await fetch(url, headers);
    const price = await response.json();
    return price / 100;
  } catch (error) {
    return console.log(error);
  }
}

export async function getTotalReadings({ currentCenter, startDate, endDate }) {
  const url = API_BASE_URL + '/' + currentCenter + '/reports/readings?start-date=' + startDate + '&end-date=' + endDate;
  const headers = getFetchOptions('GET');

  try {
    const response = await fetch(url, headers);
    const { message } = await response.json();
    return message;
  } catch (error) {
    return console.log(error);
  }
}

export async function getTotalAffluence({ currentCenter, startDate, endDate }) {
  const url =
    API_BASE_URL + '/' + currentCenter + '/reports/zones-affluence?start-date=' + startDate + '&end-date=' + endDate;
  const headers = getFetchOptions('GET');

  try {
    const response = await fetch(url, headers);
    return await response.json();
  } catch (error) {
    return console.log(error);
  }
}

export async function getViolationsCount({ currentCenter, startDate, endDate }) {
  const url =
    API_BASE_URL + '/' + currentCenter + '/reports/violations?start-date=' + startDate + '&end-date=' + endDate;
  const headers = getFetchOptions('GET');

  try {
    const response = await fetch(url, headers);
    const { message } = await response.json();
    return Number(message);
  } catch (error) {
    return console.log(error);
  }
}

export async function getExportedViolationsCount({ currentCenter, startDate, endDate }) {
  const url =
    API_BASE_URL +
    '/' +
    currentCenter +
    '/reports/exported-violations?start-date=' +
    startDate +
    '&end-date=' +
    endDate;
  const headers = getFetchOptions('GET');

  try {
    const response = await fetch(url, headers);
    const { message } = await response.json();
    return Number(message);
  } catch (error) {
    return console.log(error);
  }
}

export async function getEnvironment(centerName, startDate, endDate) {
  const url = `${API_BASE_URL}/${centerName}/reports/environment?start-date=${startDate}&end-date=${endDate}`;
  const headers = getFetchOptions('GET');

  const response = await fetch(url, headers);
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    throw new Error('Error intern de servidor');
  }
}

export async function getZarViolationsPdfFile(centerName, startDate, endDate) {
  const url = `${API_BASE_URL}/${centerName}/mobility-reports/pdf?start-date=${startDate}&end-date=${endDate}`;
  const headers = getFetchOptions('GET');

  const response = await fetch(url, headers);
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    throw new Error('Error intern de servidor');
  }
}

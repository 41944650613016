import React, { useState, useEffect, useRef } from 'react';
import {
  ConfigProvider,
  DatePicker,
  Spin,
  Tabs,
  Modal,
  notification,
  Select,
  Row,
  Col,
  Space,
  Tooltip,
  Input,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { MdRestartAlt, MdSearch, MdZoomIn } from 'react-icons/md';
import dayjs from 'dayjs';
import LicensePlateRender from 'components/LicensePlate/LicensePlateRender';
import { getAllExportDoneByCenter } from 'repository/exportDone/ExportDoneRepository';
import {
  editRedLightViolationLicensePlate,
  getAllRedLightViolationReadingsByCenterIdAndStatus,
} from 'repository/redLightViolation/RedLightViolationRepository';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import { RedLightViolationsApproved } from './components/RedLightViolationsApproved';
import { RedLightViolationsExported } from './components/RedLightViolationsExported';
import { RedLightViolationsDenied } from './components/RedLightViolationsDenied';
import { RedLightViolationsExportedFiles } from './components/RedLightViolationExportedFiles';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import AccessDenied from 'views/AccessDenied/AccessDenied';
import ImageVisor from 'components/ImageVisor';
import colors from 'components/Buttons/colors';

const RedLightViolations = ({ t, currentUser, currentCenter, getLocale }) => {
  const [violations, setViolations] = useState([]);
  const [exportFiles, setExportFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterOption, setFilterOption] = useState('Antiguitat');
  const [dateForm, setDateForm] = useState('');
  const [activeTab, setActiveTab] = useState('APPROVED');
  const [licensePlateEdit, setLicensePlateEdit] = useState({});
  const [countData, setCountData] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);

  const zoomImageRef = useRef(null);
  const columns = [
    {
      title: t('red-light-violation.table.context-image'),
      dataIndex: 'contextImageName',
      key: 'contextImageName',
      align: 'center',
      render: (text, record) => {
        return (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setLicensePlateEdit(record);
              setModalVisible(true);
            }}
          >
            <ImageVisor
              centername={currentCenter.name}
              image={record.imageNames[0]}
              t={t}
              key={record.id}
            />
          </div>
        );
      },
    },
    {
      title: t('red-light-violation.table.license-plate-image'),
      dataIndex: 'licensePlateImageName',
      key: 'licensePlateImageName',
      align: 'center',
      render: (text, record) => {
        return (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setLicensePlateEdit(record);
              setModalVisible(true);
            }}
          >
            <ImageVisor
              centername={currentCenter.name}
              image={record.licensePlateImageName}
              t={t}
              key={record.id}
            />
          </div>
        );
      },
    },
    {
      title: t('red-light-violation.table.license-plate'),
      dataIndex: 'licensePlate',
      key: 'licensePlate',
      align: 'center',
      render: (text) => (
        <span>
          <LicensePlateRender value={text} />
        </span>
      ),
    },
    {
      title: t('red-light-violation.table.read-date'),
      dataIndex: 'readDate',
      key: 'readDate',
      align: 'center',
      render: (text, record) => {
        if (record.readDate !== undefined && record.readDate !== null)
          return moment(record.readDate).format('DD-MM-YYYY HH:mm:ss');
      },
    },
    {
      title: t('red-light-violation.table.camera'),
      dataIndex: 'cameraName',
      key: 'cameraName',
      align: 'center',
    },
  ];

  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 24 }}
      spin
    />
  );

  useEffect(fetchViolations, []);

  function fetchViolations(status = 'APPROVED') {
    if (status !== 'EXPORTS') {
      getAllRedLightViolationReadingsByCenterIdAndStatus(currentCenter.name, status, dateForm)
        .then((response) => {
          setViolations(
            response.map((violation, key) => ({
              key: violation.id,
              ...violation,
            }))
          );
          setExportFiles([]);
          setCountData(response.length);
          setLoading(false);
        })
        .catch(() => {
          notification['error']({
            message: 'ZinkinData',
            description: t('zar-violations.get-all-error'),
          });
        });
    } else {
      getAllExportDoneByCenter(currentCenter.name, 'RED_LIGHT')
        .then((response) => {
          setExportFiles(
            response.map((exportedFile) => ({
              key: exportedFile.id,
              ...exportedFile,
            }))
          );
          setViolations([]);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  function editLicensePlateRead() {
    setLoading(true);

    editRedLightViolationLicensePlate(currentCenter.name, licensePlateEdit.id, licensePlateEdit.licensePlate)
      .then(() => {
        setLicensePlateEdit({});
        fetchViolations(activeTab);
        setLoading(false);
        notification['success']({
          message: 'ZinkinData',
          description: t('zar-violations.edit-plate.success'),
        });
      })
      .catch(() => {
        notification['error']({
          message: 'ZinkinData',
          description: t('zar-violations.edit-plate.error'),
        });
      });
  }

  const tabs = [
    {
      key: 'APPROVED',
      label:
        t('red-light-violation.tabs.approved.title') +
        (activeTab === 'APPROVED' ? (countData === 100 ? ` (+99)` : ` (${countData})`) : ''),
      children: (
        <RedLightViolationsApproved
          currentCenter={currentCenter}
          t={t}
          data={violations}
          fetchViolations={fetchViolations}
          initialColumns={columns}
          setLoading={setLoading}
        />
      ),
    },
    {
      key: 'EXPORTED',
      label:
        t('red-light-violation.tabs.exported.title') +
        (activeTab === 'EXPORTED' ? (countData === 100 ? ` (+99)` : ` (${countData})`) : ''),
      children: (
        <RedLightViolationsExported
          data={violations}
          t={t}
          initialColumns={columns}
        />
      ),
    },
    {
      key: 'DENIED',
      label:
        t('red-light-violation.tabs.denied.title') +
        (activeTab === 'DENIED' ? (countData === 100 ? ` (+99)` : ` (${countData})`) : ''),
      children: (
        <RedLightViolationsDenied
          data={violations}
          t={t}
          initialColumns={columns}
        />
      ),
    },
    {
      key: 'EXPORTS',
      label: t('red-light-violation.tabs.done.title'),
      children: (
        <RedLightViolationsExportedFiles
          data={exportFiles}
          t={t}
          currentCenter={currentCenter}
        />
      ),
    },
  ];

  if (!currentUser.isAdmin() && !currentUser.isBasicUser()) {
    return <AccessDenied t={t} />;
  }

  return (
    <>
      <Spin
        spinning={loading}
        indicator={antIcon}
        style={{ fontSize: 20 }}
      >
        <Row
          gutter={16}
          style={{ marginBottom: 20 }}
        >
          <Col span={4}>
            <Select
              value={filterOption}
              size="large"
              optionFilterProp="children"
              onSelect={(value) => setFilterOption(value)}
            >
              <Select.Option value={'Antiguitat'}>{t('zar-violations.temporality.older')}</Select.Option>
              <Select.Option value={'Data personalitzada'}>{t('zar-violations.temporality.custom')}</Select.Option>
            </Select>
          </Col>

          {filterOption === 'Antiguitat' ? (
            <div></div>
          ) : (
            <Col span={4}>
              <ConfigProvider locale={currentUser.getLocale()}>
                <DatePicker
                  style={{ width: '100%' }}
                  size="large"
                  onChange={(event) => {
                    if (event !== null) {
                      setDateForm(moment(event.$d).format('YYYY-MM-DD'));
                    } else {
                      setDateForm('');
                    }
                  }}
                  format={'DD/MM/YYYY'}
                  value={dateForm !== '' ? dayjs(dateForm) : null}
                ></DatePicker>
              </ConfigProvider>
            </Col>
          )}

          <Col span={4}>
            <ButtonPrimary
              color="blue"
              shape="round"
              with="link"
              size="large"
              style={{ width: '100%' }}
              onClick={() => fetchViolations(activeTab)}
            >
              {t('readings.search-button')}
              <MdSearch
                style={{
                  verticalAlign: 'sub',
                  fontSize: '20',
                  marginLeft: '5px',
                }}
              />
            </ButtonPrimary>
          </Col>
          <Col span={4}>
            <ButtonPrimary
              color="black"
              shape="round"
              size="large"
              style={{ width: '100%' }}
              onClick={async () => {
                setLoading(true);
                setDateForm('');
                setFilterOption('Antiguitat');
                if (activeTab !== 'EXPORTS') {
                  getAllRedLightViolationReadingsByCenterIdAndStatus(currentCenter.name, activeTab, null)
                    .then((response) => {
                      setViolations(
                        response.map((violation, key) => ({
                          key: violation.id,
                          ...violation,
                        }))
                      );
                      setExportFiles([]);
                      setCountData(response.length);
                      setLoading(false);
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                } else {
                  getAllExportDoneByCenter(currentCenter.name, 'RED_LIGHT')
                    .then((response) => {
                      setExportFiles(
                        response.map((exportedFile) => ({
                          key: exportedFile.id,
                          ...exportedFile,
                        }))
                      );
                      setViolations([]);
                      setLoading(false);
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
              }}
            >
              {t('readings.reset-button')}
              <MdRestartAlt
                style={{
                  verticalAlign: 'sub',
                  fontSize: '20',
                  marginLeft: '5px',
                }}
              />
            </ButtonPrimary>
          </Col>
        </Row>
        <Tabs
          defaultActiveKey={activeTab}
          items={tabs}
          type="card"
          onChange={(key) => {
            setLoading(true);
            if (key !== activeTab) {
              setActiveTab(key);
              fetchViolations(key);
            }
          }}
        />
      </Spin>
      <Modal
        title={t('zar-violations.modal-edit.title')}
        open={modalVisible}
        onOk={() => {
          zoomImageRef.current.resetTransform();
          setModalVisible(false);
          editLicensePlateRead();
        }}
        onCancel={() => {
          zoomImageRef.current.resetTransform();
          setModalVisible(false);
          setLicensePlateEdit({});
        }}
        okText={t('zar-violations.modal-edit.confirm')}
        cancelText={t('zar-violations.modal-edit.cancel')}
        okButtonProps={{ className: 'button-primary-props-blue', shape: 'round' }}
        cancelButtonProps={{ className: 'button-primary-props-white', shape: 'round' }}
      >
        {licensePlateEdit &&
          licensePlateEdit.imageNames &&
          licensePlateEdit.licensePlateImageName &&
          licensePlateEdit.imageNames.length > 0 && (
            <Space
              direction="vertical"
              align="center"
              style={{ width: '100%' }}
              wrap
            >
              <TransformWrapper ref={zoomImageRef}>
                <TransformComponent>
                  <div>
                    <ImageVisor
                      centername={currentCenter.name}
                      t={t}
                      image={licensePlateEdit.imageNames[0]}
                      isModal={true}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        top: '8px',
                        left: '8px',
                      }}
                    >
                      <Tooltip
                        placement="bottom"
                        title={t('zar-violations.modal-edit.make-zoom')}
                        color={colors.blue.main}
                      >
                        <MdZoomIn style={{ fontSize: '30px', color: 'white' }} />
                      </Tooltip>
                    </div>
                  </div>
                </TransformComponent>
              </TransformWrapper>
              <ImageVisor
                centername={currentCenter.name}
                t={t}
                image={licensePlateEdit.licensePlateImageName}
                isModal={true}
              />
              <Input
                size="large"
                style={{ width: '100%' }}
                value={licensePlateEdit.licensePlate}
                onChange={(event) => {
                  setLicensePlateEdit({ ...licensePlateEdit, licensePlate: event.target.value });
                }}
              />
            </Space>
          )}
      </Modal>
    </>
  );
};

export default withTranslation('common')(RedLightViolations);

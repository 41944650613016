import React, { useState } from 'react';
import { Space, Table, Modal, notification, Radio, Dropdown, Flex } from 'antd';
import { MdOutlineDangerous } from 'react-icons/md';
import { FaCheck } from 'react-icons/fa';
import TextArea from 'antd/es/input/TextArea';
import { DownOutlined } from '@ant-design/icons';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import { putOppositeDirectionViolationStatus } from 'repository/oppositeDirectionViolation/OppositeDirectionViolationRepository';
import { posibleDeniedReasons } from 'constants/denied_reasons';
import { v4 } from 'uuid';

export const OppositeDirectionViolationsPending = ({
  data,
  t,
  currentCenter,
  setLoading,
  initialColumns,
  fetchViolations,
}) => {
  const [deniedModalOpen, setDeniedModalOpen] = useState(false);
  const [licensePlateEdit, setLicensePlateEdit] = useState({});
  const [deniedReason, setDeniedReason] = useState('');
  const [anotherDeniedReason, setAnotherDeniedReason] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [deniedAllModalOpen, setDeniedAllModalOpen] = useState(false);
  const [violationsToDeny, setViolationsToDeny] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);

  const columns = {
    title: t('opposite-direction-violation.tabs.pending.actions'),
    dataIndex: 'acciones',
    width: '200px',
    align: 'center',
    render: (text, record) => {
      return (
        <Flex
          justify="center"
          align="center"
          vertical
        >
          <ButtonPrimary
            color="blue"
            shape="round"
            size="large"
            style={{
              margin: '5px 0px 5px 0px',
              width: '100%',
            }}
            onClick={() => {
              setLoading(true);
              putOppositeDirectionViolationStatus([record.id], currentCenter.name, 'APPROVED')
                .then(() => {
                  fetchViolations('PENDING');
                  notification['success']({
                    message: 'ZinkinData',
                    description: t('opposite-direction-violation.approve.success'),
                  });
                  setLoading(false);
                })
                .catch((error) => {
                  notification['error']({
                    message: 'ZinkinData',
                    description: t('zar-violations.put-status.error'),
                  });
                });
            }}
          >
            {t('opposite-direction-violation.tabs.pending.approve')}
            <FaCheck
              style={{
                verticalAlign: 'sub',
                fontSize: '15',
                marginLeft: '5px',
              }}
            />
          </ButtonPrimary>
          <ButtonPrimary
            color="black"
            shape="round"
            size="large"
            style={{
              margin: '5px 0px 5px 0px',
              width: '100%',
            }}
            onClick={() => {
              setLicensePlateEdit(record);
              setDeniedModalOpen(true);
            }}
          >
            {t('opposite-direction-violation.tabs.pending.deny')}
            <MdOutlineDangerous
              style={{
                verticalAlign: 'sub',
                fontSize: '20',
                marginLeft: '5px',
              }}
            />
          </ButtonPrimary>
        </Flex>
      );
    },
  };

  function denyAllViolations(ids, deniedReason, anotherDeniedReason) {
    setModalLoading(true);
    setLoading(true);
    putOppositeDirectionViolationStatus(ids, currentCenter.name, 'DENIED', deniedReason, anotherDeniedReason)
      .then(() => {
        setViolationsToDeny([]);
        setDeniedReason('');
        setAnotherDeniedReason('');
        setSelectedRows([]);
        fetchViolations('PENDING');
        notification['success']({
          message: 'ZinkinData',
          description: t('zar-violations.denegate.success'),
        });
        setModalLoading(false);
        setLoading(false);
        setDeniedAllModalOpen(false);
      })
      .catch((error) => {
        notification['error']({
          message: 'ZinkinData',
          description: t('zar-violations.denegate.error'),
        });
        setModalLoading(false);
        setLoading(false);
        setDeniedAllModalOpen(false);
      });
  }

  function approveAllViolations(ids) {
    setLoading(true);
    putOppositeDirectionViolationStatus(ids, currentCenter.name, 'APPROVED')
      .then(() => {
        notification['success']({
          message: 'ZinkinData',
          description: t('zar-violations.put-status.success'),
        });
        setSelectedRows([]);
        fetchViolations();
      })
      .catch((error) => {
        notification['error']({
          message: 'ZinkinData',
          description: t('zar-violations.put-status.error'),
        });
      });
    setLoading(false);
  }

  const pendingButtons = (
    <Space
      direction="horizontal"
      style={{ float: 'right', marginBottom: '15px' }}
    >
      <ButtonPrimary
        color="blue"
        shape="round"
        disabled={!selectedRows.length}
        onClick={() => approveAllViolations(selectedRows.map((cam) => cam.id))}
      >
        {t('zar-violations.menu.pending.approve')}
      </ButtonPrimary>
      <ButtonPrimary
        color="black"
        shape="round"
        disabled={!selectedRows.length}
        onClick={() => {
          setViolationsToDeny(selectedRows);
          setDeniedAllModalOpen(true);
        }}
      >
        {t('zar-violations.menu.pending.deny')}
      </ButtonPrimary>
      <Dropdown
        menu={{
          items: [
            {
              label: (
                <ButtonPrimary
                  color="blue"
                  shape="round"
                  disabled={!data.length}
                  style={{ width: '100%' }}
                  onClick={() => approveAllViolations(data.map((cam) => cam.id))}
                >
                  {t('zar-violations.menu.pending.approve-all')}
                </ButtonPrimary>
              ),
              key: '1',
            },
            {
              label: (
                <ButtonPrimary
                  color="black"
                  shape="round"
                  disabled={!data.length}
                  style={{ width: '100%' }}
                  onClick={() => {
                    setViolationsToDeny(data);
                    setDeniedAllModalOpen(true);
                  }}
                >
                  {t('zar-violations.menu.pending.deny-all')}
                </ButtonPrimary>
              ),
              key: '2',
            },
          ],
        }}
        trigger={['click']}
      >
        <ButtonPrimary
          color="white"
          fontColor="black"
          shape="round"
        >
          <Space>
            {t('zar-violations.menu.pending.more-options')}
            <DownOutlined />
          </Space>
        </ButtonPrimary>
      </Dropdown>
    </Space>
  );

  const rowSelection = {
    type: 'checkbox',
    selectedRowKeys: selectedRows.map((item) => item.id),
    onChange: (_, selectedRows) => {
      setSelectedRows(selectedRows);
    },
  };

  const deniedModalContent = (
    <>
      <Radio.Group
        onChange={(e) => {
          setDeniedReason(e.target.value);
          setAnotherDeniedReason('');
        }}
        style={{ marginTop: 10 }}
        size={'large'}
        value={deniedReason}
      >
        <Space direction="vertical">
          {posibleDeniedReasons.map((reason) => {
            return (
              <Radio
                key={v4()}
                value={reason.name}
                style={{ fontSize: 15, fontWeight: 'normal' }}
              >
                {t(reason.t)}
              </Radio>
            );
          })}
        </Space>
      </Radio.Group>
      {deniedReason === 'ANOTHER' ? (
        <TextArea
          type="text"
          rows={4}
          value={anotherDeniedReason}
          required
          onChange={(event) => setAnotherDeniedReason(event.target.value)}
          style={{ marginTop: 5, resize: 'none' }}
        />
      ) : null}
    </>
  );

  return (
    <>
      {pendingButtons}
      <Table
        bordered
        dataSource={data}
        columns={[...initialColumns, columns]}
        pagination={{ pageSize: 10 }}
        rowSelection={rowSelection}
      />
      <Modal
        open={deniedModalOpen}
        okButtonProps={{ className: 'button-primary-props-blue', shape: 'round', loading: modalLoading }}
        cancelButtonProps={{ className: 'button-primary-props-white', shape: 'round' }}
        onOk={() => {
          setModalLoading(true);
          setLoading(true);
          putOppositeDirectionViolationStatus(
            [licensePlateEdit.id],
            currentCenter.name,
            'DENIED',
            deniedReason,
            anotherDeniedReason
          )
            .then(() => {
              fetchViolations('PENDING');
              notification['success']({
                message: 'ZinkinData',
                description: t('red-light-violation.deny.success'),
              });
              setModalLoading(false);
              setLoading(false);
              setDeniedReason('');
              setAnotherDeniedReason('');
              setDeniedModalOpen(false);
            })
            .catch(() => {
              setModalLoading(false);
              setLoading(false);
              setDeniedReason('');
              setAnotherDeniedReason('');
              setDeniedModalOpen(false);
              notification['error']({
                message: 'ZinkinData',
                description: t('zar-violations.denegate.error'),
              });
            });
        }}
        onCancel={() => {
          setDeniedReason('');
          setAnotherDeniedReason('');
          setDeniedModalOpen(false);
        }}
        title={t('zar-violations.modal-denied.title')}
        width={'40%'}
      >
        {deniedModalContent}
      </Modal>

      <Modal
        open={deniedAllModalOpen}
        onOk={() => {
          setModalLoading(true);
          denyAllViolations(
            violationsToDeny.map((item) => item.id),
            deniedReason,
            anotherDeniedReason
          );
          setDeniedReason('');
          setAnotherDeniedReason('');
        }}
        onCancel={() => {
          setDeniedReason('');
          setAnotherDeniedReason('');
          setDeniedAllModalOpen(false);
        }}
        title={t('zar-violations.modal-denied.title')}
        okButtonProps={{ className: 'button-primary-props-blue', shape: 'round', loading: modalLoading }}
        cancelButtonProps={{ className: 'button-primary-props-white', shape: 'round' }}
        width={'40%'}
      >
        {deniedModalContent}
      </Modal>
    </>
  );
};

import { API_BASE_URL, getFetchOptions } from 'constants/constants.js';

export async function createCameraSchedule(centerName, json) {
  const url = `${API_BASE_URL}/admin/${centerName}/camera-schedules`;
  const options = getFetchOptions('POST');

  const response = await fetch(url, { ...options, body: JSON.stringify(json) });

  if (response.status !== 201) {
    throw new Error('Internal server error');
  }
}

export async function getCameraSchedule(centerName, cameraId) {
  const url = `${API_BASE_URL}/admin/${centerName}/camera-schedules?camera-id=${cameraId}`;
  const options = getFetchOptions('GET');
  const response = await fetch(url, options);

  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    throw new Error('Error intern de servidor');
  }
}

export async function deleteCameraSchedule(centerName, id) {
  const url = `${API_BASE_URL}/admin/${centerName}/camera-schedules/${id}`;
  const options = getFetchOptions('DELETE');

  const response = await fetch(url, options);
  if (!response.ok) {
    throw new Error('Error intern de servidor');
  }
}

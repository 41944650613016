import React from 'react';
import { Layout } from 'antd';
const { Footer } = Layout;
const CustomFooter = ({ transparent = false }) => {
  return (
    <Footer
      style={{
        textAlign: 'right',
        backgroundColor: transparent ? 'transparent' : '#fff',
        color: transparent ? '#DDDDDD' : 'black',
      }}
    >
      &copy; {1900 + new Date().getYear()} ZINKINDATA, made by
      <a
        href="https://www.nexotech.es"
        target="_blank"
        rel="noopener noreferrer"
      >
        {' '}
        NexoTech
      </a>
      .
    </Footer>
  );
};

export default CustomFooter;

import { Image } from 'antd';
import React from 'react';
import EB from 'assets/img/B.svg';
import EC from 'assets/img/C.svg';
import ECO from 'assets/img/ECO.svg';
import E0 from 'assets/img/0.svg';
import './environment.css';

const EnvironmentRender = ({ value }) => {
  switch (value) {
    case '16TB':
      return (
        <Image
          src={EB}
          preview={false}
          width={80}
        />
      );
    case '16TC':
      return (
        <Image
          src={EC}
          preview={false}
          width={80}
        />
      );
    case '16TE':
      return (
        <Image
          src={ECO}
          preview={false}
          width={80}
        />
      );
    case '16T0':
      return (
        <Image
          src={E0}
          preview={false}
          width={80}
        />
      );

    default:
      return <></>;
  }
};

export default EnvironmentRender;

import React, { useState, useEffect, useRef } from 'react';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { Spin, Table, Input, Space, Tabs, Tooltip, notification } from 'antd';
import * as BlacklistRepository from 'repository/blacklist/BlacklistRepository';
import * as BlacklistReadsRepository from 'repository/blacklist/BlacklistReadsRepository';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

import { MdAdd, MdSettings } from 'react-icons/md';
import LicensePlateRender from 'components/LicensePlate/LicensePlateRender';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import ButtonLink from 'components/Buttons/ButtonLink';
import { TbTrashXFilled } from 'react-icons/tb';
import colors from 'components/Buttons/colors';
import BlacklistReadsAcknowledge from './components/BlacklistReadsAcknowledge';
import BlacklistReadsNoAcknowledge from './components/BlacklistReadsNoAcknowledge';

const BlackList = (props) => {
  const [loading, setLoading] = useState(true);
  const [licensePlate, setLicensePlate] = useState('');
  const [data, setData] = useState([]);
  const [searchedColumn, setSearchedColumn] = useState('');
  const currentCenter = props.currentCenter.name;
  const searchInput = useRef(null);

  useEffect(() => {
    BlacklistReadsRepository.getAllBlacklistReads(currentCenter, false)
      .then((response) => {
        setData(response);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, [currentCenter]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={'Matrícula'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <ButtonPrimary
            color="blue"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            <SearchOutlined /> Buscar
          </ButtonPrimary>
          <ButtonPrimary
            color="black"
            onClick={() => handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reiniciar
          </ButtonPrimary>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record.licensePlate ? record.licensePlate.toString().toLowerCase().includes(value.toLowerCase()) : '',
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
  };

  const addPlate = () => {
    setLoading(true);
    BlacklistRepository.postBlacklist(currentCenter, licensePlate)
      .then(() => {
        notification['success']({
          message: 'ZinkinData',
          description: props.t('blacklist.add.success-message'),
        });
        BlacklistRepository.getAllBlacklist(currentCenter)
          .then((response) => {
            setData(response);
            setLoading(false);
            setLicensePlate('');
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => {
        if (error.message === 'Already exists') {
          notification['success']({
            message: 'ZinkinData',
            description: props.t('blacklist.add.license-plate-exists'),
          });
        } else {
          notification['success']({
            message: 'ZinkinData',
            description: error.message,
          });
        }
      });
  };

  const updateTableData = (key) => {
    setLoading(true);
    const fetchData =
      key === '1'
        ? BlacklistReadsRepository.getAllBlacklistReads(currentCenter, false)
        : BlacklistRepository.getAllBlacklist(currentCenter);

    fetchData
      .then((response) => {
        setData(response);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const deleteBlacklist = (id) => {
    setLoading(true);
    BlacklistRepository.deleteBlacklist(currentCenter, id).then(() => {
      notification['success']({
        message: 'ZinkinData',
        description: props.t('blacklist.list.delete-success-message'),
      });
      BlacklistRepository.getAllBlacklist(currentCenter)
        .then((response) => {
          setData(response);
          setLoading(false);
        })
        .catch(() => {
          notification['success']({
            message: 'ZinkinData',
            description: props.t('blacklist.list.delete-error-message'),
          });
        });
    });
  };

  if (props.currentUser.role !== 'ROLE_ADMIN' && props.currentUser.role !== 'ROLE_USER') {
    return (
      <div>
        <h1 style={{ fontWeight: 'bold', paddingLeft: '50px' }}>ACCES DENIED</h1>
      </div>
    );
  } else {
    const antIcon = (
      <LoadingOutlined
        style={{ fontSize: 24 }}
        spin
      />
    );

    const items = [
      {
        key: '1',
        label: props.t('blacklist.tabs.match'),
        children: (
          <div>
            <Tabs
              defaultActiveKey="1"
              destroyInactiveTabPane={true}
              type="card"
              items={[
                {
                  key: '1',
                  label: props.t('blacklist.tabs.no-acknowledge'),
                  children: <BlacklistReadsNoAcknowledge props={props} />,
                },
                {
                  key: '2',
                  label: props.t('blacklist.tabs.acknowledge'),
                  children: <BlacklistReadsAcknowledge props={props} />,
                },
              ]}
            />
          </div>
        ),
      },
      {
        key: '2',
        label: props.t('blacklist.tabs.license-plates'),
        children: (
          <Space
            style={{ width: '100%' }}
            size={'middle'}
            direction="vertical"
          >
            <ButtonPrimary
              color="black"
              shape="round"
              size="large"
              style={{ float: 'right' }}
              onClick={() => {
                props.history.push({
                  pathname: '/lists/blacklist-config',
                });
              }}
            >
              {props.t('blacklist.config.title')}
              <MdSettings
                style={{
                  verticalAlign: 'sub',
                  fontSize: '20',
                  marginLeft: '5px',
                }}
              />
            </ButtonPrimary>
            <Space
              style={{ width: '100%' }}
              size={'middle'}
            >
              <Input
                size="large"
                placeholder={props.t('blacklist.add.license-plate-placeholder')}
                value={licensePlate}
                onChange={(event) => setLicensePlate(event.target.value.toUpperCase())}
              />
              <ButtonPrimary
                color="blue"
                shape="round"
                size="large"
                disabled={licensePlate === ''}
                onClick={addPlate}
              >
                {props.t('blacklist.list.create')}
                <MdAdd
                  style={{
                    verticalAlign: 'sub',
                    fontSize: '20',
                    marginLeft: '5px',
                  }}
                />
              </ButtonPrimary>
            </Space>
            <Table
              bordered
              dataSource={data}
              columns={[
                {
                  title: props.t('blacklist.list.table.license-plate'),
                  dataIndex: 'licensePlate',
                  key: 'licensePlate',
                  align: 'center',
                  ...getColumnSearchProps('licensePlate'),
                  render: (text) => (
                    <span>
                      <LicensePlateRender value={text} />
                    </span>
                  ),
                },
                {
                  title: props.t('blacklist.list.table.created-date'),
                  dataIndex: 'createdDate',
                  key: 'createdDate',
                  align: 'center',
                  render: (text, record) => moment(record.createdDate).format('DD-MM-YYYY HH:mm:ss'),
                },
                {
                  title: props.t('blacklist.list.table.actions'),
                  dataIndex: 'acciones',
                  align: 'center',
                  render: (text, record) => (
                    <ButtonLink
                      color="black"
                      onClick={() => deleteBlacklist(record.id)}
                    >
                      <Tooltip
                        color={colors.blue.main}
                        title={props.t('blacklist.list.delete')}
                      >
                        <TbTrashXFilled style={{ fontSize: '30' }} />
                      </Tooltip>
                    </ButtonLink>
                  ),
                },
              ]}
              pagination={{ pageSize: 10, showSizeChanger: false }}
            />
          </Space>
        ),
      },
    ];

    return (
      <Spin
        spinning={loading}
        indicator={antIcon}
      >
        <Tabs
          defaultActiveKey="1"
          items={items}
          type="card"
          onChange={(key) => updateTableData(key)}
        />
      </Spin>
    );
  }
};

export default withTranslation('common')(BlackList);

import React, { useState, useEffect } from 'react';
import { LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { MdInfoOutline } from 'react-icons/md';
import * as UserRepository from '../../repository/users/UserRepository';
import { Spin, Select, Form, Input, notification, Descriptions, Tooltip, Card, Switch, Flex } from 'antd';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import colors from 'components/Buttons/colors';

const Profile = ({ ...props }) => {
  const [loading, setLoading] = useState(false);
  const [switchLoading, setSwitchLoading] = useState(false);
  const [passwordExpirationTime, setPasswordExpirationTime] = useState(0);
  const error_validation_password_message = props.t('users.edit.handle.password-pattern');
  const confirm_password_message = props.t('users.create.handle.password.confirm-password');

  useEffect(() => {
    setPasswordExpirationTime(Math.ceil(moment(props.currentUser.passwordExpirationDate).diff(moment(), 'days', true)));
  }, [props.currentUser.passwordExpirationDate]);

  const changePassword = (values) => {
    var json = {
      password: values.password,
    };
    UserRepository.editPassword(props.currentCenter.name, json)
      .then(() => {
        setLoading(false);
        notification['success']({
          message: 'ZinkinData',
          description: props.t('profile.update.success'),
        });
        window.location.reload();
      })
      .catch((error) => {
        setLoading(false);
        if (error.message === 'La contraseña no puede ser la misma que la caducada') {
          notification['error']({
            message: 'ZinkinData',
            description: props.t('profile.password.same-password-error'),
          });
        } else {
          notification['error']({
            message: 'ZinkinData',
            description: props.t('profile.update.error'),
          });
        }
      });
  };

  const languages = [
    {
      value: 'es',
      label: 'ES - Español',
      disabled: false,
    },
    {
      value: 'ca',
      label: 'CA - Català',
      disabled: false,
    },
    {
      value: 'ga',
      label: 'GA - Galego',
      disabled: false,
    },
    {
      value: 'eus',
      label: 'EUS - Euskera',
      disabled: false,
    },
  ];

  return (
    <Spin
      spinning={loading}
      indicator={
        <LoadingOutlined
          style={{ fontSize: 24 }}
          spin
        />
      }
    >
      <Flex
        style={{ width: '100%' }}
        gap={'middle'}
      >
        <Card
          title={props.t('profile.me.title')}
          style={{ width: '60%' }}
        >
          <Descriptions
            style={{ marginBottom: '13px' }}
            size={'middle'}
            bordered
          >
            <Descriptions.Item
              span={3}
              label={props.t('profile.me.username')}
            >
              {props.currentUser.username}
            </Descriptions.Item>
            <Descriptions.Item
              span={3}
              label={props.t('profile.me.name')}
            >
              {props.currentUser.name}
            </Descriptions.Item>
            <Descriptions.Item
              span={3}
              label={props.t('profile.me.surnames')}
            >
              {props.currentUser.surname}
            </Descriptions.Item>
            <Descriptions.Item
              span={3}
              label={props.t('profile.me.email')}
            >
              {props.currentUser.email}
            </Descriptions.Item>
            <Descriptions.Item
              span={3}
              label={props.t('profile.me.agent-code')}
            >
              {props.currentUser.agentCode}
            </Descriptions.Item>
            <Descriptions.Item
              span={3}
              label={props.t('profile.me.language')}
            >
              <Select
                size="large"
                defaultValue={props.currentUser.language}
                options={languages}
                onChange={(event) => {
                  UserRepository.editLanguage(props.currentCenter.name, event)
                    .then(() => {
                      window.location.reload();
                    })
                    .catch(() => {});
                }}
              />
            </Descriptions.Item>
            {props.currentUser.email ? (
              <Descriptions.Item
                span={3}
                label={
                  <>
                    {props.t('profile.me.mfa')}
                    <Tooltip
                      color={colors.blue.main}
                      title={props.t('profile.me.mfa-tooltip')}
                      placement="right"
                    >
                      <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                    </Tooltip>
                  </>
                }
              >
                <Switch
                  loading={switchLoading}
                  defaultValue={props.currentUser.mfa}
                  onClick={(event) => {
                    setSwitchLoading(true);
                    UserRepository.changeUserMfa(props.currentCenter.name, event)
                      .then(() => {
                        setSwitchLoading(false);
                      })
                      .catch(() => {});
                  }}
                />
              </Descriptions.Item>
            ) : null}
          </Descriptions>
        </Card>

        <Card
          title={
            <Flex align="center">
              {props.t('profile.password.title')}
              <Tooltip
                color={colors.blue.main}
                placement="bottomLeft"
                title={`${props.t('profile.password.expire-password-text')} ${passwordExpirationTime} ${
                  passwordExpirationTime === 1
                    ? props.t('profile.password.expire-password-day')
                    : props.t('profile.password.expire-password-days')
                }`}
              >
                <MdInfoOutline style={{ marginLeft: '5px', fontSize: '20' }} />
              </Tooltip>
            </Flex>
          }
          style={{ width: '40%' }}
        >
          <Form
            layout="vertical"
            onFinish={changePassword}
          >
            <Form.Item
              name="password"
              label={props.t('profile.password.new')}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: props.t('profile.password.password-required'),
                },
                () => ({
                  validator(_, value) {
                    if (value.length < 6) {
                      return Promise.reject(new Error(error_validation_password_message));
                    }

                    const patron = new RegExp(
                      '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&\\-_=+#~.,])[A-Za-z\\d@$!%*?&\\-_=+#~.,]+$'
                    );
                    if (!patron.test(value)) {
                      return Promise.reject(new Error(error_validation_password_message));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="confirm"
              label={props.t('profile.password.confirm')}
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: props.t('profile.password.password-required'),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(confirm_password_message));
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            <ButtonPrimary
              color="black"
              shape="round"
              size="large"
              htmlType="submit"
              style={{ width: '100%' }}
            >
              {props.t('profile.password.update')}
            </ButtonPrimary>
          </Form>
        </Card>
      </Flex>
    </Spin>
  );
};

export default withTranslation('common')(Profile);

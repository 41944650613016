import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import {
  addConsult,
  addMessage,
  getAllConsults,
  getMessagesByConsultId,
  setStatusConsult,
} from 'repository/consults/ConsultsRepository';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import { Form, Input, List, Modal, Select, Spin, Table, notification } from 'antd';
import moment from 'moment';
import ButtonLink from 'components/Buttons/ButtonLink';
import { TbMessages, TbTrashXFilled } from 'react-icons/tb';
import { MdAdd } from 'react-icons/md';
import AccessDenied from 'views/AccessDenied/AccessDenied';
import colors from 'components/Buttons/colors';

const Consults = ({ ...props }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [messageModalVisible, setMessageModalVisible] = useState(false);
  const [messages, setMessages] = useState({});
  const [selectOption, setSelectOption] = useState('Obert');
  const [newMessage, setNewMessage] = useState('');

  const [form] = Form.useForm();

  const columns = [
    {
      title: props.t('technical-assistance.consults.table.status'),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      sorter: (a, b) => {
        return a.status.localeCompare(b.status);
      },
    },
    {
      title: props.t('technical-assistance.consults.table.title'),
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      sorter: (a, b) => {
        return a.sigla.localeCompare(b.sigla);
      },
    },
    {
      title: props.t('technical-assistance.consults.table.created-at'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      sorter: (a, b) => {
        return moment(a.createdAt, 'DD/MM/YYYY').unix() - moment(b.createdAt, 'DD/MM/YYYY').unix();
      },
    },
    {
      title: props.t('technical-assistance.consults.table.updated-at'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      align: 'center',
      sorter: (a, b) => {
        return moment(a.updatedAt, 'DD/MM/YYYY').unix() - moment(b.updatedAt, 'DD/MM/YYYY').unix();
      },
    },

    {
      title: props.t('technical-assistance.consults.table.messages'),
      dataIndex: 'missatges',
      align: 'center',
      render: (text, record) => {
        return (
          <ButtonLink
            color="grey"
            onClick={() => {
              setLoading(true);
              getMessagesByConsultId(props.currentCenter.name, record.id).then((response) => {
                setMessages(response);
                setMessageModalVisible(true);
                setLoading(false);
              });
            }}
          >
            <TbMessages style={{ fontSize: '30' }} />
          </ButtonLink>
        );
      },
    },
    {
      title: props.t('technical-assistance.consults.table.actions'),
      dataIndex: 'acciones',
      align: 'center',
      render: (text, record) => {
        return (
          <ButtonLink
            color="black"
            onClick={() => {
              Modal.confirm({
                centered: true,
                title: props.t('technical-assistance.consults.close-alert.title'),
                okText: props.t('technical-assistance.consults.close-alert.confirm'),
                cancelText: props.t('technical-assistance.consults.close-alert.cancel'),
                onOk: () => {
                  setStatusConsult(props.currentCenter.name, record.id, 'Tancat')
                    .then(() => {
                      notification.success({
                        message: 'ZinkinData',
                        description: props.t('technical-assistance.consults.close-alert.success-close'),
                      });
                    })
                    .then(() => {
                      getConsults(selectOption);
                    })
                    .catch((error) => {
                      notification.error({
                        message: 'ZinkinData',
                        description: error.message,
                      });
                    });
                },
                okButtonProps: {
                  className: 'button-primary-props-blue',
                  shape: 'round',
                },
                cancelButtonProps: {
                  className: 'button-primary-props-white',
                  shape: 'round',
                },
              });
            }}
          >
            <TbTrashXFilled style={{ fontSize: '30' }} />
          </ButtonLink>
        );
      },
    },
  ];

  const getConsults = (status = 'Obert') => {
    getAllConsults(props.currentCenter.name, status)
      .then((response) => {
        setData(
          response.map((prop, key) => {
            return {
              key: prop.id,
              id: prop.id,
              status: prop.status,
              title: prop.title,
              createdAt: new Intl.DateTimeFormat('es-ES', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
              }).format(new Date(prop.createdAt)),
              updatedAt: new Intl.DateTimeFormat('es-ES', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
              }).format(new Date(prop.updatedAt)),
            };
          })
        );
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(getConsults, []);

  const onFinishCreateForm = (values) => {
    setLoading(true);

    addConsult(props.currentCenter.name, values)
      .then(() => {
        notification.success({
          message: 'ZinkinData',
          description: props.t('technical-assistance.consults.create.success'),
        });

        getConsults(selectOption);
        setModalVisible(false);
      })
      .catch(() => {
        notification.error({
          message: 'ZinkinData',
          description: props.t('technical-assistance.consults.create.error'),
        });
      });
  };

  const sendMessage = () => {
    setLoading(true);
    addMessage(props.currentCenter.name, messages[0].consult, props.currentUser.username, newMessage)
      .then(() => {
        getMessagesByConsultId(props.currentCenter.name, messages[0].consult).then((response) => {
          setNewMessage('');
          setMessages(response);
          setMessageModalVisible(true);
          setLoading(false);
        });
      })
      .catch((error) => {
        notification.error({
          message: 'ZinkinData',
          description: error.message,
        });
      });
  };

  if (!props.currentUser.isAdmin() && !props.currentUser.isBasicUser()) {
    return <AccessDenied t={props.t} />;
  }

  const chatItemStyle = {
    display: 'flex',
    padding: '8px 0',
  };

  const messageStyle = {
    padding: '10px 15px',
    borderRadius: '15px',
    maxWidth: '60%',
  };

  const myMessageStyle = {
    ...messageStyle,
    backgroundColor: colors.blue.main,
    color: 'white',
    textAlign: 'right',
  };

  const otherMessageStyle = {
    ...messageStyle,
    backgroundColor: '#e4e6eb',
    color: 'black',
  };

  const containerStyle = {
    padding: '20px',
    maxWidth: '600px',
    margin: '0 auto',
  };

  const inputContainerStyle = {
    display: 'flex',
    marginTop: '20px',
  };

  return (
    <Spin
      spinning={loading}
      indicator={
        <LoadingOutlined
          type="loading"
          style={{ fontSize: 24 }}
          spin
        />
      }
    >
      <ButtonPrimary
        color="blue"
        shape="round"
        size="large"
        onClick={() => {
          setModalVisible(true);
        }}
        style={{ marginBottom: '15px' }}
      >
        {props.t('technical-assistance.consults.create-consult-btn')}
        <MdAdd
          style={{
            verticalAlign: 'sub',
            fontSize: '20',
            marginLeft: '5px',
          }}
        />
      </ButtonPrimary>
      <Form.Item label={props.t('technical-assistance.consults.filter-text')}>
        <Select
          size="large"
          showSearch
          style={{ width: 200 }}
          onChange={(value) => {
            setLoading(true);
            setSelectOption(value);
            getConsults(value);
          }}
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          defaultValue={'Obert'}
        >
          <Select.Option value="Obert">{props.t('technical-assistance.consults.status-filters.open')}</Select.Option>
          <Select.Option value="Tancat">{props.t('technical-assistance.consults.status-filters.closed')}</Select.Option>
          <Select.Option value="all">
            {props.t('technical-assistance.consults.status-filters.all-status')}
          </Select.Option>
        </Select>
      </Form.Item>

      <Table
        bordered
        dataSource={data}
        columns={columns}
        pagination={{ pageSize: 10 }}
      />
      <Modal
        title={props.t('technical-assistance.consults.update-consult-messages.modal-title')}
        centered
        open={messageModalVisible}
        onCancel={() => setMessageModalVisible(false)}
        footer={<></>}
        destroyOnClose
      >
        <div style={containerStyle}>
          <List
            dataSource={messages}
            renderItem={(message) => (
              <List.Item
                key={message.id}
                style={{
                  ...chatItemStyle,
                  justifyContent: message.sender === props.currentUser.id ? 'flex-end' : 'flex-start',
                }}
              >
                <div style={message.sender === props.currentUser.id ? myMessageStyle : otherMessageStyle}>
                  {message.message}
                </div>
              </List.Item>
            )}
          />
          <div style={inputContainerStyle}>
            <Input
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder={props.t('technical-assistance.consults.update-consult-messages.placeholder')}
              onPressEnter={() => sendMessage()}
            />
            <ButtonPrimary
              type="primary"
              onClick={() => sendMessage()}
              style={{ marginLeft: '10px' }}
            >
              {props.t('technical-assistance.consults.update-consult-messages.btn-send')}
            </ButtonPrimary>
          </div>
        </div>
      </Modal>
      <Modal
        title={props.t('technical-assistance.consults.create.modal-title')}
        open={modalVisible}
        centered
        onCancel={() => setModalVisible(false)}
        okText={props.t('technical-assistance.consults.create.btn-create')}
        cancelText={props.t('technical-assistance.consults.create.btn-cancel')}
        okButtonProps={{ style: { backgroundColor: colors.blue.main }, htmlType: 'submit', autoFocus: true }}
        closable={false}
        destroyOnClose
        modalRender={(dom) => (
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinishCreateForm}
          >
            {dom}
          </Form>
        )}
      >
        <Form.Item
          name="title"
          rules={[{ required: true, message: props.t('technical-assistance.consults.create.form-error') }]}
        >
          <Input
            size="large"
            placeholder={props.t('technical-assistance.consults.create.title-placeholder')}
          />
        </Form.Item>
        <Form.Item
          name="message"
          rules={[{ required: true, message: props.t('technical-assistance.consults.create.form-error') }]}
        >
          <Input.TextArea
            rows={5}
            size="large"
            placeholder={props.t('technical-assistance.consults.create.message-placeholder')}
          />
        </Form.Item>
      </Modal>
    </Spin>
  );
};

export default withTranslation('common')(Consults);

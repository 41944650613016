import { API_BASE_URL, getFetchOptions } from 'constants/constants.js';

export async function getTelegramChannelByCenterAndType(centerName, type) {
    const url = `${API_BASE_URL}/admin/${centerName}/telegram-channel?type=${type}`;
    const headers = getFetchOptions("GET");

    let response;
    try {
        response = await fetch(url, headers);
    } catch (error) {
        console.log(error)
    }
    
    if(!response.ok){
        throw new Error("Error intern al servidor")
    }
    return await response.json()
  }
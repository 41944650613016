import React from 'react';
import './badge.css';

const Badge = ({ count = 0, color = 'white', backgroundColor = '#0064ad' }) => {
  const badgeStyle = {
    zIndex: 10,
    width: 'fit-content',
    minWidth: '20px',
    height: '20px',
    padding: '0 6px',
    color: color,
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '20px',
    textAlign: 'center',
    background: backgroundColor,
    borderRadius: '10px',
  };
  return (
    <div
      hidden={count === 0}
      style={badgeStyle}
    >
      {count}
    </div>
  );
};

export default Badge;

import React, { useState } from 'react';
import { Flex, Space, Table } from 'antd';
import moment from 'moment';
import { PROFILE } from 'constants/constants';
import { MdDownload, MdFolderZip, MdPerson } from 'react-icons/md';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import { TbClockShare } from 'react-icons/tb';

export const RedLightViolationsExportedFiles = ({ t, currentCenter, data }) => {
  const [pageSize, setPageSize] = useState(10);
  const columns = [
    {
      title: t('red-light-violation.tabs.done.exported-date'),
      dataIndex: 'exportedDate',
      key: 'exportedDate',
      align: 'center',
      render: (_, record) => {
        return (
          <Space
            align="center"
            size={'small'}
          >
            <TbClockShare style={{ fontSize: '22' }} />
            <span>{moment(record.exportedDate).format('DD-MM-YYYY HH:mm:ss')}</span>
          </Space>
        );
      },
    },
    {
      title: t('red-light-violation.tabs.done.filename'),
      dataIndex: 'filename',
      key: 'filename',
      align: 'center',
      render: (_, record) => {
        return (
          <Space
            align="start"
            size={'small'}
          >
            <MdFolderZip style={{ fontSize: '22' }} />
            <span>{record.filename}</span>
          </Space>
        );
      },
    },
    {
      title: t('red-light-violation.tabs.done.username'),
      dataIndex: 'username',
      key: 'username',
      align: 'center',
      render: (_, record) => {
        return (
          <Space
            align="start"
            size={'small'}
          >
            <MdPerson style={{ fontSize: '22' }} />
            <span>{record.username}</span>
          </Space>
        );
      },
    },
    {
      title: t('red-light-violation.table.actions'),
      align: 'center',
      render: (_, record) => {
        return (
          <Flex
            justify="center"
            align="center"
          >
            <ButtonPrimary
              color="blue"
              shape="round"
              onClick={() => {
                let url;
                PROFILE === 'development'
                  ? (url = `https://dev-lprconnect.s3.eu-west-3.amazonaws.com/wtp/${currentCenter.name}/${record.filename}`)
                  : (url = `https://lprconnect.s3.eu-west-3.amazonaws.com/wtp/${currentCenter.name}/${record.filename}`);
                window.location.href = url;
              }}
            >
              <MdDownload style={{ fontSize: '25' }} />
            </ButtonPrimary>
          </Flex>
        );
      },
    },
  ];

  return (
    <>
      <Table
        bordered
        dataSource={data}
        columns={columns}
        onChange={(e) => setPageSize(e.pageSize)}
        pagination={{ pageSize: pageSize, showSizeChanger: true }}
      />
    </>
  );
};

import { API_BASE_URL, getFetchOptions } from "constants/constants.js";
import moment from "moment";

export async function getAuditLogs(centerName, resourceType,actionType, startDate, endDate, username, resourceData, pageNumber) {
  var path_resourceType = ""
  var path_actionType = ""
  var path_startDate = ""
  var path_endDate = ""
  var path_username = ""
  var path_resourceData = ""
  var path_pageNumber = ""
  

  if (resourceType !== null && resourceType !== undefined)
    path_resourceType = "&resource-type=" + resourceType

  if (actionType !== null && actionType !== undefined)
    path_actionType = "&action-type=" + actionType

  if (startDate !== null && startDate !== undefined)
    path_startDate = "&start-date=" + moment(startDate).format('YYYY-MM-DD HH:mm:ss').toString()

  if (endDate !== null && endDate !== undefined)
    path_endDate = "&end-date=" + moment(endDate).format('YYYY-MM-DD HH:mm:ss').toString()
  
  if (username !== null && username !== undefined)
    path_username = "&username=" + username
  
  if (resourceData !== null && resourceData !== undefined)
    path_resourceData = "&resource-data=" + resourceData
  
  path_pageNumber = "page=" + pageNumber

  const url = `${API_BASE_URL}/${centerName}/audit-logs?${path_pageNumber}${path_actionType}${path_resourceData}${path_resourceType}${path_startDate}${path_endDate}${path_username}`
  const headers = getFetchOptions("GET");

  try {
    const response = await fetch(url, headers);
    if (response.status === 500) throw new Error("Internal server error");
    return await response.json();
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function getAllActionTypes(centerName) {
  const url = `${API_BASE_URL}/${centerName}/audit-logs/action-types`
  const headers = getFetchOptions("GET");

  try {
    const response = await fetch(url, headers);
    if (response.status === 500) throw new Error("Internal server error");
    return await response.json();
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function getAllResourceTypes(centerName) {
  const url = `${API_BASE_URL}/${centerName}/audit-logs/resource-types`
  const headers = getFetchOptions("GET");

  try {
    const response = await fetch(url, headers);
    if (response.status === 500) throw new Error("Internal server error");
    return await response.json();
  } catch (error) {
    throw new Error(error.message);
  }
}

import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { withTranslation } from 'react-i18next';
import Reports from 'views/Informes/mobility/Reports.jsx';
import GaugeControlReports from './GaugeControlReports';
import OppositeDirectionReports from './OppositeDirectionReports';
import RedLightReport from './RedLightReport';

const MobilityReports = ({ ...props }) => {
  const [items, setItems] = useState([]);

  const getTabsItems = () => {
    let result = [];

    if (props.currentCenter.infractionTypes.includes('ZAR')) {
      result.push({
        key: 'ZAR',
        label: props.t('reports.tabs.zar'),
        children: <Reports {...props} />,
      });
    }

    if (props.currentCenter.infractionTypes.includes('RED_LIGHT')) {
      result.push({
        key: 'RED_LIGHT',
        label: props.t('reports.tabs.red-light-violation'),
        children: <RedLightReport {...props} />,
      });
    }

    if (props.currentCenter.infractionTypes.includes('GAUGE_CONTROL')) {
      result.push({
        key: 'GAUGE_CONTROL',
        label: props.t('reports.tabs.gauge-control'),
        children: <GaugeControlReports {...props} />,
      });
    }

    if (props.currentCenter.infractionTypes.includes('OPPOSITE_DIRECTION')) {
      result.push({
        key: 'OPPOSITE_DIRECTION',
        label: props.t('reports.tabs.opposite-direction'),
        children: <OppositeDirectionReports {...props} />,
      });
    }

    setItems(result);
  };

  useEffect(getTabsItems, []);
  return (
    <Tabs
      type="card"
      items={items}
    />
  );
};

export default withTranslation('common')(MobilityReports);

import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Alert, ConfigProvider, DatePicker, Flex, Form, Input, Modal, notification, Space, Spin, Table } from 'antd';
import ButtonLink from 'components/Buttons/ButtonLink';
import { LoadingOutlined } from '@ant-design/icons';
import { TbTrashXFilled } from 'react-icons/tb';
import { v4 } from 'uuid';
import AccessDenied from 'views/AccessDenied/AccessDenied';
import moment from 'moment';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import { MdAdd } from 'react-icons/md';
import colors from 'components/Buttons/colors';
import {
  createCameraHoliday,
  deleteCameraHoliday,
  getCameraHolidays,
} from 'repository/cameraHoliday/CameraHolidayRepository';

const CameraHoliday = ({ ...props }) => {
  const [loading, setLoading] = useState(true);
  const [cameraHolidays, setCameraHolidays] = useState([]);
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);

  const { id } = props.location.state;

  const getHolidays = () => {
    getCameraHolidays(props.currentCenter.name, id)
      .then((response) => {
        setCameraHolidays(
          response.map((zone, index) => {
            return {
              ...zone,
              key: v4(),
            };
          })
        );
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(getHolidays, []);

  if (!props.currentUser.isAdmin()) {
    return <AccessDenied />;
  }

  const columns = [
    {
      title: props.t('camera.holiday.table.start-date'),
      dataIndex: 'startDate',
      key: 'startDate',
      render: (_, record) => {
        return moment(record.startDate).format('DD-MM-YYYY HH:mm:ss');
      },
    },
    {
      title: props.t('camera.holiday.table.end-date'),
      dataIndex: 'endDate',
      key: 'endDate',
      render: (_, record) => {
        return moment(record.endDate).format('DD-MM-YYYY HH:mm:ss');
      },
    },
    {
      title: props.t('camera.holiday.table.reason'),
      dataIndex: 'reason',
      key: 'reason',
    },
    {
      title: props.t('camera.holiday.table.actions'),
      dataIndex: 'acciones',
      render: (_, record) => {
        return (
          <ButtonLink
            color="black"
            onClick={() => {
              Modal.confirm({
                title: props.t('camera.holiday.warning.title'),
                okText: props.t('camera.holiday.warning.confirm'),
                cancelText: props.t('camera.holiday.warning.cancel'),
                centered: true,
                okButtonProps: {
                  className: 'button-primary-props-blue',
                  shape: 'round',
                },
                cancelButtonProps: {
                  className: 'button-primary-props-white',
                  shape: 'round',
                },
                onOk: () => {
                  deleteCameraHoliday(props.currentCenter.name, record.id)
                    .then(() => {
                      notification.success({
                        message: 'ZinkinData',
                        description: props.t('camera.holiday.delete.success'),
                      });
                      getHolidays();
                    })
                    .catch(() => {
                      notification.error({
                        message: 'ZinkinData',
                        description: props.t('camera.holiday.delete.error'),
                      });
                    });
                },
              });
            }}
          >
            <TbTrashXFilled style={{ fontSize: '30' }} />
          </ButtonLink>
        );
      },
    },
  ];

  const onFinishForm = (values) => {
    setLoading(true);
    const json = {
      id: v4(),
      cameraId: id,
      startDate: moment(values.date[0].$d).format('YYYY-MM-DDTHH:mm:ss').toString(),
      endDate: moment(values.date[1].$d).format('YYYY-MM-DDTHH:mm:ss').toString(),
      reason: values.reason,
    };
    createCameraHoliday(props.currentCenter.name, json)
      .then(() => {
        notification.success({
          message: 'ZinkinData',
          description: props.t('camera.holiday.create.success'),
        });
        getHolidays();
        setLoading(false);
        setOpen(false);
      })
      .catch(() => {
        notification.error({
          message: 'ZinkinData',
          description: props.t('camera.holiday.create.error'),
        });
        setLoading(false);
      });
  };

  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 24 }}
      spin
    />
  );

  return (
    <>
      <Spin
        spinning={loading}
        indicator={antIcon}
      >
        <Space
          style={{ width: '100%' }}
          size={'middle'}
          direction="vertical"
        >
          <Alert
            message={props.t('camera.holiday.render.alert.message')}
            description={props.t('camera.holiday.render.alert.description')}
            type="info"
            showIcon
            closable
          />
          <ButtonPrimary
            color="blue"
            shape="round"
            size="large"
            onClick={() => setOpen(true)}
          >
            <Flex
              align="center"
              gap={'small'}
            >
              {props.t('camera.holiday.render.button')}
              <MdAdd
                style={{
                  fontSize: '20',
                }}
              />
            </Flex>
          </ButtonPrimary>
          <Modal
            open={open}
            title={props.t('camera.holiday.render.modal.title')}
            okText={props.t('camera.holiday.render.modal.confirm')}
            cancelText={props.t('camera.holiday.render.modal.cancel')}
            okButtonProps={{
              autoFocus: true,
              htmlType: 'submit',
              style: { backgroundColor: colors.blue.main },
            }}
            onCancel={() => setOpen(false)}
            destroyOnClose
            closable={false}
            modalRender={(dom) => (
              <Form
                form={form}
                layout="vertical"
                onFinish={onFinishForm}
              >
                {dom}
              </Form>
            )}
          >
            <ConfigProvider locale={props.currentUser.getLocale()}>
              <Form.Item
                name="date"
                layout="vertical"
                label={props.t('camera.holiday.render.modal.date')}
                rules={[{ required: true, message: props.t('zones.holiday.create.required-date') }]}
              >
                <DatePicker.RangePicker showTime />
              </Form.Item>
            </ConfigProvider>

            <Form.Item
              name="reason"
              label={props.t('camera.holiday.render.modal.reason')}
              rules={[{ required: true, message: props.t('zones.holiday.create.required-reason') }]}
            >
              <Input.TextArea
                type="text"
                rows={4}
              />
            </Form.Item>
          </Modal>
          <Table
            bordered
            dataSource={cameraHolidays}
            columns={columns}
            pagination={{ pageSize: 5 }}
          />
        </Space>
      </Spin>
    </>
  );
};

export default withTranslation('common')(CameraHoliday);

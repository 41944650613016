import React, { useEffect, useState } from 'react';
import { Col, Flex, Form, Input, Row, Select, Space } from 'antd';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import { MdRestartAlt, MdSearch } from 'react-icons/md';
import { v4 } from 'uuid';
import { getZarZonesFromCenter } from 'repository/zone/ZoneRepository';

const WhitelistLicensePlatesFilters = ({ setData, getLicensePlates, setLoading, ...props }) => {
  const [form] = Form.useForm();
  const [zoneOptions, setZoneOptions] = useState([]);

  const getZones = () => {
    getZarZonesFromCenter(props.currentCenter.name).then((response) => {
      setZoneOptions(response);
    });
  };

  useEffect(getZones, []);
  const getFields = () => {
    return (
      <>
        <Col
          xl={8}
          lg={12}
          md={12}
          key={v4()}
        >
          <Form.Item
            name={`zone`}
            label={props.t('whitelist.filters.zone')}
          >
            <Select
              size="large"
              placeholder={props.t('whitelist.filters.zone-placeholder')}
              allowClear
            >
              {zoneOptions.map((zone) => {
                return <Select.Option key={zone.id}>{zone.name}</Select.Option>;
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col
          xl={8}
          md={12}
          lg={12}
          key={v4()}
        >
          <Form.Item
            name={`plate`}
            label={props.t('whitelist.filters.license-plate')}
          >
            <Input
              size="large"
              placeholder={props.t('whitelist.filters.license-plate-placeholder')}
              allowClear
            />
          </Form.Item>
        </Col>
        <Col
          xl={8}
          lg={12}
          md={12}
          key={v4()}
        >
          <Form.Item
            name={`dni`}
            label={props.t('whitelist.filters.dni')}
          >
            <Input
              size="large"
              placeholder={props.t('whitelist.filters.dni-placeholder')}
              allowClear
            />
          </Form.Item>
        </Col>
        <Col
          xl={8}
          lg={12}
          md={12}
          key={v4()}
        >
          <Form.Item
            name={`temporality`}
            label={props.t('whitelist.filters.temporality')}
          >
            <Select
              allowClear
              size="large"
              placeholder={props.t('whitelist.filters.temporality-placeholder')}
            >
              <Select.Option value={0}>{props.t('whitelist.filters.permanent')}</Select.Option>
              <Select.Option value={1}>{props.t('whitelist.filters.temporal')}</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col
          xl={8}
          lg={12}
          md={12}
          key={v4()}
        >
          <Form.Item
            name={`status`}
            label={props.t('whitelist.filters.status')}
          >
            <Select
              allowClear
              size="large"
              placeholder={props.t('whitelist.filters.status-placeholder')}
            >
              <Select.Option value={0}>{props.t('whitelist.filters.valid')}</Select.Option>
              <Select.Option value={1}>{props.t('whitelist.filters.expired')}</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col
          xl={8}
          lg={12}
          md={12}
          key={v4()}
        >
          <Form.Item
            name={`validity`}
            label={props.t('whitelist.filters.validity')}
          >
            <Select
              allowClear
              size="large"
              placeholder={props.t('whitelist.filters.validity-placeholder')}
            >
              <Select.Option value="APPROVED">{props.t('whitelist.filters.approved')}</Select.Option>
              <Select.Option value="PENDING">{props.t('whitelist.filters.pending')}</Select.Option>
              <Select.Option value="DENIED">{props.t('whitelist.filters.denied')}</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </>
    );
  };
  const onFinish = (values) => {
    setLoading(true);
    getLicensePlates(values.zone, values.validity, values.plate, values.status, values.dni, values.temporality);
  };

  return (
    <Form
      form={form}
      name="advanced_search"
      onFinish={onFinish}
      style={{ paddingBottom: 10 }}
    >
      <Row gutter={24}>{getFields()}</Row>
      <div
        style={{
          textAlign: 'right',
        }}
      >
        <Space size="middle">
          <ButtonPrimary
            type="primary"
            htmlType="submit"
            size="large"
            shape="round"
          >
            <Flex
              justify="center"
              align="center"
              gap={'small'}
            >
              {props.t('whitelist.filters.button')}
              <MdSearch style={{ fontSize: '20' }} />
            </Flex>
          </ButtonPrimary>
          <ButtonPrimary
            color="black"
            size="large"
            shape="round"
            onClick={() => {
              form.resetFields();
              setLoading(true);
              getLicensePlates();
            }}
          >
            <Flex
              justify="center"
              align="center"
              gap={'small'}
            >
              {props.t('whitelist.filters.reset-button')}
              <MdRestartAlt
                style={{
                  fontSize: '20',
                }}
              />
            </Flex>
          </ButtonPrimary>
        </Space>
      </div>
    </Form>
  );
};

export default WhitelistLicensePlatesFilters;

import React, { useEffect, useState } from 'react';

import { LoadingOutlined } from '@ant-design/icons';

import { Spin, notification, Typography, Select, Alert, Switch, Space, QRCode, Input, Row, Col, Flex } from 'antd';

import { withTranslation } from 'react-i18next';
import { BsClipboard, BsTelegram } from 'react-icons/bs';
import { MdOutlineEmail } from 'react-icons/md';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import {
  getBlacklistReport,
  updateBlacklistReport,
  updateBlacklistReportEmail,
  updateBlacklistReportTelegram,
} from 'repository/blacklist/BlacklistReportsRepository';
import { getTelegramChannelByCenterAndType } from 'repository/telegramChannel/TelegramChannelRepository';
import AccessDenied from 'views/AccessDenied/AccessDenied';

const BlacklistConfig = ({ ...props }) => {
  const [loading, setLoading] = useState(true);
  const [telegram, setTelegram] = useState(false);
  const [email, setEmail] = useState(false);
  const [emailsToSend, setEmailsToSend] = useState([]);
  const [telegramLink, setTelegramLink] = useState('');

  const getConfig = () => {
    getBlacklistReport(props.currentCenter.name).then((response) => {
      setTelegram(response.telegram);
      setEmail(response.email);
      setEmailsToSend(response.emails);
      setLoading(false);
    });

    getTelegramChannelByCenterAndType(props.currentCenter.name, 'BLACKLIST').then((response) => {
      setTelegramLink(response.link);
    });
  };

  useEffect(getConfig, []);

  if (!props.currentUser.isAdmin()) {
    return <AccessDenied />;
  }

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col span={12}>
        <Spin
          spinning={loading}
          indicator={
            <LoadingOutlined
              style={{ fontSize: 24 }}
              spin
            />
          }
        >
          <Space
            size={'middle'}
            direction="vertical"
            style={{ width: '100%' }}
          >
            <Typography.Title level={3}>
              <Flex
                align="center"
                gap={'small'}
              >
                <MdOutlineEmail
                  style={{
                    fontSize: '30px',
                  }}
                />
                {props.t('blacklist.config.email.title')}
                <Switch
                  checked={email}
                  onChange={(checked) => {
                    setLoading(true);
                    setEmail(checked);
                    updateBlacklistReportEmail(props.currentCenter.name, checked)
                      .then(() => {
                        notification.success({
                          message: 'ZinkinData',
                          description: props.t('blacklist.config.email.correct-update'),
                        });
                        setLoading(false);
                      })
                      .catch((error) => {
                        console.log(error.message);
                      });
                  }}
                />
              </Flex>
            </Typography.Title>
            <Alert
              message={props.t('blacklist.config.email.alert-title')}
              description={props.t('blacklist.config.email.alert-message')}
              type="info"
              showIcon
            />

            <Select
              size="large"
              mode="tags"
              style={{ width: '100%' }}
              placeholder={props.t('blacklist.config.email.email-placeholder')}
              disabled={!email}
              value={emailsToSend}
              showArrow={false}
              onChange={(event) => setEmailsToSend(event)}
              open={false}
            />

            <ButtonPrimary
              color="blue"
              disabled={!email}
              size="large"
              shape={'round'}
              onClick={() => {
                var errorEmails = false;
                emailsToSend.forEach((email) => {
                  const regex = /^[^\s@]+(?:@[^\s@]+\.[^\s@]+)$/;
                  if (!regex.test(email)) {
                    errorEmails = true;
                  }
                });
                var request = {
                  email: email,
                  telegram: telegram,
                  emails: emailsToSend,
                };
                if (errorEmails) {
                  notification.error({
                    message: 'ZinkinData',
                    description: props.t('blacklist.config.email.bad-mails'),
                  });
                } else {
                  updateBlacklistReport(props.currentCenter.name, request)
                    .then(() => {
                      notification.success({
                        message: 'ZinkinData',
                        description: props.t('blacklist.config.email.correct-update'),
                      });
                      getConfig();
                    })
                    .catch((error) => console.log(error));
                }
              }}
              htmlType="submit"
              style={{ float: 'right' }}
            >
              Guardar
            </ButtonPrimary>
          </Space>
        </Spin>
      </Col>
      <Col span={12}>
        <Spin
          spinning={loading}
          indicator={
            <LoadingOutlined
              style={{ fontSize: 24 }}
              spin
            />
          }
        >
          <Space
            size={'middle'}
            direction="vertical"
            style={{ width: '100%' }}
          >
            <Typography.Title level={3}>
              <Flex
                align="center"
                gap={'small'}
              >
                <BsTelegram
                  style={{
                    fontSize: '30px',
                  }}
                />
                {props.t('blacklist.config.telegram.title')}
                <Switch
                  checked={telegram}
                  onChange={(checked) => {
                    setLoading(true);
                    setTelegram(checked);
                    updateBlacklistReportTelegram(props.currentCenter.name, checked)
                      .then(() => {
                        notification.success({
                          message: 'ZinkinData',
                          description: props.t('blacklist.config.email.correct-update'),
                        });
                        setLoading(false);
                      })
                      .catch((error) => {
                        console.log(error.message);
                        setLoading(false);
                      });
                  }}
                />
              </Flex>
            </Typography.Title>

            <Space
              direction="vertical"
              align="center"
              style={{ width: '100%' }}
              size="large"
            >
              <QRCode
                value={telegramLink.length ? telegramLink : 'null'}
                errorLevel="H"
                size={256}
              />
              <Space.Compact
                style={{ width: '100%' }}
                size="large"
              >
                <Input
                  size="large"
                  value={telegramLink}
                  readOnly
                />
                <ButtonPrimary
                  color="blue"
                  size="large"
                  onClick={() => {
                    navigator.clipboard.writeText(telegramLink);
                  }}
                >
                  <Flex
                    align="center"
                    gap={'small'}
                  >
                    <BsClipboard
                      style={{
                        fontSize: '18',
                      }}
                    />
                    {props.t('blacklist.config.telegram.copy')}
                  </Flex>
                </ButtonPrimary>
              </Space.Compact>
            </Space>
          </Space>
        </Spin>
      </Col>
    </Row>
  );
};

export default withTranslation('common')(BlacklistConfig);

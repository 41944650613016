import { API_BASE_URL, getFetchOptions } from 'constants/constants.js';

export async function getOppositeViolationBasicStatistics(currentCenter, startDate, endDate) {
  const url =
    API_BASE_URL +
    `/${currentCenter}/reports/opposite-direction/basic-statistics?start-date=${startDate}&end-date=${endDate}`;
  const headers = getFetchOptions('GET');

  const response = await fetch(url, headers);
  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    throw new Error('Error intern de servidor');
  }
}

export async function getOppositeViolationZonesAffluence(currentCenter, startDate, endDate) {
  const url =
    API_BASE_URL +
    `/${currentCenter}/reports/opposite-direction/zones-affluence?start-date=${startDate}&end-date=${endDate}`;
  const headers = getFetchOptions('GET');

  const response = await fetch(url, headers);
  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    throw new Error('Error intern de servidor');
  }
}

export async function getOppositeViolationDeniedReasons(currentCenter, startDate, endDate) {
  const url =
    API_BASE_URL +
    `/${currentCenter}/reports/opposite-direction/denied-reasons?start-date=${startDate}&end-date=${endDate}`;
  const headers = getFetchOptions('GET');

  const response = await fetch(url, headers);
  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    throw new Error('Error intern de servidor');
  }
}

import React, { useEffect, useState } from 'react';
import { Spin, Space, Radio, Statistic, ConfigProvider, DatePicker, Card, Form, Flex } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { BsClipboardCheck } from 'react-icons/bs';
import { MdCamera, MdSearch } from 'react-icons/md';
import { FaCheck, FaTimes } from 'react-icons/fa';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import {
  getGaugeControlViolationDeniedReasons,
  getGaugeControlViolationPrice,
  getTotalGaugeControlAffluence,
  getTotalGaugeControlReadings,
  getTotalGaugeControlViolations,
} from 'repository/reports/gauge-control-violations/GaugeControlViolationReportRepository';
const { RangePicker } = DatePicker;

const OppositeDirectionReports = ({ ...props }) => {
  const [loading, setLoading] = useState(true);
  const [totalReadings, setTotalReadings] = useState(0);
  const [totalPending, setTotalPending] = useState(0);
  const [totalApproved, setTotalApproved] = useState(0);
  const [totalDenied, setTotalDenied] = useState(0);
  const [deniedReasonData, setDeniedReasonData] = useState([]);
  const [gaugeControlPrice, setGaugeControlPrice] = useState(0);
  const [affluence, setAffluence] = useState([]);
  const [optionDate, setOptionDate] = useState('today');

  const getReportsData = (startDate = moment(), endDate = moment()) => {
    const requests = [];

    requests.push(
      getTotalGaugeControlReadings(
        props.currentCenter.name,
        startDate.startOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
        endDate.endOf('day').format('YYYY-MM-DD HH:mm:ss').toString()
      )
    );
    requests.push(
      getTotalGaugeControlViolations(
        props.currentCenter.name,
        startDate.startOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
        endDate.endOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
        'PENDING'
      )
    );
    requests.push(
      getTotalGaugeControlViolations(
        props.currentCenter.name,
        startDate.startOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
        endDate.endOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
        'APPROVED'
      )
    );
    requests.push(
      getTotalGaugeControlViolations(
        props.currentCenter.name,
        startDate.startOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
        endDate.endOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
        'DENIED'
      )
    );
    requests.push(
      getTotalGaugeControlAffluence(
        props.currentCenter.name,
        startDate.startOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
        endDate.endOf('day').format('YYYY-MM-DD HH:mm:ss').toString()
      )
    );
    requests.push(getGaugeControlViolationPrice(props.currentCenter.name));
    requests.push(
      getGaugeControlViolationDeniedReasons(
        props.currentCenter.name,
        startDate.startOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
        endDate.endOf('day').format('YYYY-MM-DD HH:mm:ss').toString()
      )
    );

    Promise.all(requests)
      .then((responses) => {
        setTotalApproved(responses[2]);
        setTotalPending(responses[1]);
        setTotalReadings(responses[0]);
        setAffluence(responses[4]);
        setTotalDenied(responses[3]);
        setDeniedReasonData(responses[6]);
        setGaugeControlPrice(responses[5]);

        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(getReportsData, []);

  const handleOptionDateChange = (value) => {
    setLoading(true);

    if (value === 'custom') {
      setOptionDate(value);
      setLoading(false);
      return;
    }

    let startDate, endDate;

    switch (value) {
      case 'today':
        startDate = moment();
        endDate = moment();
        break;

      case 'yesterday':
        startDate = moment().subtract(1, 'days');
        endDate = moment().subtract(1, 'days');
        break;

      case 'week':
        startDate = moment().subtract(6, 'days');
        endDate = moment();
        break;

      case 'month':
        startDate = moment().subtract(30, 'days');
        endDate = moment();
        break;

      default:
        return; // Sale si el valor no coincide con ninguno
    }

    setOptionDate(value);
    getReportsData(startDate, endDate);
  };

  const getTotalNonGaugeControlViolationsByZones = () => {
    var total = [];
    var arrayZonesAffluence = Object.values(affluence);

    for (let i = 0; i < arrayZonesAffluence.length; i++) {
      total.push(arrayZonesAffluence[i].readings - arrayZonesAffluence[i].approvedViolations);
    }

    return total;
  };

  const getTotalGaugeControlViolationsByZones = () => {
    var total = [];
    var arrayZonesAffluence = Object.values(affluence);

    for (let i = 0; i < arrayZonesAffluence.length; i++) {
      total.push(arrayZonesAffluence[i].approvedViolations);
    }

    return total;
  };

  const readingsChartOptions = {
    chart: {
      type: 'bar',
    },
    title: {
      text: props.t('reports.gauge-control-violation-reports.denied-reasons-chart.title'),
    },
    xAxis: {
      categories: deniedReasonData.map((item) =>
        props.t('infractions.denied-reason.' + item.deniedReason.toLowerCase())
      ),
      title: {
        text: props.t('reports.gauge-control-violation-reports.denied-reasons-chart.x'),
      },
      labels: {
        style: {
          fontSize: '14px',
        },
      },
    },
    yAxis: {
      title: {
        text: props.t('reports.gauge-control-violation-reports.denied-reasons-chart.y'),
      },
      tickInterval: 5,
    },
    series: [
      {
        name: props.t('reports.gauge-control-violation-reports.denied-reasons-chart.y'),
        data: deniedReasonData.map((item) => item.times),
      },
    ],
  };

  const affluenceChartOptions = {
    title: {
      text: props.t('reports.gauge-control-violation-reports.zones-affluence.title'),
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
        },
      },
    },
    xAxis: {
      categories: Object.keys(affluence),
      labels: {
        style: {
          fontSize: '16px',
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: props.t('reports.gauge-control-violation-reports.zones-affluence.y'),
      },
      stackLabels: {
        enabled: true,
      },
    },
    colors: ['#434348', '#cf1322'],
    chart: {
      type: 'column',
    },
    series: [
      {
        name: props.t('reports.gauge-control-violation-reportszones-affluence.non-sanctioned-readings'),
        data: getTotalNonGaugeControlViolationsByZones(),
      },
      {
        name: props.t('reports.gauge-control-violation-reports.zones-affluence.approved-infractions'),
        data: getTotalGaugeControlViolationsByZones(),
      },
    ],
    credits: {
      enabled: false,
    },
  };

  return (
    <Spin
      spinning={loading}
      indicator={
        <LoadingOutlined
          style={{ fontSize: 24 }}
          spin
        />
      }
      tip={props.t('reports.loading')}
      style={{ fontSize: 20 }}
    >
      <Space
        style={{ width: '100%' }}
        size={'middle'}
        direction="vertical"
      >
        <Space size={'middle'}>
          <Radio.Group
            value={optionDate}
            onChange={(value) => handleOptionDateChange(value.target.value)}
            buttonStyle="solid"
          >
            <Radio.Button value="today">{props.t('reports.filters.today')}</Radio.Button>
            <Radio.Button value="yesterday">{props.t('reports.filters.yesterday')}</Radio.Button>
            <Radio.Button value="week">{props.t('reports.filters.week')}</Radio.Button>
            <Radio.Button value="month">{props.t('reports.filters.month')}</Radio.Button>
            <Radio.Button value="custom">{props.t('reports.filters.custom')}</Radio.Button>
          </Radio.Group>

          {optionDate === 'custom' ? (
            <Form
              layout="inline"
              onFinish={(values) => {
                setLoading(true);
                getReportsData(moment(values.date[0].$d), moment(values.date[1].$d));
              }}
              clearOnDestroy
            >
              <ConfigProvider locale={props.currentUser.getLocale()}>
                <Form.Item name="date">
                  <RangePicker
                    style={{ width: '100%' }}
                    size="middle"
                    format={'DD/MM/YYYY'}
                  />
                </Form.Item>
              </ConfigProvider>
              <ButtonPrimary
                color="blue"
                shape="round"
                size="middle"
                htmlType="submit"
              >
                <Flex
                  align="center"
                  gap={'small'}
                >
                  Buscar
                  <MdSearch
                    style={{
                      fontSize: '20',
                    }}
                  />
                </Flex>
              </ButtonPrimary>
            </Form>
          ) : (
            <></>
          )}
        </Space>

        <Flex justify="space-between">
          <Card>
            <Statistic
              title={props.t('reports.gauge-control-violation-reports.total-readings-card')}
              value={totalReadings}
              prefix={<MdCamera style={{ verticalAlign: 'sub', width: 'auto' }} />}
            />
          </Card>
          <Card>
            <Statistic
              title={props.t('reports.gauge-control-violation-reports.violations-card')}
              value={totalPending}
              valueStyle={{ color: '#cf1322' }}
              prefix={<BsClipboardCheck style={{ verticalAlign: 'sub', width: 'auto' }} />}
            />
          </Card>
          <Card>
            <Statistic
              title={props.t('reports.gauge-control-violation-reports.approved-infractions-card')}
              value={totalApproved}
              valueStyle={{ color: '#3f8600' }}
              prefix={<FaCheck style={{ verticalAlign: 'sub', width: 'auto' }} />}
            />
          </Card>
          <Card>
            <Statistic
              title={props.t('reports.gauge-control-violation-reports.denied-infractions-card')}
              value={totalDenied}
              valueStyle={{ color: '#ef8e14' }}
              prefix={<FaTimes style={{ verticalAlign: 'sub', width: 'auto' }} />}
            />
          </Card>
          <Card>
            <Statistic
              title={props.t('reports.gauge-control-violation-reports.earnings-card')}
              value={gaugeControlPrice * totalApproved}
              valueStyle={{ color: '#3f8600' }}
              suffix="€"
            />
          </Card>
          <Card>
            <Statistic
              title={props.t('reports.gauge-control-violation-reports.estimated-earings-card')}
              value={gaugeControlPrice * totalPending}
              valueStyle={{ color: '#8f19cc' }}
              suffix="€"
            />
          </Card>
        </Flex>
        <Flex justify="space-between">
          <Card style={{ width: '45%' }}>
            <HighchartsReact
              highcharts={Highcharts}
              options={readingsChartOptions}
            />
          </Card>
          <Card style={{ width: '50%' }}>
            <HighchartsReact
              highcharts={Highcharts}
              options={affluenceChartOptions}
            />
          </Card>
        </Flex>
      </Space>
    </Spin>
  );
};

export default withTranslation('common')(OppositeDirectionReports);

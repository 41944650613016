import React from 'react';
import Workbook from 'react-excel-workbook';

import { useTranslation } from 'react-i18next';
import { FaRegFileExcel } from 'react-icons/fa';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';

function CreateRedLightExcel({ data }) {
  const { t } = useTranslation('common');
  const { totalReadings, totalPending, totalApproved, totalDenied, redLightPrice, affluence } = data;

  function getData() {
    let list = [
      {
        name: 'Total lectures:',
        value: String(totalReadings) + ' vehicles',
      },
      {
        name: 'Total propostes de sanció:',
        value: String(totalPending) + ' propostes de sanció',
      },
      {
        name: 'Total propostes de sanció aprovades:',
        value: String(totalApproved) + ' propostes de sanció aprovades',
      },
      {
        name: 'Total propostes de sanció denegades:',
        value: String(totalDenied) + ' propostes de sanció denegades',
      },
      {
        name: t('reports.excel.total-income') + ':',
        value: String(redLightPrice * totalApproved) + ' €',
      },
      {
        name: t('reports.excel.estimated-income') + ':',
        value: String(redLightPrice * totalPending) + ' €',
      },
      {
        name: '',
        value: '',
      },
      {
        name: 'Percentatge de lectures:',
        value: String(Number(((totalReadings - totalApproved) / totalReadings) * 100).toFixed(2)) + ' %',
      },
      {
        name: 'Percentatge de sancions validades:',
        value: String(Number((totalApproved / totalReadings) * 100).toFixed(2)) + ' %',
      },
      {
        name: '',
        value: '',
      },
    ];

    Object.entries(affluence).forEach((entry) => {
      list.push({
        name: t('reports.excel.zones-affluence') + ' ' + entry[0] + ':',
        value: String(entry[1].redLightReadings) + ' vehicles',
      });
    });

    return list;
  }

  return (
    <Workbook
      filename="report.xlsx"
      element={
        <ButtonPrimary
          color="green"
          size="middle"
          style={{ width: '100%' }}
        >
          <FaRegFileExcel
            style={{
              verticalAlign: 'sub',
              fontSize: '20',
              marginRight: '5px',
            }}
          />
          Excel
        </ButtonPrimary>
      }
    >
      <Workbook.Sheet
        data={getData()}
        name="Sheet A"
      >
        <Workbook.Column
          label=""
          value="name"
        />
        <Workbook.Column
          label=""
          value="value"
        />
      </Workbook.Sheet>
    </Workbook>
  );
}

export default CreateRedLightExcel;

import React, { useState } from 'react';

import { notification, Input, Form } from 'antd';

import { withTranslation } from 'react-i18next';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import AccessDenied from 'views/AccessDenied/AccessDenied';
import { editZone } from 'repository/zone/ZoneRepository';

const { TextArea } = Input;

const ZoneEdit = ({ ...props }) => {
  const [loading, setLoading] = useState(false);

  const { id, name, description } = props.location.state.zone;

  if (!props.currentUser.isAdmin()) {
    return <AccessDenied />;
  }

  const onFinishEdit = (values) => {
    setLoading(true);
    editZone(props.currentCenter.name, id, values.name, values.description)
      .then(() => {
        notification['success']({
          message: 'ZinkinData',
          description: props.t('zones.edit.method.success'),
        });
        setLoading(false);
        props.history.push('/zones');
      })
      .catch(() => {
        setLoading(false);
        notification['error']({
          message: 'ZinkinData',
          description: props.t('zones.edit.method.error'),
        });
      });
  };

  return (
    <Form
      onFinish={onFinishEdit}
      labelCol={{ span: 3 }}
      wrapperCol={{ span: 12 }}
    >
      <Form.Item
        label={props.t('zones.edit.render.name')}
        name="name"
        initialValue={name}
        rules={[{ required: true, message: props.t('zones.edit.method.name') }]}
      >
        <Input
          size="large"
          disabled={loading}
          placeholder={props.t('zones.edit.render.name-placeholder')}
        />
      </Form.Item>
      <Form.Item
        label={props.t('zones.edit.render.description')}
        name="description"
        initialValue={description}
        rules={[{ required: true, message: props.t('zones.edit.method.description') }, { max: 60 }]}
      >
        <TextArea
          size="large"
          disabled={loading}
          showCount
          maxLength={60}
          placeholder={props.t('zones.edit.render.description-placeholder')}
        />
      </Form.Item>
      <ButtonPrimary
        loading={loading}
        color="blue"
        shape="round"
        size="large"
        htmlType="submit"
      >
        {props.t('zones.edit.render.button')}
      </ButtonPrimary>
    </Form>
  );
};

export default withTranslation('common')(ZoneEdit);

import { API_BASE_URL, getFetchOptions } from 'constants/constants.js';

export async function createZoneSchedule(centerName, request) {
  const url = `${API_BASE_URL}/admin/${centerName}/zone-schedules`;
  const headers = getFetchOptions('POST');

  const response = await fetch(url, Object.assign({}, headers, { body: JSON.stringify(request) }));
  if (response.status !== 201) throw new Error('Error intern de servidor');
}

export async function getZoneSchedule(centerName, id) {
  const url = `${API_BASE_URL}/admin/${centerName}/zone-schedules?zone-id=${id}`;
  const headers = getFetchOptions('GET');

  const response = await fetch(url, headers);
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    throw new Error('Error intern de servidor');
  }
}

export async function deleteZoneSchedule(centerName, id) {
  const url = `${API_BASE_URL}/admin/${centerName}/zone-schedules/${id}`;
  const headers = getFetchOptions('DELETE');

  const response = await fetch(url, headers);
  if (response.status !== 204) throw new Error('Error intern de servidor');
}

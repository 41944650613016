import {
  Card,
  ConfigProvider,
  DatePicker,
  Flex,
  Form,
  InputNumber,
  Modal,
  Space,
  Spin,
  Statistic,
  Table,
  notification,
} from 'antd';
import ButtonLink from 'components/Buttons/ButtonLink';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TbTrashXFilled } from 'react-icons/tb';
import { LoadingOutlined, WarningOutlined } from '@ant-design/icons';
import {
  deleteRecord,
  getRecords,
  saveRegister,
} from 'repository/reports/red-light-violations/RedLightViolationReportRepository';
import AccessDenied from 'views/AccessDenied/AccessDenied';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import { MdAdd, MdCamera } from 'react-icons/md';
import { FaTimes } from 'react-icons/fa';
import { FaCheck } from 'react-icons/fa6';
import moment from 'moment';
import dayjs from 'dayjs';
import colors from 'components/Buttons/colors';

const RedLightReportCreate = (props) => {
  const { t } = useTranslation('common');
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [totalReadings, setTotalReadings] = useState(0);
  const [totalPending, setTotalPending] = useState(0);
  const [totalApproved, setTotalApproved] = useState(0);
  const [totalDenied, setTotalDenied] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [month, setMonth] = useState(moment());

  const [form] = Form.useForm();

  const columns = [
    {
      title: t('reports.red-light-violation-create.table.date'),
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      render: (text, record) => {
        return moment(record.date).format('DD-MM-YYYY').toString();
      },
    },
    {
      title: t('reports.red-light-violation-create.table.readings'),
      dataIndex: 'readings',
      key: 'readings',
      align: 'center',
    },
    {
      title: t('reports.red-light-violation-create.table.pending'),
      dataIndex: 'pending',
      key: 'peding',
      align: 'center',
    },
    {
      title: t('reports.red-light-violation-create.table.approved'),
      dataIndex: 'approved',
      key: 'approved',
      align: 'center',
    },
    {
      title: t('reports.red-light-violation-create.table.denied'),
      dataIndex: 'denied',
      key: 'denied',
      align: 'center',
    },
  ];

  const getRedLightRecords = (month = moment()) => {
    getRecords(
      props.currentCenter.name,
      month.startOf('month').format('YYYY-MM-DD'),
      month.endOf('month').format('YYYY-MM-DD')
    ).then((response) => {
      setData(response);
      setTotalReadings(
        response.reduce((acumulador, objeto) => {
          return acumulador + objeto.readings;
        }, 0)
      );

      setTotalApproved(
        response.reduce((acumulador, objeto) => {
          return acumulador + objeto.approved;
        }, 0)
      );

      setTotalDenied(
        response.reduce((acumulador, objeto) => {
          return acumulador + objeto.denied;
        }, 0)
      );

      setTotalPending(
        response.reduce((acumulador, objeto) => {
          return acumulador + objeto.pending;
        }, 0)
      );

      setLoading(false);
    });
  };

  useEffect(getRedLightRecords, []);

  const getColumns = () => {
    if (props.currentUser.isAdmin())
      return [
        ...columns,
        {
          title: t('reports.red-light-violation-create.table.actions'),
          dataIndex: 'actions',
          width: '150px',
          align: 'center',
          render: (text, record) => {
            return (
              <ButtonLink
                color="black"
                onClick={() => {
                  Modal.confirm({
                    title: t('reports.red-light-violation-create.delete.title'),
                    okText: t('reports.red-light-violation-create.delete.confirm-button'),
                    cancelText: t('reports.red-light-violation-create.delete.cancel-button'),
                    onOk: () => {
                      setLoading(true);
                      deleteRecord(props.currentCenter.name, record.uuid).then((response) => {
                        notification.success({
                          message: 'ZinkinData',
                          description: t(response),
                        });
                        getRedLightRecords(month);
                      });
                    },
                    okButtonProps: {
                      className: 'button-primary-props-blue',
                      shape: 'round',
                    },
                    cancelButtonProps: {
                      className: 'button-primary-props-white',
                      shape: 'round',
                    },
                    centered: true,
                  });
                }}
              >
                <TbTrashXFilled style={{ fontSize: '30' }} />
              </ButtonLink>
            );
          },
        },
      ];

    return this.columns;
  };

  const onFinishCreateForm = (values) => {
    const register = {
      center: props.currentCenter.name,
      readings: 0,
      pending: values.pending,
      accepted: values.pending - values.denied,
      denied: values.denied,
      date: moment(values.date).format('YYYY-MM-DD').toString(),
    };
    saveRegister(register).then((response) => {
      notification.success({
        message: 'ZinkinData',
        description: t(response),
      });
      getRedLightRecords(month);
      setModalVisible(false);
    });
  };

  if (!props.currentUser.isAdmin() && !props.currentUser.isBasicUser()) {
    return <AccessDenied t={t} />;
  }

  return (
    <Spin
      spinning={loading}
      indicator={
        <LoadingOutlined
          style={{ fontSize: 24 }}
          spin
        />
      }
    >
      <Space
        size={'middle'}
        style={{ width: '100%' }}
        direction="vertical"
      >
        <Space style={{ width: '100%' }}>
          <ConfigProvider locale={props.currentUser.getLocale()}>
            <DatePicker
              data-testid="select-date"
              size="large"
              style={{ marginRight: '10px' }}
              onChange={(event) => {
                setMonth(event.$d);
                getRedLightRecords(event.$d);
              }}
              picker="month"
            />
          </ConfigProvider>
          <ButtonPrimary
            color="blue"
            shape="round"
            size="large"
            style={{ float: 'right' }}
            onClick={() => {
              setModalVisible(true);
            }}
          >
            <Flex
              align="center"
              gap={'small'}
            >
              {t('reports.red-light-violation-create.create-btn')}
              <MdAdd
                style={{
                  fontSize: '20',
                }}
              />
            </Flex>
          </ButtonPrimary>
        </Space>
        <Flex justify="space-between">
          <Card>
            <Statistic
              title={t('reports.red-light-violation-create.total.readings')}
              value={totalReadings}
              prefix={<MdCamera style={{ verticalAlign: 'sub', width: 'auto' }} />}
            />
          </Card>
          <Card>
            <Statistic
              title={t('reports.red-light-violation-create.total.pending')}
              value={totalPending}
              valueStyle={{ color: '#cf1322' }}
              prefix={<WarningOutlined />}
            />
          </Card>
          <Card>
            <Statistic
              title={t('reports.red-light-violation-create.total.approved')}
              value={totalApproved}
              valueStyle={{ color: '#3f8600' }}
              prefix={<FaCheck style={{ verticalAlign: 'sub', width: 'auto' }} />}
            />
          </Card>
          <Card>
            <Statistic
              title={t('reports.red-light-violation-create.total.denied')}
              value={totalDenied}
              valueStyle={{ color: '#ef8e14' }}
              prefix={<FaTimes style={{ verticalAlign: 'sub', width: 'auto' }} />}
            />
          </Card>
        </Flex>
        <Table
          bordered
          data-testid="reports-table"
          dataSource={data}
          columns={getColumns()}
          pagination={{
            pageSize: 10,
            showSizeChanger: false,
          }}
        />
      </Space>

      <Modal
        title={t('reports.red-light-violation-create.modal.title')}
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        closable={false}
        okButtonProps={{ style: { backgroundColor: colors.blue.main }, htmlType: 'submit', autoFocus: true }}
        destroyOnClose
        modalRender={(dom) => (
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinishCreateForm}
          >
            {dom}
          </Form>
        )}
      >
        <Form.Item
          name="pending"
          label={t('reports.red-light-violation-create.modal.pending')}
        >
          <InputNumber
            size="large"
            min={0}
          />
        </Form.Item>
        <Form.Item
          name="denied"
          label={t('reports.red-light-violation-create.modal.denied')}
        >
          <InputNumber
            size="large"
            min={0}
          />
        </Form.Item>
        <ConfigProvider locale={props.currentUser.getLocale()}>
          <Form.Item
            label={t('reports.red-light-violation-create.modal.date')}
            name={'date'}
          >
            <DatePicker
              style={{ width: '100%' }}
              size="large"
              format={'DD/MM/YYYY'}
              placeholder={t('readings.filters.start-date')}
              disabledDate={(current) => current > dayjs().endOf('day')}
            />
          </Form.Item>
        </ConfigProvider>
      </Modal>
    </Spin>
  );
};

export default RedLightReportCreate;

// External Libraries/Modules
import React, { Component } from 'react';
import { Route, withRouter, Switch, Redirect } from 'react-router-dom';
import { Collapse, Layout, Modal, notification, Typography } from 'antd';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import esES from 'antd/locale/es_ES';
import caES from 'antd/locale/ca_ES';
import eusES from 'antd/locale/eu_ES.js'
import gaEs from 'antd/locale/gl_ES.js'
import 'moment/locale/es';
import 'moment/locale/ca';
import 'moment/locale/eu';
import 'moment/locale/gl';
import 'dayjs/locale/es';
import 'dayjs/locale/ca';
import 'dayjs/locale/eu';
import 'dayjs/locale/gl';
import moment from 'moment';
import dayjs from 'dayjs';
// Internal Dependencies
import indexRoutes from '../routes/indexRoutes.jsx';
import LoadingIndicator from '../components/LoadingIndicator/LoadingIndicator';
import * as AuthRepository from '../repository/auth/AuthRepository';
import * as UserRepository from '../repository/users/UserRepository';
import { ACCESS_TOKEN } from '../constants/constants.js';

// Relative Imports
import Pages from 'layouts/Pages/Pages.jsx';
import LogoutView from 'views/Login/LogoutView.jsx';
import colors from 'components/Buttons/colors.js';

const { Paragraph, Text } = Typography;
const { Content } = Layout;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      currentCenter: 'default',
      isAuthenticated: false,
      loading: false,
      modalVisible: false,
    };

    this.handleLogout = this.handleLogout.bind(this);
    this.loadCurrentUser = this.loadCurrentUser.bind(this);
    this.handleLogin = this.handleLogin.bind(this);

    notification.config({
      placement: 'topRight',
      top: 70,
      duration: 3,
    });
  }

  componentWillMount() {
    this.loadCurrentUser();
  }

  async loadCurrentUser() {
    this.setState({ loading: true });
    const centerName = localStorage.getItem('center');

    if (centerName === null) {
      this.setState({ loading: false });
      return;
    }

    try {
      let center = await AuthRepository.getCenter(centerName);
      let user = await UserRepository.getCurrentUser(center.name);
      const userHasAccess = user.centers.some((center) => center.toUpperCase() === centerName.toUpperCase());

      if (!userHasAccess) {
        this.handleLogout();
        return;
      }

      if (user.passwordExpired) {
        this.props.history.push({
          pathname: '/pages/change-password',
          state: {
            title: 'profile.password.expire-password-title',
            description: 'profile.password.expire-password-description',
          },
        });
      }

      i18next.changeLanguage(user.language);

      switch(user.language){
        case 'es' : 
          moment.locale('es')
          dayjs.locale('es')
          break
        case 'ca' : 
          moment.locale('ca')
          dayjs.locale('ca')
          break
        case 'gal' : 
          moment.locale('gl')
          dayjs.locale('gl')
          break
        case 'eus' : 
          moment.locale('eu')
          dayjs.locale('eu')
          break
        default: 
          moment.locale('es')
          dayjs.locale('es')
      }

      const currentUser = {
        ...user,
        isAdmin: () => user.role === 'ROLE_ADMIN',
        isReporter: () => user.role === 'ROLE_REPORTER',
        isBasicUser: () => user.role === 'ROLE_USER',
        getLocale: () => {
          switch(user.language){
            case 'es' :return esES
            case 'ca' : return caES
            case 'gal' : return gaEs
            case 'eus' : return eusES
            default: return esES
          }
        }
      };

      this.setState({
        currentCenter: center,
        currentUser: currentUser,
        isAuthenticated: true,
        loading: false,
      });
    } catch (error) {
      this.setState({ loading: false });
      localStorage.removeItem('center');
      localStorage.removeItem(ACCESS_TOKEN);
      this.props.history.push('/');
    }
  }

  handleLogin() {
    this.loadCurrentUser();

    notification.success({
      message: 'ZinkinData',
      description: 'Has iniciat sessió amb èxit.',
    });

    this.setState({ modalVisible: true });

    this.props.history.push('/home');
  }

  handleLogout() {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem('center');

    this.setState({
      currentUser: null,
      isAuthenticated: false,
      loading: false,
    });

    this.props.history.push('/pages/login');
  }

  render() {
    if (this.state.loading) {
      return <LoadingIndicator />;
    }

    return (
      <>
        <Layout>
          <Content>
            <div>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={() => {
                    this.props.history.push('/home');
                    return true;
                  }}
                />
                <Route
                  path="/pages"
                  render={(props) => (
                    <Pages
                      handleLoginForm={this.handleLogin}
                      loadCurrentUser={this.loadCurrentUser}
                      handleLogout={this.handleLogout}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/logout"
                  render={(props) => (
                    <LogoutView
                      handleLogout={this.handleLogout}
                      {...props}
                    />
                  )}
                />

                {indexRoutes.map((route, index) => {
                  return (
                    <Route
                      path={route.path}
                      key={index}
                      render={(props) =>
                        this.state.isAuthenticated ? (
                          <route.component
                            handleLogout={this.handleLogout}
                            currentUser={this.state.currentUser}
                            currentCenter={this.state.currentCenter}
                            {...props}
                          />
                        ) : (
                          <Redirect to={{ pathname: '/pages/login', state: { from: props.location } }} />
                        )
                      }
                    />
                  );
                })}
              </Switch>
              <Modal
                title={this.props.t('terms-and-conditions.modal-title')}
                open={this.state.modalVisible}
                centered
                okText={this.props.t('terms-and-conditions.modal-ok-button')}
                cancelText={this.props.t('terms-and-conditions.modal-cancel-button')}
                cancelButtonProps={{ danger: true }}
                onOk={() => this.setState({ modalVisible: false })}
                okButtonProps={{ style: { backgroundColor: colors.blue.main } }}
                onCancel={() => {
                  this.setState({ modalVisible: false });
                  this.handleLogout();
                }}
              >
                <Collapse ghost>
                  <Collapse.Panel
                    key={'1'}
                    header={<Text strong>{this.props.t('terms-and-conditions.modal-collapse-title')}</Text>}
                  >
                    <Paragraph style={{ textAlign: 'justify' }}>
                      {this.props.t('terms-and-conditions.modal-content-1')}
                    </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}>
                      {this.props.t('terms-and-conditions.modal-content-2')}
                    </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}>
                      {this.props.t('terms-and-conditions.modal-content-3')}
                    </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}>
                      {this.props.t('terms-and-conditions.modal-content-4')}
                    </Paragraph>
                  </Collapse.Panel>
                </Collapse>
              </Modal>
            </div>
          </Content>
        </Layout>
      </>
    );
  }
}

export default withRouter(withTranslation('common')(App));

import React, { Component } from 'react';
import * as userRepository from '../../repository/users/UserRepository';
import * as authRepository from '../../repository/auth/AuthRepository';
import { ACCESS_TOKEN } from '../../constants/constants';
import { Form, Input, Spin, Modal, notification } from 'antd';
import Icon from '@ant-design/icons';
import { MdPerson, MdPassword, MdLocationCity } from 'react-icons/md';
import moment from 'moment';
import { LuCalendarCheck2 } from 'react-icons/lu';
import { withTranslation } from 'react-i18next';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import colors from 'components/Buttons/colors';

const FormItem = Form.Item;

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      modalEmail: '',
      loading: false,
      emailError: false,
    };
    this.submit = this.submit.bind(this);
    this.handleUnauthorizedError = this.handleUnauthorizedError.bind(this);
    this.handleForbiddenError = this.handleForbiddenError.bind(this);
    this.handleInternalServerError = this.handleInternalServerError.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  async resetPassword() {
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    if (emailRegex.test(this.state.modalEmail)) {
      this.setState({ emailError: null });
      try {
        await userRepository.resetPassword(this.state.modalEmail);
        notification['success']({
          message: 'ZinkinData',
          description:
            "S'ha iniciat el procés de recuperació de contrasenya / Se ha iniciado el proceso de recuperación de contraseña",
        });
        this.setModalVisible(false);
        this.setState({ modalLicensePlate: '', modalEmail: false });
      } catch (err) {
        notification['error']({
          message: 'ZinkinData',
          description: err.message,
        });
      }
    } else {
      this.setState({
        emailError: <small className="text-danger">El formato de la dirección de correo no es válido.</small>,
      });
    }
  }

  setModalVisible(modalVisible) {
    this.setState({ modalVisible });
  }

  async submit(values) {
    this.setState({ loading: true });
    const loginRequest = { ...values };

    try {
      const response = await authRepository.login(loginRequest);
      const { status } = response;

      switch (status) {
        case 401: {
          await this.handleUnauthorizedError(response);
          break;
        }
        case 403:
          this.handleForbiddenError();
          break;
        case 500:
          this.handleInternalServerError();
          break;
        default:
          await this.handleLogin(response, loginRequest.center);
          break;
      }
    } catch (error) {
      notification['error']({
        message: 'Error',
        description: error.message,
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  async handleUnauthorizedError(response) {
    let textBody = await response.text();
    let number = textBody.charAt(textBody.length - 2);
    let attemptsRemaining = 4 - number;

    let errorMessage;
    if (isNaN(attemptsRemaining)) {
      errorMessage = 'Credencials incorrectes. Torna a intentar-ho.';
    } else {
      errorMessage =
        attemptsRemaining > 0
          ? `Intents restants abans del bloqueig: ${attemptsRemaining}`
          : "Número màxim d'intents exhaurit. Contacta amb un administrador.";
    }

    notification['error']({
      message: 'Credencials incorrectes',
      description: errorMessage,
    });
  }

  handleForbiddenError() {
    notification['error']({
      message: 'Usuari bloquejat',
      description: "Número màxim d'intents exhaurit. Contacta amb un administrador.",
    });
  }

  handleInternalServerError() {
    notification['error']({
      message: 'Server Error',
      description: 'Error intern del servidor.',
    });
  }

  async handleLogin(response, centerName) {
    localStorage.setItem(ACCESS_TOKEN, response.accessToken);
    localStorage.setItem('center', centerName);

    let responseBody = await authRepository.getCenter(centerName);
    this.setState({ currentCenter: responseBody });

    let responseUser = await userRepository.getCurrentUser(responseBody.name);
    if (responseUser.firstLogin) {
      this.props.onFirstLogin(responseBody.name);
    } else if (responseUser.mfa) {
      this.props.onMfaVerify(responseUser.id, responseUser.email, response.lastAccessAt);
    } else {
      this.props.onLogin();
      if (response.lastAccessAt !== null) {
        notification['info']({
          message: this.props.t('login.last-access'),
          description: moment(response.lastAccessAt).format('DD-MM-YYYY HH:mm:ss'),
          icon: <LuCalendarCheck2 style={{ color: '#0085cb' }} />,
          duration: 5,
        });
      }
    }
  }

  render() {
    const iconStyles = { fontSize: 22, color: '#0085cb' };
    const loadingIcon = (
      <Icon
        type="loading-3-quarters"
        spin
        style={{ color: '#0085cb', fontSize: 30 }}
      />
    );
    return (
      <Spin
        spinning={this.state.loading}
        indicator={loadingIcon}
      >
        <Form onFinish={this.submit}>
          <FormItem
            name="center"
            rules={[
              {
                required: true,
                message: 'Introduce el nombre del centro, por favor',
              },
            ]}
          >
            <Input
              suffix={<MdLocationCity style={iconStyles} />}
              size="large"
              placeholder="Centro"
              name="center"
            />
          </FormItem>
          <FormItem
            name="username"
            rules={[
              {
                required: true,
                message: 'Introduce el nombre de usuario, por favor',
              },
            ]}
          >
            <Input
              suffix={<MdPerson style={iconStyles} />}
              size="large"
              placeholder="Usuario"
              name="username"
            />
          </FormItem>
          <FormItem
            name="password"
            rules={[
              {
                required: true,
                message: 'Introduce la contraseña, por favor',
              },
            ]}
          >
            <Input
              size="large"
              type="password"
              placeholder="Contraseña"
              name="password"
              suffix={<MdPassword style={iconStyles} />}
            />
          </FormItem>
          <FormItem>
            <ButtonPrimary
              color="blue"
              htmlType="submit"
              size="large"
              shape="round"
              block
            >
              Login
            </ButtonPrimary>
            <p style={{ textAlign: 'center', marginTop: '20px', marginBottom: '5px' }}>
              <span
                className="pass-recovery"
                onClick={() => this.setModalVisible(true)}
                style={{ color: colors.blue.main }}
              >
                Forgot your password?
              </span>
            </p>
          </FormItem>
        </Form>
        <Modal
          title="Forgot your password?"
          open={this.state.modalVisible}
          onOk={() => this.resetPassword()}
          onCancel={() => this.setModalVisible(false)}
          okText="Recuperar"
          cancelText="Cancelar"
          className="forgotPass"
          okButtonProps={{ style: { backgroundColor: colors.blue.main, borderColor: colors.blue.main } }}
        >
          <div>
            <FormItem>
              {
                <div>
                  <Input
                    suffix={<Icon type="mail" />}
                    size="large"
                    placeholder="Email"
                    name="email"
                    value={this.state.modalEmail}
                    onChange={(event) => this.setState({ modalEmail: event.target.value })}
                  />
                  {this.state.emailError}
                </div>
              }
            </FormItem>
          </div>
        </Modal>
      </Spin>
    );
  }
}

export default withTranslation('common')(LoginForm);

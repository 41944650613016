import React from 'react';
import { Col, Row } from 'antd';
import { withTranslation } from 'react-i18next';
import EnvironmentTagsReports from './EnvironmentTagsReports';
import SoundReports from './SoundReports';

const EnvirontmentReports = ({ ...props }) => {
  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col span={12}>
        <EnvironmentTagsReports {...props} />
      </Col>
      <Col span={12}>
        <SoundReports {...props} />
      </Col>
    </Row>
  );
};
export default withTranslation('common')(EnvirontmentReports);

import React, { useState, useEffect } from 'react';

import 'leaflet/dist/leaflet.css';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import { Spin, notification, Select, Input, Form, InputNumber, Tooltip } from 'antd';
import Icon from '@ant-design/icons';
import { withTranslation } from 'react-i18next';

import * as ZoneRepository from '../../../repository/zone/ZoneRepository';
import * as CameraRepository from '../../../repository/cameras/CamerasRepository';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import { useMapEvents } from 'react-leaflet';
import { Icon as MarkerIcon } from 'leaflet';
import { MdInfoOutline } from 'react-icons/md';
import { TbWorldLongitude, TbWorldLatitude } from 'react-icons/tb';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import { getBrandsOptions } from '../CameraBrand';
import AccessDenied from 'views/AccessDenied/AccessDenied';
import colors from 'components/Buttons/colors';

const Option = Select.Option;

const CreateCamara = ({ currentCenter, currentUser, t, history }) => {
  const [zoneList, setZoneList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [latitude, setLatitude] = useState(currentCenter.latitude);
  const [longitude, setLongitude] = useState(currentCenter.longitude);
  const [infractionTypesSelected, setInfractionTypesSelected] = useState([]);

  useEffect(fetchZones, []);

  function fetchZones() {
    ZoneRepository.getZonesFromCenter(currentCenter.name)
      .then((response) => {
        setZoneList(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function renderInfractionTypesSelectOptions() {
    const infractionTypes = [
      { value: 'ZAR', text: t('camera.list.table.infraction-types.zar') },
      {
        value: 'RED_LIGHT',
        text: t('camera.list.table.infraction-types.red-light'),
      },
      {
        value: 'OPPOSITE_DIRECTION',
        text: t('camera.list.table.infraction-types.opposite-direction'),
      },
      {
        value: 'GAUGE_CONTROL',
        text: t('camera.list.table.infraction-types.gauge-control'),
      },
      {
        value: 'SECTION_CONTROL',
        text: t('camera.list.table.infraction-types.section-control'),
      },
    ];

    const filteredInfractionTypes = infractionTypes.filter((item) =>
      currentCenter.infractionTypes.includes(item.value)
    );

    return (
      <Select
        mode="multiple"
        size="large"
        style={{ width: '100%' }}
        placeholder={t('camera.create.render.infraction-types')}
        maxTagCount={2}
        optionLabelProp="label"
        onChange={(event) => setInfractionTypesSelected(event)}
      >
        {filteredInfractionTypes.map((infractionType) => (
          <Option
            value={infractionType.value}
            key={infractionType.text}
            label={infractionType.text}
          >
            {infractionType.text}
          </Option>
        ))}
      </Select>
    );
  }

  const onFinish = (values) => {
    setLoading(true);
    var signupstring = {
      name: values.name,
      marca: values.marca,
      numeroSerie: values.serialNumber,
      zone: values.zone,
      location: values.location,
      latitude: latitude,
      longitude: longitude,
      ip: values.ip,
      correctDirectionType: values.correctDirectionType === undefined ? 'ANY' : values.correctDirectionType,
      infractionTypes: values.infractionTypes,
    };

    CameraRepository.postCamera(currentCenter.name, signupstring)
      .then((response) => {
        notification['success']({
          message: 'ZinkinData',
          description: t('camera.create.method.success'),
        });
        history.push('/cameras');
      })
      .catch((error) => {
        notification['error']({
          message: 'ZinkinData',
          description: t('camera.create.method.error'),
        });
      });

    setLoading(false);
  };

  if (currentUser.role !== 'ROLE_ADMIN') {
    return <AccessDenied t={t} />;
  }

  const antIcon = (
    <Icon
      type="loading"
      style={{ fontSize: 24 }}
      spin
    />
  );
  const zoneOptions = zoneList.map((zone) => <Option key={zone.name}>{zone.name}</Option>);

  function MyComponent() {
    useMapEvents({
      click: (e) => {
        const { lat, lng } = e.latlng;
        setLatitude(lat);
        setLongitude(lng);
      },
    });
    return null;
  }

  return (
    <Spin
      spinning={loading}
      indicator={antIcon}
    >
      <Form
        name="basic"
        initialValues={{ remember: true }}
        autoComplete="off"
        size="large"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 10 }}
        onFinish={onFinish}
      >
        <Form.Item
          label={t('camera.create.render.name')}
          name="name"
          rules={[
            {
              required: true,
              message: t('camera.create.errors.name.required'),
            },
          ]}
        >
          <Input placeholder={t('camera.create.render.name-placeholder')} />
        </Form.Item>

        <Form.Item
          label={t('camera.create.render.serial-number')}
          name="serialNumber"
        >
          <Input placeholder={t('camera.create.render.name-placeholder')} />
        </Form.Item>

        <Form.Item
          label={t('camera.create.render.ip')}
          name="ip"
          rules={[
            () => ({
              validator(_, value) {
                const patronIP = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
                if (value !== '' || patronIP.test(value)) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(new Error(t('camera.create.errors.ip.incorrect-ip')));
                }
              },
            }),
          ]}
        >
          <Input placeholder={t('camera.create.render.ip-placeholder')} />
        </Form.Item>

        <Form.Item
          label={t('camera.create.render.marca')}
          name="marca"
        >
          <Select
            showSearch
            size="large"
            style={{ width: '100%' }}
            placeholder={t('camera.create.render.marca-placeholder')}
            name="marca"
          >
            {getBrandsOptions()}
          </Select>
        </Form.Item>

        <Form.Item
          label={t('camera.create.render.zone')}
          name="zone"
          rules={[
            {
              required: true,
              message: t('camera.create.errors.zone.required'),
            },
          ]}
        >
          <Select
            showSearch
            size="large"
            style={{ width: '100%' }}
            optionFilterProp="children"
            placeholder={t('camera.create.render.zone-placeholder')}
            name="zone"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            allowClear
            defaultActiveFirstOption={false}
          >
            {zoneOptions}
          </Select>
        </Form.Item>
        <Form.Item
          label={t('camera.create.render.location')}
          name="location"
          rules={[
            {
              required: true,
              message: t('camera.create.errors.location.required'),
            },
            {
              max: 60,
              message: t('camera.create.errors.location.max'),
            },
          ]}
        >
          <Input placeholder={t('camera.create.render.location-placeholder')} />
        </Form.Item>
        <Form.Item
          label={t('camera.create.render.coordenades')}
          required
        >
          <InputNumber
            addonBefore={
              <Tooltip
                color={colors.blue.main}
                title={t('camera.create.render.latitude-tooltip')}
              >
                <TbWorldLatitude style={{ fontSize: '22' }} />
              </Tooltip>
            }
            value={latitude}
            decimalSeparator="."
            controls={false}
            onChange={(event) => setLatitude(event)}
            style={{ width: '40%', marginRight: '5%' }}
          />

          <InputNumber
            addonBefore={
              <Tooltip
                color={colors.blue.main}
                title={t('camera.create.render.longitude-tooltip')}
              >
                <TbWorldLongitude style={{ fontSize: '22' }} />
              </Tooltip>
            }
            decimalSeparator="."
            value={longitude}
            controls={false}
            onChange={(event) => setLongitude(event)}
            style={{ width: '40%' }}
          />
          <Tooltip
            color={colors.blue.main}
            title={t('camera.create.render.coordenades-tooltip')}
          >
            <ButtonPrimary
              color="blue"
              size="large"
              style={{ width: '10%', fontSize: '22px', float: 'right' }}
              icon={<MdInfoOutline />}
            />
          </Tooltip>
          <MapContainer
            center={[latitude !== null ? latitude : 0, longitude !== null ? longitude : 0]}
            zoom={14}
            style={{
              height: '500px',
              marginTop: '10px',
              width: '100%',
            }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
            />
            <MyComponent />
            <Marker
              position={[latitude !== null ? latitude : 0, longitude !== null ? longitude : 0]}
              icon={new MarkerIcon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })}
            />
          </MapContainer>
        </Form.Item>

        <Form.Item
          label={t('camera.create.render.infraction-types')}
          name="infractionTypes"
        >
          {renderInfractionTypesSelectOptions()}
        </Form.Item>

        {infractionTypesSelected.includes('OPPOSITE_DIRECTION') ? (
          <Form.Item
            label={t('camera.create.render.correct-direction.title')}
            name="correctDirectionType"
            rules={[
              {
                required: true,
                message: t('camera.create.errors.correct-direction.required'),
              },
            ]}
          >
            <Select
              showSearch
              size="large"
              style={{ width: '100%' }}
              optionFilterProp="children"
              placeholder={t('camera.create.render.correct-direction.title')}
              name="correctDirection"
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              allowClear
              defaultActiveFirstOption={false}
            >
              <Option key={'ANY'}>{t('camera.create.render.correct-direction.any')}</Option>
              <Option key={'MOVING_AWAY'}>{t('camera.create.render.correct-direction.moving_away')}</Option>
              <Option key={'APPROACHING'}>{t('camera.create.render.correct-direction.approaching')}</Option>
            </Select>
          </Form.Item>
        ) : null}

        <ButtonPrimary
          color="blue"
          shape="round"
          size="large"
          htmlType="submit"
        >
          {t('camera.create.render.button')}
        </ButtonPrimary>
      </Form>
    </Spin>
  );
};

export default withTranslation('common')(CreateCamara);

import React from 'react';
import { Table } from 'antd';
import moment from 'moment';

export const OppositeDirectionViolationsExported = ({ data, t, initialColumns }) => {
  const columns = [
    {
      title: t('red-light-violation.table.exported-date'),
      dataIndex: 'exportedDate',
      key: 'exportedDate',
      align: 'center',
      render: (text, record) => {
        if (record.exportedDate !== undefined && record.exportedDate !== null)
          return moment(record.exportedDate).format('DD-MM-YYYY HH:mm:ss');
      },
    },
  ];

  return (
    <>
      <Table
        bordered
        dataSource={data}
        columns={initialColumns.concat(columns)}
        pagination={{ pageSize: 10 }}
      />
    </>
  );
};

import { API_BASE_URL, getFetchOptions } from "constants/constants.js";

export function getRecords(center, startDate, endDate) {
  let url =
    API_BASE_URL +
    "/" + center +
    "/reports" +
    "/red-light-violation?startDate=" +
    startDate +
    "&endDate=" +
    endDate;
  const headers = Object.assign(
    {},
    {
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      }),
    },
    { method: "GET" }
  );

  return fetch(url, headers)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export function saveRegister({
  center,
  date,
  readings,
  pending,
  accepted,
  denied,
}) {
  let url =
    API_BASE_URL +
    "/" + center +
    "/reports" +
    "/red-light-violation?date=" +
    date +
    "&readings=" +
    readings +
    "&pending=" +
    pending +
    "&approved=" +
    accepted +
    "&denied=" +
    denied;
  const headers = Object.assign(
    {},
    {
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      }),
    },
    { method: "POST" }
  );

  return fetch(url, headers)
    .then((response) => "reports.red-light-violation-create.create.success")
    .catch((error) => console.log(error));
}

export function deleteRecord(center, id) {
  let url = API_BASE_URL + "/" + center + "/reports/red-light-violation/" + id;
  const headers = Object.assign(
    {},
    {
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      }),
    },
    { method: "DELETE" }
  );

  return fetch(url, headers)
    .then((response) => "reports.red-light-violation-create.delete.success")
    .catch((error) => console.log(error));
}

export function getTotalRedLightReadings(currentCenter, startDate, endDate) {
  const url =
    API_BASE_URL +
    `/${currentCenter}/reports/red-light-violation/readings?start-date=${startDate}&end-date=${endDate}`;
  const headers = getFetchOptions("GET");

  return fetch(url, headers)
    .then((response) => {
      return response.json();
    })
    .catch((error) => console.log(error));
}

export function getTotalRedLightViolations(
  currentCenter,
  startDate,
  endDate,
  status
) {
  const url =
    API_BASE_URL +
    `/${currentCenter}/reports/red-light-violation/infractions?start-date=${startDate}&end-date=${endDate}&status=${status}`;
  const headers = getFetchOptions("GET");

  return fetch(url, headers)
    .then((response) => {
      return response.json();
    })
    .catch((error) => console.log(error));
}

export function getTotalAffluence(currentCenter, startDate, endDate) {
  const url =
    API_BASE_URL +
    `/${currentCenter}/reports/red-light-violation/zones-affluence?start-date=${startDate}&end-date=${endDate}`;
  const headers = getFetchOptions("GET");

  return fetch(url, headers)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export function getRedLightViolationPrice(centerName) {
  return 200;
}

//-----------------------------------

export function updateReportConfiguration(
  centername,
  active,
  sendTo,
  type,
  pdf,
  excel
) {
  const url =
    API_BASE_URL +
    `/${centername}/periodic-reports?active=${active}&type=${type}&pdf=${pdf}&excel=${excel}`;
  const headers = getFetchOptions("PUT");

  return fetch(
    url,
    Object.assign({}, headers, { body: JSON.stringify(sendTo) })
  ).then((response) => {
    if (response.status !== 200) throw new Error("Error intern de servidor");
  });
}

export function getReportConfiguration(centername, type) {
  const url = API_BASE_URL + `/${centername}/periodic-reports?type=${type}`;
  const headers = getFetchOptions("GET");

  return fetch(url, headers)
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function getRedLightPdfFile(centerName, startDate, endDate) {
  const url = `${API_BASE_URL}/${centerName}/red-light-reports/pdf?start-date=${startDate}&end-date=${endDate}`;
  const headers = getFetchOptions("GET");

  try {
      const response = await fetch(url, headers);
      if (response.status === 200) {
          const data = await response.json()
          return data;
      }
      else if (response.status === 422) {
          throw new Error("Invalid parameters")
      } else {
          throw new Error("Error http status: " + response.status)
      }
  } catch (err) {
      throw new Error("Error: " + err)
  }
}

import { API_BASE_URL, getFetchOptions } from "constants/constants.js";

export async function postBlacklist(centerName, licensePlate) {
  const url = `${API_BASE_URL}/${centerName}/blacklist?license-plate=${licensePlate}`;
  const headers = getFetchOptions("POST");

  const response = await fetch(url, headers);
  if (response.status === 409) throw new Error("Already exists");
  if (response.status !== 201) throw new Error("Error intern de servidor");
}

export async function getAllBlacklist(centerName) {
  const url = `${API_BASE_URL}/${centerName}/blacklist`;
  const headers = getFetchOptions("GET");

  const response = await fetch(url, headers);
  if (response.status !== 200) throw new Error("Error intern de servidor");
  return await response.json();
}

export async function deleteBlacklist(centerName, id) {
  const url = `${API_BASE_URL}/${centerName}/blacklist?id=${id}`;
  const headers = getFetchOptions("DELETE");

  const response = await fetch(url, headers);
  if (response.status !== 200) throw new Error("Error intern de servidor");
}

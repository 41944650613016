import React, { useState } from 'react';
import { Descriptions, Flex, Space, Table } from 'antd';
import moment from 'moment';
import { TiExport } from 'react-icons/ti';
import { MdLocationOn } from 'react-icons/md';
import { HiMiniSignal } from 'react-icons/hi2';
import { TbCalendar } from 'react-icons/tb';
import { BsCheckAll } from 'react-icons/bs';

export const GaugeControlViolationsExported = ({ data, t, initialColumns }) => {
  const [pageSize, setPageSize] = useState(10);

  const columns = [
    {
      title: t('zar-violations.table.read-date'),
      dataIndex: 'processedDate',
      key: 'processedDate',
      align: 'center',
      render: (text, record) => {
        return (
          <Flex
            align="center"
            justify="center"
            gap={'small'}
          >
            <TbCalendar
              style={{
                fontSize: '20',
              }}
            />
            {moment(record.readDate).format('DD-MM-YYYY HH:mm:ss')}
          </Flex>
        );
      },
    },
    {
      title: t('zar-violations.tabs.exported.exported-date'),
      dataIndex: 'exportedDate',
      key: 'exportedDate',
      align: 'center',
      render: (text, record) => {
        return (
          <Flex
            align="center"
            justify="center"
            gap={'small'}
          >
            <TiExport
              style={{
                fontSize: '20',
              }}
            />
            {moment(record.exportedDate).format('DD-MM-YYYY HH:mm:ss')}
          </Flex>
        );
      },
    },
  ];

  return (
    <>
      <Table
        bordered
        dataSource={data}
        columns={initialColumns.concat(columns)}
        onChange={(e) => setPageSize(e.pageSize)}
        pagination={{ pageSize: pageSize, showSizeChanger: true }}
        expandable={{
          expandedRowRender: (record) => (
            <Descriptions
              title={t('gauge-control-violation.tabs.exported.collapse.title')}
              items={[
                {
                  key: '2',
                  label: (
                    <Space size={'small'}>
                      <MdLocationOn
                        style={{
                          verticalAlign: 'sub',
                          fontSize: '20',
                          marginRight: '-5px',
                        }}
                      />
                      {t('gauge-control-violation.tabs.exported.collapse.zone')}
                    </Space>
                  ),
                  children: record.zone,
                },
                {
                  key: '5',
                  label: (
                    <Space size="small">
                      <HiMiniSignal
                        style={{
                          verticalAlign: 'sub',
                          fontSize: '20',
                          marginRight: '-5px',
                        }}
                      />
                      {t('gauge-control-violation.tabs.exported.collapse.gauge-date')}
                    </Space>
                  ),
                  children: moment(record.readDate).format('DD-MM-YYYY HH:mm:ss'),
                },
                {
                  key: '6',
                  label: (
                    <Space size="small">
                      <BsCheckAll
                        style={{
                          verticalAlign: 'sub',
                          fontSize: '20',
                          marginRight: '-5px',
                        }}
                      />
                      {t('gauge-control-violation.tabs.exported.collapse.approve-date')}
                    </Space>
                  ),
                  children: moment(record.processedDate).format('DD-MM-YYYY HH:mm:ss'),
                },
              ]}
            />
          ),
        }}
      />
    </>
  );
};

import { API_BASE_URL, getFetchOptions } from 'constants/constants.js';

export async function getAllOppositeDirectionViolations(centerName, status, startDate, licensePlate) {
  var path_start_date = '';
  var path_license_plate = '';

  if (startDate !== null && startDate !== undefined && startDate !== '') path_start_date = '&date=' + startDate;

  if (licensePlate !== null && licensePlate !== undefined && licensePlate !== '')
    path_license_plate = '&license-plate=' + licensePlate;

  const url = `${API_BASE_URL}/${centerName}/opposite-direction-violations?status=${status}${path_start_date}${path_license_plate}`;
  const headers = getFetchOptions('GET');

  const response = await fetch(url, headers);
  if (response.status !== 200) throw new Error('Error intern de servidor');
  return await response.json();
}

export async function putOppositeDirectionViolationStatus(
  id,
  centerName,
  status,
  deniedReason = 'NO_REASON',
  reason = ''
) {
  var body = {
    ids: id,
    deniedReason: deniedReason,
    customDeniedReason: reason,
  };

  const url = `${API_BASE_URL}/${centerName}/opposite-direction-violations/status?status=${status}`;
  const headers = getFetchOptions('PUT');

  try {
    const response = await fetch(url, Object.assign({}, headers, { body: JSON.stringify(body) }));
    return response;
  } catch (error) {
    return console.log(error);
  }
}

export async function putOppositeDirectionViolationLicensePlate(id, centerName, licensePlate) {
  var body = {
    newLicensePlate: licensePlate,
  };

  const url = `${API_BASE_URL}/${centerName}/opposite-direction-violations/${id}`;
  const headers = getFetchOptions('PUT');

  try {
    const response = await fetch(url, Object.assign({}, headers, { body: JSON.stringify(body) }));
    return response;
  } catch (error) {
    return console.log(error);
  }
}

import { Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { getAllSpecialLicensePlatesBy } from 'repository/specialLicensePlate/SpecialLicensePlateRepository';
import SpecialLicensePlatesFilters from './SpecialLicensePlatesFilters';
import SpecialLicensePlatesTable from './SpecialLicensePlatesTable';

const WhiteListLicensePlates = ({ ...props }) => {
  const [specialLicensePlates, setSpecialLicensePlates] = useState([]);
  const [loading, setLoading] = useState(true);

  const getSpecialLicensePlates = (centerEntity, plate) => {
    getAllSpecialLicensePlatesBy(props.currentCenter.name, centerEntity, plate).then((response) => {
      setSpecialLicensePlates(
        response.map((plate, _) => {
          return {
            ...plate,
            key: v4(),
          };
        })
      );
      setLoading(false);
    });
  };

  useEffect(getSpecialLicensePlates, []);

  return (
    <Space
      style={{ width: '100%' }}
      direction="vertical"
      size={'middle'}
    >
      <SpecialLicensePlatesFilters
        {...props}
        getSpecialLicensePlates={getSpecialLicensePlates}
        setData={setSpecialLicensePlates}
        setLoading={setLoading}
      />
      <SpecialLicensePlatesTable
        {...props}
        data={specialLicensePlates}
        loading={loading}
      />
    </Space>
  );
};

export default withTranslation('common')(WhiteListLicensePlates);

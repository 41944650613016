import React, { useEffect, useState } from 'react';
import {
  Spin,
  Space,
  Radio,
  Statistic,
  ConfigProvider,
  DatePicker,
  Card,
  Form,
  Flex,
  Dropdown,
  Menu,
  Modal,
  Switch,
  Alert,
  Checkbox,
  Select,
  notification,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { BsClipboardCheck } from 'react-icons/bs';
import { MdCamera, MdSearch } from 'react-icons/md';
import { FaCheck, FaTimes } from 'react-icons/fa';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import {
  getRedLightPdfFile,
  getRedLightViolationPrice,
  getReportConfiguration,
  getTotalAffluence,
  getTotalRedLightReadings,
  getTotalRedLightViolations,
  updateReportConfiguration,
} from 'repository/reports/red-light-violations/RedLightViolationReportRepository';
import colors from 'components/Buttons/colors';
import { IoMdArrowDropdown } from 'react-icons/io';
import CreateRedLightExcel from './components/CreateRedLightExcel';
import { AiFillFilePdf } from 'react-icons/ai';
import CreateCSV from './components/CreateCSV';
const { RangePicker } = DatePicker;

const RedLightReport = ({ ...props }) => {
  const [loading, setLoading] = useState(true);
  const [totalReadings, setTotalReadings] = useState(0);
  const [totalPending, setTotalPending] = useState(0);
  const [totalApproved, setTotalApproved] = useState(0);
  const [totalDenied, setTotalDenied] = useState(0);
  const [redLightPrice, setRedLightPrice] = useState(0);
  const [affluence, setAffluence] = useState([]);
  const [optionDate, setOptionDate] = useState('today');
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [isActive, setIsActive] = useState(false);
  const [sendToMail, setSendToMail] = useState([]);
  const [pdfFormat, setPdfFormat] = useState(false);
  const [excelFormat, setExcelFormat] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const getReportsData = (startDate = moment(), endDate = moment()) => {
    const requests = [];

    requests.push(
      getTotalRedLightReadings(
        props.currentCenter.name,
        startDate.startOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
        endDate.endOf('day').format('YYYY-MM-DD HH:mm:ss').toString()
      )
    );
    requests.push(
      getTotalRedLightViolations(
        props.currentCenter.name,
        startDate.startOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
        endDate.endOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
        'PENDING'
      )
    );
    requests.push(
      getTotalRedLightViolations(
        props.currentCenter.name,
        startDate.startOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
        endDate.endOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
        'APPROVED'
      )
    );
    requests.push(
      getTotalRedLightViolations(
        props.currentCenter.name,
        startDate.startOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
        endDate.endOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
        'DENIED'
      )
    );
    requests.push(
      getTotalAffluence(
        props.currentCenter.name,
        startDate.startOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
        endDate.endOf('day').format('YYYY-MM-DD HH:mm:ss').toString()
      )
    );

    requests.push(getRedLightViolationPrice(props.currentCenter.name));

    Promise.all(requests)
      .then((responses) => {
        setTotalReadings(responses[0]);
        setTotalPending(responses[1]);
        setTotalApproved(responses[2]);
        setTotalDenied(responses[3]);
        setAffluence(responses[4]);
        setRedLightPrice(responses[5]);
        setLoading(false);
      })
      .catch((error) => setLoading(false));
  };

  const getReportConfig = () => {
    getReportConfiguration(props.currentCenter.name, 'RED_LIGHT').then((response) => {
      if (response !== undefined) {
        setIsActive(response.active);
        setSendToMail(response.sendTo);
        setPdfFormat(response.pdfFormat);
        setExcelFormat(response.excelFormat);
      }
    });
  };

  useEffect(getReportsData, []);
  useEffect(getReportConfig, []);

  const handleOptionDateChange = (value) => {
    setLoading(true);

    if (value === 'custom') {
      setOptionDate(value);
      setLoading(false);
      return;
    }

    let startDate, endDate;

    switch (value) {
      case 'today':
        startDate = moment();
        endDate = moment();
        setStartDate(startDate);
        setEndDate(endDate);
        break;

      case 'yesterday':
        startDate = moment().subtract(1, 'days');
        endDate = moment().subtract(1, 'days');
        setStartDate(startDate);
        setEndDate(endDate);
        break;

      case 'week':
        startDate = moment().subtract(6, 'days');
        endDate = moment();
        setStartDate(startDate);
        setEndDate(endDate);
        break;

      case 'month':
        startDate = moment().subtract(30, 'days');
        endDate = moment();
        setStartDate(startDate);
        setEndDate(endDate);
        break;

      default:
        return; // Sale si el valor no coincide con ninguno
    }

    setOptionDate(value);
    getReportsData(startDate, endDate);
  };

  const getTotalNonRedLightViolationsByZones = () => {
    var total = [];
    var arrayZonesAffluence = Object.values(affluence);

    for (let i = 0; i < arrayZonesAffluence.length; i++) {
      total.push(arrayZonesAffluence[i].redLightReadings - arrayZonesAffluence[i].approvedRedLightViolations);
    }

    return total;
  };

  const getTotalRedLightViolationsByZones = () => {
    var total = [];
    var arrayZonesAffluence = Object.values(affluence);

    for (let i = 0; i < arrayZonesAffluence.length; i++) {
      total.push(arrayZonesAffluence[i].approvedRedLightViolations);
    }

    return total;
  };

  const readingsChartOptions = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
    },
    title: {
      text: props.t('reports.red-light-violation-reports.readings-chart.title'),
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '{point.y:.2f} %',
          distance: -50,
          filter: {
            property: 'percentage',
            operator: '>',
            value: 4,
          },
        },
        showInLegend: true,
      },
    },
    colors: [colors.grey.main, colors.blue.main],
    series: [
      {
        name: 'lectures',
        colorByPoint: true,
        data: [
          {
            name: props.t('reports.red-light-violation-reports.readings-chart.non-sanctioned-readings'),
            y: parseFloat(Number(((totalReadings - totalApproved) / totalReadings) * 100).toFixed(2)),
          },
          {
            name: props.t('reports.red-light-violation-reports.readings-chart.approved-infractions'),
            y: parseFloat(Number((totalApproved / totalReadings) * 100).toFixed(2)),
            sliced: true,
            selected: true,
          },
        ],
      },
    ],
    credits: {
      enabled: false,
    },
  };

  const affluenceChartOptions = {
    title: {
      text: props.t('reports.red-light-violation-reports.zones-affluence.title'),
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
        },
      },
    },
    xAxis: {
      categories: Object.keys(affluence),
    },
    yAxis: {
      min: 0,
      title: {
        text: props.t('reports.red-light-violation-reports.zones-affluence.y'),
      },
      stackLabels: {
        enabled: true,
      },
    },
    colors: [colors.grey.main, colors.blue.main],
    chart: {
      type: 'column',
    },
    series: [
      {
        name: props.t('reports.red-light-violation-reports.zones-affluence.non-sanctioned-readings'),
        data: getTotalNonRedLightViolationsByZones(),
      },
      {
        name: props.t('reports.red-light-violation-reports.zones-affluence.approved-infractions'),
        data: getTotalRedLightViolationsByZones(),
      },
    ],
    credits: {
      enabled: false,
    },
  };

  const addZonesAffluenceToCSVData = (array) => {
    let zones = Object.keys(affluence);

    for (let i = 0; i < zones.length; i++) {
      array.push(['Afluencia de la zona ' + zones[i], String(affluence[zones[i]].redLightReadings + ' vehicles')]);
    }

    return array;
  };

  const getFiltersCSV = () => {
    if (optionDate === 'custom')
      return [
        props.t('reports.csv.headers.applied-filter'),
        props.t('reports.filters.custom'),
        props.t('reports.csv.headers.start-date'),
        moment(startDate).format('DD-MM-YYYY'),
        props.t('reports.csv.headers.end-date'),
        moment(endDate).format('DD-MM-YYYY'),
      ];

    return [props.t('reports.csv.headers.applied-filter'), props.t('reports.filters.' + optionDate)];
  };

  function getCSVData() {
    let centerInfo = ['Centre', props.currentCenter.name];
    let exportedDate = ['Informe generat el dia', moment().format('DD-MM-YYYY HH:mm:ss')];
    let filters = getFiltersCSV();
    let csvTotalReadings = ['Total lectures', String(totalReadings + ' vehicles')];
    let csvTotalPending = ['Total propostes de sanció', String(totalPending + ' propostes de sanció')];
    let csvTotalApproved = ['Total infraccions aprovades', String(totalApproved + ' infraccions aprovades')];
    let csvTotalDenied = ['Total infracciones denegades', String(totalDenied + ' infraccions denegades')];
    let earnings = ['Total ingressos', String(totalApproved * redLightPrice + ' €')];
    let estimatedEarnings = ['Ingressos estimats', String(totalPending * redLightPrice + ' €')];

    let result = [
      centerInfo,
      exportedDate,
      filters,
      [],
      csvTotalReadings,
      [],
      csvTotalPending,
      [],
      csvTotalApproved,
      [],
      csvTotalDenied,
      [],
      earnings,
      estimatedEarnings,
      [],
    ];

    result = addZonesAffluenceToCSVData(result);

    return result;
  }

  const dropdownMenu = (
    <Menu>
      <Menu.Item>
        <CreateRedLightExcel
          data={{
            totalReadings,
            totalPending,
            totalApproved,
            totalDenied,
            redLightPrice,
            affluence,
          }}
        />
      </Menu.Item>
      <Menu.Item>
        <CreateCSV data={getCSVData()}>CSV</CreateCSV>
      </Menu.Item>
      <Menu.Item>
        <ButtonPrimary
          color="red"
          size="middle"
          style={{ width: '100%' }}
          onClick={() => {
            setLoading(true);
            getRedLightPdfFile(
              props.currentCenter.name,
              moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
              moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss').toString()
            )
              .then((pdfUrl) => {
                const element = document.createElement('a');
                element.href = pdfUrl;
                document.body.appendChild(element); // Required so it works on FireFox
                element.click();
                setLoading(false);
              })
              .catch((err) => console.log(err));
          }}
        >
          <AiFillFilePdf
            style={{
              verticalAlign: 'sub',
              fontSize: '22',
              marginRight: '5px',
            }}
          />
          PDF
        </ButtonPrimary>
      </Menu.Item>
    </Menu>
  );

  const saveReportSettings = () => {
    if (isActive && sendToMail.length === 0) {
      notification.error({
        message: 'ZinkinData',
        description: props.t('reports.zar-violations-weekly-sending-reports.error.empty-emails'),
      });
    } else {
      const regexEmail = /^[^\s@]+(?:@[^\s@]+\.[^\s@]+)$/;
      let validate = false;
      for (let i = 0; i < sendToMail.length; i++) {
        const email = sendToMail[i];
        if (!regexEmail.test(email)) {
          validate = true;
        }
      }
      if (validate) {
        notification.error({
          message: 'ZinkinData',
          description: props.t('reports.zar-violations-weekly-sending-reports.error.incorrect-emails'),
        });
      } else {
        updateReportConfiguration(props.currentCenter.name, isActive, sendToMail, 'RED_LIGHT', pdfFormat, excelFormat)
          .then((response) => {
            setModalVisible(false);
          })
          .catch((error) => {
            notification.error({
              message: 'ZinkinData',
              description: props.t(error.message),
            });
          });
      }
    }
  };

  return (
    <Spin
      spinning={loading}
      indicator={
        <LoadingOutlined
          style={{ fontSize: 24 }}
          spin
        />
      }
      tip={props.t('reports.loading')}
      style={{ fontSize: 20 }}
    >
      <Space
        style={{ width: '100%' }}
        size={'middle'}
        direction="vertical"
      >
        <Space size={'middle'}>
          <Radio.Group
            value={optionDate}
            onChange={(value) => handleOptionDateChange(value.target.value)}
            buttonStyle="solid"
          >
            <Radio.Button value="today">{props.t('reports.filters.today')}</Radio.Button>
            <Radio.Button value="yesterday">{props.t('reports.filters.yesterday')}</Radio.Button>
            <Radio.Button value="week">{props.t('reports.filters.week')}</Radio.Button>
            <Radio.Button value="month">{props.t('reports.filters.month')}</Radio.Button>
            <Radio.Button value="custom">{props.t('reports.filters.custom')}</Radio.Button>
          </Radio.Group>

          {optionDate === 'custom' ? (
            <Form
              layout="inline"
              onFinish={(values) => {
                setLoading(true);
                getReportsData(moment(values.date[0].$d), moment(values.date[1].$d));
              }}
              clearOnDestroy
            >
              <ConfigProvider locale={props.currentUser.getLocale()}>
                <Form.Item name="date">
                  <RangePicker
                    style={{ width: '100%' }}
                    size="middle"
                    format={'DD/MM/YYYY'}
                  />
                </Form.Item>
              </ConfigProvider>
              <ButtonPrimary
                color="blue"
                shape="round"
                size="middle"
                htmlType="submit"
              >
                <Flex
                  align="center"
                  gap={'small'}
                >
                  Buscar
                  <MdSearch
                    style={{
                      fontSize: '20',
                    }}
                  />
                </Flex>
              </ButtonPrimary>
            </Form>
          ) : (
            <></>
          )}
        </Space>
        <Space
          size={'middle'}
          style={{ float: 'right' }}
        >
          <ButtonPrimary
            color="white"
            fontColor="black"
            shape="round"
            size="middle"
            onClick={() => setModalVisible(true)}
          >
            {props.t('reports.red-light-weekly-sending-reports.button')}
          </ButtonPrimary>
          {props.currentUser.isAdmin() ? (
            <ButtonPrimary
              color="blue"
              shape="round"
              size="middle"
              onClick={() => {
                props.history.push('/create-red-light-reports');
              }}
            >
              {props.t('reports.red-light-violation-create.create-btn')}
            </ButtonPrimary>
          ) : (
            <></>
          )}
          <Dropdown
            overlay={dropdownMenu}
            placement="bottom"
          >
            <ButtonPrimary
              color="black"
              shape="round"
            >
              <Flex
                align="center"
                gap={'small'}
              >
                {props.t('reports.export')}
                <IoMdArrowDropdown
                  style={{
                    fontSize: '20',
                  }}
                />
              </Flex>
            </ButtonPrimary>
          </Dropdown>
        </Space>

        <Flex justify="space-between">
          <Card>
            <Statistic
              title={props.t('reports.red-light-violation-reports.total-readings-card')}
              value={totalReadings}
              prefix={<MdCamera style={{ verticalAlign: 'sub', width: 'auto' }} />}
            />
          </Card>
          <Card>
            <Statistic
              title={props.t('reports.red-light-violation-reports.violations-card')}
              value={totalPending}
              valueStyle={{ color: '#cf1322' }}
              prefix={<BsClipboardCheck style={{ verticalAlign: 'sub', width: 'auto' }} />}
            />
          </Card>
          <Card>
            <Statistic
              title={props.t('reports.red-light-violation-reports.approved-infractions-card')}
              value={totalApproved}
              valueStyle={{ color: '#3f8600' }}
              prefix={<FaCheck style={{ verticalAlign: 'sub', width: 'auto' }} />}
            />
          </Card>
          <Card>
            <Statistic
              title={props.t('reports.red-light-violation-reports.denied-infractions-card')}
              value={totalDenied}
              valueStyle={{ color: '#ef8e14' }}
              prefix={<FaTimes style={{ verticalAlign: 'sub', width: 'auto' }} />}
            />
          </Card>
          <Card>
            <Statistic
              title={props.t('reports.red-light-violation-reports.earnings-card')}
              value={redLightPrice * totalApproved}
              valueStyle={{ color: '#3f8600' }}
              suffix="€"
            />
          </Card>
          <Card>
            <Statistic
              title={props.t('reports.red-light-violation-reports.estimated-earings-card')}
              value={redLightPrice * totalPending}
              valueStyle={{ color: '#8f19cc' }}
              suffix="€"
            />
          </Card>
        </Flex>
        <Flex justify="space-between">
          <Card style={{ width: '45%' }}>
            <HighchartsReact
              highcharts={Highcharts}
              options={readingsChartOptions}
            />
          </Card>
          <Card style={{ width: '50%' }}>
            <HighchartsReact
              highcharts={Highcharts}
              options={affluenceChartOptions}
            />
          </Card>
        </Flex>
      </Space>
      <Modal
        title={props.t('reports.red-light-weekly-sending-reports.title')}
        open={modalVisible}
        onOk={saveReportSettings}
        onCancel={() => setModalVisible(false)}
        closable={false}
        okButtonProps={{ className: 'button-primary-props-blue', shape: 'round' }}
        cancelButtonProps={{ className: 'button-primary-props-white', shape: 'round' }}
      >
        <Space
          style={{ width: '100%' }}
          direction="vertical"
          size={'middle'}
        >
          <div>
            <Switch
              checked={isActive}
              onChange={(value) => setIsActive(value)}
            />
            <span style={{ marginLeft: '10px' }}>
              {props.t('reports.red-light-weekly-sending-reports.enable-reporting')}
            </span>
          </div>

          <Alert
            message=""
            description={props.t('reports.red-light-weekly-sending-reports.description')}
            type="info"
          />

          <div>
            <div>Format:</div>
            <Checkbox
              checked={pdfFormat}
              onChange={(e) => {
                setPdfFormat(e.target.checked);
              }}
            >
              PDF
            </Checkbox>
            <Checkbox
              checked={excelFormat}
              onChange={(e) => {
                setExcelFormat(e.target.checked);
              }}
            >
              EXCEL
            </Checkbox>
          </div>

          <div>
            <div>Enviar al mail:</div>
            <Select
              size="large"
              mode="tags"
              style={{ width: '100%' }}
              placeholder="martiperez@gmail.com"
              value={sendToMail === null ? [] : sendToMail}
              onChange={(event) => {
                setSendToMail(event);
              }}
              showArrow={false}
              open={false}
            />
          </div>
        </Space>
      </Modal>
    </Spin>
  );
};

export default withTranslation('common')(RedLightReport);

import React from 'react';
import { Tabs } from 'antd';
import { withTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import WhiteListLicensePlates from './components/WhiteListLicensePlates';
import SpecialLicensePlates from './components/SpecialLicensePlates';

const WhitelistView = ({ ...props }) => {
  const items = [
    {
      key: v4(),
      label: props.t('whitelist.table.standard.title'),
      children: <WhiteListLicensePlates {...props} />,
    },
    {
      key: v4(),
      label: props.t('whitelist.table.special.title'),
      children: <SpecialLicensePlates {...props} />,
    },
  ];
  return (
    <Tabs
      defaultActiveKey="1"
      type="card"
      items={items}
    />
  );
};

export default withTranslation('common')(WhitelistView);

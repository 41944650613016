import { API_BASE_URL, getFetchOptions } from 'constants/constants';

export async function generateWTPZipFile(centerName, json) {
  const url = `${API_BASE_URL}/${centerName}/violations/wtp`;
  const headers = getFetchOptions('POST');

  const response = await fetch(url, Object.assign({}, headers, { body: JSON.stringify(json) }));
  if (response.status === 201) {
    return;
  } else {
    throw new Error('Error intern de servidor');
  }
}

export async function getPendingViolationsCount(centerName) {
  const url = `${API_BASE_URL}/${centerName}/violations/pending-count`;
  const headers = getFetchOptions('GET');

  const response = await fetch(url, headers);
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    throw new Error('Error intern de servidor');
  }
}

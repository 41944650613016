import { Table, Tag, Tooltip } from 'antd';
import colors from 'components/Buttons/colors';
import moment from 'moment';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { FaCircle } from 'react-icons/fa6';

const WhiteListLicensePlatesTable = ({ data, loading, ...props }) => {
  const columns = [
    {
      title: props.t('whitelist.table.standard.validity'),
      dataIndex: 'validity',
      key: 'validity',
      align: 'center',
      render: (_, record) => {
        const validityTags = {
          APPROVED: (
            <Tooltip
              color={colors.blue.main}
              placement="top"
              title={props.t('whitelist.table.standard.approved-text')}
            >
              <FaCircle style={{ color: '#28a745' }} />
            </Tooltip>
          ),
          PENDING: (
            <Tooltip
              color={colors.blue.main}
              placement="top"
              title={props.t('whitelist.table.standard.pending-text')}
            >
              <FaCircle style={{ color: 'orange' }} />
            </Tooltip>
          ),
          DENIED: (
            <Tooltip
              color={colors.blue.main}
              placement="top"
              title={props.t('whitelist.table.standard.denied-text')}
            >
              <FaCircle style={{ color: 'red' }} />
            </Tooltip>
          ),
        };

        return validityTags[record.validity] || <div></div>;
      },
    },
    {
      title: props.t('whitelist.table.standard.username'),
      dataIndex: 'username',
      key: 'username',
      align: 'center',
    },
    {
      title: props.t('whitelist.table.standard.license-plate'),
      dataIndex: 'licensePlate',
      key: 'licensePlate',
      align: 'center',
    },
    {
      title: props.t('whitelist.table.standard.status'),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (_, record) => {
        if (record.status === 'VALID')
          return <Tag color="green">{props.t('whitelist.filters.valid').toUpperCase()}</Tag>;
        else return <Tag color="volcano">{props.t('whitelist.filters.expired').toUpperCase()}</Tag>;
      },
    },
    {
      title: props.t('whitelist.table.standard.start-date'),
      dataIndex: 'startDate',
      key: 'startDate',
      align: 'center',
      render: (text, record) => {
        return moment(record.startDate).format('DD-MM-YYYY HH:mm:ss');
      },
    },
    {
      title: props.t('whitelist.table.standard.finish-date'),
      dataIndex: 'finishDate',
      key: 'finishDate',
      align: 'center',
      render: (text, record) => {
        if (record.finishDate !== undefined && record.finishDate !== null)
          return moment(record.finishDate).format('DD-MM-YYYY HH:mm:ss');
      },
    },
  ];

  return (
    <Table
      bordered
      loading={loading}
      dataSource={data}
      columns={columns}
      pagination={{ pageSize: 10, showSizeChanger: false }}
    />
  );
};

export default withTranslation('common')(WhiteListLicensePlatesTable);

import { Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { getWhiteListEntries } from 'repository/whitelistEntries/WhitelistEntryRepository';
import WhitelistLicensePlatesFilters from './WhitelistLicensePlatesFilters';
import WhitelistLicensePlatesTable from './WhitelistLicensePlatesTable';
import { v4 } from 'uuid';

const WhiteListLicensePlates = ({ ...props }) => {
  const [licensePlates, setLicensePlates] = useState([]);
  const [loading, setLoading] = useState(true);

  const getLicensePlates = (zoneId, validity, plate, status, username, temporality) => {
    getWhiteListEntries(zoneId, validity, plate, status, username, temporality, props.currentCenter.name).then(
      (response) => {
        setLicensePlates(
          response.map((plate, _) => {
            return {
              ...plate,
              key: v4(),
            };
          })
        );
        setLoading(false);
      }
    );
  };

  useEffect(getLicensePlates, []);

  return (
    <Space
      style={{ width: '100%' }}
      direction="vertical"
      size={'middle'}
    >
      <WhitelistLicensePlatesFilters
        {...props}
        getLicensePlates={getLicensePlates}
        setData={setLicensePlates}
        setLoading={setLoading}
      />
      <WhitelistLicensePlatesTable
        {...props}
        data={licensePlates}
        loading={loading}
      />
    </Space>
  );
};

export default withTranslation('common')(WhiteListLicensePlates);

import React from "react";
import { Spin, } from "antd";
import Icon from '@ant-design/icons';

export default function LoadingIndicator(props) {
  const antIcon = <Icon type="loading-3-quarters" style={{ fontSize: 30 }} spin />;
  
  return (
    <Spin
      tip="Cargando..."
      indicator={antIcon}
      style={{ display: "block", textAlign: "center", marginTop: 30 }}
    />
  );
}

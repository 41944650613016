import { API_BASE_URL, getFetchOptions } from 'constants/constants.js';

export async function getAllRedLightViolationReadingsByCenterIdAndStatus(centerName, status, date) {
  var path_date = '';

  if (date !== null && date !== undefined && date !== '') path_date = '&date=' + date;

  const url = `${API_BASE_URL}/${centerName}/red-light-violation-infraction?status=${status}${path_date}`;
  const headers = getFetchOptions('GET');

  const response = await fetch(url, headers);
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    throw new Error('Error intern de servidor');
  }
}

export async function putRedLightViolationStatus(id, centerName, status, deniedReason = 'NO_REASON', reason = '') {
  var body = {
    deniedReason: deniedReason,
    customDeniedReason: reason,
  };

  const url = `${API_BASE_URL}/${centerName}/red-light-violation-infraction/${id}/status?status=${status}`;
  const headers = getFetchOptions('PUT');

  try {
    const response = await fetch(url, Object.assign({}, headers, { body: JSON.stringify(body) }));
    return response;
  } catch (error) {
    return console.log(error);
  }
}

export async function editRedLightViolationLicensePlate(centerName, redLightViolationId, newLicensePlate) {
  const url = `${API_BASE_URL}/${centerName}/red-light-violation-infraction/${redLightViolationId}/license-plate?plate=${newLicensePlate}`;
  const headers = getFetchOptions('PUT');

  const response = await fetch(url, headers);
  if (response.status !== 200) throw new Error('Error intern de servidor');
}

import React, { useState } from 'react';
import { Table } from 'antd';

export const ZarViolationsVisible = ({ data, initialColumns }) => {
  const [pageSize, setPageSize] = useState(10);

  return (
    <>
      <Table
        bordered
        dataSource={data}
        columns={initialColumns}
        onChange={(e) => setPageSize(e.pageSize)}
        pagination={{ pageSize: pageSize, showSizeChanger: true }}
      />
    </>
  );
};

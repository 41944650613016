import React, { useState, useEffect, useRef } from 'react';
import {
  ConfigProvider,
  DatePicker,
  Input,
  Spin,
  Modal,
  Space,
  notification,
  Tooltip,
  Row,
  Col,
  Switch,
  Flex,
  Select,
  Table,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { MdRestartAlt, MdSearch, MdZoomIn } from 'react-icons/md';
import dayjs from 'dayjs';
import LicensePlateRender from 'components/LicensePlate/LicensePlateRender';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import colors from 'components/Buttons/colors';
import ImageVisor from 'components/ImageVisor';
import AccessDenied from 'views/AccessDenied/AccessDenied';
import { getZonesFromCenter } from 'repository/zone/ZoneRepository';
import { getAllCamaras } from 'repository/cameras/CamerasRepository';
import {
  deleteLicensePlateReads,
  editPlate,
  getLastLicensePlateReadings,
  getLicensePlateReadings,
} from 'repository/licensePlateReads/LicensePlateReadRepository';
import ButtonLink from 'components/Buttons/ButtonLink';
import EnvironmentRender from '../../components/EnvironmentTag/EnvironmentRender';
import { TbEdit, TbTrashXFilled } from 'react-icons/tb';

const Readings = ({ t, currentUser, currentCenter }) => {
  const [loading, setLoading] = useState(true);
  const [readings, setReadings] = useState([]);
  const [page, setPage] = useState(0);
  const [licensePlateEdit, setLicensePlateEdit] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [partialSearch, setPartialSearch] = useState(false);
  const [plateSearch, setPlateSearch] = useState('');
  const [zoneSearch, setZoneSearch] = useState(undefined);
  const [zoneList, setZoneList] = useState([]);
  const [cameraSearch, setCameraSearch] = useState(undefined);
  const [cameraList, setCameraList] = useState([]);
  const [startDateSearch, setStartDateSearch] = useState(null);
  const [endDateSearch, setEndDateSearch] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const zoomImageRef = useRef(null);
  const columns = [
    {
      title: t('readings.table.context-image'),
      dataIndex: 'contextImage',
      key: 'contextImage',
      align: 'center',
      render: (text, record) => {
        return (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setLicensePlateEdit(record);
              setModalVisible(true);
            }}
          >
            <ImageVisor
              centername={currentCenter.name}
              t={t}
              image={record.contextImage}
              key={record.id}
            />
          </div>
        );
      },
    },
    {
      title: t('readings.table.license-plate-image'),
      dataIndex: 'licensePlateImage',
      key: 'licensePlateImage',
      align: 'center',
      render: (text, record) => {
        return (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setLicensePlateEdit(record);
              setModalVisible(true);
            }}
          >
            <ImageVisor
              centername={currentCenter.name}
              t={t}
              image={record.licensePlateImage}
              isPlate
              key={record.id}
            />
          </div>
        );
      },
    },
    {
      title: t('readings.table.license-plate'),
      dataIndex: 'licensePlate',
      key: 'licensePlate',
      align: 'center',
      render: (_, record) => (
        <LicensePlateRender
          key={record.id}
          value={record.licensePlate}
        />
      ),
    },
    {
      title: t('readings.table.environment-tag'),
      dataIndex: 'environment-tag',
      key: 'environment-tag',
      align: 'center',
      render: (text, record) => <EnvironmentRender value={record.environment} />,
    },
    {
      title: t('readings.table.read-date'),
      dataIndex: 'readDate',
      key: 'readDate',
      align: 'center',
      render: (text, record) => moment(record.readDate).format('DD-MM-YYYY HH:mm:ss'),
    },
    {
      title: t('readings.table.zone'),
      dataIndex: 'zoneName',
      key: 'zoneName',
      align: 'center',
    },
    {
      title: t('readings.table.camera'),
      dataIndex: 'cameraName',
      key: 'cameraName',
      align: 'center',
    },
    {
      title: t('readings.table.actions'),
      dataIndex: 'acciones',
      width: '150px',
      align: 'center',
      render: (text, record) => {
        return (
          <Flex
            justify="center"
            align="center"
          >
            <ButtonLink
              color="grey"
              onClick={() => {
                setLicensePlateEdit(record);
                setModalVisible(true);
              }}
            >
              <TbEdit style={{ fontSize: '30' }} />
            </ButtonLink>
            <ButtonLink
              color="black"
              onClick={() => {
                Modal.confirm({
                  title: t('readings.delete-warning.message'),
                  okText: t('readings.delete-warning.confirm-button'),
                  cancelText: t('readings.delete-warning.cancel-button'),
                  centered: true,
                  okButtonProps: {
                    className: 'button-primary-props-blue',
                    shape: 'round',
                  },
                  cancelButtonProps: {
                    className: 'button-primary-props-white',
                    shape: 'round',
                  },
                  onOk: () => {
                    setLoading(true);
                    deleteLicensePlateReads(currentCenter.name, record.id)
                      .then(() => {
                        notification['success']({
                          message: 'ZinkinData',
                          description: 'Lectura eliminada correctament.',
                        });

                        getReadings(page);
                      })
                      .catch((error) => {
                        notification['error']({
                          message: 'ZinkinData',
                          description: error.message,
                        });
                      });
                  },
                });
              }}
            >
              <TbTrashXFilled style={{ fontSize: '30' }} />
            </ButtonLink>
          </Flex>
        );
      },
    },
  ];

  useEffect(fetchReadings, []);

  function fetchReadings() {
    getZonesFromCenter(currentCenter.name).then((response) => setZoneList(response));
    getAllCamaras(currentCenter.name).then((response) => setCameraList(response));
    getLastLicensePlateReadings(currentCenter.name, 0).then((response) => {
      setReadings(
        response.map((read, key) => ({
          key: read.id,
          ...read,
        }))
      );
      setLoading(false);
    });
  }

  const refreshTable = () => {
    if (
      plateSearch === '' &&
      partialSearch === false &&
      zoneSearch === undefined &&
      cameraSearch === undefined &&
      startDateSearch === null &&
      endDateSearch === null
    ) {
      getLastLicensePlateReadings(currentCenter.name, 0).then((response) =>
        setReadings(
          response.map((read, _) => ({
            key: read.id,
            ...read,
          }))
        )
      );
    }
  };

  useEffect(() => {
    const intervalId = setInterval(refreshTable, 10000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plateSearch, partialSearch, zoneSearch, cameraSearch, page, startDateSearch, endDateSearch]);

  const getReadings = (page) => {
    setLoading(true);
    if (
      plateSearch !== '' ||
      partialSearch !== false ||
      zoneSearch !== undefined ||
      cameraSearch !== undefined ||
      startDateSearch !== null ||
      endDateSearch !== null
    ) {
      getLicensePlateReadings(
        currentCenter.name,
        plateSearch,
        partialSearch,
        zoneSearch,
        cameraSearch,
        startDateSearch,
        endDateSearch,
        page
      ).then((response) => {
        setReadings(
          response.map((read, _) => ({
            key: read.id,
            ...read,
          }))
        );
        setLoading(false);
      });
    } else {
      getLastLicensePlateReadings(currentCenter.name, page).then((response) => {
        setReadings(
          response.map((read, _) => ({
            key: read.id,
            ...read,
          }))
        );
        setLoading(false);
      });
    }
  };

  const editLicensePlateRead = () => {
    setLoading(true);

    editPlate(currentCenter.name, licensePlateEdit.id, licensePlateEdit.licensePlate)
      .then(() => {
        setLicensePlateEdit({});
        setModalVisible(false);
        setConfirmLoading(false);
        getReadings(page);
        notification['success']({
          message: 'ZinkinData',
          description: t('readings.edit-plates.success'),
        });
        setLoading(false);
      })
      .catch((error) => {
        setLicensePlateEdit({});
        setModalVisible(false);
        setConfirmLoading(false);
        setLoading(false);
        notification['error']({
          message: 'ZinkinData',
          description: t('readings.edit-plates.error'),
        });
      });
  };

  if (!currentUser.isAdmin() && !currentUser.isBasicUser()) {
    return <AccessDenied t={t} />;
  }

  return (
    <>
      <Spin
        spinning={loading}
        indicator={
          <LoadingOutlined
            style={{ fontSize: 24 }}
            spin
          />
        }
        style={{ fontSize: 20 }}
      >
        <Row
          gutter={16}
          style={{ marginBottom: 20 }}
        >
          <Col span={3}>
            <Tooltip
              title={t('readings.popover')}
              placement="bottom"
              color={colors.blue.main}
            >
              <Flex
                align="center"
                vertical
              >
                <label>{t('readings.filters.parcial-search')}</label>
                <Switch
                  checked={partialSearch}
                  onChange={(event) => {
                    setPartialSearch(event);
                  }}
                />
              </Flex>
            </Tooltip>
          </Col>
          <Col span={3}>
            <Input
              size="large"
              placeholder={t('readings.filters.license-plate')}
              name="text"
              value={plateSearch}
              onChange={(event) => {
                setPlateSearch(event.target.value);
              }}
            />
          </Col>
          <Col span={3}>
            <Select
              value={zoneSearch}
              size="large"
              optionFilterProp="children"
              placeholder={t('readings.filters.zone')}
              onSelect={(value) => setZoneSearch(value)}
              allowClear
              onClear={() => setZoneSearch(undefined)}
              defaultActiveFirstOption={false}
              options={zoneList.map((option) => ({
                value: option.id,
                label: option.name,
              }))}
            />
          </Col>
          <Col span={3}>
            <Select
              value={cameraSearch}
              size="large"
              optionFilterProp="children"
              placeholder={t('readings.filters.camera')}
              onSelect={(value) => setCameraSearch(value)}
              allowClear
              onClear={() => setCameraSearch(undefined)}
              defaultActiveFirstOption={false}
              options={cameraList.map((option) => ({
                value: option.id,
                label: option.name,
              }))}
            />
          </Col>
          <Col span={6}>
            <ConfigProvider locale={currentUser.getLocale()}>
              <DatePicker.RangePicker
                onChange={(event) => {
                  setStartDateSearch(moment(event[0].$d).format('YYYY-MM-DD HH:mm:ss').toString());
                  setEndDateSearch(moment(event[1].$d).format('YYYY-MM-DD HH:mm:ss').toString());
                }}
                value={[
                  startDateSearch === null ? null : dayjs(startDateSearch),
                  endDateSearch === null ? null : dayjs(endDateSearch),
                ]}
                size="large"
                format={'DD/MM/YYYY HH:mm'}
                showTime={{
                  format: 'HH:mm',
                }}
                placeholder={[t('audit-logs.filters.start-date'), t('audit-logs.filters.end-date')]}
              />
            </ConfigProvider>
          </Col>
          <Col span={3}>
            <ButtonPrimary
              color="blue"
              shape="round"
              with="link"
              size="large"
              style={{ width: '100%' }}
              onClick={() => {
                setLoading(true);
                setPage(0);
                getReadings(0);
              }}
            >
              {t('readings.search-button')}
              <MdSearch
                style={{
                  verticalAlign: 'sub',
                  fontSize: '20',
                  marginLeft: '5px',
                }}
              />
            </ButtonPrimary>
          </Col>
          <Col span={3}>
            <ButtonPrimary
              color="black"
              shape="round"
              size="large"
              disabled={
                plateSearch === '' &&
                partialSearch === false &&
                zoneSearch === undefined &&
                cameraSearch === undefined &&
                startDateSearch === null &&
                endDateSearch === null
              }
              style={{ width: '100%' }}
              onClick={() => {
                setCameraSearch(undefined);
                setZoneSearch(undefined);
                setEndDateSearch(null);
                setStartDateSearch(null);
                setPartialSearch(false);
                setPlateSearch('');
                setLoading(true);
                setPage(0);

                getLastLicensePlateReadings(currentCenter.name, 0).then((response) => {
                  setReadings(
                    response.map((read, key) => ({
                      key: read.id,
                      ...read,
                    }))
                  );
                  setLoading(false);
                });
              }}
            >
              {t('readings.reset-button')}
              <MdRestartAlt
                style={{
                  verticalAlign: 'sub',
                  fontSize: '20',
                  marginLeft: '5px',
                }}
              />
            </ButtonPrimary>
          </Col>
        </Row>
        <Table
          bordered
          dataSource={readings}
          columns={columns}
          pagination={{ position: ['none', 'none'] }}
          footer={() => {
            return (
              <Flex
                gap={'middle'}
                justify="end"
              >
                <ButtonPrimary
                  color="blue"
                  shape="round"
                  size="large"
                  loading={loading}
                  disabled={page === 0}
                  onClick={() => {
                    setPage(page - 1);
                    getReadings(page - 1);
                  }}
                >
                  {t('audit-logs.previous-button')}
                </ButtonPrimary>
                <ButtonPrimary
                  color="blue"
                  shape="round"
                  size="large"
                  loading={loading}
                  disabled={readings && readings.length < 10}
                  onClick={() => {
                    setPage(page + 1);
                    getReadings(page + 1);
                  }}
                >
                  {t('audit-logs.next-button')}
                </ButtonPrimary>
              </Flex>
            );
          }}
        />
      </Spin>
      <Modal
        title={t('zar-violations.modal-edit.title')}
        open={modalVisible}
        onOk={() => {
          setConfirmLoading(true);
          editLicensePlateRead();
          zoomImageRef.current.resetTransform();
        }}
        onCancel={() => {
          zoomImageRef.current.resetTransform();
          setModalVisible(false);
          setLicensePlateEdit({});
        }}
        okText={t('zar-violations.modal-edit.confirm')}
        cancelText={t('zar-violations.modal-edit.cancel')}
        confirmLoading={confirmLoading}
        okButtonProps={{ style: { backgroundColor: colors.blue.main } }}
      >
        <Space
          direction="vertical"
          align="center"
          style={{ width: '100%' }}
          wrap
        >
          <TransformWrapper ref={zoomImageRef}>
            <TransformComponent>
              <div>
                <ImageVisor
                  centername={currentCenter.name}
                  t={t}
                  image={licensePlateEdit.contextImage}
                  isModal
                  key={licensePlateEdit.id}
                />
                <div
                  style={{
                    position: 'absolute',
                    top: '8px',
                    left: '8px',
                  }}
                >
                  <Tooltip
                    placement="bottom"
                    title={t('zar-violations.modal-edit.make-zoom')}
                    color={colors.blue.main}
                  >
                    <MdZoomIn style={{ fontSize: '30px', color: 'white' }} />
                  </Tooltip>
                </div>
              </div>
            </TransformComponent>
          </TransformWrapper>

          <ImageVisor
            centername={currentCenter.name}
            t={t}
            image={licensePlateEdit.licensePlateImage}
            isModal
            key={licensePlateEdit.id}
            isPlate
          />
          <Input
            size="large"
            style={{ width: '100%' }}
            value={licensePlateEdit.licensePlate}
            onChange={(event) => {
              setLicensePlateEdit({ ...licensePlateEdit, licensePlate: event.target.value });
            }}
          />
        </Space>
      </Modal>
    </>
  );
};

export default withTranslation('common')(Readings);

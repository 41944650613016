import React, { useState } from 'react';

import { optionsRoles } from 'constants/constants';

import { notification, Select, Form, Input } from 'antd';

import { withTranslation } from 'react-i18next';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import AccessDenied from 'views/AccessDenied/AccessDenied';
import { createUser } from 'repository/users/UserRepository';

const Option = Select.Option;

const UserCreate = ({ ...props }) => {
  const [loading, setLoading] = useState(false);

  if (!props.currentUser.isAdmin()) {
    return <AccessDenied t={props.t} />;
  }

  const onFinish = (values) => {
    setLoading(true);
    const json = { ...values, enable: true };

    createUser(props.currentCenter.name, json)
      .then(() => {
        notification['success']({
          message: 'ZinkinData',
          description: props.t('users.create.success'),
        });
        props.history.push('/users');
      })
      .catch(() => {
        notification['error']({
          message: 'ZinkinData',
          description: props.t('users.create.error'),
        });
      });

    setLoading(false);
  };

  return (
    <Form
      name="basic"
      initialValues={{ remember: true }}
      autoComplete="off"
      size="large"
      labelCol={{ span: 3 }}
      wrapperCol={{ span: 12 }}
      onFinish={onFinish}
    >
      <Form.Item
        label={props.t('users.create.form.username')}
        name="username"
        rules={[
          { required: true, message: props.t('users.create.handle.username.required') },
          { min: 3, message: props.t('users.create.handle.username.min') },
          { max: 15, message: props.t('users.create.handle.username.max') },
        ]}
      >
        <Input
          disabled={loading}
          placeholder={props.t('users.create.form.username-placeholder')}
        />
      </Form.Item>

      <Form.Item
        label={props.t('users.create.form.name')}
        name="name"
        rules={[
          { required: true, message: props.t('users.create.handle.name.required') },
          { min: 3, message: props.t('users.create.handle.name.min') },
        ]}
      >
        <Input
          disabled={loading}
          placeholder={props.t('users.create.form.name-placeholder')}
        />
      </Form.Item>

      <Form.Item
        label={props.t('users.create.form.surnames')}
        name="surname"
        rules={[{ min: 3, message: props.t('users.create.handle.surnames.min') }]}
      >
        <Input
          disabled={loading}
          placeholder={props.t('users.create.form.surnames-placeholder')}
        />
      </Form.Item>

      <Form.Item
        label={props.t('users.create.form.email')}
        name="email"
        rules={[
          { required: true, message: props.t('users.create.handle.email.required') },
          {
            type: 'email',
            message: props.t('users.create.handle.email.error'),
          },
        ]}
      >
        <Input
          disabled={loading}
          placeholder={props.t('users.create.form.email-placeholder')}
        />
      </Form.Item>

      <Form.Item
        label={props.t('users.create.form.agent-code')}
        name="agentCode"
        rules={[
          { required: true, message: props.t('users.create.handle.agent-code.required') },
          { min: 3, message: props.t('users.create.handle.agent-code.equals') },
          { max: 5, message: props.t('users.create.handle.agent-code.equals') },
        ]}
      >
        <Input
          disabled={loading}
          placeholder={props.t('users.create.form.agent-code-placeholder')}
        />
      </Form.Item>

      <Form.Item
        label={props.t('users.create.form.role')}
        name="role"
        rules={[{ required: true, message: props.t('users.create.handle.role.required') }]}
      >
        <Select
          size="large"
          disabled={loading}
          placeholder={props.t('users.create.form.role-placeholder')}
        >
          {optionsRoles.map((roles, key) => {
            return (
              <Option
                key={key}
                value={roles.value}
              >
                {roles.label}
              </Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item colon={false}>
        <ButtonPrimary
          loading={loading}
          color="blue"
          shape="round"
          size="large"
          htmlType="submit"
        >
          {props.t('users.create.form.create-button')}
        </ButtonPrimary>
      </Form.Item>
    </Form>
  );
};

export default withTranslation('common')(UserCreate);

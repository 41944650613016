import React from 'react';
import { Col, Descriptions, Flex, Modal, notification, Row, Table, Tag, Tooltip } from 'antd';
import { FaRoute, FaTruck } from 'react-icons/fa';
import { TbCalendarPlus, TbClockPlus, TbDirections, TbEdit, TbNetwork, TbTrashXFilled } from 'react-icons/tb';
import { MdCarCrash, MdDoNotDisturbOn, MdLocationOn, MdTraffic, MdVpnKey } from 'react-icons/md';
import { CiBarcode } from 'react-icons/ci';

import ButtonLink from 'components/Buttons/ButtonLink';
import { deleteCamara } from 'repository/cameras/CamerasRepository';
import { renderBrandWithLogo } from '../CameraBrand';
import colors from 'components/Buttons/colors';
import { v4 } from 'uuid';

export const CameraTable = ({ data, t, currentUser, onClickEdit, centerName, fetchCameras, history }) => {
  const columns = [
    {
      title: t('camera.list.table.name'),
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      align: 'center',
      width: 200,
    },
    {
      title: t('camera.list.table.marca'),
      dataIndex: 'marca',
      key: 'marca',
      width: 200,
      align: 'center',
      render: (_, record) => {
        return renderBrandWithLogo(record.marca);
      },
    },
    {
      title: t('camera.list.table.zone'),
      dataIndex: 'zone',
      key: 'zone',
      width: 200,
      align: 'center',
      render: (_, record) => {
        return (
          <Flex
            align="center"
            justify="center"
          >
            <MdLocationOn style={{ fontSize: '22' }} />
            {record.zone}
          </Flex>
        );
      },
    },
    {
      title: t('camera.list.table.infraction-types.title'),
      key: 'infractions',
      align: 'center',
      width: 250,
      render: (_, record) => {
        return (
          <Flex
            gap={'small'}
            align="center"
            justify="center"
          >
            {record.infractionTypes.map((type) => {
              switch (type.type) {
                case 'ZAR':
                  return (
                    <Tooltip
                      key={v4()}
                      color={colors.blue.main}
                      title={t('camera.list.table.infraction-types.zar')}
                    >
                      <Tag
                        color="geekblue"
                        key={type.id}
                      >
                        <MdCarCrash style={{ verticalAlign: 'sub', fontSize: '26' }} />
                      </Tag>
                    </Tooltip>
                  );
                case 'RED_LIGHT':
                  return (
                    <Tooltip
                      key={v4()}
                      color={colors.blue.main}
                      title={t('camera.list.table.infraction-types.red-light')}
                    >
                      <Tag
                        color="red"
                        key={type.id}
                      >
                        <MdTraffic style={{ verticalAlign: 'sub', fontSize: '26' }} />
                      </Tag>
                    </Tooltip>
                  );
                case 'OPPOSITE_DIRECTION':
                  return (
                    <Tooltip
                      key={v4()}
                      color={colors.blue.main}
                      title={t('camera.list.table.infraction-types.opposite-direction')}
                    >
                      <Tag
                        color="orange"
                        key={type.id}
                      >
                        <MdDoNotDisturbOn style={{ verticalAlign: 'sub', fontSize: '26' }} />
                      </Tag>
                    </Tooltip>
                  );
                case 'GAUGE_CONTROL':
                  return (
                    <Tooltip
                      key={v4()}
                      color={colors.blue.main}
                      title={t('camera.list.table.infraction-types.gauge-control')}
                    >
                      <Tag
                        color="green"
                        key={type.id}
                      >
                        <FaTruck style={{ verticalAlign: 'sub', fontSize: '26' }} />
                      </Tag>
                    </Tooltip>
                  );
                case 'SECTION_CONTROL':
                  return (
                    <Tooltip
                      key={v4()}
                      color={colors.blue.main}
                      title={t('camera.list.table.infraction-types.section-control')}
                    >
                      <Tag
                        color="purple"
                        key={type.id}
                      >
                        <FaRoute style={{ verticalAlign: 'sub', fontSize: '26' }} />
                      </Tag>
                    </Tooltip>
                  );
                default:
                  return <></>;
              }
            })}
          </Flex>
        );
      },
    },
  ];

  const getCorrectDirectionLabel = (correctDirection) => {
    switch (correctDirection) {
      case 'ANY':
        return <Tag>{t('camera.list.table.correct-direction.any')}</Tag>;
      case 'MOVING_AWAY':
        return <Tag>{t('camera.list.table.correct-direction.moving_away')}</Tag>;
      case 'APPROACHING':
        return <Tag>{t('camera.list.table.correct-direction.approaching')}</Tag>;
      default:
        return <Tag>{t('camera.list.table.correct-direction.any')}</Tag>;
    }
  };

  return (
    <Table
      bordered
      dataSource={data}
      expandable={{
        expandedRowRender: (record) => {
          return (
            <>
              <Row justify={'space-around'}>
                <Col span={11}>
                  <Descriptions
                    items={[
                      {
                        key: '1',
                        label: (
                          <Flex
                            align="center"
                            justify="center"
                            gap={'small'}
                          >
                            <TbNetwork style={{ fontSize: '22' }} />
                            {t('camera.list.table.ip')}
                          </Flex>
                        ),
                        children: record.ip,
                        span: 3,
                      },
                      {
                        key: '2',
                        label: (
                          <Flex
                            align="center"
                            justify="center"
                            gap={'small'}
                          >
                            <CiBarcode style={{ fontSize: '22' }} />
                            {t('camera.list.table.numeroSerie')}
                          </Flex>
                        ),
                        children: record.numeroSerie,
                        span: 3,
                      },
                    ]}
                    bordered
                    labelStyle={{ width: '30%', textAlign: 'end' }}
                  />
                </Col>
                <Col span={11}>
                  <Descriptions
                    items={[
                      {
                        key: '1',
                        label: (
                          <Flex
                            align="center"
                            justify="center"
                            gap={'small'}
                          >
                            <TbDirections style={{ fontSize: '22' }} />
                            {t('camera.list.table.correct-direction.title')}
                          </Flex>
                        ),
                        children: getCorrectDirectionLabel(record.correctDirection),
                        span: 3,
                      },
                      {
                        key: '2',
                        label: (
                          <Flex
                            align="center"
                            justify="center"
                            gap={'small'}
                          >
                            <MdVpnKey style={{ fontSize: '22' }} />
                            {t('camera.list.table.api-key')}
                          </Flex>
                        ),
                        children: record.apiKey,
                        span: 3,
                      },
                    ]}
                    bordered
                    labelStyle={{ width: '30%', textAlign: 'end' }}
                  />
                </Col>
              </Row>
            </>
          );
        },
      }}
      columns={
        currentUser.role !== 'ROLE_ADMIN' && currentUser.role !== 'ROLE_SUPERADMIN'
          ? columns
          : [
              ...columns,
              {
                title: t('camera.list.table.actions'),
                dataIndex: 'acciones',
                align: 'center',
                fixed: 'right',
                width: 310,
                render: (_, record) => {
                  return (
                    <Flex
                      justify="center"
                      align="center"
                    >
                      <ButtonLink
                        color="blue"
                        onClick={() => {
                          history.push({
                            pathname: '/camera-schedule',
                            state: { id: record.id },
                          });
                        }}
                      >
                        <Tooltip
                          color={colors.blue.main}
                          title={t('camera.list.table.schedule')}
                        >
                          <TbClockPlus style={{ fontSize: '30' }} />
                        </Tooltip>
                      </ButtonLink>

                      <ButtonLink
                        color="blue"
                        onClick={() => {
                          history.push({
                            pathname: '/camera-holidays',
                            state: { id: record.id },
                          });
                        }}
                      >
                        <Tooltip
                          color={colors.blue.main}
                          title={t('camera.list.table.holiday')}
                        >
                          <TbCalendarPlus style={{ fontSize: '30' }} />
                        </Tooltip>
                      </ButtonLink>

                      <ButtonLink
                        color="grey"
                        onClick={() =>
                          onClickEdit(
                            record.name,
                            record.numeroSerie,
                            record.id,
                            record.ip,
                            record.marca,
                            record.zone,
                            record.location,
                            record.latitude,
                            record.longitude,
                            record.correctDirection,
                            record.infractionTypes.map((item) => item.type)
                          )
                        }
                      >
                        <Tooltip
                          color={colors.blue.main}
                          title={t('camera.list.table.edit')}
                        >
                          <TbEdit style={{ fontSize: '30' }} />
                        </Tooltip>
                      </ButtonLink>

                      <ButtonLink
                        color="black"
                        onClick={() => {
                          Modal.confirm({
                            title: t('camera.list.warning.title'),
                            okText: t('camera.list.warning.confirm'),
                            cancelText: t('camera.list.warning.cancel'),
                            content: t('camera.list.warning.description'),
                            centered: true,
                            onOk: () => {
                              deleteCamara(centerName, record.id)
                                .then(() => {
                                  notification['success']({
                                    message: 'ZinkinData',
                                    description: t('camera.list.delete.success'),
                                  });
                                  fetchCameras();
                                })
                                .catch(() => {
                                  notification['error']({
                                    message: 'ZinkinData',
                                    description: t('camera.list.delete.error'),
                                  });
                                });
                            },
                            okButtonProps: {
                              className: 'button-primary-props-blue',
                              shape: 'round',
                            },
                            cancelButtonProps: {
                              className: 'button-primary-props-white',
                              shape: 'round',
                            },
                          });
                        }}
                      >
                        <Tooltip
                          color={colors.blue.main}
                          title={t('camera.list.table.delete')}
                        >
                          <TbTrashXFilled style={{ fontSize: '30' }} />
                        </Tooltip>
                      </ButtonLink>
                    </Flex>
                  );
                },
              },
            ]
      }
      pagination={{ pageSize: 10 }}
    />
  );
};

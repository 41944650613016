import { API_BASE_URL, getFetchOptions } from 'constants/constants.js';

export async function getAllConsults(centerName, status) {
  const url = `${API_BASE_URL}/${centerName}/consults?status=${status}`;
  const headers = getFetchOptions('GET');

  const response = await fetch(url, headers);
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    throw new Error('Error intern de servidor');
  }
}

export async function addConsult(centerName, json) {
  const url = `${API_BASE_URL}/${centerName}/consults`;
  const headers = getFetchOptions('POST');

  const response = await fetch(url, Object.assign({}, headers, { body: JSON.stringify(json) }));
  if (response.status !== 201) throw new Error('Error intern de servidor');
}

export async function addMessage(centerName, id, sender, message) {
  const url = `${API_BASE_URL}/${centerName}/consult-messages?consult-id=${id}&sender=${sender}&message=${message}`;
  const headers = getFetchOptions('POST');

  const response = await fetch(url, headers);
  if (response.status !== 201) throw new Error('Error intern de servidor');
}

export async function getMessagesByConsultId(centerName, id) {
  const url = `${API_BASE_URL}/${centerName}/consult-messages?consult-id=${id}`;
  const headers = getFetchOptions('GET');

  const response = await fetch(url, headers);
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    throw new Error('Error intern de servidor');
  }
}

export async function setStatusConsult(centerName, id, status) {
  const url = `${API_BASE_URL}/${centerName}/consults/${id}?status=${status}`;
  const headers = getFetchOptions('PUT');

  const response = await fetch(url, headers);
  if (response.status !== 200) throw new Error('Error intern de servidor');
}

import { API_BASE_URL, getFetchOptions } from 'constants/constants.js';

export async function getAllViolationsFilteredBy(centerName, status, startDate, licensePlate, zoneId) {
  var path_start_date = '';
  var path_license_plate = '';
  var path_zone_id = '';

  if (startDate !== null && startDate !== undefined && startDate !== '') path_start_date = '&date=' + startDate;

  if (licensePlate !== null && licensePlate !== undefined && licensePlate !== '')
    path_license_plate = '&license-plate=' + licensePlate;

  if (zoneId !== null && zoneId !== undefined) path_zone_id = '&zone-id=' + zoneId;

  const url = `${API_BASE_URL}/${centerName}/zar-violations?status=${status}${path_start_date}${path_license_plate}${path_zone_id}`;
  const headers = getFetchOptions('GET');

  const response = await fetch(url, headers);
  if (response.status !== 200) throw new Error('Error intern de servidor');
  return await response.json();
}

export async function putViolationStatus(id, centerName, status, deniedReason = 'NO_REASON', reason = '') {
  var body = {
    ids: id,
    deniedReason: deniedReason,
    customDeniedReason: reason,
  };

  const url = `${API_BASE_URL}/${centerName}/zar-violations/status?status=${status}`;
  const headers = getFetchOptions('PUT');

  try {
    const response = await fetch(url, Object.assign({}, headers, { body: JSON.stringify(body) }));
    return response;
  } catch (error) {
    return console.log(error);
  }
}

export async function putViolationLicensePlate(id, centerName, licensePlate) {
  var body = {
    newLicensePlate: licensePlate,
  };

  const url = `${API_BASE_URL}/${centerName}/zar-violations/${id}`;
  const headers = getFetchOptions('PUT');

  try {
    const response = await fetch(url, Object.assign({}, headers, { body: JSON.stringify(body) }));
    return response;
  } catch (error) {
    return console.log(error);
  }
}

export async function getPendingViolationsCount(centerName, status) {
  const url = `${API_BASE_URL}/${centerName}/zar-violations/count?status=${status}`;
  const headers = getFetchOptions('GET');

  try {
    const response = await fetch(url, headers);

    if (response.status !== 200) {
      throw new Error('Server internal error');
    }

    return await response.json();
  } catch (error) {
    console.error(error);
    throw error;
  }
}

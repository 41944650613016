import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Alert, ConfigProvider, Flex, Form, Modal, notification, Select, Space, Spin, Table, TimePicker } from 'antd';
import ButtonLink from 'components/Buttons/ButtonLink';
import { LoadingOutlined } from '@ant-design/icons';
import { TbTrashXFilled } from 'react-icons/tb';
import { v4 } from 'uuid';
import AccessDenied from 'views/AccessDenied/AccessDenied';
import moment from 'moment';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import { MdAdd } from 'react-icons/md';
import colors from 'components/Buttons/colors';
import {
  createZoneSchedule,
  deleteZoneSchedule,
  getZoneSchedule,
} from 'repository/zoneSchedule/ZoneScheduleRepository';

const ZoneSchedule = ({ ...props }) => {
  const [loading, setLoading] = useState(true);
  const [zoneSchedules, setZoneSchedules] = useState([]);
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);

  const { id } = props.location.state;

  const getSchedules = () => {
    getZoneSchedule(props.currentCenter.name, id)
      .then((response) => {
        setZoneSchedules(
          response.map((zone, _) => {
            return {
              ...zone,
              key: v4(),
            };
          })
        );
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(getSchedules, []);

  if (!props.currentUser.isAdmin()) {
    return <AccessDenied />;
  }

  const columns = [
    {
      title: props.t('zones.schedule.table.day.title'),
      dataIndex: 'day',
      key: 'day',
      render: (text, record) => {
        if (record.day !== undefined)
          switch (record.day) {
            case 'MONDAY':
              return <span>{props.t('zones.schedule.table.day.monday')}</span>;
            case 'TUESDAY':
              return <span>{props.t('zones.schedule.table.day.tuesday')}</span>;
            case 'WEDNESDAY':
              return <span>{props.t('zones.schedule.table.day.wednesday')}</span>;
            case 'THURSDAY':
              return <span>{props.t('zones.schedule.table.day.thursday')}</span>;
            case 'FRIDAY':
              return <span>{props.t('zones.schedule.table.day.friday')}</span>;
            case 'SATURDAY':
              return <span>{props.t('zones.schedule.table.day.saturday')}</span>;
            case 'SUNDAY':
              return <span>{props.t('zones.schedule.table.day.sunday')}</span>;

            default:
              break;
          }
      },
    },
    {
      title: props.t('zones.schedule.table.start-date'),
      dataIndex: 'startDate',
      key: 'startDate',
    },
    {
      title: props.t('zones.schedule.table.end-date'),
      dataIndex: 'endDate',
      key: 'endDate',
    },
    {
      title: props.t('zones.schedule.table.actions'),
      dataIndex: 'acciones',
      align: 'center',
      render: (text, record) => {
        return (
          <ButtonLink
            color="black"
            onClick={() => {
              Modal.confirm({
                title: props.t('zones.schedule.warning.title'),
                okText: props.t('zones.schedule.warning.confirm'),
                cancelText: props.t('zones.schedule.warning.cancel'),
                centered: true,
                okButtonProps: {
                  className: 'button-primary-props-blue',
                  shape: 'round',
                },
                cancelButtonProps: {
                  className: 'button-primary-props-white',
                  shape: 'round',
                },
                onOk: () => {
                  deleteZoneSchedule(props.currentCenter.name, record.id)
                    .then(() => {
                      notification.success({
                        message: 'ZinkinData',
                        description: props.t('zones.schedule.delete.success'),
                      });
                      getSchedules();
                    })
                    .catch(() => {
                      notification.success({
                        message: 'ZinkinData',
                        description: props.t('zones.schedule.delete.error'),
                      });
                    });
                },
              });
            }}
          >
            <TbTrashXFilled style={{ fontSize: '30' }} />
          </ButtonLink>
        );
      },
    },
  ];

  const onFinishForm = (values) => {
    setLoading(true);
    const json = {
      zoneId: id,
      startDate: moment(values.date[0].$d).format('HH:mm:ss').toString(),
      endDate: moment(values.date[1].$d).format('HH:mm:ss').toString(),
      days: values.days,
    };
    createZoneSchedule(props.currentCenter.name, json)
      .then(() => {
        notification.success({
          message: 'ZinkinData',
          description: props.t('zones.schedule.create.success'),
        });
        getSchedules();
        setLoading(false);
        setOpen(false);
      })
      .catch(() => {
        notification.error({
          message: 'ZinkinData',
          description: props.t('zones.schedule.create.error'),
        });
        setLoading(false);
      });
  };

  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 24 }}
      spin
    />
  );

  return (
    <>
      <Spin
        spinning={loading}
        indicator={antIcon}
      >
        <Space
          style={{ width: '100%' }}
          size={'middle'}
          direction="vertical"
        >
          <Alert
            message={props.t('zones.schedule.render.alert.message')}
            description={props.t('zones.schedule.render.alert.description')}
            type="info"
            showIcon
            closable
          />
          <ButtonPrimary
            color="blue"
            shape="round"
            size="large"
            onClick={() => setOpen(true)}
          >
            <Flex
              align="center"
              gap={'small'}
            >
              {props.t('zones.schedule.render.button')}
              <MdAdd
                style={{
                  fontSize: '20',
                }}
              />
            </Flex>
          </ButtonPrimary>
          <Modal
            open={open}
            title={props.t('zones.schedule.render.modal.title')}
            okText={props.t('zones.schedule.render.modal.confirm')}
            cancelText={props.t('zones.schedule.render.modal.cancel')}
            okButtonProps={{
              autoFocus: true,
              htmlType: 'submit',
              style: { backgroundColor: colors.blue.main },
            }}
            onCancel={() => setOpen(false)}
            destroyOnClose
            closable={false}
            modalRender={(dom) => (
              <Form
                form={form}
                layout="vertical"
                onFinish={onFinishForm}
              >
                {dom}
              </Form>
            )}
          >
            <ConfigProvider locale={props.currentUser.getLocale()}>
              <Form.Item
                name="date"
                layout="vertical"
                label={props.t('zones.schedule.render.modal.date')}
                rules={[{ required: true, message: props.t('zones.holiday.create.required-date') }]}
              >
                <TimePicker.RangePicker showTime />
              </Form.Item>
            </ConfigProvider>

            <Form.Item
              name="days"
              label={props.t('zones.schedule.render.modal.day.title')}
              rules={[{ required: true, message: props.t('zones.holiday.create.required-reason') }]}
            >
              <Select
                mode="multiple"
                placeholder={props.t('zones.schedule.render.modal.day.placeholder')}
              >
                <Select.Option value={'MONDAY'}>{props.t('zones.schedule.render.modal.day.monday')}</Select.Option>
                <Select.Option value={'TUESDAY'}>{props.t('zones.schedule.render.modal.day.tuesday')}</Select.Option>
                <Select.Option value={'WEDNESDAY'}>
                  {props.t('zones.schedule.render.modal.day.wednesday')}
                </Select.Option>
                <Select.Option value={'THURSDAY'}>{props.t('zones.schedule.render.modal.day.thursday')}</Select.Option>
                <Select.Option value={'FRIDAY'}>{props.t('zones.schedule.render.modal.day.friday')}</Select.Option>
                <Select.Option value={'SATURDAY'}>{props.t('zones.schedule.render.modal.day.saturday')}</Select.Option>
                <Select.Option value={'SUNDAY'}>{props.t('zones.schedule.render.modal.day.sunday')}</Select.Option>
              </Select>
            </Form.Item>
          </Modal>
          <Table
            bordered
            dataSource={zoneSchedules}
            columns={columns}
            pagination={{ pageSize: 5 }}
          />
        </Space>
      </Spin>
    </>
  );
};

export default withTranslation('common')(ZoneSchedule);

import React, { useEffect, useState } from 'react';
import { LoadingOutlined, UserOutlined } from '@ant-design/icons';
import { Spin, Table, Form, Input, notification, Select, Tag, ConfigProvider, DatePicker, Flex } from 'antd';
import { getAllActionTypes, getAllResourceTypes, getAuditLogs } from 'repository/auditLogs/AuditLogsRepository';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { MdRestartAlt, MdSearch } from 'react-icons/md';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import AccessDenied from 'views/AccessDenied/AccessDenied';
import { v4 } from 'uuid';
const FormItem = Form.Item;
const Option = Select.Option;
const { RangePicker } = DatePicker;

const AuditLogs = (props) => {
  const currentCenter = props.currentCenter.name;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [endDate, setEndDate] = useState(undefined);
  const [startDate, setStartDate] = useState(undefined);
  const [actionTypes, setActionTypes] = useState([]);
  const [resourceTypes, setResourceTypes] = useState([]);
  const [actionTypesSearch, setActionTypesSearch] = useState(undefined);
  const [resourceTypesSearch, setResourceTypesSearch] = useState(undefined);
  const [usernameSearch, setUsernameSearch] = useState(undefined);
  const [resourceDataSearch, setResourceDataSearch] = useState(undefined);
  const [resetButton, setResetButton] = useState(true);
  const [previousButton, setPreviousButton] = useState(true);
  const [nextButton, setNextButton] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);

  const columns = [
    {
      title: props.t('audit-logs.table.date'),
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      sorter: (a, b) => moment(a.date) - moment(b.date),
      render: (text, record) => {
        if (record.date !== undefined && record.date !== null) return moment(record.date).format('DD-MM-YYYY HH:mm:ss');
      },
    },
    {
      title: props.t('audit-logs.table.action'),
      dataIndex: 'actionType',
      key: 'actionType',
      align: 'center',
      render: (text, record) => {
        switch (record.actionType) {
          case 'CREATE':
            return <Tag color="blue">{props.t('audit-logs.action-types.create')}</Tag>;
          case 'UPDATE':
            return <Tag color="orange">{props.t('audit-logs.action-types.update')}</Tag>;
          case 'PENDING':
            return <Tag color="gold">{props.t('audit-logs.action-types.pending')}</Tag>;
          case 'APPROVE':
            return <Tag color="green">{props.t('audit-logs.action-types.approve')}</Tag>;
          case 'DENY':
            return <Tag color="magenta">{props.t('audit-logs.action-types.deny')}</Tag>;
          case 'EXPORT':
            return <Tag color="default">{props.t('audit-logs.action-types.export')}</Tag>;
          case 'DELETE':
            return <Tag color="volcano">{props.t('audit-logs.action-types.delete')}</Tag>;
          case 'VALIDITY_CHANGE':
            return <Tag color="geekblue">{props.t('audit-logs.action-types.validity-change')}</Tag>;
          case 'ACKNOWLEDGE':
            return <Tag color="purple">{props.t('audit-logs.action-types.acknowledge')}</Tag>;
          case 'LOGIN_ATTEMPT':
            return <Tag color="cyan">{props.t('audit-logs.action-types.login-attempt')}</Tag>;
          case 'UNINTENTIONAL':
            return <Tag color="red">{props.t('audit-logs.action-types.unintentional')}</Tag>;
          case 'INCOMPLETE':
            return <Tag color="lime">{props.t('audit-logs.action-types.incomplete')}</Tag>;
          case 'COMPLETE':
            return <Tag color="yellow">{props.t('audit-logs.action-types.complete')}</Tag>;
          case 'SEND':
            return <Tag color="brown">{props.t('audit-logs.action-types.send')}</Tag>;
          default:
            return <div></div>;
        }
      },
    },
    {
      title: props.t('audit-logs.table.resource'),
      dataIndex: 'resourceType',
      key: 'resourceType',
      align: 'center',
      render: (text, record) => {
        switch (record.resourceType) {
          case 'EXPORT_DONE':
            return props.t('audit-logs.resource-type.export-done');
          case 'LICENSE_PLATE_READING':
            return props.t('audit-logs.resource-type.license-plate-reading');
          case 'INFRACTION':
            return props.t('audit-logs.resource-type.infraction');
          case 'CAMERA':
            return props.t('audit-logs.resource-type.camera');
          case 'ZONE':
            return props.t('audit-logs.resource-type.zone');
          case 'WHITELIST_ENTRY':
            return props.t('audit-logs.resource-type.whitelist-entry');
          case 'ZONE_HOLIDAY':
            return props.t('audit-logs.resource-type.zone-holiday');
          case 'ZONE_SCHEDULE':
            return props.t('audit-logs.resource-type.zone-schedule');
          case 'ZONE_AUTHORIZATION':
            return props.t('audit-logs.resource-type.zone-authorization');
          case 'BLACKLIST':
            return props.t('audit-logs.resource-type.blacklist');
          case 'BLACKLIST_READ':
            return props.t('audit-logs.resource-type.blacklist-read');
          case 'SECURITY':
            return props.t('audit-logs.resource-type.security');
          case 'CAMERA_HOLIDAY':
            return props.t('audit-logs.resource-type.camera-holiday');
          case 'CAMERA_SCHEDULE':
            return props.t('audit-logs.resource-type.camera-schedule');
          case 'BWC_REPORT':
            return props.t('audit-logs.resource-type.bwc-report');
          default:
            return '';
        }
      },
      filters: [
        {
          text: props.t('audit-logs.resource-type.export-done'),
          value: 'EXPORT_DONE',
        },
        {
          text: props.t('audit-logs.resource-type.license-plate-reading'),
          value: 'LICENSE_PLATE_READING',
        },
        {
          text: props.t('audit-logs.resource-type.infraction'),
          value: 'INFRACTION',
        },
        {
          text: props.t('audit-logs.resource-type.camera'),
          value: 'CAMERA',
        },
        {
          text: props.t('audit-logs.resource-type.zone'),
          value: 'ZONE',
        },
        {
          text: props.t('audit-logs.resource-type.zone-holiday'),
          value: 'ZONE_HOLIDAY',
        },
        {
          text: props.t('audit-logs.resource-type.zone-schedule'),
          value: 'ZONE_SCHEDULE',
        },
        {
          text: props.t('audit-logs.resource-type.zone-authorization'),
          value: 'ZONE_AUTHORIZATION',
        },
        {
          text: props.t('audit-logs.resource-type.whitelist-entry'),
          value: 'WHITELIST_ENTRY',
        },
        {
          text: props.t('audit-logs.resource-type.blacklist'),
          value: 'BLACKLIST',
        },
        {
          text: props.t('audit-logs.resource-type.blacklist-read'),
          value: 'BLACKLIST_READ',
        },
        {
          text: props.t('audit-logs.resource-type.security'),
          value: 'SECURITY',
        },
        {
          text: props.t('audit-logs.resource-type.camera-holiday'),
          value: 'CAMERA_HOLIDAY',
        },
        {
          text: props.t('audit-logs.resource-type.camera-schedule'),
          value: 'CAMERA_SCHEDULE',
        },
      ],
    },
    {
      title: props.t('audit-logs.table.resource-data'),
      dataIndex: 'resourceData',
      key: 'resourceData',
      align: 'center',
    },
    {
      title: props.t('audit-logs.table.username'),
      dataIndex: 'username',
      key: 'username',
      align: 'center',
    },
  ];

  function fetchData() {
    setLoading(true);

    getAuditLogs(currentCenter, null, null, null, null, null, null, pageNumber)
      .then((response) => {
        setData(
          response.responses.map((log, index) => {
            return {
              ...log,
              key: v4(),
            };
          })
        );
      })
      .catch((error) => {
        notification['error']({
          message: 'ZinkinData',
          description: error.message,
        });
      });

    getAuditLogs(currentCenter, null, null, null, null, null, null, pageNumber + 1)
      .then((response) => {
        if (response.length === 0) {
          setNextButton(true);
        }
      })
      .catch((error) => {
        notification['error']({
          message: 'ZinkinData',
          description: error.message,
        });
      });

    getAllActionTypes(currentCenter)
      .then((response) => {
        setActionTypes(response);
      })
      .catch((error) => {
        notification['error']({
          message: 'ZinkinData',
          description: error.message,
        });
      });

    getAllResourceTypes(currentCenter)
      .then((response) => {
        setResourceTypes(response);
        setLoading(false);
      })
      .catch((error) => {
        notification['error']({
          message: 'ZinkinData',
          description: error.message,
        });
      });

    setLoading(false);
  }

  useEffect(fetchData, []);

  function getAllAuditLogs() {
    getAuditLogs(currentCenter, null, null, null, null, null, null, 0)
      .then((response) => {
        setData(response.responses);
      })
      .catch((error) => {
        notification['error']({
          message: 'ZinkinData',
          description: error.message,
        });
      });

    getAuditLogs(currentCenter, null, null, null, null, null, null, 1)
      .then((response) => {
        if (response.length === 0) {
          setNextButton(true);
        }
      })
      .catch((error) => {
        notification['error']({
          message: 'ZinkinData',
          description: error.message,
        });
      });
  }

  function getFilteredAuditLogs(page) {
    setLoading(true);
    getAuditLogs(
      currentCenter,
      resourceTypesSearch,
      actionTypesSearch,
      startDate,
      endDate,
      usernameSearch,
      resourceDataSearch,
      page
    )
      .then((response) => {
        setData(response.responses);
        setResetButton(false);
      })
      .catch((error) => {
        notification['error']({
          message: 'ZinkinData',
          description: error.message,
        });
      });

    getAuditLogs(
      currentCenter,
      resourceTypesSearch,
      actionTypesSearch,
      startDate,
      endDate,
      usernameSearch,
      resourceDataSearch,
      page + 1
    )
      .then((response) => {
        if (response.length === 0) {
          setNextButton(true);
        } else {
          setNextButton(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        notification['error']({
          message: 'ZinkinData',
          description: error.message,
        });
      });
  }

  function getTableData() {
    getAllAuditLogs();
  }

  const resetForm = () => {
    setStartDate();
    setEndDate();
    setResetButton(true);
    setActionTypesSearch(undefined);
    setResourceTypesSearch(undefined);
    setUsernameSearch(undefined);
    setResourceDataSearch(undefined);
    setPageNumber(0);
    getTableData();
  };

  function updatePageNumber(newPageNumber) {
    return new Promise((resolve) => {
      setPageNumber(pageNumber + newPageNumber);

      if (newPageNumber > 0) {
        setPreviousButton(false);
      } else {
        if (pageNumber + newPageNumber === 0) {
          setPreviousButton(true);
        } else {
          setPreviousButton(false);
        }
      }
      resolve();
    });
  }

  async function nextPage() {
    await updatePageNumber(1);
    getFilteredAuditLogs(pageNumber);
  }

  async function previousPage() {
    await updatePageNumber(-1);
    getFilteredAuditLogs(pageNumber);
  }

  function getActionTypesArray() {
    let actionTypesArray = [];
    actionTypes.forEach((element) => {
      switch (element) {
        case 'CREATE':
          actionTypesArray.push({
            actionType: 'CREATE',
            traduccion: props.t('audit-logs.action-types.create'),
          });
          break;
        case 'UPDATE':
          actionTypesArray.push({
            actionType: 'UPDATE',
            traduccion: props.t('audit-logs.action-types.update'),
          });
          break;
        case 'APPROVE':
          actionTypesArray.push({
            actionType: 'APPROVE',
            traduccion: props.t('audit-logs.action-types.approve'),
          });
          break;
        case 'PENDING':
          actionTypesArray.push({
            actionType: 'PENDING',
            traduccion: props.t('audit-logs.action-types.pending'),
          });
          break;
        case 'DENY':
          actionTypesArray.push({
            actionType: 'DENY',
            traduccion: props.t('audit-logs.action-types.deny'),
          });
          break;
        case 'EXPORT':
          actionTypesArray.push({
            actionType: 'EXPORT',
            traduccion: props.t('audit-logs.action-types.export'),
          });
          break;
        case 'DELETE':
          actionTypesArray.push({
            actionType: 'DELETE',
            traduccion: props.t('audit-logs.action-types.delete'),
          });
          break;
        case 'VALIDITY_CHANGE':
          actionTypesArray.push({
            actionType: 'VALIDITY_CHANGE',
            traduccion: props.t('audit-logs.action-types.validity-change'),
          });
          break;
        case 'ACKNOWLEDGE':
          actionTypesArray.push({
            actionType: 'ACKNOWLEDGE',
            traduccion: props.t('audit-logs.action-types.acknowledge'),
          });
          break;
        case 'LOGIN_ATTEMPT':
          actionTypesArray.push({
            actionType: 'LOGIN_ATTEMPT',
            traduccion: props.t('audit-logs.action-types.login-attempt'),
          });
          break;
        case 'UNINTENTIONAL':
          actionTypesArray.push({
            actionType: 'UNINTENTIONAL',
            traduccion: props.t('audit-logs.action-types.unintentional'),
          });
          break;
        case 'INCOMPLETE':
          actionTypesArray.push({
            actionType: 'INCOMPLETE',
            traduccion: props.t('audit-logs.action-types.incomplete'),
          });
          break;
        case 'COMPLETE':
          actionTypesArray.push({
            actionType: 'COMPLETE',
            traduccion: props.t('audit-logs.action-types.complete'),
          });
          break;
        case 'SEND':
          actionTypesArray.push({
            actionType: 'SEND',
            traduccion: props.t('audit-logs.action-types.send'),
          });
          break;
        default:
          actionTypesArray.push({
            actionType: '',
            traduccion: '',
          });
      }
    });

    return actionTypesArray;
  }

  function getResourceTypesArray() {
    let resourceTypesArray = [];
    resourceTypes.forEach((element) => {
      switch (element) {
        case 'EXPORT_DONE':
          resourceTypesArray.push({
            resourceType: 'EXPORT_DONE',
            traduccion: props.t('audit-logs.resource-type.export-done'),
          });
          break;
        case 'LICENSE_PLATE_READING':
          resourceTypesArray.push({
            resourceType: 'LICENSE_PLATE_READING',
            traduccion: props.t('audit-logs.resource-type.license-plate-reading'),
          });
          break;
        case 'INFRACTION':
          resourceTypesArray.push({
            resourceType: 'INFRACTION',
            traduccion: props.t('audit-logs.resource-type.infraction'),
          });
          break;
        case 'CAMERA':
          resourceTypesArray.push({
            resourceType: 'CAMERA',
            traduccion: props.t('audit-logs.resource-type.camera'),
          });
          break;
        case 'ZONE':
          resourceTypesArray.push({
            resourceType: 'ZONE',
            traduccion: props.t('audit-logs.resource-type.zone'),
          });
          break;
        case 'ZONE_HOLIDAY':
          resourceTypesArray.push({
            resourceType: 'ZONE_HOLIDAY',
            traduccion: props.t('audit-logs.resource-type.zone-holiday'),
          });
          break;
        case 'ZONE_SCHEDULE':
          resourceTypesArray.push({
            resourceType: 'ZONE_SCHEDULE',
            traduccion: props.t('audit-logs.resource-type.zone-schedule'),
          });
          break;
        case 'ZONE_AUTHORIZATION':
          resourceTypesArray.push({
            resourceType: 'ZONE_AUTHORIZATION',
            traduccion: props.t('audit-logs.resource-type.zone-authorization'),
          });
          break;
        case 'WHITELIST_ENTRY':
          resourceTypesArray.push({
            resourceType: 'WHITELIST_ENTRY',
            traduccion: props.t('audit-logs.resource-type.whitelist-entry'),
          });
          break;
        case 'BLACKLIST':
          resourceTypesArray.push({
            resourceType: 'BLACKLIST',
            traduccion: props.t('audit-logs.resource-type.blacklist'),
          });
          break;
        case 'BLACKLIST_READ':
          resourceTypesArray.push({
            resourceType: 'BLACKLIST_READ',
            traduccion: props.t('audit-logs.resource-type.blacklist-read'),
          });
          break;
        case 'SECURITY':
          resourceTypesArray.push({
            resourceType: 'SECURITY',
            traduccion: props.t('audit-logs.resource-type.security'),
          });
          break;
        case 'CAMERA_SCHEDULE':
          resourceTypesArray.push({
            resourceType: 'CAMERA_SCHEDULE',
            traduccion: props.t('audit-logs.resource-type.camera-schedule'),
          });
          break;
        case 'CAMERA_HOLIDAY':
          resourceTypesArray.push({
            resourceType: 'CAMERA_HOLIDAY',
            traduccion: props.t('audit-logs.resource-type.camera-holiday'),
          });
          break;
        case 'BWC_REPORT':
          resourceTypesArray.push({
            resourceType: 'BWC_REPORT',
            traduccion: props.t('audit-logs.resource-type.bwc-report'),
          });
          break;
        default:
          resourceTypesArray.push({
            resourceType: '',
            traduccion: '',
          });
          break;
      }
    });
    return resourceTypesArray;
  }

  if (props.currentUser.role !== 'ROLE_ADMIN') {
    return <AccessDenied t={props.t} />;
  }

  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 24 }}
      spin
    />
  );
  const actionTypesOptions = getActionTypesArray().map((record) => (
    <Option
      key={v4()}
      value={record.actionType}
    >
      {record.traduccion}
    </Option>
  ));
  const resourceTypesOptions = getResourceTypesArray().map((record) => (
    <Option
      key={v4()}
      value={record.resourceType}
    >
      {record.traduccion}
    </Option>
  ));

  return (
    <>
      {alert}
      <Spin
        spinning={loading}
        indicator={antIcon}
      >
        <div>
          <Form layout="inline">
            <FormItem>
              <Select
                value={actionTypesSearch}
                showSearch
                size="large"
                style={{ height: '40px', width: '200px' }}
                optionFilterProp="children"
                placeholder={props.t('audit-logs.filters.action')}
                onChange={(value) => {
                  if (typeof value !== 'undefined') {
                    setActionTypesSearch(value);
                    setResetButton(false);
                  } else {
                    setActionTypesSearch(undefined);
                  }
                }}
                onSelect={(value) => {
                  setActionTypesSearch(value);
                  setResetButton(false);
                }}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                allowClear
                defaultActiveFirstOption={false}
              >
                {actionTypesOptions}
              </Select>
            </FormItem>
            <FormItem>
              <Select
                showSearch
                size="large"
                style={{ height: '40px', width: '200px' }}
                optionFilterProp="children"
                placeholder={props.t('audit-logs.filters.resource')}
                onChange={(value) => {
                  if (typeof value !== 'undefined') {
                    setResourceTypesSearch(value);
                    setResetButton(false);
                  } else {
                    setResourceTypesSearch(undefined);
                  }
                }}
                onSelect={(value) => {
                  setResourceTypesSearch(value);
                  setResetButton(false);
                }}
                name="camara"
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                allowClear
                value={resourceTypesSearch}
                defaultActiveFirstOption={false}
              >
                {resourceTypesOptions}
              </Select>
            </FormItem>
            <FormItem>
              {
                <ConfigProvider locale={props.currentUser.getLocale()}>
                  <RangePicker
                    style={{ width: '250px' }}
                    size="large"
                    onChange={(event) => {
                      setStartDate(event[0].$d);
                      setEndDate(event[1].$d);
                      setResetButton(false);
                    }}
                    format={'DD/MM/YYYY'}
                    placeholder={[props.t('audit-logs.filters.start-date'), props.t('audit-logs.filters.end-date')]}
                  />
                </ConfigProvider>
              }
            </FormItem>
            <FormItem>
              {
                <Input
                  size="large"
                  placeholder={props.t('audit-logs.filters.resource-data')}
                  name="resourceDataSearch"
                  value={resourceDataSearch}
                  onChange={(event) => {
                    setResourceDataSearch(event.target.value);
                    setResetButton(false);
                  }}
                />
              }
            </FormItem>
            <FormItem>
              {
                <Input
                  suffix={<UserOutlined />}
                  size="large"
                  placeholder={props.t('audit-logs.filters.username')}
                  name="usernameSearch"
                  value={usernameSearch}
                  onChange={(event) => {
                    setUsernameSearch(event.target.value);
                    setResetButton(false);
                  }}
                />
              }
            </FormItem>
            <FormItem>
              <ButtonPrimary
                color="blue"
                shape="round"
                size="large"
                style={{ width: '100%' }}
                onClick={() => {
                  getFilteredAuditLogs(0);
                  setPreviousButton(true);
                }}
              >
                {props.t('audit-logs.search-button')}
                <MdSearch
                  style={{
                    verticalAlign: 'sub',
                    fontSize: '20',
                    marginLeft: '5px',
                  }}
                />
              </ButtonPrimary>
            </FormItem>
            <FormItem>
              <ButtonPrimary
                color="black"
                shape="round"
                size="large"
                disabled={resetButton}
                style={{ width: '100%' }}
                onClick={() => {
                  resetForm();
                }}
              >
                {props.t('audit-logs.reset-button')}
                <MdRestartAlt
                  style={{
                    verticalAlign: 'sub',
                    fontSize: '20',
                    marginLeft: '5px',
                  }}
                />
              </ButtonPrimary>
            </FormItem>
          </Form>
          <Table
            bordered
            dataSource={data}
            columns={columns}
            pagination={{ position: ['none', 'none'] }}
            style={{ marginTop: 15 }}
            footer={() => {
              return (
                <Flex
                  gap={'middle'}
                  justify="end"
                >
                  <ButtonPrimary
                    color="blue"
                    shape="round"
                    size="large"
                    disabled={previousButton}
                    onClick={() => {
                      previousPage();
                    }}
                  >
                    {props.t('audit-logs.previous-button')}
                  </ButtonPrimary>
                  <ButtonPrimary
                    color="blue"
                    shape="round"
                    size="large"
                    disabled={nextButton}
                    onClick={() => {
                      nextPage();
                    }}
                  >
                    {props.t('audit-logs.next-button')}
                  </ButtonPrimary>
                </Flex>
              );
            }}
          />
        </div>
      </Spin>
    </>
  );
};

export default withTranslation('common')(AuditLogs);

import React from 'react';
import { withTranslation } from 'react-i18next';

const AccessDenied = ({ t }) => (
  <div>
    <div style={{ fontWeight: 'bold', margin: '35px' }}>{t('access-denied')}</div>
  </div>
);

export default withTranslation('common')(AccessDenied);

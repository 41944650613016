import { API_BASE_URL, getFetchOptions } from 'constants/constants.js';

export async function getSoundReports(centerName, date) {
    const url = `${API_BASE_URL}/${centerName}/reports/sound?date=${date}`;
    const headers = getFetchOptions("GET");


    const response = await fetch(url, headers);
    if(response.status === 200){
      const data = await response.json()
      return data;
    }
    else{
      throw new Error("Error intern de servidor")
    }

  }
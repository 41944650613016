import React from 'react';
import { Flex, Table, notification } from 'antd';
import { MdUndo } from 'react-icons/md';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import { putOppositeDirectionViolationStatus } from 'repository/oppositeDirectionViolation/OppositeDirectionViolationRepository';
import { getTraductionByName } from 'constants/denied_reasons';

export const OppositeDirectionViolationsDenied = ({
  data,
  t,
  currentCenter,
  initialColumns,
  fetchViolations,
  setLoading,
}) => {
  const columns = [
    {
      title: t('zar-violations.tabs.denied.deniedReason'),
      dataIndex: 'deniedReason',
      align: 'center',
      render: (text, record) => {
        return t(getTraductionByName(record.deniedReason));
      },
    },
    {
      title: t('opposite-direction-violation.tabs.approved.actions'),
      align: 'center',
      width: '200px',
      render: (text, record) => {
        return (
          <Flex
            justify="center"
            align="center"
            vertical
          >
            <ButtonPrimary
              color="grey"
              shape="round"
              size="large"
              style={{
                margin: '5px 0px 5px 0px',
                width: '100%',
              }}
              onClick={() => {
                setLoading(true);
                putOppositeDirectionViolationStatus([record.id], currentCenter.name, 'PENDING')
                  .then(() => {
                    fetchViolations('DENIED');
                    notification['success']({
                      message: 'ZinkinData',
                      description: t('red-light-violation.deny.success'),
                    });
                    setLoading(false);
                  })
                  .catch(() => {
                    this.setState({ loading: false });
                    notification['error']({
                      message: 'ZinkinData',
                      description: t('zar-violations.pending.error'),
                    });
                  });
              }}
            >
              {t('opposite-direction-violation.tabs.denied.rollback')}
              <MdUndo
                style={{
                  verticalAlign: 'sub',
                  fontSize: '20',
                  marginLeft: '5px',
                }}
              />
            </ButtonPrimary>
          </Flex>
        );
      },
    },
  ];

  return (
    <>
      <Table
        bordered
        dataSource={data}
        columns={initialColumns.concat(columns)}
        pagination={{ pageSize: 10 }}
      />
    </>
  );
};

import React from 'react';
import './license-plate.css';

const LicensePlateRender = ({ value }) => {
  return value.split('').map((caracter, index) => {
    let estilo = {};
    let className = 'letter';
    if (/[aeiouAEIOU]/.test(caracter)) {
      estilo.color = '#b504d9';
      className = 'letter';
    } else if (/[bcdfghjklmnpqrstvwxyzBCDFGHJKLMNPQRSTVWXYZ]/.test(caracter)) {
      className = 'letter';
    } else if (!isNaN(caracter)) {
      className = 'number';
    }
    return (
      <span
        key={index}
        className={className}
        style={estilo}
      >
        {caracter.toUpperCase()}
      </span>
    );
  });
};

export default LicensePlateRender;

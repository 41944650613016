import React, { useState } from 'react';
import { Table, notification } from 'antd';
import { MdUndo } from 'react-icons/md';
import moment from 'moment';
import { putViolationStatus } from 'repository/zarViolations/ZarViolationsRepository';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import { getTraductionByName } from 'constants/denied_reasons';

export const ZarViolationsDenied = ({ data, t, currentCenter, initialColumns, fetchViolations, setLoading }) => {
  const [pageSize, setPageSize] = useState(10);

  const columns = [
    {
      title: t('zar-violations.tabs.denied.processed-date'),
      dataIndex: 'processedDate',
      key: 'processedDate',
      align: 'center',
      render: (text, record) => {
        if (record.processedDate !== undefined && record.processedDate !== null)
          return moment(record.processedDate).format('DD-MM-YYYY HH:mm:ss');
      },
    },
    {
      title: t('zar-violations.tabs.denied.deniedReason'),
      dataIndex: 'deniedReason',
      width: '200px',
      align: 'center',
      render: (text, record) => {
        return t(getTraductionByName(record.deniedReason));
      },
    },
    {
      title: t('zar-violations.table.actions'),
      width: '200px',
      align: 'center',
      render: (text, record) => {
        return (
          <ButtonPrimary
            color="grey"
            shape="round"
            size="large"
            style={{
              margin: '5px 0px 5px 0px',
              width: '100%',
            }}
            onClick={() => {
              setLoading(true);
              putViolationStatus([record.id], currentCenter.name, 'PENDING')
                .then(() => {
                  fetchViolations('DENIED');
                  notification['success']({
                    message: 'ZinkinData',
                    description: t('zar-violations.pending.success'),
                  });
                  setLoading(false);
                })
                .catch(() => {
                  this.setState({ loading: false });
                  notification['error']({
                    message: 'ZinkinData',
                    description: t('zar-violations.pending.error'),
                  });
                });
            }}
          >
            {t('zar-violations.tabs.denied.rollback')}
            <MdUndo
              style={{
                verticalAlign: 'sub',
                fontSize: '20',
                marginLeft: '5px',
              }}
            />
          </ButtonPrimary>
        );
      },
    },
  ];

  return (
    <>
      <Table
        bordered
        dataSource={data}
        columns={initialColumns.concat(columns)}
        onChange={(e) => setPageSize(e.pageSize)}
        pagination={{ pageSize: pageSize, showSizeChanger: true }}
      />
    </>
  );
};

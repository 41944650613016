import React, { Component } from 'react';
import { Form, Input } from 'antd';
import { notification } from 'antd';

import { withTranslation } from 'react-i18next';

import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import { editPassword } from 'repository/users/UserRepository';

class ChangePasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  render() {
    const confirm_password_message = this.props.t('users.create.handle.password.confirm-password');
    const error_validation_password_message = this.props.t('users.edit.handle.password-pattern');
    const submitFirstLogin = (values) => {
      editPassword(this.props.currentCenterName, { password: values.password })
        .then(() => {
          notification['success']({
            message: 'ZinkinData',
            description: this.props.t('profile.language.success'),
          });
          this.props.updatedPassword();
        })
        .catch((error) => {
          notification['error']({
            message: 'ZinkinData',
            description: error.message,
          });
        });
    };
    return (
      <Form
        layout="vertical"
        onFinish={submitFirstLogin}
        size="large"
      >
        <Form.Item
          name="password"
          label={this.props.t('profile.password.new')}
          rules={[
            {
              required: true,
              message: this.props.t('users.edit.handle.password-required'),
            },
            () => ({
              validator(_, value) {
                if (value.length < 6) {
                  return Promise.reject(new Error(error_validation_password_message));
                }

                const patron = new RegExp(
                  '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&\\-_=+#~,.])[A-Za-z\\d@$!%*?&\\-_=+#~,.]+$'
                );
                if (!patron.test(value)) {
                  return Promise.reject(new Error(error_validation_password_message));
                }
                return Promise.resolve();
              },
            }),
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="confirm"
          label={this.props.t('users.edit.set-password.confirm')}
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: this.props.t('users.create.handle.password.required'),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(confirm_password_message));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <ButtonPrimary
          color="blue"
          htmlType="submit"
          size="large"
          shape="round"
          style={{ width: '100%' }}
        >
          {this.props.t('users.edit.set-password.update')}
        </ButtonPrimary>
      </Form>
    );
  }
}

export default withTranslation('common')(ChangePasswordForm);

import React, { useState } from 'react';
import { notification } from 'antd';
import { withTranslation } from 'react-i18next';

const LogoutView = (props) => {
  useState(() => {
    notification.success({
      message: 'ZinkinData',
      description: props.t('sidebar.exit-message'),
    });
    props.handleLogout();
  }, []);

  return <></>;
};

export default withTranslation('common')(LogoutView);

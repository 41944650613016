import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import logo from 'assets/img/ZinkinData_100_25.png';
import Card from 'components/Card/Card.jsx';
import LoginForm from 'views/Forms/LoginForm';

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCardHidden: true,
      userFound: false,
    };
  }

  componentDidMount() {
    // Set 1ms timeout so card animation triggers when going from hidden to visible
    setTimeout(() => {
      this.setState({ isCardHidden: false });
    }, 1);
  }

  render() {
    return (
      <Grid>
        <Row>
          <Col
            md={4}
            sm={6}
            mdOffset={4}
            smOffset={3}
          >
            <Card
              hidden={this.state.isCardHidden}
              textCenter
              title={
                <>
                  <div style={{ marginTop: '-20px' }}>Bienvenido / Benvingut /</div>
                  <div style={{ marginBottom: '5px' }}>Benvindo / Ongi etorri</div>
                </>
              }
              image={logo}
              legend={<div />}
              ftTextCenter
              content={
                <div>
                  <LoginForm
                    onLogin={this.props.onLogin}
                    onFirstLogin={this.props.onFirstLogin}
                    onMfaVerify={this.props.onMfaVerify}
                  />
                </div>
              }
            />
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default LoginPage;

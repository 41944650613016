export const PROFILE = process.env.NODE_ENV;

var type = 'https://api.lprconnect.com/api';

if (PROFILE === 'development') type = 'http://localhost:8100/api';

export const API_BASE_URL = type;
export const ACCESS_TOKEN = 'accessToken';

export const POLL_LIST_SIZE = 30;
export const MAX_CHOICES = 6;
export const POLL_QUESTION_MAX_LENGTH = 140;
export const POLL_CHOICE_MAX_LENGTH = 40;

export const USERNAME_MIN_LENGTH = 3;
export const USERNAME_MAX_LENGTH = 15;

export const PASSWORD_MIN_LENGTH = 4;
export const PASSWORD_MAX_LENGTH = 20;

export const NAME_MIN_LENGTH = 3;
export const NAME_MAX_LENGTH = 15;

export const userLogged = false;
export const userName = '';
export const userRoles = {
  ROLE_ADMIN: 'Administrador',
  ROLE_USER: 'Gestor',
  ROLE_SUPERADMIN: 'Superadministrador',
  ROLE_REPORTER: 'Regidor',
};

export const optionsRoles = [
  { value: 'ROLE_ADMIN', label: 'Administrador', alt: 'Admin' },
  { value: 'ROLE_USER', label: 'Gestor', alt: 'User' },
  { value: 'ROLE_REPORTER', label: 'Regidor', alt: 'Regidor' },
];

export function getFetchOptions(method) {
  const headers = new Headers({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  });

  return { headers, method };
}

export function getFetchOptionsNoAuth(method) {
  const headers = new Headers({ 'Content-Type': 'application/json' });

  return { headers, method };
}

import { API_BASE_URL, getFetchOptions, getFetchOptionsNoAuth } from 'constants/constants.js';

export function login(loginRequest) {
  const url = `${API_BASE_URL}/auth/signin`;
  const headers = getFetchOptionsNoAuth("POST");

  return fetch(url, Object.assign({}, headers, { body: JSON.stringify(loginRequest) }))
    .then((response) =>
      response.ok
        ? response.json().then((json) => {
          if (!response.ok) {
            return Promise.reject(json);
          }
          return json;
        })
        : response.status === 409
          ? response.text().then((json) => Promise.reject(json))
          : response
    )
    .catch((error) => {
      return Promise.reject(error);
    });
}

export async function verifyMfa(userId, code){
  const url = `${API_BASE_URL}/auth/mfa/${userId}?code=${code}`;
  const headers = getFetchOptionsNoAuth('PUT');

  const response = await fetch(url, headers);
  if (response.status !== 200) throw new Error(response.status);
}

export async function getCenter(name) {
  const url = `${API_BASE_URL}/centers/${name}`;
  const headers = getFetchOptions("GET");

  const response = await fetch(url, headers);
  if (response.status === 200) {
    const data = await response.json()
    return data;
  }
  else {
    throw new Error("Error intern de servidor")
  }
}
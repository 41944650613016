import React, { useEffect, useState } from 'react';
import ReactDragListView from 'react-drag-listview';
import {
  notification,
  Spin,
  Statistic,
  Radio,
  Dropdown,
  Menu,
  Space,
  Switch,
  Modal,
  Alert,
  ConfigProvider,
  DatePicker,
  Checkbox,
  Select,
  Card,
  Flex,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { BsClipboardCheck } from 'react-icons/bs';
import { IoWarningOutline } from 'react-icons/io5';
import { MdCamera, MdSearch } from 'react-icons/md';
import { AiFillFilePdf } from 'react-icons/ai';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import CreateZarViolationsExcel from './components/CreateZarViolationsExcel.jsx';
import CreateCSV from 'views/Informes/mobility/components/CreateCSV.jsx';
import * as ReportsRepository from 'repository/reports/mobility/ReportsRepository';
import * as RedLightViolationReportRepository from 'repository/reports/red-light-violations/RedLightViolationReportRepository';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import ButtonPrimary from 'components/Buttons/ButtonPrimary.jsx';
import colors from 'components/Buttons/colors.js';
import { IoMdArrowDropdown } from 'react-icons/io';

require('jspdf-autotable');
const { RangePicker } = DatePicker;

const Reports = ({ ...props }) => {
  const [loading, setLoading] = useState(true);
  const [optionDate, setOptionDate] = useState('today');
  const [startDate, setStartDate] = useState(moment().startOf('day'));
  const [endDate, setEndDate] = useState(moment().endOf('day'));
  const [affluence, setAffluence] = useState({});
  const [violationPrice, setViolationPrice] = useState(0);
  const [readings, setReadings] = useState(0);
  const [violations, setViolations] = useState(0);
  const [exportedViolations, setExportedViolations] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [sendToMail, setSendToMail] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [pdfFormat, setPdfFormat] = useState(false);
  const [excelFormat, setExcelFormat] = useState(false);
  const currentCenterName = props.currentCenter.name;

  function getInitialData() {
    findViolationPrice();

    RedLightViolationReportRepository.getReportConfiguration(currentCenterName, 'ZAR')
      .then((response) => {
        if (response !== undefined) {
          setIsActive(response.active);
          setSendToMail(response.sendTo);
          setPdfFormat(response.pdfFormat);
          setExcelFormat(response.excelFormat);
        }
      })
      .catch((error) => {
        notification['error']({
          message: 'ZinkinData',
          description: props.t(error.message),
        });
      });

    const start = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss').toString();
    const end = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss').toString();
    getReportsData(start, end);
  }

  useEffect(getInitialData, []);

  async function findViolationPrice() {
    const violationPrice = await ReportsRepository.getViolationPrice(currentCenterName);
    setViolationPrice(violationPrice);
  }

  async function getReportsData(startDate, endDate) {
    setLoading(true);
    const payload = { currentCenter: currentCenterName, startDate, endDate };
    const requests = [];
    requests.push(ReportsRepository.getTotalReadings(payload));
    requests.push(ReportsRepository.getTotalAffluence(payload));
    requests.push(ReportsRepository.getViolationsCount(payload));
    requests.push(ReportsRepository.getExportedViolationsCount(payload));

    Promise.all(requests)
      .then((responses) => {
        setReadings(responses[0]);
        setAffluence(responses[1]);
        setViolations(responses[2]);
        setExportedViolations(responses[3]);
        setLoading(false);
      })
      .catch((error) => setLoading(false));
  }

  function handleOptionDateChange(value) {
    setLoading(true);

    if (value === 'custom') {
      setLoading(false);
      setOptionDate(value);
    }

    var start = undefined;
    var end = undefined;

    switch (value) {
      case 'today':
        start = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss').toString();
        end = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss').toString();
        break;

      case 'yesterday':
        start = moment().startOf('day').subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss').toString();
        end = moment().endOf('day').subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss').toString();
        break;

      case 'week':
        start = moment().startOf('day').subtract(6, 'days').format('YYYY-MM-DD HH:mm:ss').toString();
        end = moment().format('YYYY-MM-DD HH:mm:ss').toString();
        break;

      case 'month':
        start = moment().startOf('day').subtract(30, 'days').format('YYYY-MM-DD HH:mm:ss').toString();
        end = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss').toString();
        break;

      default:
        break;
    }

    if (value !== 'custom') {
      getReportsData(start, end);

      setOptionDate(value);
      setStartDate(start);
      setEndDate(end);
    }
  }

  function getAffluenceChartCategories() {
    return Object.keys(affluence);
  }

  function getTotalWhitelistEntriesByZones() {
    var total = [];
    var arrayZonesAffluence = Object.values(affluence);

    for (let i = 0; i < arrayZonesAffluence.length; i++) {
      total.push(arrayZonesAffluence[i].readings - arrayZonesAffluence[i].violations);
    }

    return total;
  }

  function getTotalViolationsByZones() {
    var total = [];
    var arrayZonesAffluence = Object.values(affluence);

    for (let i = 0; i < arrayZonesAffluence.length; i++) {
      total.push(arrayZonesAffluence[i].violations);
    }

    return total;
  }

  function getAverageAffluenceInCenter() {
    var total = 0;
    var arrayZonesAffluence = Object.values(affluence);

    for (let i = 0; i < arrayZonesAffluence.length; i++) {
      total += arrayZonesAffluence[i].readings;
    }

    if (total !== 0) total = Math.round(total / arrayZonesAffluence.length);

    return total;
  }

  function getHighestAffluenceInCenter() {
    var total = 0;
    var arrayZonesAffluence = Object.values(affluence);

    for (let i = 0; i < arrayZonesAffluence.length; i++) {
      let actualValue = arrayZonesAffluence[i].readings;

      if (total < actualValue) total = actualValue;
    }

    return total;
  }

  function getLowestAffluenceInCenter() {
    var total = undefined;
    var arrayZonesAffluence = Object.values(affluence);

    for (let i = 0; i < arrayZonesAffluence.length; i++) {
      let actualValue = arrayZonesAffluence[i].readings;

      if (total === undefined || total > actualValue) total = actualValue;
    }

    if (total === undefined) return 0;

    return total;
  }

  function getTotalWhitelist() {
    let result = readings - violations;

    if (result < 0) {
      return 0;
    }

    return result;
  }

  function customSearch() {
    var start = moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss').toString();
    var end = moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss').toString();

    getReportsData(start, end);
  }

  function addZonesAffluenceToCSVData(array) {
    let zones = Object.keys(affluence);

    for (let i = 0; i < zones.length; i++) {
      array.push([props.t('reports.csv.zones-affluence') + ' ' + zones[i], affluence[zones[i]].readings]);
    }

    return array;
  }

  function getFiltersCSV() {
    if (optionDate === 'custom')
      return [
        props.t('reports.csv.headers.applied-filter'),
        props.t('reports.filters.custom'),
        props.t('reports.csv.headers.start-date'),
        moment(startDate).format('DD-MM-YYYY'),
        props.t('reports.csv.headers.end-date'),
        moment(endDate).format('DD-MM-YYYY'),
      ];

    return [props.t('reports.csv.headers.applied-filter'), props.t('reports.filters.' + optionDate)];
  }

  function getCSVData() {
    let centerInfo = [props.t('reports.csv.headers.center'), currentCenterName];
    let exportedDate = [props.t('reports.csv.headers.export-date'), moment().format('DD-MM-YYYY HH:mm:ss')];
    let filters = getFiltersCSV();
    let totalReadings = [props.t('reports.csv.total-readings'), readings];
    let totalWhiteList = [props.t('reports.csv.total-whitelist-readings'), getTotalWhitelist()];
    let totalViolations = [props.t('reports.csv.total-violations'), violations];
    let totalExportedViolations = [props.t('reports.csv.total-exported-violations'), exportedViolations];
    let earnings = [props.t('reports.csv.total-income'), exportedViolations * violationPrice];
    let estimatedEarnings = [props.t('reports.csv.estimated-income'), violations * violationPrice];

    let result = [
      centerInfo,
      exportedDate,
      filters,
      [],
      totalReadings,
      [],
      totalWhiteList,
      [],
      totalViolations,
      [],
      totalExportedViolations,
      [],
      earnings,
      estimatedEarnings,
      [],
    ];

    result = addZonesAffluenceToCSVData(result);

    return result;
  }

  function saveReportSettings() {
    if (isActive && sendToMail.length === 0) {
      notification['error']({
        message: 'ZinkinData',
        description: props.t('reports.zar-violations-weekly-sending-reports.error.empty-emails'),
      });
    } else {
      const regexEmail = /^[^\s@]+(?:@[^\s@]+\.[^\s@]+)$/;
      let validate = false;
      for (let i = 0; i < sendToMail.length; i++) {
        const email = sendToMail[i];
        if (!regexEmail.test(email)) {
          validate = true;
        }
      }
      if (validate) {
        notification['error']({
          message: 'ZinkinData',
          description: props.t('reports.zar-violations-weekly-sending-reports.error.incorrect-emails'),
        });
      } else {
        RedLightViolationReportRepository.updateReportConfiguration(
          currentCenterName,
          isActive,
          sendToMail,
          'ZAR',
          pdfFormat,
          excelFormat
        )
          .then((response) => {
            setModalVisible(false);
          })
          .catch((error) => {
            notification['error']({
              message: 'ZinkinData',
              description: props.t('zar-violations.get-all-error'),
            });
          });
      }
    }
  }

  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 24 }}
      spin
    />
  );

  const whitelistReadings = readings - violations;
  const totalIncome = isNaN(violationPrice * exportedViolations) ? 0 : violationPrice * exportedViolations;
  const estimatedIncome = isNaN(violationPrice * violations) ? 0 : violationPrice * violations;

  const affluenceChartOptions = {
    title: {
      text: props.t('reports.statistics.zones-affluence.title'),
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
        },
      },
    },
    xAxis: {
      categories: getAffluenceChartCategories(),
    },
    yAxis: {
      min: 0,
      title: {
        text: props.t('reports.statistics.zones-affluence.y'),
      },
      stackLabels: {
        enabled: true,
      },
    },
    colors: [colors.grey.main, colors.blue.main],
    chart: {
      type: 'column',
    },
    series: [
      {
        name: props.t('reports.statistics.readings.whitelist'),
        data: getTotalWhitelistEntriesByZones(),
      },
      {
        name: props.t('reports.statistics.readings.violations'),
        data: getTotalViolationsByZones(),
      },
    ],
    credits: {
      enabled: false,
    },
  };

  const readingsChartOptions = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
    },
    title: {
      text: props.t('reports.statistics.readings.title'),
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '{point.y:.2f} %',
          distance: -50,
          filter: {
            property: 'percentage',
            operator: '>',
            value: 4,
          },
        },
        showInLegend: true,
      },
    },
    colors: [colors.grey.main, colors.blue.main],
    series: [
      {
        name: 'lectures',
        colorByPoint: true,
        data: [
          {
            name: props.t('reports.statistics.readings.whitelist'),
            y: parseFloat(Number((getTotalWhitelist() / readings) * 100).toFixed(2)),
          },
          {
            name: props.t('reports.statistics.readings.violations'),
            y: parseFloat(Number((violations / readings) * 100).toFixed(2)),
            sliced: true,
            selected: true,
          },
        ],
      },
    ],
    credits: {
      enabled: false,
    },
  };

  const dropdownMenu = (
    <Menu>
      <Menu.Item>
        <CreateZarViolationsExcel
          data={{
            readings,
            whitelistReadings,
            violations: violations,
            exportedViolations: exportedViolations,
            totalIncome,
            estimatedIncome,
            affluence,
          }}
        />
      </Menu.Item>
      <Menu.Item>
        <CreateCSV data={getCSVData()}>CSV</CreateCSV>
      </Menu.Item>
      <Menu.Item>
        <ButtonPrimary
          color="red"
          size="middle"
          style={{ width: '100%' }}
          onClick={() => {
            setLoading(true);
            ReportsRepository.getZarViolationsPdfFile(
              currentCenterName,
              moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
              moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss').toString()
            ).then((response) => {
              const element = document.createElement('a');
              element.href = response;
              document.body.appendChild(element); // Required so it works on FireFox
              element.click();
              setLoading(false);
            });
          }}
        >
          <AiFillFilePdf
            style={{
              verticalAlign: 'sub',
              fontSize: '22',
              marginRight: '5px',
            }}
          />
          PDF
        </ButtonPrimary>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Spin
        spinning={loading}
        indicator={antIcon}
        tip={props.t('reports.loading')}
        style={{ fontSize: 20 }}
      >
        <Space
          size={'middle'}
          style={{ width: '100%' }}
          direction="vertical"
        >
          <Space
            size={'middle'}
            style={{ width: '100%' }}
          >
            <Radio.Group
              value={optionDate}
              onChange={(value) => handleOptionDateChange(value.target.value)}
              buttonStyle="solid"
            >
              <Radio.Button value="today">{props.t('reports.filters.today')}</Radio.Button>
              <Radio.Button value="yesterday">{props.t('reports.filters.yesterday')}</Radio.Button>
              <Radio.Button value="week">{props.t('reports.filters.week')}</Radio.Button>
              <Radio.Button value="month">{props.t('reports.filters.month')}</Radio.Button>
              <Radio.Button value="custom">{props.t('reports.filters.custom')}</Radio.Button>
            </Radio.Group>

            {optionDate === 'custom' ? (
              <>
                <ConfigProvider locale={props.currentUser.getLocale()}>
                  <RangePicker
                    style={{ width: '100%' }}
                    size="middle"
                    onChange={(event) => {
                      setStartDate(event[0].$d);
                      setEndDate(event[1].$d);
                    }}
                    format={'DD/MM/YYYY'}
                  />
                </ConfigProvider>
                <ButtonPrimary
                  color="blue"
                  shape="round"
                  size="middle"
                  onClick={() => customSearch()}
                >
                  Buscar
                  <MdSearch
                    style={{
                      verticalAlign: 'sub',
                      fontSize: '20',
                      marginLeft: '5px',
                    }}
                  />
                </ButtonPrimary>
              </>
            ) : (
              <></>
            )}
          </Space>
          <Space
            style={{ float: 'right' }}
            size={'middle'}
          >
            <ButtonPrimary
              color="white"
              fontColor="black"
              shape="round"
              size="middle"
              onClick={() => {
                setModalVisible(true);
              }}
            >
              {props.t('reports.zar-violations-weekly-sending-reports.button')}
            </ButtonPrimary>

            <Dropdown
              overlay={dropdownMenu}
              placement="bottom"
            >
              <ButtonPrimary
                color="black"
                shape="round"
                size="middle"
              >
                {props.t('reports.export')}
                <IoMdArrowDropdown
                  style={{
                    verticalAlign: 'sub',
                    fontSize: '20',
                    marginLeft: '5px',
                  }}
                />
              </ButtonPrimary>
            </Dropdown>
          </Space>
          <Flex justify="space-between">
            <Card>
              <Statistic
                title={props.t('reports.readings')}
                value={readings}
                prefix={<MdCamera style={{ verticalAlign: 'sub', width: 'auto' }} />}
              />
            </Card>
            <Card>
              <Statistic
                title={props.t('reports.whitelist')}
                value={readings - violations}
                precision={0}
                prefix={<BsClipboardCheck style={{ verticalAlign: 'sub', width: 'auto' }} />}
              />
            </Card>
            <Card>
              <Statistic
                title={props.t('reports.violations')}
                value={violations}
                valueStyle={{ color: '#cf1322' }}
                prefix={<IoWarningOutline style={{ verticalAlign: 'sub', width: 'auto' }} />}
              />
            </Card>
            <Card>
              <Statistic
                title={props.t('reports.exported-violations')}
                value={exportedViolations}
                prefix={<IoWarningOutline style={{ verticalAlign: 'sub', width: 'auto' }} />}
              />
            </Card>
            <Card>
              <Statistic
                title={props.t('reports.earnings')}
                value={totalIncome}
                valueStyle={{ color: '#3f8600' }}
                suffix="€"
              />
            </Card>
            <Card>
              <Statistic
                title={props.t('reports.estimated-earings')}
                value={estimatedIncome}
                valueStyle={{ color: '#8f19cc' }}
                suffix="€"
              />
            </Card>
          </Flex>

          <Flex justify="space-between">
            <Card style={{ width: '45%' }}>
              <HighchartsReact
                highcharts={Highcharts}
                options={readingsChartOptions}
              />
            </Card>
            <Card style={{ width: '50%' }}>
              <HighchartsReact
                highcharts={Highcharts}
                options={affluenceChartOptions}
              />
            </Card>
          </Flex>

          <Flex justify="space-between">
            <Card
              title={props.t('reports.tables.readings.title')}
              style={{ width: '48%' }}
            >
              <ReactDragListView.DragColumn>
                <p style={{ width: '90%' }}>
                  <strong>{props.t('reports.tables.readings.total')}</strong>{' '}
                  <span style={{ float: 'right' }}>{readings}</span>
                </p>
                <p style={{ width: '90%' }}>
                  <strong>{props.t('reports.tables.readings.whitelist')}</strong>{' '}
                  <span style={{ float: 'right' }}>{getTotalWhitelist()}</span>
                </p>
                <p style={{ width: '90%' }}>
                  <strong>{props.t('reports.tables.readings.violations')}</strong>{' '}
                  <span style={{ float: 'right' }}>{violations}</span>
                </p>
              </ReactDragListView.DragColumn>
            </Card>
            <Card
              title={props.t('reports.tables.affluence.title')}
              style={{ width: '48%' }}
            >
              <ReactDragListView.DragColumn>
                <p style={{ width: '90%' }}>
                  <strong>{props.t('reports.tables.affluence.average')}</strong>{' '}
                  <span style={{ float: 'right' }}>{getAverageAffluenceInCenter()}</span>
                </p>
                <p style={{ width: '90%' }}>
                  <strong>{props.t('reports.tables.affluence.more')}</strong>{' '}
                  <span style={{ float: 'right' }}>{getHighestAffluenceInCenter()}</span>
                </p>
                <p style={{ width: '90%' }}>
                  <strong>{props.t('reports.tables.affluence.less')}</strong>{' '}
                  <span style={{ float: 'right' }}>{getLowestAffluenceInCenter()}</span>
                </p>
              </ReactDragListView.DragColumn>
            </Card>
          </Flex>
        </Space>

        <Modal
          title={props.t('reports.zar-violations-weekly-sending-reports.title')}
          open={modalVisible}
          onOk={() => saveReportSettings()}
          onCancel={() => setModalVisible(false)}
          okButtonProps={{ style: { backgroundColor: colors.blue.main } }}
        >
          <div>
            <Switch
              checked={isActive}
              onChange={(value) => setIsActive(value)}
            />
            <span style={{ marginLeft: '10px' }}>
              {props.t('reports.zar-violations-weekly-sending-reports.enable-reporting')}
            </span>
          </div>

          <Alert
            style={{ margin: '10px 0px 0px 0px' }}
            message=""
            description={props.t('reports.zar-violations-weekly-sending-reports.description')}
            type="info"
          />

          <div style={{ margin: '20px 0px 0px 0px' }}>
            <div>Format:</div>
            <Checkbox
              checked={pdfFormat}
              onChange={(e) => setPdfFormat(e.target.checked)}
            >
              PDF
            </Checkbox>
            <Checkbox
              checked={excelFormat}
              onChange={(e) => setExcelFormat(e.target.checked)}
            >
              EXCEL
            </Checkbox>
          </div>

          <div style={{ margin: '20px 0px 0px 0px' }}>
            <div>Enviar al mail:</div>
            <Select
              size="large"
              mode="tags"
              style={{ width: '100%' }}
              placeholder="martiperez@gmail.com"
              value={sendToMail === null ? [] : sendToMail}
              onChange={(event) => setSendToMail(event)}
              showArrow={false}
              open={false}
            />
          </div>
        </Modal>
      </Spin>
    </>
  );
};

export default withTranslation('common')(Reports);

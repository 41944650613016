import { Table, Tag } from 'antd';
import moment from 'moment';
import React from 'react';
import { withTranslation } from 'react-i18next';

const SpecialLicensePlatesTable = ({ data, loading, ...props }) => {
  const columns = [
    {
      title: props.t('whitelist.table.special.center-entity'),
      dataIndex: 'centerEntity',
      key: 'centerEntity',
      align: 'center',
    },
    {
      title: props.t('whitelist.table.special.license-plate'),
      dataIndex: 'licensePlate',
      key: 'licensePlate',
      align: 'center',
    },
    {
      title: props.t('whitelist.table.special.status'),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (text, record) => {
        return <Tag color="green">{props.t('whitelist.filters.valid').toUpperCase()}</Tag>;
      },
    },
    {
      title: props.t('whitelist.table.special.created-at'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: (text, record) => {
        if (record.createdAt !== undefined && record.createdAt !== null)
          return moment(record.createdAt).format('DD-MM-YYYY HH:mm:ss');
      },
    },
  ];

  return (
    <Table
      bordered
      loading={loading}
      dataSource={data}
      columns={columns}
      pagination={{ pageSize: 10, showSizeChanger: false }}
    />
  );
};

export default withTranslation('common')(SpecialLicensePlatesTable);

import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Card, ConfigProvider, DatePicker, Flex, Form, Space, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { getSoundReports } from 'repository/reports/sound/SoundReportRepository';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import { MdSearch } from 'react-icons/md';

const SoundReports = ({ ...props }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const getSoundData = (date = moment().format('YYYY-MM-DD')) => {
    getSoundReports(props.currentCenter.name, date)
      .then((response) => {
        setData(
          response.map((element) => {
            return { name: element.zone, data: element.affluence };
          })
        );
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(getSoundData, []);

  const soundReportConfiguration = {
    chart: {
      type: 'spline',
      scrollablePlotArea: {
        minWidth: 600,
        scrollPositionX: 1,
      },
    },
    title: {
      text: props.t('reports.sound.title'),
    },
    xAxis: {
      type: 'datetime',
      labels: {
        formatter: function () {
          return Highcharts.dateFormat('%H:%M', this.value);
        },
      },
    },
    yAxis: {
      title: {
        text: props.t('reports.sound.yAxis'),
      },
      minorGridLineWidth: 0,
      gridLineWidth: 0,
      alternateGridColor: null,
      plotBands: [
        {
          from: 0,
          to: 30,
          color: 'rgba(107, 255, 51, 0.1)',
          label: {
            text: 'Nivel bajo',
            style: {
              color: '#606060',
            },
          },
        },
        {
          from: 31,
          to: 59,
          color: 'rgba(246, 255, 51, 0.1)',
          label: {
            text: 'Nivel moderado',
            style: {
              color: '#606060',
            },
          },
        },
        {
          from: 60,
          to: 9999999,
          color: 'rgba(255, 51, 51, 0.1)',
          label: {
            text: 'Nivel alto',
            style: {
              color: '#606060',
            },
          },
        },
      ],
    },
    tooltip: {
      xDateFormat: '%H:%M',
      valueSuffix: ' ' + props.t('reports.sound.sufix'),
    },
    plotOptions: {
      spline: {
        lineWidth: 4,
        states: {
          hover: {
            lineWidth: 5,
          },
        },
        marker: {
          enabled: false,
        },
        pointInterval: 3600000, // one hour
        pointStart: Date.UTC(moment().year(), moment().month(), moment().date(), 0, 0, 0),
      },
    },
    series: data,
    navigation: {
      menuItemStyle: {
        fontSize: '10px',
      },
    },
    credits: {
      enabled: false,
    },
  };

  return (
    <Spin
      spinning={loading}
      indicator={
        <LoadingOutlined
          style={{ fontSize: 24 }}
          spin
        />
      }
      tip={props.t('reports.loading')}
      style={{ fontSize: 20 }}
    >
      <Card>
        <Space
          direction="vertical"
          style={{ marginBottom: '20px' }}
        >
          <Form
            layout="inline"
            onFinish={(values) => {
              setLoading(true);
              getSoundData(moment(values.date.$d).format('YYYY-MM-DD'));
            }}
          >
            <ConfigProvider locale={props.currentUser.getLocale()}>
              <Form.Item name="date">
                <DatePicker
                  size="large"
                  format={'DD/MM/YYYY'}
                />
              </Form.Item>
            </ConfigProvider>
            <ButtonPrimary
              color="blue"
              shape="round"
              size="large"
              htmlType="submit"
            >
              <Flex
                align="center"
                justify="center"
                gap="small"
              >
                Buscar
                <MdSearch
                  style={{
                    fontSize: '20',
                  }}
                />
              </Flex>
            </ButtonPrimary>
          </Form>

          <HighchartsReact
            allowChartUpdate={true}
            highcharts={Highcharts}
            options={soundReportConfiguration}
            style={{ width: '100%' }}
          />
        </Space>
      </Card>
    </Spin>
  );
};

export default withTranslation('common')(SoundReports);

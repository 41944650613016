import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './app/App.js';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/sass/light-bootstrap-dashboard.css?v=1.1.1';
import './assets/css/demo.css';

import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

import common_es from './translations/es/common.json';
import common_ca from './translations/ca/common.json';
import common_ga from './translations/ga/common.json';
import common_eus from './translations/eus/common.json';

// Translation
i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: 'es', // language to use
  resources: {
    es: {
      common: common_es,
    },
    ca: {
      common: common_ca,
    },
    ga: {
      common: common_ga,
    },
    eus: {
      common: common_eus,
    },
  },
});

ReactDOM.render(
  <BrowserRouter>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

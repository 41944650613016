import React, { useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { CheckOutlined, CloseOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { Spin, notification, Table, Switch, Tooltip, Modal, Flex, Space } from 'antd';
import { userRoles } from 'constants/constants';
import { withTranslation } from 'react-i18next';
import { MdAdd, MdLockReset } from 'react-icons/md';
import { GoUnverified, GoVerified } from 'react-icons/go';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import ButtonLink from 'components/Buttons/ButtonLink';
import { TbEdit, TbTrashXFilled } from 'react-icons/tb';
import colors from 'components/Buttons/colors';
import AccessDenied from 'views/AccessDenied/AccessDenied';
import {
  changeUserEnable,
  deleteUser,
  getUserListByCenter,
  resetUserCredentials,
} from 'repository/users/UserRepository';

const UserList = ({ ...props }) => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);

  const getUsers = () => {
    getUserListByCenter(props.currentCenter.name).then((response) => {
      setUsers(
        response.map((user, index) => {
          return {
            ...user,
            key: user.id,
          };
        })
      );
      setLoading(false);
    });
  };

  useEffect(getUsers, []);

  const columns = [
    {
      title: props.t('users.list.table.active'),
      dataIndex: 'enable',
      key: 'enable',
      align: 'center',
      render: (text, record) => {
        return (
          <Switch
            disabled={!props.currentUser.isAdmin()}
            checked={record.enable}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            onClick={() => {
              setLoading(true);
              changeUserEnable(record.id, props.currentCenter.name, !record.enable).then((response) => {
                getUsers();
              });
            }}
          />
        );
      },
    },
    {
      title: props.t('users.list.table.verified.title'),
      dataIndex: 'verified',
      key: 'verified',
      align: 'center',
      width: '7%',
      render: (_, record) => {
        return record.verified ? (
          <Tooltip
            color={colors.blue.main}
            title={props.t('users.list.table.verified.true')}
          >
            <GoVerified style={{ fontSize: '22', color: 'green' }} />
          </Tooltip>
        ) : (
          <Tooltip
            color={colors.blue.main}
            title={props.t('users.list.table.verified.false')}
          >
            <GoUnverified style={{ fontSize: '22', color: 'red' }} />
          </Tooltip>
        );
      },
    },
    {
      title: props.t('users.list.table.username'),
      dataIndex: 'username',
      key: 'username',
      align: 'center',
    },
    {
      title: props.t('users.list.table.name'),
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: props.t('users.list.table.surnames'),
      dataIndex: 'surname',
      key: 'surname',
      align: 'center',
    },
    {
      title: props.t('users.list.table.role'),
      dataIndex: 'role',
      key: 'role',
      align: 'center',
      render: (text, _) => {
        return userRoles[text];
      },
    },
    {
      title: props.t('users.list.table.actions.title'),
      dataIndex: 'acciones',
      align: 'center',
      width: '20%',
      render: (text, record) => {
        return (
          <Flex
            justify="center"
            align="center"
          >
            <ButtonLink
              color="blue"
              onClick={() => {
                Modal.confirm({
                  title: props.t('users.list.table.actions.reset-title'),
                  icon: <ExclamationCircleFilled />,
                  content: props.t('users.list.table.actions.reset-description'),
                  centered: true,
                  cancelText: props.t('users.list.table.actions.cancel'),
                  okText: props.t('users.list.table.actions.ok'),
                  okButtonProps: {
                    className: 'button-primary-props-blue',
                    shape: 'round',
                  },
                  cancelButtonProps: {
                    className: 'button-primary-props-white',
                    shape: 'round',
                  },
                  onOk: () => {
                    resetUserCredentials(props.currentCenter.name, record.id)
                      .then(() => {
                        notification['success']({
                          message: 'ZinkinData',
                          description: props.t('users.list.table.actions.success-message'),
                        });
                      })
                      .catch(() => {
                        notification['error']({
                          message: 'ZinkinData',
                          description: props.t('users.list.table.actions.error-message'),
                        });
                      });
                  },
                });
              }}
            >
              <Tooltip
                color={colors.blue.main}
                title={props.t('users.list.table.actions.reset')}
              >
                <MdLockReset style={{ fontSize: '30' }} />
              </Tooltip>
            </ButtonLink>
            <ButtonLink
              color="grey"
              onClick={() => {
                props.history.push({
                  pathname: '/user-edit',
                  state: { user: record },
                });
              }}
            >
              <Tooltip
                color={colors.blue.main}
                title={props.t('users.list.table.actions.edit')}
              >
                <TbEdit style={{ fontSize: '30' }} />
              </Tooltip>
            </ButtonLink>

            <ButtonLink
              color="black"
              onClick={() => {
                Modal.confirm({
                  title: props.t('users.list.warning.title'),
                  okText: props.t('users.list.warning.confirm'),
                  cancelText: props.t('users.list.warning.cancel'),
                  centered: true,
                  okButtonProps: {
                    className: 'button-primary-props-blue',
                    shape: 'round',
                  },
                  cancelButtonProps: {
                    className: 'button-primary-props-white',
                    shape: 'round',
                  },
                  onOk: () => {
                    deleteUser(props.currentCenter.name, record.username)
                      .then(() => {
                        setLoading(true);
                        notification['success']({
                          message: 'ZinkinData',
                          description: props.t('users.list.delete.success'),
                        });
                        getUsers();
                      })
                      .catch(() => {
                        notification['error']({
                          message: 'ZinkinData',
                          description: props.t('users.list.delete.error'),
                        });
                      });
                  },
                });
              }}
            >
              <Tooltip
                color={colors.blue.main}
                title={props.t('users.list.table.actions.delete')}
              >
                <TbTrashXFilled style={{ fontSize: '30' }} />
              </Tooltip>
            </ButtonLink>
          </Flex>
        );
      },
    },
  ];
  if (!props.currentUser.isAdmin()) {
    return <AccessDenied t={props.t} />;
  }

  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 24 }}
      spin
    />
  );

  return (
    <Spin
      spinning={loading}
      indicator={antIcon}
    >
      <Space
        direction="vertical"
        style={{ width: '100%' }}
        size={'middle'}
      >
        <ButtonPrimary
          color="blue"
          shape="round"
          size="large"
          onClick={() => props.history.push('/user-create')}
        >
          <Flex
            align="center"
            gap={'small'}
          >
            {props.t('sidebar.users.create')}
            <MdAdd
              style={{
                fontSize: '20',
              }}
            />
          </Flex>
        </ButtonPrimary>
        <Table
          bordered
          dataSource={users}
          columns={columns}
          pagination={{ pageSize: 5 }}
        />
      </Space>
    </Spin>
  );
};

export default withTranslation('common')(UserList);

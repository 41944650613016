import Profile from '../views/Profile/Profile';
import Home from 'views/Home/Home.jsx';
import BlackList from 'views/Lists/BlackList';
import BlacklistConfig from 'views/Lists/BlacklistConfig';
import CreateZone from 'views/Zones/CreateZone';
import ZoneEdit from 'views/Zones/ZoneEdit';
import ZoneList from 'views/Zones/ZoneList';
import ZoneSchedule from 'views/Zones/ZoneSchedule';
import MobilityReports from 'views/Informes/mobility/MobilityReports';
import EnvironmentReports from 'views/Informes/environment/EnvironmentReports';
import CameraList from 'views/Camaras/CameraList/CameraList';
import CameraEdit from 'views/Camaras/CameraEdit/CameraEdit.jsx';
import CreateCamara from 'views/Camaras/CreateCamara/CreateCamara.jsx';
import CameraHoliday from 'views/Camaras/CameraHoliday';
import UserList from 'views/User/UserList.jsx';
import UserEdit from 'views/User/UserEdit.jsx';
import UserCreate from 'views/User/UserCreate.jsx';
import AuditLogs from 'views/AuditLogs/AuditLogs';
import ZarViolations from 'views/ZarViolations/ZarViolations';

import {
  MdCamera,
  MdLocationOn,
  MdDoNotDisturbOn,
  MdPerson,
  MdTraffic,
  MdFormatListBulleted,
  MdManageSearch,
  MdSupportAgent,
  MdSettings,
  MdHome,
  MdCarCrash,
  MdDirectionsCarFilled,
  MdForest,
  MdContactSupport,
  MdPlaylistAddCheck,
  MdPlaylistRemove,
} from 'react-icons/md';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { IoIosVideocam } from 'react-icons/io';
import { BiMailSend } from 'react-icons/bi';
import { FaFileVideo, FaTruck } from 'react-icons/fa6';
import { RxLapTimer } from 'react-icons/rx';
import StayTimeViolation from 'views/StayTimeViolation/StayTimeViolation';
import RedLightViolations from 'views/RedLightViolation/RedLightViolations';
import Readings from 'views/Readings/Readings';
import OppositeDirectionViolations from 'views/OppositeDirectionViolation/OppositeDirectionViolations';
import GaugeControlViolations from 'views/GaugeControlViolation/GaugeControlViolations';
import BwcReports from 'views/BwcReports/BwcReports';
import EditBwcReport from 'views/BwcReports/EditBwcReport';
import ZoneHoliday from 'views/Zones/ZoneHoliday';
import CameraSchedule from 'views/Camaras/CameraSchedule';
import WhitelistView from 'views/Lists/WhitelistView';
import Suggeriments from 'views/Support/Suggeriments';
import Consults from 'views/Support/Consults';
import RedLightReportCreate from 'views/Informes/mobility/RedLightReportCreate';

export const dashboardRoutes = [
  {
    path: '/home',
    name: 'sidebar.menu',
    category: 'menu',
    icon: MdHome,
    modules: ['*'],
    roles: ['ROLE_ADMIN', 'ROLE_SUPERADMIN', 'ROLE_USER'],
    component: Home,
  },
  {
    path: '/readings',
    name: 'sidebar.readings',
    category: 'readings',
    icon: MdCamera,
    modules: ['ALPR'],
    roles: ['ROLE_ADMIN', 'ROLE_SUPERADMIN', 'ROLE_USER'],
    component: Readings,
  },
  {
    path: '/zar-violations',
    name: 'sidebar.zar-violations',
    category: 'zar-violations',
    icon: MdCarCrash,
    modules: ['ZAR'],
    roles: ['ROLE_ADMIN', 'ROLE_SUPERADMIN', 'ROLE_USER'],
    pendingCountKey: 'ZAR',
    component: ZarViolations,
  },
  {
    path: '/red-light-violation',
    name: 'sidebar.red-light-violation',
    category: 'red-light-violation',
    icon: MdTraffic,
    modules: ['RED_LIGHT'],
    roles: ['ROLE_ADMIN', 'ROLE_SUPERADMIN', 'ROLE_USER'],
    pendingCountKey: 'RED_LIGHT',
    component: RedLightViolations,
  },
  {
    path: '/opposite-direction-violation',
    name: 'sidebar.opposite-direction-violation',
    category: 'opposite-direction-violation',
    icon: MdDoNotDisturbOn,
    modules: ['OPPOSITE_DIRECTION'],
    roles: ['ROLE_ADMIN', 'ROLE_SUPERADMIN', 'ROLE_USER'],
    pendingCountKey: 'OPPOSITE_DIRECTION',
    component: OppositeDirectionViolations,
  },
  {
    path: '/gauge-control-violation',
    name: 'sidebar.galibo-control',
    category: 'gauge-control-violation',
    icon: FaTruck,
    modules: ['GAUGE_CONTROL'],
    roles: ['ROLE_ADMIN', 'ROLE_SUPERADMIN', 'ROLE_USER'],
    pendingCountKey: 'GAUGE_CONTROL',
    component: GaugeControlViolations,
  },
  {
    path: '/stay-time-violation',
    name: 'sidebar.stay-time-violation',
    category: 'stay-time-violation',
    icon: RxLapTimer,
    modules: ['SECTION_CONTROL'],
    roles: ['ROLE_ADMIN', 'ROLE_SUPERADMIN', 'ROLE_USER'],
    component: StayTimeViolation,
  },
  {
    path: '/bwc-reports',
    name: 'sidebar.bwc-reports',
    category: 'bwc-reports',
    icon: FaFileVideo,
    modules: ['BWC_REPORTS'],
    roles: ['ROLE_ADMIN', 'ROLE_SUPERADMIN', 'ROLE_USER'],
    pendingCountKey: 'BWC_REPORTS',
    component: BwcReports,
  },
  {
    hidden: true,
    path: '/edit-bwc-report',
    name: 'sidebar.edit-bwc-report',
    category: 'bwc-reports.edit-bwc-report',
    icon: FaFileVideo,
    modules: [],
    roles: ['ROLE_ADMIN', 'ROLE_SUPERADMIN', 'ROLE_USER'],
    component: EditBwcReport,
  },
  {
    collapse: true,
    path: '/lists',
    name: 'sidebar.lists.title',
    category: 'lists',
    state: 'openConfig',
    modules: ['ALPR', 'ZAR'],
    roles: ['ROLE_ADMIN', 'ROLE_SUPERADMIN', 'ROLE_USER'],
    icon: MdFormatListBulleted,
    views: [
      {
        path: '/lists/whitelist',
        name: 'sidebar.lists.whitelist',
        category: 'lists.whitelist',
        icon: MdPlaylistAddCheck,
        roles: ['ROLE_ADMIN', 'ROLE_SUPERADMIN', 'ROLE_USER'],
        modules: ['ZAR'],
        component: WhitelistView,
      },
      {
        path: '/lists/blacklist',
        name: 'sidebar.lists.blacklist',
        category: 'lists.blacklist',
        icon: MdPlaylistRemove,
        roles: ['ROLE_ADMIN', 'ROLE_SUPERADMIN', 'ROLE_USER'],
        modules: ['ALPR'],
        component: BlackList,
      },
    ],
  },
  {
    hidden: true,
    path: '/lists/blacklist-config',
    name: 'sidebar.lists.blacklist-config',
    category: 'lists.blacklist-config',
    component: BlacklistConfig,
    icon: MdPlaylistRemove,
    roles: ['ROLE_ADMIN', 'ROLE_SUPERADMIN'],
    modules: [],
  },
  {
    collapse: true,
    path: '/reports',
    name: 'sidebar.reports.title',
    category: 'reports',
    state: 'openInformes',
    modules: ['ALPR', 'ZAR', 'RED_LIGHT', 'OPPOSITE_DIRECTION', 'GAUGE_CONTROL', 'SECTION_CONTROL'],
    icon: HiOutlineDocumentReport,
    roles: ['*'],
    views: [
      {
        path: '/reports/mobility',
        name: 'sidebar.reports.mobility',
        category: 'reports',
        state: 'openInformes',
        icon: MdDirectionsCarFilled,
        modules: ['ZAR', 'RED_LIGHT', 'OPPOSITE_DIRECTION', 'GAUGE_CONTROL', 'SECTION_CONTROL'],
        roles: ['*'],
        component: MobilityReports,
      },
      {
        path: '/reports/environment',
        name: 'sidebar.reports.environment',
        category: 'reports',
        state: 'openInformes',
        icon: MdForest,
        modules: ['ALPR'],
        roles: ['*'],
        component: EnvironmentReports,
      },
    ],
  },
  {
    collapse: true,
    name: 'sidebar.manage',
    category: 'manage',
    icon: MdSettings,
    modules: ['*'],
    roles: ['ROLE_ADMIN', 'ROLE_SUPERADMIN'],
    views: [
      {
        path: '/zones',
        name: 'sidebar.zones.title',
        category: 'zones',
        icon: MdLocationOn,
        modules: ['ALPR', 'ZAR', 'RED_LIGHT', 'OPPOSITE_DIRECTION', 'GAUGE_CONTROL', 'SECTION_CONTROL'],
        roles: ['ROLE_ADMIN', 'ROLE_SUPERADMIN'],
        component: ZoneList,
      },
      {
        path: '/cameras',
        name: 'sidebar.cameras.title',
        category: 'cameras',
        icon: IoIosVideocam,
        modules: ['ALPR', 'ZAR', 'RED_LIGHT', 'OPPOSITE_DIRECTION', 'GAUGE_CONTROL', 'SECTION_CONTROL'],
        roles: ['ROLE_ADMIN', 'ROLE_SUPERADMIN'],
        component: CameraList,
      },
      {
        path: '/users',
        name: 'sidebar.users.title',
        category: 'users',
        icon: MdPerson,
        modules: ['*'],
        roles: ['ROLE_ADMIN', 'ROLE_SUPERADMIN'],
        component: UserList,
      },
    ],
  },

  {
    hidden: true,
    path: '/zone-create',
    name: 'sidebar.zones.create',
    category: 'zones.create',
    component: CreateZone,
    icon: MdLocationOn,
    roles: ['ROLE_ADMIN', 'ROLE_SUPERADMIN'],
    modules: [],
  },
  {
    hidden: true,
    path: '/zone-edit',
    name: 'sidebar.zones.edit',
    category: 'zones.edit',
    component: ZoneEdit,
    icon: MdLocationOn,
    roles: ['ROLE_ADMIN', 'ROLE_SUPERADMIN'],
    modules: [],
  },
  {
    hidden: true,
    path: '/zone-schedule',
    name: 'sidebar.zones.schedule',
    category: 'zones.schedule',
    component: ZoneSchedule,
    icon: MdLocationOn,
    roles: ['ROLE_ADMIN', 'ROLE_SUPERADMIN'],
    modules: [],
  },
  {
    hidden: true,
    path: '/zone-holiday',
    name: 'sidebar.zones.holiday',
    category: 'zones.holiday',
    component: ZoneHoliday,
    icon: MdLocationOn,
    roles: ['ROLE_ADMIN', 'ROLE_SUPERADMIN'],
    modules: [],
  },

  {
    hidden: true,
    path: '/camera-create',
    name: 'sidebar.cameras.create',
    category: 'cameras.create',
    component: CreateCamara,
    icon: IoIosVideocam,
    roles: ['ROLE_ADMIN', 'ROLE_SUPERADMIN'],
    modules: [],
  },
  {
    hidden: true,
    path: '/camera-edit',
    name: 'sidebar.cameras.edit',
    category: 'cameras.edit',
    component: CameraEdit,
    icon: IoIosVideocam,
    roles: ['ROLE_ADMIN', 'ROLE_SUPERADMIN'],
    modules: [],
  },
  {
    hidden: true,
    path: '/camera-holidays',
    name: 'sidebar.cameras.holiday',
    category: 'cameras.holiday',
    component: CameraHoliday,
    icon: IoIosVideocam,
    roles: ['ROLE_ADMIN', 'ROLE_SUPERADMIN'],
    modules: [],
  },
  {
    hidden: true,
    path: '/camera-schedule',
    name: 'sidebar.cameras.schedule',
    category: 'cameras.schedule',
    component: CameraSchedule,
    icon: IoIosVideocam,
    roles: ['ROLE_ADMIN', 'ROLE_SUPERADMIN'],
    modules: [],
  },

  {
    path: '/audit-logs',
    name: 'sidebar.audit-logs',
    category: 'audit-logs',
    icon: MdManageSearch,
    modules: ['*'],
    roles: ['ROLE_ADMIN', 'ROLE_SUPERADMIN'],
    component: AuditLogs,
  },

  {
    hidden: true,
    path: '/user-create',
    name: 'sidebar.users.create',
    category: 'users.create',
    component: UserCreate,
    icon: MdPerson,
    roles: ['ROLE_ADMIN', 'ROLE_SUPERADMIN'],
    modules: [],
  },
  {
    hidden: true,
    path: '/user-edit',
    name: 'sidebar.users.edit',
    category: 'users.edit',
    component: UserEdit,
    icon: MdPerson,
    roles: ['ROLE_ADMIN', 'ROLE_SUPERADMIN'],
    modules: [],
  },
  {
    collapse: true,
    path: '/sat',
    name: 'sidebar.sat.title',
    category: 'sat',
    state: 'openSat',
    icon: MdSupportAgent,
    modules: ['*'],
    roles: ['ROLE_ADMIN', 'ROLE_SUPERADMIN', 'ROLE_USER'],
    views: [
      {
        path: '/sat/consults',
        name: 'sidebar.sat.consults',
        category: 'sat.consults',
        icon: MdContactSupport,
        component: Consults,
        modules: ['*'],
        roles: ['ROLE_ADMIN', 'ROLE_SUPERADMIN', 'ROLE_USER'],
      },
      {
        path: '/sat/suggeriments',
        name: 'sidebar.sat.suggestions',
        category: 'sat.suggestions',
        icon: BiMailSend,
        modules: ['*'],
        roles: ['ROLE_ADMIN', 'ROLE_SUPERADMIN', 'ROLE_USER'],
        component: Suggeriments,
      },
    ],
  },
  {
    hidden: true,
    path: '/create-red-light-reports',
    name: 'Crear registros foto rojo',
    category: 'crear registros foto rojo',
    component: RedLightReportCreate,
    icon: HiOutlineDocumentReport,
    modules: [],
    roles: ['ROLE_ADMIN', 'ROLE_SUPERADMIN', 'ROLE_USER'],
  },
  {
    hidden: true,
    path: '/profile',
    name: 'sidebar.profile',
    category: 'profile',
    component: Profile,
    icon: MdPerson,
    modules: [],
    roles: ['*'],
  },
];

export const getRoutesByModulesAndUserRole = (modules, role) => {
  return dashboardRoutes
    .filter(
      (route) =>
        (route.roles.includes(role) || route.roles.includes('*')) &&
        !route.hidden &&
        (route.modules.some((module) => modules.includes(module)) || route.modules.includes('*'))
    )
    .map((route) => {
      if (Array.isArray(route.views)) {
        const filteredViews = route.views.filter((view) =>
          view.modules.some((module) => modules.includes(module) || view.modules.includes('*'))
        );
        return { ...route, views: filteredViews };
      }
      return route;
    });
};

export const getIcon = (path) => {
  const section = dashboardRoutes.find((section) => {
    if (section.collapse) {
      return section.views.some((sub) => sub.path === path);
    } else {
      return section.path === path;
    }
  });

  return { name: section.name, icon: section.icon };
};

import React, { useState } from 'react';
import { notification, Input, Form } from 'antd';
import { withTranslation } from 'react-i18next';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import AccessDenied from 'views/AccessDenied/AccessDenied';
import { createZone } from 'repository/zone/ZoneRepository';

const { TextArea } = Input;

const ZoneEdit = ({ ...props }) => {
  const [loading, setLoading] = useState(false);

  if (!props.currentUser.isAdmin()) {
    return <AccessDenied />;
  }

  const onFinishEdit = (values) => {
    setLoading(true);
    createZone(props.currentCenter.name, values.name, values.description)
      .then(() => {
        notification['success']({
          message: 'ZinkinData',
          description: props.t('zones.create.method.success'),
        });
        setLoading(false);
        props.history.push('/zones');
      })
      .catch(() => {
        setLoading(false);
        notification['error']({
          message: 'ZinkinData',
          description: props.t('zones.create.method.error'),
        });
      });
  };

  return (
    <Form
      onFinish={onFinishEdit}
      labelCol={{ span: 3 }}
      wrapperCol={{ span: 12 }}
    >
      <Form.Item
        label={props.t('zones.create.zone')}
        name="name"
        rules={[{ required: true, message: props.t('zones.create.method.name') }]}
      >
        <Input
          size="large"
          disabled={loading}
          placeholder={props.t('zones.create.zone-placeholder')}
        />
      </Form.Item>
      <Form.Item
        label={props.t('zones.create.description')}
        name="description"
        rules={[{ required: true, message: props.t('zones.create.method.description') }, { max: 60 }]}
      >
        <TextArea
          size="large"
          disabled={loading}
          showCount
          maxLength={60}
          placeholder={props.t('zones.create.description-placeholder')}
        />
      </Form.Item>
      <ButtonPrimary
        loading={loading}
        color="blue"
        shape="round"
        size="large"
        htmlType="submit"
      >
        {props.t('zones.create.button')}
      </ButtonPrimary>
    </Form>
  );
};

export default withTranslation('common')(ZoneEdit);

const denied_reasons = [
  {
    name: 'WHITELIST',
    t: 'denied-reasons.whitelist',
  },
  {
    name: 'SPECIAL_LICENSE_PLATE',
    t: 'denied-reasons.special-license-plate',
  },
  {
    name: 'NOT_A_PLATE',
    t: 'denied-reasons.not-a-plate',
  },
  {
    name: 'ILLEGIBLE_PLATE',
    t: 'denied-reasons.illegible-plate',
  },
  {
    name: 'RECOGNIZABLE_PERSON',
    t: 'denied-reasons.recognizable-person',
  },
  {
    name: 'RECENT_VIOLATION',
    t: 'denied-reasons.recent-violation',
  },
  {
    name: 'FOREIGN_CAR',
    t: 'denied-reasons.foreigner-vehicle',
  },
  {
    name: 'NOT_DRIVING',
    t: 'denied-reasons.not-driving',
  },
  {
    name: 'ANOTHER',
    t: 'denied-reasons.another',
  },
];

export const posibleDeniedReasons = denied_reasons.filter(
  (reason) => reason.name !== 'WHITELIST' && reason.name !== 'SPECIAL_LICENSE_PLATE'
);

export function getTraductionByName(deniedReason) {
  const reason = denied_reasons.find((reason) => reason.name === deniedReason);
  return reason ? reason.t : deniedReason;
}

import { API_BASE_URL, getFetchOptions } from 'constants/constants.js';

export async function getReportSchema(centerName) {
  const url = `${API_BASE_URL}/${centerName}/body-cam-report-formats`;
  const options = getFetchOptions('GET');

  return fetch(url, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      throw new Error(`Error status: ${response.status}`);
    })
    .catch((error) => {
      console.log(error);
      throw new Error('Error en la peticion');
    });
}

export async function getReportsFromCurrentUser(centerName, status, date) {
  let dateParam;

  if (date) {
    dateParam = `&date=${date}`;
  } else {
    dateParam = '';
  }

  const url = `${API_BASE_URL}/${centerName}/body-cam-reports/me?status=${status}${dateParam}`;
  const options = getFetchOptions('GET');

  return fetch(url, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      throw new Error(`Error status: ${response.status}`);
    })
    .catch((error) => {
      console.log(error);
      throw new Error('Error en la peticion');
    });
}

export async function downloadBodyCamReport(bodyCamReportId, centerName) {
  const url = `${API_BASE_URL}/${centerName}/body-cam-reports/${bodyCamReportId}/download`;
  const options = getFetchOptions('GET');

  return fetch(url, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      throw new Error(`Error status: ${response.status}`);
    })
    .catch((error) => {
      console.log(error);
      throw new Error('Error en la peticion');
    });
}

export async function getAllReportsFromCenter(centerName, status, username, date) {
  const usernameParam = username ? `&username=${username}` : '';
  const dateParam = date ? `&date=${date}` : '';

  const url = `${API_BASE_URL}/admin/${centerName}/body-cam-reports?status=${status}${usernameParam}${dateParam}`;
  const options = getFetchOptions('GET');

  return fetch(url, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      throw new Error(`Error status: ${response.status}`);
    })
    .catch((error) => {
      console.log(error);
      throw new Error('Error en la peticion');
    });
}

export async function sendReportData(centerName, bwcReportId) {
  const url = `${API_BASE_URL}/${centerName}/body-cam-reports/${bwcReportId}/send`;
  let options = getFetchOptions('PUT');

  return fetch(url, options)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Error status: ${response.status}`);
      }
    })
    .catch((error) => {
      console.log(error);
      throw new Error('Error en la peticion');
    });
}

export async function updateReportData(centerName, reportData, bwcReportId) {
  const url = `${API_BASE_URL}/${centerName}/body-cam-reports/${bwcReportId}`;
  let options = getFetchOptions('PUT');
  options = {
    ...options,
    body: JSON.stringify(reportData, replacer),
  };

  return fetch(url, options)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Error status: ${response.status}`);
      }
    })
    .catch((error) => {
      console.log(error);
      throw new Error('Error en la peticion');
    });
}

function replacer(key, value) {
  if (value === null) {
    return '';
  }
  if (value === '') {
    return '';
  }
  if (value === undefined) {
    return '';
  }
  return value;
}

export async function updateBodyCamDestinations(centerName, emails) {
  const url = `${API_BASE_URL}/admin/${centerName}/body-cam-report-destinations/update`;
  let options = getFetchOptions('PUT');
  options = {
    ...options,
    body: JSON.stringify(emails),
  };

  return fetch(url, options)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Error status: ${response.status}`);
      }
    })
    .catch((error) => {
      console.log(error);
      throw new Error('Error en la peticion');
    });
}

export async function markUnintentionalBodyCamDestinations(centerName, bodyCamReportId) {
  const url = `${API_BASE_URL}/${centerName}/body-cam-reports/${bodyCamReportId}/unintentional`;
  const options = getFetchOptions('PUT');

  return fetch(url, options)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Error status: ${response.status}`);
      }
    })
    .catch((error) => {
      console.log(error);
      throw new Error('Error en la peticion');
    });
}

export async function updateToPendingBodyCamDestinations(centerName, bodyCamReportId) {
  const url = `${API_BASE_URL}/${centerName}/body-cam-reports/${bodyCamReportId}/pending`;
  const options = getFetchOptions('PUT');

  return fetch(url, options)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Error status: ${response.status}`);
      }
    })
    .catch((error) => {
      console.log(error);
      throw new Error('Error en la peticion');
    });
}

export async function getBodyCamDestinations(centerName) {
  const url = `${API_BASE_URL}/admin/${centerName}/body-cam-report-destinations`;
  const options = getFetchOptions('GET');

  return fetch(url, options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(`Error status: ${response.status}`);
    })
    .catch((error) => {
      console.log(error);
      throw new Error('Error en la peticion');
    });
}

import { API_BASE_URL, getFetchOptions } from 'constants/constants.js';

export async function getAllSpecialLicensePlatesBy(centerName, centerEntityName, specialLicensePlate) {
    let path_centerEntityName = ""
    let path_license_plate = ""

    if (centerEntityName !== null && centerEntityName !== undefined && centerEntityName !== "")
        path_centerEntityName = "&center-entity=" + centerEntityName

    if (specialLicensePlate !== null && specialLicensePlate !== undefined && specialLicensePlate !== "")
        path_license_plate = "&special-license-plate=" + specialLicensePlate

    const url = `${API_BASE_URL}/${centerName}/special-license-plates?${path_centerEntityName}${path_license_plate}`;
    const headers = getFetchOptions("GET");

    const response = await fetch(url, headers);
    if (response.status === 200) {
        const data = await response.json()
        return data;
    }
    else {
        throw new Error("Error intern de servidor")
    }
}

export async function getEntitiesFromCenter(centerName) {
    const url = `${API_BASE_URL}/${centerName}/center-entities`;
    const headers = getFetchOptions("GET");

    const response = await fetch(url, headers);
    if (response.status === 200) {
        const data = await response.json()
        return data;
    }
    else {
        throw new Error("Error intern de servidor")
    }
}
import React, { useEffect, useState } from 'react';

import { Spin, Space, ConfigProvider, DatePicker, Card, Form, Flex } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';

import { withTranslation } from 'react-i18next';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import { MdSearch } from 'react-icons/md';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import { getEnvironment } from 'repository/reports/mobility/ReportsRepository';
const { RangePicker } = DatePicker;

const EnvirontmentTagsReports = ({ ...props }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const getEnvironmentData = (startDate = moment().format('YYYY-MM-DD'), endDate = moment().format('YYYY-MM-DD')) => {
    getEnvironment(
      props.currentCenter.name,
      moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
      moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss').toString()
    ).then((response) => {
      setData(getEnvironmentTagsData(response));
      setLoading(false);
    });
  };

  useEffect(getEnvironmentData, []);

  const getEnvironmentTagsData = (data) => {
    const environmentTagsCounter = getTotalEnvironmentTagsCount(data);
    const tags = ['0', 'ECO', 'C', 'B', 'without', 'null'];
    const translations = {
      without: props.t('reports.statistics.environment.without'),
      null: props.t('reports.statistics.environment.null'),
    };

    return tags.map((tag) => ({
      name: translations[tag] || tag,
      y: getPercentageNumber(data, tag, environmentTagsCounter),
    }));
  };

  const getPercentageNumber = (data, key, environmentTagsCounter) => {
    return +((data[key] / environmentTagsCounter) * 100).toFixed(2);
  };

  const getTotalEnvironmentTagsCount = (list) => {
    return Object.values(list).reduce((total, value) => total + value, 0);
  };

  const environmentalImpactChartOptions = {
    title: {
      text: props.t('reports.statistics.environment.title'),
    },
    // colors: colorsBar,
    plotOptions: {
      bar: {
        pointPadding: 0.2,
        borderWidth: 1,
        dataLabels: {
          enabled: true,
          format: '{point.y:.1f}%',
        },
      },
    },
    xAxis: {
      type: 'category',
    },
    yAxis: {
      min: 0,
      title: {
        text: props.t('reports.statistics.environment.y'),
      },
    },
    chart: {
      type: 'bar',
    },
    series: {
      name: 'Distintivos',
      colorByPoint: true,
      data: data,
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
  };

  return (
    <Spin
      spinning={loading}
      indicator={
        <LoadingOutlined
          style={{ fontSize: 24 }}
          spin
        />
      }
      tip={props.t('reports.loading')}
      style={{ fontSize: 20 }}
    >
      <Card>
        <Space>
          <Form
            onFinish={(values) => {
              setLoading(true);
              getEnvironmentData(values.date[0].$d, values.date[1].$d);
            }}
            layout="inline"
          >
            <ConfigProvider locale={props.currentUser.getLocale()}>
              <Form.Item name="date">
                <RangePicker
                  format={'DD/MM/YYYY'}
                  size="large"
                />
              </Form.Item>
            </ConfigProvider>
            <ButtonPrimary
              color="blue"
              shape="round"
              size="large"
              htmlType="submit"
            >
              <Flex
                align="center"
                justify="center"
                gap="small"
              >
                Buscar
                <MdSearch
                  style={{
                    fontSize: '20',
                  }}
                />
              </Flex>
            </ButtonPrimary>
          </Form>
        </Space>

        <HighchartsReact
          highcharts={Highcharts}
          options={environmentalImpactChartOptions}
        />
      </Card>
    </Spin>
  );
};

export default withTranslation('common')(EnvirontmentTagsReports);

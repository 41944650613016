import { API_BASE_URL, getFetchOptions } from 'constants/constants';

export async function getAllGaugeControlViolationByCenterNameAndStatus(centerName, status, date) {
  var path_date = '';

  if (date !== null && date !== undefined && date !== '') path_date = '&date=' + date;

  const url = `${API_BASE_URL}/${centerName}/gauge-control-violations?status=${status}${path_date}`;
  const headers = getFetchOptions('GET');

  const response = await fetch(url, headers);
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    throw new Error('Error intern de servidor');
  }
}

export async function getGaugeControlViolationByCenterNameAndId(centerName, id) {
  const url = `${API_BASE_URL}/${centerName}/gauge-control-violation/${id}`;
  const headers = getFetchOptions('GET');

  const response = await fetch(url, headers);
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    throw new Error('Error intern de servidor');
  }
}

export async function editGaugeControlViolationLicensePlate(centerName, violationId, newLicensePlate) {
  const url = `${API_BASE_URL}/${centerName}/gauge-control-violations/${violationId}`;
  const headers = getFetchOptions('PUT');

  const response = await fetch(url, Object.assign({}, headers, { body: JSON.stringify({ plate: newLicensePlate }) }));
  if (response.status === 404) throw new Error('Error 404');
  if (response.status === 500) throw new Error('Error intern de servidor');
}

export async function approveGaugeControlViolation(centername, violationId) {
  const url = `${API_BASE_URL}/${centername}/gauge-control-violations/${violationId}/approve`;
  const headers = getFetchOptions('PUT');

  const response = await fetch(url, headers);
  if (response.status === 404) throw new Error('Error 404');
  if (response.status === 500) throw new Error('Error intern de servidor');
}

export async function pendingGaugeControlViolation(centername, violationId) {
  const url = `${API_BASE_URL}/${centername}/gauge-control-violations/${violationId}/pending`;
  const headers = getFetchOptions('PUT');

  const response = await fetch(url, headers);
  if (response.status === 404) throw new Error('Error 404');
  if (response.status === 500) throw new Error('Error intern de servidor');
}

export async function denyGaugeControlViolation(centername, violationId, deniedReason = 'NO_REASON', reason = '') {
  const url = `${API_BASE_URL}/${centername}/gauge-control-violations/${violationId}/deny`;
  const headers = getFetchOptions('PUT');
  var body = {
    deniedReason: deniedReason,
    customDeniedReason: reason,
  };

  const response = await fetch(url, Object.assign({}, headers, { body: JSON.stringify(body) }));
  if (response.status === 404) throw new Error('Error 404');
  if (response.status === 500) throw new Error('Error intern de servidor');
}

export async function generateGaugeControlViolationsZip(centerName, ids) {
  const url = `${API_BASE_URL}/${centerName}/gauge-control-violation/export`;
  const headers = getFetchOptions('POST');

  const response = await fetch(url, Object.assign({}, headers, { body: JSON.stringify(ids) }));
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else if (response.status === 404) {
    throw new Error('Error 404');
  } else if (response.status === 500) {
    throw new Error('Error intern de servidor');
  }
}

import { API_BASE_URL, getFetchOptions } from 'constants/constants.js';

export async function getLastLicensePlateReadings(centerName, page) {
  const url = `${API_BASE_URL}/${centerName}/license-plate-reads/last-readings?page=${page}`;
  const headers = getFetchOptions('GET');

  const response = await fetch(url, headers);
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    throw new Error('Error intern de servidor');
  }
}

export async function getLicensePlateReadings(
  centerName,
  licensePlate,
  parcialLicensePlate,
  zoneId,
  cameraId,
  startDate,
  endingDate,
  page
) {
  var path_license_plate = '';
  var path_parcial_license_plate = '';
  var path_zone_id = '';
  var path_camera_id = '';
  var path_start_date = '';
  var path_ending_date = '';
  var path_page = '&page=' + page;

  if (licensePlate !== null && licensePlate !== undefined && licensePlate !== '')
    path_license_plate = '&license-plate=' + licensePlate;

  if (parcialLicensePlate !== null && parcialLicensePlate !== undefined && parcialLicensePlate !== false)
    path_parcial_license_plate = '&parcial-license-plate=' + parcialLicensePlate;

  if (zoneId !== null && zoneId !== undefined) path_zone_id = '&zone-id=' + zoneId;

  if (cameraId !== null && cameraId !== undefined) path_camera_id = '&camera-id=' + cameraId;

  if (startDate !== null && startDate !== undefined) path_start_date = '&start-date=' + startDate;

  if (endingDate !== null && endingDate !== undefined) path_ending_date = '&ending-date=' + endingDate;

  const url = `${API_BASE_URL}/${centerName}/license-plate-reads?${path_license_plate}${path_parcial_license_plate}${path_zone_id}${path_camera_id}${path_start_date}${path_ending_date}${path_page}`;
  const headers = getFetchOptions('GET');

  const response = await fetch(url, headers);
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    throw new Error('Error intern de servidor');
  }
}

export async function editPlate(centerName, licensePlateId, licensePlate) {
  const url = `${API_BASE_URL}/${centerName}/license-plate-reads/${licensePlateId}?license-plate=${licensePlate}`;
  const headers = getFetchOptions('PUT');

  const response = await fetch(url, headers);
  if (response.status !== 200) throw new Error('Error intern de servidor');
}

export async function deleteLicensePlateReads(centerName, id) {
  const url = `${API_BASE_URL}/${centerName}/license-plate-reads/${id}`;
  const headers = getFetchOptions('DELETE');

  const response = await fetch(url, headers);
  if (response.status !== 200) throw new Error('Error intern de servidor');
}

import React, { useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, notification, Table, Modal, Tooltip, Flex, Space } from 'antd';
import { withTranslation } from 'react-i18next';
import { MdAdd } from 'react-icons/md';
import ButtonLink from 'components/Buttons/ButtonLink';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import { TbCalendarPlus, TbClockPlus, TbEdit, TbTrashXFilled } from 'react-icons/tb';
import colors from 'components/Buttons/colors';
import { deleteZone, getZonesFromCenter } from 'repository/zone/ZoneRepository';
import { v4 } from 'uuid';
import AccessDenied from 'views/AccessDenied/AccessDenied';

const ZoneList = ({ ...props }) => {
  const [loading, setLoading] = useState(true);
  const [zones, setZones] = useState([]);

  const getZones = () => {
    getZonesFromCenter(props.currentCenter.name)
      .then((response) => {
        setZones(
          response.map((zone, index) => {
            return {
              ...zone,
              key: v4(),
            };
          })
        );
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(getZones, []);

  if (!props.currentUser.isAdmin()) {
    return <AccessDenied />;
  }

  const columns = [
    {
      title: props.t('zones.list.table.name'),
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: props.t('zones.list.table.description'),
      dataIndex: 'description',
      key: 'description',
      align: 'center',
    },
    {
      title: props.t('zones.list.table.actions'),
      dataIndex: 'acciones',
      align: 'center',
      render: (text, record) => {
        return (
          <Flex
            justify="center"
            align="center"
          >
            <ButtonLink
              color="blue"
              onClick={() => {
                props.history.push({
                  pathname: '/zone-schedule',
                  state: { id: record.id, name: record.name },
                  props,
                });
              }}
            >
              <Tooltip
                color={colors.blue.main}
                title={props.t('zones.list.table.schedule')}
              >
                <TbClockPlus style={{ fontSize: '30' }} />
              </Tooltip>
            </ButtonLink>

            <ButtonLink
              color="blue"
              onClick={() => {
                props.history.push({
                  pathname: '/zone-holiday',
                  state: { id: record.id, name: record.name },
                  props,
                });
              }}
            >
              <Tooltip
                color={colors.blue.main}
                title={props.t('zones.list.table.holiday')}
              >
                <TbCalendarPlus style={{ fontSize: '30' }} />
              </Tooltip>
            </ButtonLink>

            <ButtonLink
              color="grey"
              onClick={() => {
                props.history.push({
                  pathname: '/zone-edit',
                  state: { zone: record },
                });
              }}
            >
              <Tooltip
                color={colors.blue.main}
                title={props.t('zones.list.table.edit')}
              >
                <TbEdit style={{ fontSize: '30' }} />
              </Tooltip>
            </ButtonLink>

            <ButtonLink
              color="black"
              onClick={() => {
                Modal.confirm({
                  title: props.t('zones.list.warning.title'),
                  okText: props.t('zones.list.warning.confirm'),
                  cancelText: props.t('zones.list.warning.cancel'),
                  content: props.t('zones.list.warning.description'),
                  centered: true,
                  okButtonProps: {
                    className: 'button-primary-props-blue',
                    shape: 'round',
                  },
                  cancelButtonProps: {
                    className: 'button-primary-props-white',
                    shape: 'round',
                  },
                  onOk: () => {
                    deleteZone(props.currentCenter.name, record.id)
                      .then(() => {
                        setLoading(true);
                        notification['success']({
                          message: 'ZinkinData',
                          description: props.t('zones.list.delete.success'),
                        });
                        getZones();
                      })
                      .catch((error) => {
                        notification['error']({
                          message: 'ZinkinData',
                          description: props.t(error.message),
                        });
                      });
                  },
                });
              }}
            >
              <Tooltip
                color={colors.blue.main}
                title={props.t('zones.list.table.delete')}
              >
                <TbTrashXFilled style={{ fontSize: '30' }} />
              </Tooltip>
            </ButtonLink>
          </Flex>
        );
      },
    },
  ];

  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 24 }}
      spin
    />
  );

  return (
    <Spin
      spinning={loading}
      indicator={antIcon}
    >
      <Space
        direction="vertical"
        style={{ width: '100%' }}
        size={'middle'}
      >
        <ButtonPrimary
          color="blue"
          shape="round"
          size="large"
          onClick={() => props.history.push('/zone-create')}
        >
          <Flex
            align="center"
            gap={'small'}
          >
            {props.t('sidebar.zones.create')}
            <MdAdd
              style={{
                fontSize: '20',
              }}
            />
          </Flex>
        </ButtonPrimary>
        <Table
          bordered
          dataSource={zones}
          columns={columns}
          pagination={{ pageSize: 5 }}
        />
      </Space>
    </Spin>
  );
};

export default withTranslation('common')(ZoneList);

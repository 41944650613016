import React, { useState } from 'react';
import { Space, Table, notification, Dropdown, Flex } from 'antd';
import { PROFILE } from 'constants/constants';
import { MdFolderZip, MdUndo } from 'react-icons/md';
import moment from 'moment';
import { DownOutlined } from '@ant-design/icons';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import { generateWTPZipFile } from 'repository/violations/ViolationsRepository';
import { BsCheckAll } from 'react-icons/bs';
import { TiExport } from 'react-icons/ti';
import { pendingGaugeControlViolation } from 'repository/gaugeControlViolations/GaugeControlViolarionRepository';
import { HiMiniSignal } from 'react-icons/hi2';
import { TbCalendar } from 'react-icons/tb';

export const GaugeControlViolationsApprove = ({
  data,
  t,
  currentCenter,
  fetchViolations,
  setLoading,
  initialColumns,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [pageSize, setPageSize] = useState(10);

  const columns = [
    {
      title: t('gauge-control-violation.tabs.approve.table.gauge-date'),
      dataIndex: 'eventRead',
      key: 'eventRead',
      align: 'center',
      render: (text, record) => {
        return (
          <>
            <HiMiniSignal
              style={{
                verticalAlign: 'sub',
                fontSize: '20',
                marginRight: '5px',
              }}
            />
            {moment(record.readDate).format('DD-MM-YYYY HH:mm:ss')}
          </>
        );
      },
    },
    {
      title: t('gauge-control-violation.tabs.approve.table.read-date'),
      dataIndex: 'readDate',
      key: 'readDate',
      align: 'center',
      render: (text, record) => {
        return (
          <>
            <TbCalendar
              style={{
                verticalAlign: 'sub',
                fontSize: '20',
                marginRight: '5px',
              }}
            />
            {moment(record.readDate).format('DD-MM-YYYY HH:mm:ss')}
          </>
        );
      },
    },
    {
      title: t('gauge-control-violation.tabs.approve.table.approve-date'),
      dataIndex: 'processedDate',
      key: 'processedDate',
      align: 'center',
      render: (text, record) => {
        return (
          <>
            <BsCheckAll
              style={{
                verticalAlign: 'sub',
                fontSize: '20',
                marginRight: '5px',
              }}
            />
            {moment(record.processedDate).format('DD-MM-YYYY HH:mm:ss')}
          </>
        );
      },
    },
    {
      title: t('zar-violations.tabs.approved.actions'),
      dataIndex: 'acciones',
      width: '200px',
      align: 'center',
      render: (_, record) => {
        return (
          <Flex
            align="center"
            vertical
          >
            <ButtonPrimary
              color="black"
              shape="round"
              size="large"
              style={{
                margin: '5px 0px 5px 0px',
                width: '100%',
              }}
              onClick={() => exportViolations([record.id])}
            >
              {t('zar-violations.tabs.approved.export')}
              <TiExport
                style={{
                  verticalAlign: 'sub',
                  fontSize: '20',
                  marginLeft: '5px',
                }}
              />
            </ButtonPrimary>
            <ButtonPrimary
              color="grey"
              shape="round"
              size="large"
              style={{
                margin: '5px 0px 5px 0px',
                width: '100%',
              }}
              onClick={() => {
                setLoading(true);
                pendingGaugeControlViolation(currentCenter.name, record.id)
                  .then(() => {
                    setSelectedRows([]);
                    fetchViolations('APPROVED');
                    notification['success']({
                      message: 'ZinkinData',
                      description: t('gauge-control-violation.tabs.approve.success-rollback'),
                    });
                    setLoading(false);
                  })
                  .catch(() => {
                    notification['error']({
                      message: 'ZinkinData',
                      description: t('gauge-control-violation.tabs.approve.error-rollback'),
                    });
                  });
              }}
            >
              {t('zar-violations.tabs.approved.rollback')}
              <MdUndo
                style={{
                  verticalAlign: 'sub',
                  fontSize: '20',
                  marginLeft: '5px',
                }}
              />
            </ButtonPrimary>
          </Flex>
        );
      },
    },
  ];

  function exportViolations(ids) {
    setLoading(true);
    var json = {
      violations: ids,
      filename: `${currentCenter.name}_gauge_control_${moment().valueOf()}`,
      type: 'GAUGE_CONTROL',
    };
    generateWTPZipFile(currentCenter.name, json)
      .then(() => {
        const tempLink = document.createElement('a');
        if (PROFILE === 'development') {
          tempLink.href = `https://dev-lprconnect.s3.eu-west-3.amazonaws.com/wtp/${currentCenter.name}/${json.filename}.zip`;
        } else {
          tempLink.href = `https://lprconnect.s3.eu-west-3.amazonaws.com/wtp/${currentCenter.name}/${json.filename}.zip`;
        }
        tempLink.download = json.filename;
        document.body.appendChild(tempLink);
        tempLink.click();
        fetchViolations('APPROVED');
        notification['success']({
          message: 'ZinkinData',
          description: t('zar-violations.put-status-export.success'),
        });
        setLoading(true);
      })
      .catch(() => {
        notification['error']({
          message: 'ZinkinData',
          description: t('zar-violations.generate-wtp'),
        });
      });
  }

  const approveButtons = (
    <Space
      direction="horizontal"
      style={{ float: 'right', marginBottom: '15px' }}
    >
      <ButtonPrimary
        color="black"
        shape="round"
        disabled={selectedRows.length === 0}
        onClick={() => {
          exportViolations(selectedRows.map((item) => item.id));
        }}
      >
        {t('zar-violations.menu.approved.export')}
        <MdFolderZip
          style={{
            verticalAlign: 'sub',
            fontSize: '20',
            marginLeft: '5px',
          }}
        />
      </ButtonPrimary>

      <Dropdown
        menu={{
          items: [
            {
              label: (
                <ButtonPrimary
                  color="black"
                  shape="round"
                  onClick={() => {
                    exportViolations(data.map((item) => item.id));
                  }}
                >
                  {t('zar-violations.menu.approved.export-all')}
                </ButtonPrimary>
              ),
              key: '1',
            },
          ],
        }}
      >
        <ButtonPrimary
          color="white"
          fontColor="black"
          shape="round"
        >
          <Space>
            {t('zar-violations.menu.approved.more-options')}
            <DownOutlined />
          </Space>
        </ButtonPrimary>
      </Dropdown>
    </Space>
  );

  const rowSelection = {
    type: 'checkbox',
    selectedRowKeys: selectedRows.map((item) => item.id),
    onChange: (_, selectedRows) => {
      setSelectedRows(selectedRows);
    },
  };

  return (
    <>
      {approveButtons}
      <Table
        dataSource={data}
        columns={initialColumns.concat(columns)}
        onChange={(e) => setPageSize(e.pageSize)}
        pagination={{ pageSize: pageSize, showSizeChanger: true }}
        rowSelection={rowSelection}
      />
    </>
  );
};

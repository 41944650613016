import React, { useState, useRef, useEffect } from 'react';

import { Modal, Space, Table, Tooltip, notification } from 'antd';

import * as BlacklistReadsRepository from 'repository/blacklist/BlacklistReadsRepository';

import moment from 'moment';
import LicensePlateRender from 'components/LicensePlate/LicensePlateRender';
import { BsCheckAll } from 'react-icons/bs';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import ImageVisor from 'components/ImageVisor';
import colors from 'components/Buttons/colors';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { MdZoomIn } from 'react-icons/md';
import { withTranslation } from 'react-i18next';

const BlackListReadsNoAcknowledge = ({ props }) => {
  const zoomImageRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [readingZoom, setReadingZoom] = useState({});
  const [modalVisible, setModalVisible] = useState(false);

  const columns = [
    {
      title: props.t('blacklist.match.no-acknowledge.table.context-image'),
      dataIndex: 'contextImageName',
      key: 'contextImageName',
      align: 'center',
      render: (_, record) => {
        return (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setReadingZoom(record);
              setModalVisible(true);
            }}
          >
            <ImageVisor
              centername={props.currentCenter.name}
              image={record.contextImageName}
              t={props.t}
              key={record.id}
            />
          </div>
        );
      },
    },
    {
      title: props.t('blacklist.match.no-acknowledge.table.license-plate-image'),
      dataIndex: 'licensePlateImageName',
      key: 'licensePlateImageName',
      align: 'center',
      render: (_, record) => {
        return (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setReadingZoom(record);
              setModalVisible(true);
            }}
          >
            <ImageVisor
              centername={props.currentCenter.name}
              image={record.licensePlateImageName}
              t={props.t}
              key={record.id}
            />
          </div>
        );
      },
    },
    {
      title: props.t('blacklist.match.no-acknowledge.table.license-plate'),
      dataIndex: 'licensePlate',
      key: 'licensePlate',
      align: 'center',
      render: (_, record) => (
        <LicensePlateRender
          key={record.id}
          value={record.licensePlate}
        />
      ),
    },
    {
      title: props.t('blacklist.match.no-acknowledge.table.read-date'),
      dataIndex: 'dataCaptura',
      key: 'dataCaptura',
      align: 'center',
      sorter: (a, b) => moment(a.readDate) - moment(b.readDate),
      render: (_, record) => {
        if (record.readDate !== undefined && record.readDate !== null)
          return moment(record.readDate).format('DD-MM-YYYY HH:mm:ss');
      },
    },
    {
      title: props.t('blacklist.match.no-acknowledge.table.zone'),
      dataIndex: 'zoneName',
      key: 'zoneName',
      align: 'center',
    },
    {
      title: props.t('blacklist.match.no-acknowledge.table.camera'),
      dataIndex: 'cameraName',
      key: 'cameraName',
      align: 'center',
    },
    {
      title: props.t('blacklist.match.no-acknowledge.table.actions.title'),
      dataIndex: 'acciones',
      align: 'center',
      render: (_, record) => {
        return (
          <Space align="center">
            <ButtonPrimary
              color="blue"
              onClick={() => {
                setLoading(true);
                BlacklistReadsRepository.acknowledgeBlacklistRead(props.currentCenter.name, record.id)
                  .then(() => {
                    notification['success']({
                      message: 'ZinkinData',
                      description: props.t('blacklist.match.no-acknowledge.notification.success'),
                    });

                    getAllBlacklistReads();
                  })
                  .catch((error) => {
                    notification['success']({
                      message: 'ZinkinData',
                      description: error.message,
                    });
                    setLoading(false);
                  });
              }}
            >
              <Tooltip
                color={colors.blue.main}
                title={props.t('blacklist.match.no-acknowledge.table.actions.acknowledge')}
              >
                <BsCheckAll style={{ fontSize: '30' }} />
              </Tooltip>
            </ButtonPrimary>
          </Space>
        );
      },
    },
  ];

  useEffect(getAllBlacklistReads, []);

  function getAllBlacklistReads() {
    BlacklistReadsRepository.getAllBlacklistReads(props.currentCenter.name, false)
      .then((response) => {
        setData(response);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }

  return (
    <>
      <Table
        loading={loading}
        bordered
        dataSource={data}
        columns={columns}
        pagination={{ pageSize: 10, showSizeChanger: false }}
      />
      <Modal
        open={modalVisible}
        closable={false}
        onCancel={() => {
          setModalVisible(false);
          setReadingZoom({});
          zoomImageRef.current.resetTransform();
        }}
        cancelButtonProps={{ hidden: true }}
        onOk={() => {
          setModalVisible(false);
          setReadingZoom({});
          zoomImageRef.current.resetTransform();
        }}
      >
        <Space
          direction="vertical"
          align="center"
          style={{ width: '100%' }}
          wrap
        >
          <TransformWrapper ref={zoomImageRef}>
            <TransformComponent>
              <div>
                <ImageVisor
                  centername={props.currentCenter.name}
                  t={props.t}
                  image={readingZoom.contextImageName}
                  isModal
                  key={readingZoom.id}
                />
                <div
                  style={{
                    position: 'absolute',
                    top: '8px',
                    left: '8px',
                  }}
                >
                  <Tooltip
                    placement="bottom"
                    title={props.t('zar-violations.modal-edit.make-zoom')}
                    color={colors.blue.main}
                  >
                    <MdZoomIn style={{ fontSize: '30px', color: 'white' }} />
                  </Tooltip>
                </div>
              </div>
            </TransformComponent>
          </TransformWrapper>

          <ImageVisor
            centername={props.currentCenter.name}
            t={props.t}
            image={readingZoom.licensePlateImageName}
            isModal
            isPlate
            key={readingZoom.id}
          />
        </Space>
      </Modal>
    </>
  );
};

export default withTranslation('common')(BlackListReadsNoAcknowledge);

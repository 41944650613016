import LoginPage from "views/Login/LoginPage.jsx";
import ChangePasswordPage from "views/Login/ChangePasswordPage";

const loginRoutes = [
  {
    path: "/pages/login",
    name: "Login Page",
    component: LoginPage
  },  
  {
    path: "/pages/change-password",
    name: "Change Password Page",
    component: ChangePasswordPage
  }
];

export default loginRoutes;

import React, { useState, useEffect } from 'react';
import {
  Card,
  DatePicker,
  TimePicker,
  Input,
  Button,
  Form,
  Flex,
  ConfigProvider,
  Modal,
  Checkbox,
  notification,
} from 'antd';
import { withTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { getReportSchema, updateReportData, sendReportData } from 'repository/bwcReports/BwcReportsRepository';

const EditBwcReport = ({ currentCenter, currentUser, t, history, location }) => {
  const [schema, setSchema] = useState(null);
  const [formData, setFormData] = useState(null);
  const [recordingDestination, setRecordingDestination] = useState([]);
  const [isRecordingDestinationErrorVisible, setRecordingDestinationErrorVisible] = useState(false);
  const bwcReport = location.state.report;

  useEffect(() => {
    const fetchSchema = async () => {
      try {
        const schemaResponse = await getReportSchema(currentCenter.name);
        setSchema(schemaResponse.dataSchema);
      } catch (error) {
        notification.error({
          message: 'ZinkinData',
          description: t('bwc-reports.edit-report.error-fetching-schema'),
        });
      }
    };
    fetchSchema();

    // ADAPTAR ESTO PARA CUALQUIER FECHA DE CUALQUIER CAMPO
    const initialFormData = location.state?.report.data || {};
    let parsedDate = dayjs(initialFormData.date, 'YYYY-MM-DD');

    if (!parsedDate.isValid()) {
      parsedDate = dayjs(initialFormData.date, 'DD-MM-YYYY');
    }
    const parsedTime = dayjs(initialFormData.time, 'HH:mm:ss');
    setFormData({ ...initialFormData, date: parsedDate, time: parsedTime });
  }, [currentCenter.name, location.state.report.data, t]);

  const renderFormField = (name, property) => {
    switch (property.format) {
      case 'date':
        return (
          <DatePicker
            placeholder={t('bwc-reports.edit-report.choose-date-placeholder')}
            style={{ width: '50%' }}
          />
        );
      case 'time':
        return (
          <TimePicker
            placeholder={t('bwc-reports.edit-report.choose-time-placeholder')}
            style={{ width: '50%' }}
          />
        );
      case 'textInput':
        return (
          <Input
            type="text"
            placeholder={property.description}
            style={{ width: '50%' }}
          />
        );
      case 'textArea':
        return (
          <Input.TextArea
            showCount
            maxLength={800}
          />
        );
      // MIRAR DE ADAPTAR ESTE CAMPO PARA QUE NO ESTE TAN HARDCODEADO
      case 'checkBox': {
        return (
          <Flex
            vertical
            style={{ marginBottom: '20px' }}
          >
            <div style={{ fontWeight: 'bold', margin: '0px 5px 5px 0px' }}>Destinació de l'enregistrament</div>
            <Checkbox.Group
              onChange={(e) => {
                setRecordingDestination(e);
              }}
            >
              <Flex vertical>
                {property.enum.map((x) => {
                  return (
                    <Checkbox
                      key={x}
                      value={x}
                      style={{ fontWeight: '400', marginTop: '5px' }}
                    >
                      {x}
                    </Checkbox>
                  );
                })}
              </Flex>
            </Checkbox.Group>
            {isRecordingDestinationErrorVisible && (
              <div style={{ color: 'red', marginTop: '5px' }}>
                {t('bwc-reports.edit-report.recording-destination-warning')}
              </div>
            )}
          </Flex>
        );
      }
      default:
        notification.error({
          message: 'ZinkinData',
          description: t('bwc-reports.edit-report.error-fetching-form-field', { name }),
        });
    }
  };

  const onFinish = async (values) => {
    let valuesCopy = { ...values, recordingDestination };

    // Validacion del ultimo campo (MIRAR SI SE PUEDE AUTOMATIZAR)
    if (!isValidValue(valuesCopy.recordingDestination, schema.properties.recordingDestination.validValues)) {
      setRecordingDestinationErrorVisible(true);
      return;
    } else {
      setRecordingDestinationErrorVisible(false);
    }

    const formattedValues = {
      ...valuesCopy,
      autoritatJudicial: valuesCopy.recordingDestination.includes('Autoritat judicial') ? 'yes' : 'Off',
      autoritatAdministrativa: valuesCopy.recordingDestination.includes('Autoritat administrativa') ? 'yes' : 'Off',
      destruccioTresMesos: valuesCopy.recordingDestination.includes(
        'Destrucció als TRES mesos des de la data de la gravació'
      )
        ? 'yes'
        : 'Off',
    };

    if (
      !recordingDestination.includes('Autoritat judicial') &&
      !recordingDestination.includes('Autoritat administrativa') &&
      !recordingDestination.includes('Destrucció als TRES mesos des de la data de la gravació')
    ) {
      formattedValues.autoritatJudicial = '';
      formattedValues.autoritatAdministrativa = '';
      formattedValues.destruccioTresMesos = '';
    }

    delete formattedValues.recordingDestination;

    if (formattedValues.date) {
      formattedValues.date = dayjs(formattedValues.date).format('DD-MM-YYYY');
    }

    if (formattedValues.time) {
      formattedValues.time = dayjs(formattedValues.time).format('HH:mm:ss');
    }

    Modal.confirm({
      title: t('bwc-reports.edit-report.send-report-confirmation'),
      okText: t('Ok'),
      cancelText: t('Cancelar'),
      centered: true,
      okButtonProps: {
        className: 'button-primary-props-blue',
        shape: 'round',
      },
      cancelButtonProps: {
        className: 'button-primary-props-white',
        shape: 'round',
      },
      onOk: async () => {
        try {
          await updateReportData(currentCenter.name, formattedValues, bwcReport.id);
          await sendReportData(currentCenter.name, bwcReport.id);
          history.push('bwc-reports');
          notification.success({
            message: 'ZinkinData',
            description: t('bwc-reports.edit-report.send-report-success'),
          });
        } catch (error) {
          console.log('Error: ', error);
          notification.error({
            message: 'ZinkinData',
            description: t('bwc-reports.edit-report.send-report-error'),
          });
        }
      },
    });
  };

  function arraysMatch(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }
    return arr1.every((item) => arr2.includes(item)) && arr2.every((item) => arr1.includes(item));
  }

  const isValidValue = (actualValue, validValuesList) => {
    for (let key in validValuesList) {
      if (arraysMatch(actualValue, validValuesList[key])) {
        return true;
      }
    }
    return false;
  };

  if (!schema) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Card>
        <Flex>
          <ConfigProvider locale={currentUser.getLocale()}>
            <Form
              name="basic"
              initialValues={formData}
              autoComplete="off"
              size="large"
              onFinish={onFinish}
              layout="vertical"
            >
              {Object.keys(schema.properties).map((field) => {
                const { description } = schema.properties[field];
                const isRequired = schema.required.includes(field);
                const rules = isRequired
                  ? [{ required: true, message: t('bwc-reports.edit-report.form-field-required-error') }]
                  : [];

                if (field !== 'recordingDestination') {
                  return (
                    <Form.Item
                      key={field}
                      name={field}
                      label={description}
                      rules={rules}
                    >
                      {renderFormField(field, schema.properties[field])}
                    </Form.Item>
                  );
                } else {
                  return renderFormField(field, schema.properties[field]);
                }
              })}
              <Button
                type="primary"
                htmlType="submit"
              >
                {t('bwc-reports.edit-report.send-report-button')}
              </Button>
            </Form>
          </ConfigProvider>
        </Flex>
      </Card>
    </>
  );
};

export default withTranslation('common')(EditBwcReport);

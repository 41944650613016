import React, { useState } from 'react';
import { Flex, Input, Modal, Radio, Space, Table, notification } from 'antd';
import { PROFILE } from 'constants/constants';
import { MdFolderZip, MdOutlineDangerous } from 'react-icons/md';
import { TiExport } from 'react-icons/ti';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import { putRedLightViolationStatus } from 'repository/redLightViolation/RedLightViolationRepository';
import moment from 'moment';
import { generateWTPZipFile } from 'repository/violations/ViolationsRepository';
import { posibleDeniedReasons } from 'constants/denied_reasons';
import { v4 } from 'uuid';

export const RedLightViolationsApproved = ({ data, t, currentCenter, initialColumns, fetchViolations, setLoading }) => {
  const [pageSize, setPageSize] = useState(10);
  const [selectedRows, setSelectedRows] = useState([]);
  const [deniedReason, setDeniedReason] = useState('');
  const [anotherDeniedReason, setAnotherDeniedReason] = useState('');
  const [deniedModal, setDeniedModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [rowToDeny, setRowToDeny] = useState('');

  const columns = [
    {
      title: t('red-light-violation.tabs.approved.actions'),
      dataIndex: 'acciones',
      width: '150px',
      align: 'center',
      render: (text, record) => {
        return (
          <Flex
            justify="center"
            align="center"
            vertical
          >
            <ButtonPrimary
              color="blue"
              size="large"
              shape="round"
              style={{
                margin: '5px 0px 5px 0px',
                width: '100%',
              }}
              onClick={() => {
                exportViolations([record.id]);
              }}
            >
              {t('red-light-violation.tabs.approved.export')}
              <TiExport
                style={{
                  verticalAlign: 'sub',
                  fontSize: '20',
                  marginLeft: '5px',
                }}
              />
            </ButtonPrimary>
            <ButtonPrimary
              color="black"
              shape="round"
              size="large"
              style={{
                margin: '5px 0px 5px 0px',
                width: '100%',
              }}
              onClick={() => {
                setRowToDeny(record.id);
                setDeniedModal(true);
              }}
            >
              {t('red-light-violation.tabs.approved.deny')}
              <MdOutlineDangerous
                style={{
                  verticalAlign: 'sub',
                  fontSize: '20',
                  marginLeft: '5px',
                }}
              />
            </ButtonPrimary>
          </Flex>
        );
      },
    },
  ];

  function exportViolations(ids) {
    setLoading(true);
    var json = {
      violations: ids,
      filename: `${currentCenter.name}_red_light_${moment().valueOf()}`,
      type: 'RED_LIGHT',
    };
    generateWTPZipFile(currentCenter.name, json)
      .then(() => {
        const tempLink = document.createElement('a');
        if (PROFILE === 'development') {
          tempLink.href = `https://dev-lprconnect.s3.eu-west-3.amazonaws.com/wtp/${currentCenter.name}/${json.filename}.zip`;
        } else {
          tempLink.href = `https://lprconnect.s3.eu-west-3.amazonaws.com/wtp/${currentCenter.name}/${json.filename}.zip`;
        }
        tempLink.download = json.filename;
        document.body.appendChild(tempLink);
        tempLink.click();
        fetchViolations('APPROVED');
        notification['success']({
          message: 'ZinkinData',
          description: t('red-light-violation.export.success'),
        });
        setLoading(true);
      })
      .catch(() => {
        notification['error']({
          message: 'ZinkinData',
          description: t('zar-violations.generate-wtp'),
        });
      });
  }

  const approveButtons = (
    <Space
      direction="horizontal"
      style={{ float: 'right', marginBottom: '15px' }}
    >
      <ButtonPrimary
        color="black"
        shape="round"
        disabled={selectedRows.length === 0}
        onClick={() => {
          exportViolations(selectedRows.map((item) => item.id));
        }}
      >
        {t('red-light-violation.tabs.approved.export-selected')}
        <MdFolderZip
          style={{
            verticalAlign: 'sub',
            fontSize: '20',
            marginLeft: '5px',
          }}
        />
      </ButtonPrimary>

      <ButtonPrimary
        color="black"
        shape="round"
        disabled={!data.length}
        onClick={() => {
          exportViolations(data.map((item) => item.id));
        }}
      >
        {t('red-light-violation.tabs.approved.export-all')}
      </ButtonPrimary>
    </Space>
  );

  const rowSelection = {
    type: 'checkbox',
    selectedRowKeys: selectedRows.map((item) => item.id),
    onChange: (_, selectedRows) => {
      setSelectedRows(selectedRows);
    },
  };

  return (
    <>
      {approveButtons}
      <Table
        bordered
        dataSource={data}
        columns={initialColumns.concat(columns)}
        onChange={(e) => setPageSize(e.pageSize)}
        pagination={{ pageSize: pageSize, showSizeChanger: true }}
        rowSelection={rowSelection}
      />

      <Modal
        title={t('red-light-violation.modal-denied.title')}
        open={deniedModal}
        onOk={() => {
          setModalLoading(true);
          if (deniedReason === '') {
            notification['error']({
              message: 'ZinkinData',
              description: t('red-light-violation.modal-denied.error'),
            });
          } else {
            putRedLightViolationStatus(rowToDeny, currentCenter.name, 'DENIED', deniedReason, anotherDeniedReason)
              .then(() => {
                setDeniedModal(false);
                setDeniedReason('');
                setAnotherDeniedReason('');
                setRowToDeny('');

                notification['success']({
                  message: 'ZinkinData',
                  description: t('red-light-violation.deny.success'),
                });
                setModalLoading(false);
                fetchViolations('APPROVED');
              })
              .catch((error) => {
                setModalLoading(false);
                notification['error']({
                  message: 'ZinkinData',
                  description: t(error.message),
                });
              });
          }
        }}
        onCancel={() => setDeniedModal(false)}
        okText={t('red-light-violation.modal-denied.confirm')}
        cancelText={t('red-light-violation.modal-denied.cancel')}
        okButtonProps={{ className: 'button-primary-props-blue', shape: 'round', loading: modalLoading }}
        cancelButtonProps={{ className: 'button-primary-props-white', shape: 'round' }}
      >
        <Radio.Group
          onChange={(e) => setDeniedReason(e.target.value)}
          style={{ marginTop: 10 }}
          size={'large'}
        >
          <Space direction="vertical">
            {posibleDeniedReasons.map((reason) => {
              return (
                <Radio
                  key={v4()}
                  value={reason.name}
                  style={{ fontSize: 15, fontWeight: 'normal' }}
                >
                  {t(reason.t)}
                </Radio>
              );
            })}
          </Space>
        </Radio.Group>
        {deniedReason === 'ANOTHER' ? (
          <Input.TextArea
            type="text"
            rows={4}
            value={anotherDeniedReason}
            onChange={(event) => setAnotherDeniedReason(event.target.value)}
            style={{ marginTop: 5, resize: 'none' }}
          />
        ) : null}
      </Modal>
    </>
  );
};

import React from "react";
import { CSVLink } from "react-csv";
import { withTranslation } from "react-i18next";
import { FaFileCsv } from "react-icons/fa";
import ButtonPrimary from "components/Buttons/ButtonPrimary";

const CreateCSV = ({ data, t }) => {
  return (
    <CSVLink filename={"report.csv"} data={data} separator={":"}>
      <ButtonPrimary color="black" size="middle" style={{ width: "100%" }}>
        <FaFileCsv
          style={{
            verticalAlign: "sub",
            fontSize: "20px",
            marginRight: "5px",
          }}
        />
        {t("create-csv.button")}
      </ButtonPrimary>
    </CSVLink>
  );
};

export default withTranslation("common")(CreateCSV);

import React, { useState, useEffect } from 'react';
import { Spin, Flex, Space } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import { MdAdd } from 'react-icons/md';
import { getAllCamaras } from '../../../repository/cameras/CamerasRepository';
import { CameraTable } from './CameraTable';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import { CameraMap } from './CameraMap';

const CameraList = ({ t, currentUser, currentCenter, history }) => {
  const [cameras, setCameras] = useState([]);
  const [loading, setLoading] = useState(false);
  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 24 }}
      spin
    />
  );

  useEffect(fetchCameras, []);

  function fetchCameras() {
    getAllCamaras(currentCenter.name)
      .then((response) => {
        setCameras(
          response.map((camera, key) => ({
            key: camera.id,
            identifier: key,
            id: camera.id,
            name: camera.name,
            ip: camera.ip,
            marca: camera.marca,
            numeroSerie: camera.numeroSerie,
            zone: camera.zone,
            location: camera.location,
            latitude: camera.latitude,
            longitude: camera.longitude,
            correctDirection: camera.correctDirectionType,
            infractionTypes: camera.infractionTypes,
            apiKey: camera.apiKey,
          }))
        );
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function editCamera(
    name,
    numeroSerie,
    id,
    ip,
    marca,
    zone,
    location,
    latitude,
    longitude,
    correctDirection,
    infractionTypes
  ) {
    history.push({
      pathname: '/camera-edit',
      state: {
        id,
        name,
        ip,
        numeroSerie,
        marca,
        zone,
        location,
        latitude,
        longitude,
        correctDirectionType: correctDirection,
        infractionTypes,
      },
    });
  }

  return (
    <>
      <Spin
        spinning={loading}
        indicator={antIcon}
        style={{ fontSize: 20 }}
      >
        <Space
          direction="vertical"
          style={{ width: '100%' }}
          size={'middle'}
        >
          {currentUser.role === 'ROLE_ADMIN' || currentUser.role === 'ROLE_SUPERADMIN' ? (
            <ButtonPrimary
              color="blue"
              shape="round"
              size="large"
              onClick={() => history.push('/camera-create')}
              style={{ width: '15%' }}
            >
              <Flex
                align="center"
                gap={'small'}
              >
                {t('sidebar.cameras.create')}
                <MdAdd style={{ fontSize: '20' }} />
              </Flex>
            </ButtonPrimary>
          ) : (
            <></>
          )}
          <CameraMap
            cameras={cameras}
            center={currentCenter}
          />
          <CameraTable
            history={history}
            data={cameras}
            t={t}
            onClickEdit={editCamera}
            centerName={currentCenter.name}
            fetchCameras={fetchCameras}
            currentUser={currentUser}
          />
        </Space>
      </Spin>
    </>
  );
};

export default withTranslation('common')(CameraList);

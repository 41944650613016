import { Layout } from 'antd';
import CustomFooter from 'components/Footer/CustomFooter';

import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { dashboardRoutes } from 'routes/dashboardRoutes';

import { withTranslation } from 'react-i18next';
import CustomSidebar from 'components/Sidebar/CustomSidebar';
import CustomHeader from 'components/Header/CustomHeader';

const { Content } = Layout;

const Dashboard = (props) => {
  const [collapse, setCollapse] = useState(false);

  const renderMainContent = () => {
    return (
      <Switch>
        {dashboardRoutes.map((section, index) => {
          if (section.collapse) {
            return section.views.map((subsection, subIndex) => (
              <Route
                path={subsection.path}
                key={subIndex}
                render={(routeProps) => (
                  <subsection.component
                    handleLogout={props.handleLogout}
                    currentUser={props.currentUser}
                    currentCenter={props.currentCenter}
                    {...routeProps}
                  />
                )}
              />
            ));
          }

          return (
            <Route
              path={section.path}
              key={index}
              render={(routeProps) => (
                <section.component
                  {...routeProps}
                  currentUser={props.currentUser}
                  currentCenter={props.currentCenter}
                  handleLogout={props.handleLogout}
                />
              )}
            />
          );
        })}
      </Switch>
    );
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <CustomSidebar
        collapse={collapse}
        setCollapse={setCollapse}
        {...props}
      />
      <Layout style={{ marginLeft: collapse ? '0px' : '274px' }}>
        <CustomHeader
          setCollapse={setCollapse}
          collapse={collapse}
          {...props}
        />
        <Content
          style={{
            margin: '16px 16px',
            overflow: 'initial',
          }}
        >
          <div style={{ padding: 24, height: 'auto', backgroundColor: '#fff', borderRadius: '10px' }}>
            {renderMainContent()}
          </div>
        </Content>
        <CustomFooter />
      </Layout>
    </Layout>
  );
};

export default withTranslation('common')(Dashboard);

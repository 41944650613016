import React, { useState, useEffect, useRef } from 'react';
import {
  ConfigProvider,
  DatePicker,
  Input,
  Spin,
  Tabs,
  Modal,
  Space,
  notification,
  Tooltip,
  Row,
  Col,
  Select,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { MdRestartAlt, MdSearch, MdZoomIn } from 'react-icons/md';
import { getAllViolationsFilteredBy, putViolationLicensePlate } from 'repository/zarViolations/ZarViolationsRepository';
import dayjs from 'dayjs';
import LicensePlateRender from 'components/LicensePlate/LicensePlateRender';
import { ZarViolationsPending } from './components/ZarViolationsPending';
import { ZarViolationsApproved } from './components/ZarViolationsApproved';
import { ZarViolationsDenied } from './components/ZarViolationsDenied';
import { ZarViolationsExported } from './components/ZarViolationsExported';
import { ZarViolationsExpired } from './components/ZarViolationsExpired';
import { ZarViolationsExportedFiles } from './components/ZarViolationsExportedFiles';
import { ZarViolationsVisible } from './components/ZarViolationsVisible';
import { getAllExportDoneByCenter } from 'repository/exportDone/ExportDoneRepository';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import colors from 'components/Buttons/colors';
import ImageVisor from 'components/ImageVisor';
import { getZarZonesFromCenter } from 'repository/zone/ZoneRepository';
import AccessDenied from 'views/AccessDenied/AccessDenied';

const ZarViolations = ({ t, currentUser, currentCenter }) => {
  const [violations, setViolations] = useState([]);
  const [exportFiles, setExportFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dateForm, setDateForm] = useState('');
  const [licensePlateForm, setLicensePlateForm] = useState('');
  const [activeTab, setActiveTab] = useState('PENDING');
  const [zarZones, setZarZones] = useState([]);
  const [zoneId, setZoneId] = useState(undefined);
  const [licensePlateEdit, setLicensePlateEdit] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [countData, setCountData] = useState(0);
  const zoomImageRef = useRef(null);
  const columns = [
    {
      title: t('zar-violations.table.context-image'),
      dataIndex: 'contextImage',
      key: 'contextImage',
      align: 'center',
      render: (text, record) => {
        return (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setLicensePlateEdit(record);
              setModalVisible(true);
            }}
          >
            <ImageVisor
              centername={currentCenter.name}
              image={record.contextImage}
              t={t}
              key={record.id}
            />
          </div>
        );
      },
    },
    {
      title: t('zar-violations.table.license-plate-image'),
      dataIndex: 'licensePlateImage',
      key: 'licensePlateImage',
      align: 'center',
      render: (text, record) => {
        return (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setLicensePlateEdit(record);
              setModalVisible(true);
            }}
          >
            <ImageVisor
              centername={currentCenter.name}
              image={record.licensePlateImage}
              t={t}
              isPlate
              key={record.id}
            />
          </div>
        );
      },
    },
    {
      title: t('zar-violations.table.license-plate'),
      dataIndex: 'licensePlate',
      key: 'licensePlate',
      align: 'center',
      sorter: (a, b) => a.licensePlate.localeCompare(b.licensePlate),
      render: (text, record) => <LicensePlateRender value={text} />,
    },
    {
      title: t('zar-violations.table.read-date'),
      dataIndex: 'dataCaptura',
      key: 'dataCaptura',
      align: 'center',
      sorter: (a, b) => moment(a.readDate) - moment(b.readDate),
      render: (text, record) => {
        if (record.readDate !== undefined && record.readDate !== null)
          return moment(record.readDate).format('DD-MM-YYYY HH:mm:ss');
      },
    },
    {
      title: t('zar-violations.table.zone'),
      dataIndex: 'zone',
      key: 'zone',
      align: 'center',
    },
    {
      title: t('zar-violations.table.camera'),
      dataIndex: 'camera',
      key: 'camera',
      align: 'center',
    },
  ];

  useEffect(fetchViolations, []);

  useEffect(fetchZarZones, []);

  function fetchZarZones() {
    getZarZonesFromCenter(currentCenter.name)
      .then((response) => {
        setZarZones(
          response.map((zone, _) => ({
            value: zone.id,
            label: zone.name,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function fetchViolations(status = 'PENDING') {
    if (status !== 'EXPORTED_FILES') {
      getAllViolationsFilteredBy(currentCenter.name, status, dateForm, licensePlateForm, zoneId)
        .then((response) => {
          setViolations(
            response.map((violation, key) => ({
              key: violation.id,
              ...violation,
            }))
          );
          setExportFiles([]);
          setCountData(response.length);
          setLoading(false);
        })
        .catch((error) => {
          notification['error']({
            message: 'ZinkinData',
            description: t('zar-violations.get-all-error'),
          });
        });
    } else {
      getAllExportDoneByCenter(currentCenter.name, 'ZAR')
        .then((response) => {
          setExportFiles(
            response.map((exportedFile) => ({
              key: exportedFile.id,
              ...exportedFile,
            }))
          );
          setViolations([]);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  function editLicensePlateRead() {
    setLoading(true);

    putViolationLicensePlate(licensePlateEdit.id, currentCenter.name, licensePlateEdit.licensePlate)
      .then(() => {
        setLicensePlateEdit({});
        fetchViolations(activeTab);
        setLoading(false);
        notification['success']({
          message: 'ZinkinData',
          description: t('zar-violations.edit-plate.success'),
        });
      })
      .catch((error) => {
        notification['error']({
          message: 'ZinkinData',
          description: t('zar-violations.edit-plate.error'),
        });
      });
  }

  const tabs = [
    {
      key: 'VISIBLE',
      label:
        t('zar-violations.tabs.visible.title') +
        (activeTab === 'VISIBLE' ? (countData === 100 ? ` (+99)` : ` (${countData})`) : ''),
      children: (
        <ZarViolationsVisible
          data={violations}
          initialColumns={columns}
        />
      ),
    },
    {
      key: 'PENDING',
      label:
        t('zar-violations.tabs.pending.title') +
        (activeTab === 'PENDING' ? (countData === 100 ? ` (+99)` : ` (${countData})`) : ''),
      children: (
        <ZarViolationsPending
          t={t}
          data={violations}
          currentCenter={currentCenter}
          setLoading={setLoading}
          initialColumns={columns}
          fetchViolations={fetchViolations}
        />
      ),
    },
    {
      key: 'APPROVED',
      label:
        t('zar-violations.tabs.approved.title') +
        (activeTab === 'APPROVED' ? (countData === 100 ? ` (+99)` : ` (${countData})`) : ''),
      children: (
        <ZarViolationsApproved
          t={t}
          data={violations}
          currentCenter={currentCenter}
          initialColumns={columns}
          fetchViolations={fetchViolations}
          setLoading={setLoading}
        />
      ),
    },
    {
      key: 'DENIED',
      label:
        t('zar-violations.tabs.denied.title') +
        (activeTab === 'DENIED' ? (countData === 100 ? ` (+99)` : ` (${countData})`) : ''),
      children: (
        <ZarViolationsDenied
          t={t}
          data={violations}
          currentCenter={currentCenter}
          initialColumns={columns}
          fetchViolations={fetchViolations}
          setLoading={setLoading}
        />
      ),
    },
    {
      key: 'EXPORTED',
      label:
        t('zar-violations.tabs.exported.title') +
        (activeTab === 'EXPORTED' ? (countData === 100 ? ` (+99)` : ` (${countData})`) : ''),
      children: (
        <ZarViolationsExported
          t={t}
          data={violations}
          initialColumns={columns}
        />
      ),
    },
    {
      key: 'EXPIRED',
      label:
        t('zar-violations.tabs.expired.title') +
        (activeTab === 'EXPIRED' ? (countData === 100 ? ` (+99)` : ` (${countData})`) : ''),
      children: (
        <ZarViolationsExpired
          t={t}
          data={violations}
          initialColumns={columns}
        />
      ),
    },
    {
      key: 'EXPORTED_FILES',
      label: t('zar-violations.tabs.done.title'),
      children: (
        <ZarViolationsExportedFiles
          t={t}
          currentCenter={currentCenter}
          data={exportFiles}
        />
      ),
    },
  ];

  if (!currentUser.isAdmin() && !currentUser.isBasicUser()) {
    return <AccessDenied t={t} />;
  }

  return (
    <>
      <Spin
        spinning={loading}
        indicator={
          <LoadingOutlined
            style={{ fontSize: 24 }}
            spin
          />
        }
        style={{ fontSize: 20 }}
      >
        <Row
          gutter={16}
          style={{ marginBottom: 20 }}
        >
          <Col span={4}>
            <ConfigProvider locale={currentUser.getLocale()}>
              <DatePicker
                style={{ width: '100%' }}
                size="large"
                onChange={(event) => {
                  setDateForm(moment(event.$d).format('YYYY-MM-DD'));
                }}
                format={'DD/MM/YYYY'}
                value={dateForm !== '' ? dayjs(dateForm) : null}
              ></DatePicker>
            </ConfigProvider>
          </Col>
          <Col span={4}>
            <Select
              size="large"
              value={zoneId}
              placeholder={t('zar-violations.table.zone')}
              options={zarZones}
              onChange={(event) => {
                setZoneId(event);
              }}
            />
          </Col>
          <Col span={4}>
            <Input
              size="large"
              value={licensePlateForm}
              placeholder={t('readings.filters.license-plate')}
              name="text"
              onChange={(event) => {
                setLicensePlateForm(event.target.value);
              }}
            />
          </Col>
          <Col span={4}>
            <ButtonPrimary
              color="blue"
              shape="round"
              with="link"
              size="large"
              style={{ width: '100%' }}
              onClick={() => fetchViolations(activeTab)}
            >
              {t('readings.search-button')}
              <MdSearch
                style={{
                  verticalAlign: 'sub',
                  fontSize: '20',
                  marginLeft: '5px',
                }}
              />
            </ButtonPrimary>
          </Col>
          <Col span={4}>
            <ButtonPrimary
              color="black"
              shape="round"
              size="large"
              style={{ width: '100%' }}
              onClick={async () => {
                setLoading(true);
                setDateForm('');
                setLicensePlateForm('');
                setZoneId(undefined);
                if (activeTab !== 'EXPORTED_FILES') {
                  getAllViolationsFilteredBy(currentCenter.name, activeTab, undefined, undefined)
                    .then((response) => {
                      setViolations(
                        response.map((violation, key) => ({
                          key: violation.id,
                          ...violation,
                        }))
                      );
                      setExportFiles([]);
                      setCountData(response.length);
                      setLoading(false);
                    })
                    .catch((error) => {
                      notification['error']({
                        message: 'ZinkinData',
                        description: t('zar-violations.get-all-error'),
                      });
                    });
                } else {
                  getAllExportDoneByCenter(currentCenter.name, 'ZAR')
                    .then((response) => {
                      setExportFiles(
                        response.map((exportedFile) => ({
                          key: exportedFile.id,
                          ...exportedFile,
                        }))
                      );
                      setViolations([]);
                      setLoading(false);
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
              }}
            >
              {t('readings.reset-button')}
              <MdRestartAlt
                style={{
                  verticalAlign: 'sub',
                  fontSize: '20',
                  marginLeft: '5px',
                }}
              />
            </ButtonPrimary>
          </Col>
        </Row>

        <Tabs
          defaultActiveKey={activeTab}
          items={tabs}
          type="card"
          onChange={(key) => {
            setLoading(true);
            if (key !== activeTab) {
              setActiveTab(key);
              fetchViolations(key);
            }
          }}
        />
      </Spin>

      <Modal
        title={t('zar-violations.modal-edit.title')}
        open={modalVisible}
        onOk={() => {
          zoomImageRef.current.resetTransform();
          setModalVisible(false);
          editLicensePlateRead();
        }}
        onCancel={() => {
          zoomImageRef.current.resetTransform();
          setModalVisible(false);
          setLicensePlateEdit({});
        }}
        okText={t('zar-violations.modal-edit.confirm')}
        cancelText={t('zar-violations.modal-edit.cancel')}
        okButtonProps={{ style: { backgroundColor: colors.blue.main } }}
      >
        <Space
          direction="vertical"
          align="center"
          style={{ width: '100%' }}
          wrap
        >
          <TransformWrapper ref={zoomImageRef}>
            <TransformComponent>
              <div>
                <ImageVisor
                  centername={currentCenter.name}
                  t={t}
                  image={licensePlateEdit.contextImage}
                  isModal
                  key={licensePlateEdit.id}
                />
                <div
                  style={{
                    position: 'absolute',
                    top: '8px',
                    left: '8px',
                  }}
                >
                  <Tooltip
                    placement="bottom"
                    title={t('zar-violations.modal-edit.make-zoom')}
                    color={colors.blue.main}
                  >
                    <MdZoomIn style={{ fontSize: '30px', color: 'white' }} />
                  </Tooltip>
                </div>
              </div>
            </TransformComponent>
          </TransformWrapper>

          <ImageVisor
            centername={currentCenter.name}
            t={t}
            image={licensePlateEdit.licensePlateImage}
            isModal
            isPlate
            key={licensePlateEdit.id}
          />
          <Input
            size="large"
            style={{ width: '100%' }}
            value={licensePlateEdit.licensePlate}
            onChange={(event) => {
              setLicensePlateEdit({ ...licensePlateEdit, licensePlate: event.target.value });
            }}
          />
        </Space>
      </Modal>
    </>
  );
};

export default withTranslation('common')(ZarViolations);

import React, { useState } from 'react';
import { Tooltip, Image } from 'antd';
import { withTranslation } from 'react-i18next';
import { PROFILE } from 'constants/constants';
import NO_IMAGE from 'assets/img/no_image.png';
import colors from './Buttons/colors';

const ImageVisor = ({ t, centername, image, isModal = false, isPlate = false, key }) => {
  const [isValidUrl, setIsValidUrl] = useState(true);

  return (
    <>
      {isValidUrl && image !== null ? (
        <Image
          width={'100%'}
          style={
            isModal
              ? isPlate
                ? { borderRadius: 3, width: 400 }
                : { borderRadius: 3, maxHeight: 450 }
              : { borderRadius: 3, maxHeight: 175, maxWidth: 175 }
          }
          src={
            PROFILE === 'development'
              ? `https://dev-lprconnect.s3.eu-west-3.amazonaws.com/images/${centername}/${image}`
              : `https://lprconnect.s3.eu-west-3.amazonaws.com/images/${centername}/${image}`
          }
          preview={false}
          onError={() => {
            setIsValidUrl(false);
          }}
          key={`${key}_1`}
        />
      ) : (
        <Tooltip
          title={t('image.failed-message')}
          color={colors.blue.main}
        >
          <Image
            width={'100%'}
            style={
              isModal
                ? { borderRadius: 3, maxHeight: 450, maxWidth: 450 }
                : { borderRadius: 3, maxHeight: 150, maxWidth: 150 }
            }
            src={NO_IMAGE}
            preview={false}
            key={`${key}_2`}
          />
        </Tooltip>
      )}
    </>
  );
};

export default withTranslation('common')(ImageVisor);

import React, { useEffect, useState } from 'react';
import { getIcon } from 'routes/dashboardRoutes.jsx';
import { withTranslation } from 'react-i18next';
import { RiMenuFoldLine, RiMenuUnfoldLine } from 'react-icons/ri';

import { Button, Layout, Grid, Tooltip, notification, Typography } from 'antd';
import { LuLogOut } from 'react-icons/lu';
import colors from 'components/Buttons/colors.js';
const { Header } = Layout;
const { useBreakpoint } = Grid;

const CustomHeader = ({ collapse, setCollapse, ...props }) => {
  const [section, setSection] = useState({});
  const screens = useBreakpoint();

  const getSection = () => {
    setSection(getIcon(props.location.pathname));
  };

  useEffect(getSection, [props.location.pathname]);

  return (
    <Header
      style={{
        padding: 0,
        background: 'white',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Button
        type="text"
        icon={collapse ? <RiMenuUnfoldLine /> : <RiMenuFoldLine />}
        onClick={() => setCollapse(!collapse)}
        hidden={screens.lg}
        style={{
          fontSize: '20px',
          width: 64,
          height: 64,
        }}
      />
      {(section.icon !== undefined && collapse) || screens.lg ? (
        <section.icon style={{ fontSize: '25px', color: '#777', marginLeft: 15 }}></section.icon>
      ) : (
        <></>
      )}
      <Typography.Title
        level={3}
        style={{
          margin: 10,
          color: '#777',
        }}
        hidden={!collapse && !screens.lg}
      >
        {props.t(section.name)}
      </Typography.Title>

      <Tooltip
        title={props.t('sidebar.exit')}
        color={colors.blue.main}
        placement="left"
      >
        <Button
          type="text"
          icon={<LuLogOut />}
          onClick={() => {
            notification.success({
              message: 'ZinkinData',
              description: props.t('sidebar.exit-message'),
            });
            props.handleLogout();
          }}
          style={{
            fontSize: '20px',
            width: 64,
            height: 64,
            marginLeft: 'auto',
          }}
        />
      </Tooltip>
    </Header>
  );
};

export default withTranslation('common')(CustomHeader);

import React, { useState, useEffect } from 'react';
import {
  ConfigProvider,
  DatePicker,
  Spin,
  Tabs,
  Grid,
  Card,
  Flex,
  Input,
  Modal,
  Form,
  Space,
  Button,
  Tag,
  Alert,
  notification,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { MdRestartAlt, MdSearch } from 'react-icons/md';
import { LuFileText, LuCamera, LuUser, LuCalendarClock, LuMailCheck, LuMailX, LuSettings } from 'react-icons/lu';
import dayjs from 'dayjs';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import AccessDenied from 'views/AccessDenied/AccessDenied';
import { BwcReportsComplete } from './components/BwcReportsComplete';
import { BwcReportsPending } from './components/BwcReportsPending';
import BwcReportsIncomplete from './components/BwcReportsIncomplete';
import {
  getAllReportsFromCenter,
  getBodyCamDestinations,
  getReportsFromCurrentUser,
  updateBodyCamDestinations,
} from 'repository/bwcReports/BwcReportsRepository';
import { BwcReportsUnintentional } from './components/BwcReportsUnintentional';

const { useBreakpoint } = Grid;

const BwcReports = ({ t, history, currentUser, currentCenter }) => {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dateForm, setDateForm] = useState('');
  const [username, setUsername] = useState(undefined);
  const [activeTab, setActiveTab] = useState('PENDING');
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [emailInput, setEmailInput] = useState('');
  const [emailList, setEmailList] = useState([]);

  const screens = useBreakpoint();

  const columns = [
    {
      title: 'Nom de la grabacio',
      dataIndex: 'name',
      key: 'name',
      width: '500px',
      render: (_, record) => {
        return (
          <Flex gap="small">
            <LuFileText style={{ fontSize: '20px' }} />
            <strong>{record.clip.incidentName}</strong>
          </Flex>
        );
      },
    },
    {
      title: 'Data de creacio',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      render: (_, record) => {
        return (
          <Flex
            gap="small"
            justify="center"
          >
            <LuCalendarClock style={{ fontSize: '20px' }} />
            {moment(record?.createdAt).format('DD-MM-YYYY HH:mm:ss')}
          </Flex>
        );
      },
    },
    {
      title: 'Data de la grabacio',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      render: (_, record) => {
        return (
          <Flex
            gap="small"
            justify="center"
          >
            <LuCamera style={{ fontSize: '20px' }} />
            {moment(record?.clip?.startDate).format('DD-MM-YYYY HH:mm:ss')}
          </Flex>
        );
      },
    },
    {
      title: 'Usuari',
      dataIndex: 'username',
      key: 'username',
      align: 'center',
      sorter: (a, b) => a.username.localeCompare(b.username),
      render: (_, record) => {
        return (
          <Flex
            justify="center"
            gap="small"
          >
            <LuUser style={{ fontSize: '20px' }} />
            {record.username}
          </Flex>
        );
      },
    },
    {
      title: 'Enviat',
      dataIndex: 'sent',
      key: 'sent',
      align: 'center',
      filters: [
        { text: 'Enviat', value: true },
        { text: 'No Enviat', value: false },
      ],
      onFilter: (value, record) => record.sent === value,
      render: (_, record) => {
        if (record.sent === true) {
          return <LuMailCheck style={{ color: 'green', fontSize: '20px' }} />;
        } else {
          return <LuMailX style={{ color: 'red', fontSize: '20px' }} />;
        }
      },
    },
  ];

  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 24 }}
      spin
    />
  );

  useEffect(() => {
    fetchReports();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchReports = async (status = 'PENDING') => {
    setLoading(true);

    const fetchReportsHelper = async (fetchFunction) => {
      try {
        const response = await fetchFunction(currentCenter.name, status, dateForm);
        setReports(response.map((report) => ({ ...report, key: report.id })));
      } catch (error) {
        notification.error({
          message: 'ZinkinData',
          description: t('bwc-reports.error-fetching-reports'),
        });
      } finally {
        setLoading(false);
      }
    };

    if (currentUser.isAdmin()) {
      await fetchReportsHelper((centerName, status, dateForm) =>
        getAllReportsFromCenter(centerName, status, username, dateForm)
      );
    } else {
      await fetchReportsHelper(getReportsFromCurrentUser);
    }
  };

  function fetchReportDestinations() {
    getBodyCamDestinations(currentCenter.name)
      .then((response) => {
        setEmailList(response.destinations);
      })
      .catch((error) => {
        notification.error({
          message: 'ZinkinData',
          description: t('bwc-reports.error-fetching-mails'),
        });
      });
  }

  const tabs = [
    {
      key: 'PENDING',
      label: 'Pendents',
      children: (
        <BwcReportsPending
          data={reports}
          t={t}
          currentUser={currentUser}
          currentCenter={currentCenter}
          history={history}
          initialColumns={columns}
          fetchReports={fetchReports}
          setLoading={setLoading}
        />
      ),
    },
    {
      key: 'INCOMPLETE',
      label: 'Incomplets',
      children: (
        <BwcReportsIncomplete
          data={reports}
          t={t}
          currentUser={currentUser}
          currentCenter={currentCenter}
          history={history}
          initialColumns={columns}
          setLoading={setLoading}
        />
      ),
    },
    {
      key: 'COMPLETE',
      label: 'Completats',
      children: (
        <BwcReportsComplete
          data={reports}
          t={t}
          currentUser={currentUser}
          currentCenter={currentCenter}
          history={history}
          initialColumns={columns}
          setLoading={setLoading}
        />
      ),
    },
    {
      key: 'UNINTENTIONAL',
      label: 'Involuntaries',
      children: (
        <BwcReportsUnintentional
          data={reports}
          t={t}
          currentUser={currentUser}
          currentCenter={currentCenter}
          history={history}
          initialColumns={columns}
          setLoading={setLoading}
          fetchReports={fetchReports}
        />
      ),
    },
  ];

  const showModal = () => {
    fetchReportDestinations();
    setShowEmailModal(true);
  };

  const handleModalCancel = () => {
    setEmailInput('');
    setShowEmailModal(false);
  };

  const handleAddEmail = () => {
    setEmailList([...emailList, emailInput]);
  };

  const handleRemoveEmail = (emailToRemove) => {
    setEmailList(emailList.filter((email) => email !== emailToRemove));
  };

  if (!currentUser.isAdmin() && !currentUser.isBasicUser()) {
    return <AccessDenied t={t} />;
  }

  const modalFooter = (
    <Space>
      <Button onClick={handleModalCancel}>Cancelar</Button>
      <Button
        type="primary"
        onClick={() => {
          updateBodyCamDestinations(currentCenter.name, emailList)
            .then((response) => {
              notification.success({
                message: 'ZinkinData',
                description: t('bwc-reports.success-saving-changes'),
              });
            })
            .catch((error) => {
              notification.error({
                message: 'ZinkinData',
                description: t('bwc-reports.error-updating-mails'),
              });
            });

          setShowEmailModal(false);
        }}
      >
        Guardar
      </Button>
    </Space>
  );

  return (
    <>
      <Spin
        spinning={loading}
        indicator={antIcon}
        style={{ fontSize: 20 }}
      >
        <Card>
          <Flex
            gap="small"
            vertical={!screens.lg ? true : false}
            style={{ marginBottom: '20px', width: '100%', justifyContent: 'space-between' }}
          >
            <Flex
              gap="small"
              vertical={!screens.lg ? true : false}
            >
              <ConfigProvider locale={currentUser.getLocale()}>
                <DatePicker
                  placeholder="Data de la grabacio"
                  style={{ width: '300px' }}
                  size="large"
                  onChange={(event) => {
                    if (event !== null) {
                      setDateForm(moment(event.$d).format('YYYY-MM-DD'));
                    } else {
                      setDateForm('');
                    }
                  }}
                  format={'DD/MM/YYYY'}
                  value={dateForm !== '' ? dayjs(dateForm) : null}
                ></DatePicker>
              </ConfigProvider>

              {currentUser.isAdmin() && (
                <Input
                  size="large"
                  placeholder={t('bwc-reports.input-username-placeholder')}
                  style={{ width: '300px' }}
                  value={username}
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                />
              )}
              <ButtonPrimary
                color="blue"
                shape="round"
                with="link"
                size="large"
                style={{ width: '200px' }}
                onClick={() => fetchReports(activeTab)}
              >
                {t('bwc-reports.search-button')}
                <MdSearch
                  style={{
                    verticalAlign: 'sub',
                    fontSize: '20',
                    marginLeft: '5px',
                  }}
                />
              </ButtonPrimary>
              <ButtonPrimary
                color="black"
                shape="round"
                size="large"
                style={{ width: '200px' }}
                onClick={async () => {
                  setLoading(true);
                  setDateForm('');
                  fetchReports(activeTab);
                }}
              >
                {t('bwc-reports.reset-button')}
                <MdRestartAlt
                  style={{
                    verticalAlign: 'sub',
                    fontSize: '20',
                    marginLeft: '5px',
                  }}
                />
              </ButtonPrimary>
            </Flex>

            {currentUser.isAdmin() && (
              <ButtonPrimary
                color="black"
                shape="round"
                size="large"
                style={{ width: '300px' }}
                onClick={showModal}
              >
                {t('bwc-reports.configure-emails-button')}
                <LuSettings
                  style={{
                    verticalAlign: 'sub',
                    fontSize: '20',
                    marginLeft: '5px',
                  }}
                />
              </ButtonPrimary>
            )}
          </Flex>

          <Tabs
            defaultActiveKey={activeTab}
            items={tabs}
            type="card"
            onChange={(key) => {
              setLoading(true);
              if (key !== activeTab) {
                setActiveTab(key);
                fetchReports(key);
              }
            }}
          />
        </Card>
      </Spin>
      {/* Email Configuration Modal */}
      <Modal
        title={t('bwc-reports.configure-emails-modal-title')}
        open={showEmailModal}
        onCancel={handleModalCancel}
        footer={modalFooter}
      >
        <Alert
          message={t('bwc-reports.info-email-destinations')}
          type="info"
          style={{ marginBottom: '16px' }}
        />
        <Form layout="vertical">
          <Form.Item>
            <Flex>
              <Input
                value={emailInput}
                onChange={(e) => setEmailInput(e.target.value)}
                placeholder={t('bwc-reports.input-email-placeholder')}
                style={{ marginRight: '5px' }}
              />
              <Button
                type="primary"
                onClick={handleAddEmail}
                style={{ float: 'right' }}
              >
                Afegir
              </Button>
            </Flex>
          </Form.Item>
        </Form>
        <div style={{ marginBottom: '16px' }}>
          {emailList.map((email, index) => (
            <Tag
              key={email}
              closable
              onClose={() => handleRemoveEmail(email)}
              style={{ padding: '5px', margin: '5px', fontSize: '14px' }}
            >
              {email}
            </Tag>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default withTranslation('common')(BwcReports);

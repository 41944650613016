import React, { useState } from 'react';
import { Table } from 'antd';
import moment from 'moment';

export const RedLightViolationsExported = ({ data, t, initialColumns }) => {
  const [pageSize, setPageSize] = useState(10);
  const columns = [
    {
      title: t('red-light-violation.table.exported-date'),
      dataIndex: 'exportedDate',
      key: 'exportedDate',
      align: 'center',
      render: (_, record) => {
        if (record.exportedDate !== undefined && record.exportedDate !== null)
          return moment(record.exportedDate).format('DD-MM-YYYY HH:mm:ss');
      },
    },
  ];

  return (
    <>
      <Table
        bordered
        dataSource={data}
        columns={initialColumns.concat(columns)}
        onChange={(e) => setPageSize(e.pageSize)}
        pagination={{ pageSize: pageSize, showSizeChanger: true }}
      />
    </>
  );
};

import React, { useState } from 'react';
import { Table, Flex, notification } from 'antd';
import { PROFILE } from 'constants/constants';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import { LuDownload } from 'react-icons/lu';
import { downloadBodyCamReport } from 'repository/bwcReports/BwcReportsRepository';

export const BwcReportsComplete = ({ data, t, currentCenter, initialColumns, setLoading }) => {
  const [pageSize, setPageSize] = useState(10);

  const triggerDownload = (url, filename) => {
    const tempLink = document.createElement('a');
    tempLink.href = url;
    tempLink.target = '_blank';
    tempLink.download = filename;
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
  };

  const handleDownloadClick = async (recordId, centerName) => {
    setLoading(true);
    try {
      const response = await downloadBodyCamReport(recordId, centerName);
      const filename = response.fileName;

      const DEV_URL = 'https://dev-lprconnect.s3.eu-west-3.amazonaws.com/bwc-reports/';
      const PROD_URL = 'https://lprconnect.s3.eu-west-3.amazonaws.com/bwc-reports/';
      const baseUrl = PROFILE === 'development' ? DEV_URL : PROD_URL;
      const downloadUrl = `${baseUrl}${centerName}/temp/${filename}`;
      triggerDownload(downloadUrl, filename);
    } catch (error) {
      notification.error({
        message: 'ZinkinData',
        description: t('bwc-reports.complete-reports.error-downloading-report'),
      });
    }
    setLoading(false);
  };

  const columns = [
    {
      title: t('bwc-reports.complete-reports.actions'),
      dataIndex: 'acciones',
      width: '200px',
      align: 'center',
      render: (_, record) => {
        return (
          <Flex
            align="center"
            vertical
          >
            <ButtonPrimary
              color="blue"
              shape="round"
              size="large"
              style={{
                margin: '5px 0px 5px 0px',
                width: '100%',
              }}
              onClick={() => handleDownloadClick(record.id, currentCenter.name)}
            >
              <LuDownload
                style={{
                  verticalAlign: 'sub',
                  fontSize: '20',
                  marginRight: '5px',
                }}
              />
              {t('bwc-reports.complete-reports.download-report')}
            </ButtonPrimary>
          </Flex>
        );
      },
    },
  ];

  return (
    <>
      <Table
        dataSource={data}
        columns={initialColumns.concat(columns)}
        onChange={(e) => setPageSize(e.pageSize)}
        pagination={{ pageSize: pageSize, showSizeChanger: true }}
      />
    </>
  );
};
